import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcDropDownMenuComponent} from './ac-dropdown-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {InfraPipesModule} from "../../pipes/infra.pipes.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatMenuModule,
        AcButtonModule,
        AcIconsModule,
        InfraPipesModule
    ],
    declarations: [
        AcDropDownMenuComponent,
    ],
    entryComponents: [
        AcDropDownMenuComponent,
    ],
    exports: [
        AcDropDownMenuComponent,
    ]
})
export class AcDropdownMenuModule {
}
