import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcNumSpinnerComponent} from './ac-num-spinner.component';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule
    ],
    declarations: [
        AcNumSpinnerComponent,
    ],
    entryComponents: [
        AcNumSpinnerComponent,
    ],
    exports: [
        AcNumSpinnerComponent,
    ]
})
export class AcNumSpinnerModule {
}
