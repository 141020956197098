import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';


@Injectable({providedIn: 'root'})
export class CombinedNetworkRestService extends Rest {

    constructor() {
        super({});
    }

    public setNetworkCoordinates = (success, failure, shapesToMove) => {
        const sentObject = {entities: shapesToMove.map(this.shapesMapper)};
        this.put(success, failure, sentObject, 'topology/actions/saveLocations');
    };

    private shapesMapper = (shape) => ({
        id: shape.id,
        location: {
            x: shape.x,
            y: shape.y,
            latitude: shape.lat,
            longitude: shape.lon
        }
    });

}
