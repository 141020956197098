import {Component, EventEmitter, HostBinding, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';

@Component({
    selector: 'ac-button',
    templateUrl: './ac-button.component.html',
    styleUrls: ['./ac-button.component.less'],
})
export class AcButtonComponent {
    @Output() acClick: EventEmitter<string> = new EventEmitter<string>();
    @Input() buttonId = '';
    @Input() buttonValue = '';
    @Input() type = 'button';
    @Input() transparentMode = false;

    @HostBinding('style.pointer-events') myStyle  = 'auto';
    @Input()
    set ngDisabled(state) {
        this._ngDisabled = state;
        this.myStyle = state ? 'none' : 'auto';
    }
    _ngDisabled = false;

    constructor(@Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this._ngDisabled = true;
        }
    }

    onClick($event) {
        this.acClick.emit($event);
    }
}



