<ac-form *ngIf="dialogConfig.ready"
         [formModel]="site"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [validateAuxiliaryForm]="networkValidationsService.validateNetworkEntityName('site', additionalSettings.name,
         site.regionId || additionalSettings.regionId, 'regionId')"
         formModelName="site">

    <div id="site-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Site Name">
                <ac-textfield [(acModel)]="site.name"
                              [attr.alternativeName]="'site.name'"
                              maxLength="30" textFieldId="site-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container label="Description">
                <ac-textarea [(acModel)]="site.description"
                             [attr.alternativeName]="'site.description'"
                             textAreaId="site-description">
                </ac-textarea>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="site"
                                         [additionalSettings]="additionalSettings"
                                         [isEdit]="isEdit"
                                         [ngDisabledTenant]="isEdit"
                                         [ngDisabledRegion]="isEdit"
                                         [selectedTopologyItem]="selectedTopologyItem"
                                         [regionAlternativeName]="'site.regionId'"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container label="Address">
            <ac-geo-location (acChanged)="site.location=$event"
                             [acModel]="site.location"
                             [attr.alternativeName]="'site.location'"></ac-geo-location>
        </ac-input-container>

        <ac-input-container [ignoreRequired]="true" label="Subnet (CIDR Notation)">
            <ac-select [(acModel)]="site.autoDetectionAddressSpace.subnetMasks"
                       [addCustomTags]="true"
                       [attr.alternativeName]="'site.autoDetectionAddressSpace.subnetMasks'"
                       [bindLabel]="null" [bindValue]="null"
                       [multiple]="true"
                       selectionName="site.autoDetectionAddressSpace.subnetMasks">
            </ac-select>
        </ac-input-container>
    </div>
</ac-form>
