import {Pipe, PipeTransform} from '@angular/core';
import * as IPv6Utils from 'ip-num/IPv6Utils';
import {Validator} from 'ip-num/Validator';
import {byString} from '../utils/by-string';
import {GeneralService} from 'ac-infra';

@Pipe({name: 'ByStringPipe'})
export class ByStringPipe implements PipeTransform {
    transform(obj: any, key: string): any {
        return GeneralService.byString(obj, key);
    }
}
