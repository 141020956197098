<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="additionalSettings"
         [formModel]="audioCodesDevice"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="audioCodesDevice">
    <ac-tabs tabsId="audio-codes-device-tab-set" id="audio-codes-device-dialog-content">
        <ng-container *acTab="'General'; id:'general-section-tab'">

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="{{isMultiple? 'Name Prefix' : 'Name'}}">
                    <ac-textfield [(acModel)]="audioCodesDevice.name"
                                  [attr.alternativeName]="'audioCodesDevice.name'"
                                  maxLength="100" textFieldId="audio-codes-device-name">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Description">
                    <ac-textfield [(acModel)]="audioCodesDevice.description"
                                  [attr.alternativeName]="'audioCodesDevice.description'"
                                  maxLength="100" textFieldId="audio-codes-device-description">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <network-tenant-region-selection [model]="audioCodesDevice"
                                             [additionalSettings]="additionalSettings"
                                             [isEdit]="isEdit"
                                             [ngDisabledTenant]="isEdit"
                                             [ngDisabledRegion]="isEdit"
                                             [regionAlternativeName]="'audioCodesDevice.regionId'"
                                             [selectedTopologyItem]="dialogConfig.dialogData.selectedTopologyItem"
                                             (acTenantChanged)="tenantChanged($event)"
                                             [horizontalView]="true">
            </network-tenant-region-selection>

            <div *ngIf="isMultiple" id="device-configurations-container-multiple">
                <ac-input-container label="Configured Device By">
                    <ac-single-select [(acModel)]="additionalSettings.configuredBy"
                               (acModelChange)="additionalSettings.configuredBy = $event"
                               [items]="configuredByMultipleItems">
                    </ac-single-select>
                </ac-input-container>

                <div class="flexRowParentEqualChildren"  *ngIf="additionalSettings.configuredBy === 'ipAddressRange'">
                    <ac-input-container label="From">
                        <ac-textfield [(acModel)]="additionalSettings.fromIpAddress"
                                      [attr.alternativeName]="'additionalSettings.fromIpAddress'"
                                      textFieldId="add-multiple-audio-codes-devices-from-ip">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container class="multiple-radio-button" label="To">
                        <ac-textfield [(acModel)]="additionalSettings.toIpAddress"
                                      [attr.alternativeName]="'additionalSettings.toIpAddress'"
                                      textFieldId="add-multiple-audio-codes-devices-to-ip">
                        </ac-textfield>
                    </ac-input-container>
                </div>

                <ac-input-container label="IP Address List" *ngIf="additionalSettings.configuredBy === 'ipAddressList'">
                    <ac-select [(acModel)]="additionalSettings.ipAddressList"
                               [addCustomTags]="true"
                               [attr.alternativeName]="'additionalSettings.ipAddressList'"
                               [bindLabel]="null"
                               [bindValue]="null"
                               [multiple]="true"
                               selectionName="additionalSettings.ipAddressList">
                    </ac-select>
                </ac-input-container>

                <ac-input-container *ngIf="additionalSettings.configuredBy === 'serialNumberList'" label="Serial Number List">
                    <ac-select [(acModel)]="additionalSettings.serialNumberList"
                               [addCustomTags]="true"
                               [attr.alternativeName]="'additionalSettings.serialNumberList'"
                               [bindLabel]="null"
                               [bindValue]="null"
                               [multiple]="true"
                               selectionName="additionalSettings.serialNumberList">
                    </ac-select>
                </ac-input-container>
            </div>

            <div *ngIf="!isMultiple" [class.ac-disable]="isEdit" id="device-configurations-container">

                <ac-input-container label="Configured Device By" *ngIf="!isEdit">
                    <ac-single-select [(acModel)]="additionalSettings.configuredBy"
                               (acModelChange)="additionalSettings.configuredBy = $event; configuredByChanged($event)"
                               [items]="configuredByItems">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container label="IP Address" *ngIf="(!isEdit && additionalSettings.configuredBy === 'ipAddress') || (isEdit && audioCodesDevice?.ipAddress)">
                    <ac-textfield [(acModel)]="audioCodesDevice.ipAddress"
                                  [attr.alternativeName]="'audioCodesDevice.ipAddress'"
                                  [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'ipAddress'"
                                  maxLength="50"
                                  textFieldId="ip-device-sql-connection-port">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="FQDN" *ngIf="(!isEdit && additionalSettings.configuredBy === 'deviceFqdn') || (isEdit && additionalSettings?.sbcInfo?.deviceFqdn)">
                    <ac-textfield
                            [acModel]="(audioCodesDevice.sbcInfo && audioCodesDevice.sbcInfo.deviceFqdn) || additionalSettings.sbcInfo?.deviceFqdn"
                            (acModelChange)="audioCodesDevice.sbcInfo.deviceFqdn = $event"
                            [attr.alternativeName]="'audioCodesDevice.sbcInfo.deviceFqdn'"
                            [ngDisabled]="additionalSettings.configuredBy !== 'deviceFqdn' || isEdit"
                            maxLength="100">
                    </ac-textfield>
                </ac-input-container>

                <div class="flexRowParentEqualChildren" *ngIf="(!isEdit && additionalSettings.configuredBy === 'serialNum') || (isEdit && audioCodesDevice?.sbcInfo?.serialNum)">
                    <ac-input-container label="Serial Number 1">
                        <ac-textfield
                                [acModel]="audioCodesDevice.sbcInfo?.serialNum"
                                (acModelChange)="audioCodesDevice?.sbcInfo ? audioCodesDevice.sbcInfo.serialNum = $event : null"
                                [attr.alternativeName]="'audioCodesDevice.sbcInfo.serialNum'"
                                [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'serialNum'"
                                maxLength="50"
                                textFieldId="audio-codes-device-serial-number-1">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container label="Serial Number 2">
                        <ac-textfield [acModel]="audioCodesDevice.sbcInfo?.secondSerialNum"
                                      (acModelChange)="audioCodesDevice?.sbcInfo ? audioCodesDevice.sbcInfo.secondSerialNum = $event : null"
                                      [attr.alternativeName]="'audioCodesDevice.sbcInfo.secondSerialNum'"
                                      [ngDisabled]="isEdit || additionalSettings.configuredBy !== 'serialNum'"
                                      maxLength="50"
                                      textFieldId="audio-codes-device-serial-number-2">
                        </ac-textfield>
                    </ac-input-container>
                </div>

            </div>
            <ac-input-container label="Address">
                <ac-geo-location (acChanged)="audioCodesDevice.location=$event"
                                 [acModel]="audioCodesDevice.location"
                                 [attr.alternativeName]="'audioCodesDevice.location'"></ac-geo-location>
            </ac-input-container>
        </ng-container>

        <snmp *acTab="'SNMP'; id:'snmp-section-tab'; if: !isEdit || (isEdit && audioCodesDevice.snmpInfo)"
              [isEdit]="isEdit"
              [snmpObject]="audioCodesDevice.snmpInfo"
              (acChanged)="audioCodesDevice.snmpInfo = $event;snmpChanged()"
              [hasDefaultObject]="true"
              [hasCheckboxForV3Edit]="true"
              componentType="device"
              snmpName="audioCodesDevice.snmpInfo"
              snmpV3ProfileName="snmpUserProfileInfo"
              viewType="radioButtons"
              [selectedTenantFullObject]="selectedTenantFullObject">
        </snmp>

        <http-connectivity *acTab="'HTTP'; id:'http-section-tab'" #httpElement
                           [httpObject]="audioCodesDevice.sbcInfo"
                           [hasDefaultObject]="true"
                           [isEdit]="isEdit"
                           componentType="device"
                           httpObjectName="audioCodesDevice.sbcInfo">
        </http-connectivity>

        <ng-container *acTab="'SBA'; id:'sba-section-tab'; if: !isMultiple">


            <ac-checkbox labelText="Enable SBA" [(acModel)]="additionalSettings.sbaEnabled"
                         (acModelChange)="sbaEnabledChanged($event)"
                         [ngDisabled]="isEdit">
            </ac-checkbox>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="SBA Configured By">
                    <ac-single-select [(acModel)]="additionalSettings.sbaConfiguredBy"
                               (acModelChange)="additionalSettings.sbaConfiguredBy = $event; sbaConfiguredByChanged($event)"
                               [ngDisabled]="!additionalSettings.sbaEnabled"
                               [items]="deviceSBATypesItems">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container label="IP Address" *ngIf="additionalSettings.sbaConfiguredBy === 'ipAddress'">
                    <ac-textfield
                            [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.ipAddress"
                            (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.ipAddress = $event : null"
                            [attr.alternativeName]="'audioCodesDevice.sbcInfo.SBAsInfo.ipAddress'"
                            [ngDisabled]="isSbaCanBeConfigured('ipAddress')"
                            maxLength="50"
                            textFieldId="audio-codes-device-sba-ip-address-text">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="FQDN Name" *ngIf="additionalSettings.sbaConfiguredBy === 'fqdnName'">
                    <ac-textfield [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.fqdnName"
                                  (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.fqdnName = $event : null"
                                  [attr.alternativeName]="'audioCodesDevice.sbcInfo.SBAsInfo.fqdnName'"
                                  [ngDisabled]="isSbaCanBeConfigured('fqdnName')"
                                  maxLength="100"
                                  textFieldId="audio-codes-device-fdqn-name">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container editModePrefix="Change" label="SNMP Read Community">
                    <ac-password [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.snmpReadCommunity"
                                 (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.snmpReadCommunity = $event : null"
                                 [attr.alternativeName]="'audioCodesDevice.sbcInfo.SBAsInfo.snmpReadCommunity'"
                                 [ngDisabled]="!additionalSettings.sbaEnabled"
                                 maxLength="100"
                                 passwordId="audio-codes-device-sba-read-community">
                    </ac-password>
                </ac-input-container>

                <ac-input-container editModePrefix="Change" label="SNMP Write Community">
                    <ac-password [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.snmpWriteCommunity"
                                 (acModelChange)="audioCodesDevice?.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.snmpWriteCommunity = $event : null"
                                 [attr.alternativeName]="'audioCodesDevice.sbcInfo.SBAsInfo.snmpWriteCommunity'"
                                 [ngDisabled]="!additionalSettings.sbaEnabled"
                                 maxLength="100"
                                 passwordId="audio-codes-device-sba-write-community">
                    </ac-password>
                </ac-input-container>
            </div>

            <ac-input-container label="Description">
                <ac-textfield [acModel]="audioCodesDevice.sbcInfo?.SBAsInfo?.description"
                              (acModelChange)="audioCodesDevice.sbcInfo?.SBAsInfo ? audioCodesDevice.sbcInfo.SBAsInfo.description = $event : null"
                              [attr.alternativeName]="'audioCodesDevice.sbcInfo.SBAsInfo.description'"
                              [ngDisabled]="!additionalSettings.sbaEnabled"
                              maxLength="100" textFieldId="audio-codes-device-sba-description">
                </ac-textfield>
            </ac-input-container>
        </ng-container>

        <div *acTab="'First Connection'; id:'first-connection-section-tab'" id="first-connection-section-container">

            <ac-checkbox labelText="Enable Initial Connection Provisioning"
                         [(acModel)]="additionalSettings.initialConnectionEnabled"
                         (acModelChange)="initialConnectionEnabledChanged()"
                         [ngDisabled]="isFirstConnectionCheckboxDisabled()">
            </ac-checkbox>

            <ac-input-container label="Configuration File (INI/CLI/CONF)">
                <ac-single-select [acModel]="audioCodesDevice.sbcInfo?.preProvInfo?.iniFileId"
                           (acModelChange)="iniFileIdChanged($event)"
                           [attr.alternativeName]="'audioCodesDevice.sbcInfo.preProvInfo.iniFileId'"
                           [bindLabel]="'viewName'"
                           [bindValue]="'id'"
                           [items]="configurationFileOptionsList"
                           [ngDisabled]="isFirstConnectionInputDisabled()">
                </ac-single-select>
            </ac-input-container>

            <ac-input-container label="Firmware File (CMP/RMS/RMT)">
                <ac-single-select [acModel]="audioCodesDevice.sbcInfo?.preProvInfo?.cmpFileId"
                           (acModelChange)="cmpFileIdChanged($event)"
                           [attr.alternativeName]="'audioCodesDevice.sbcInfo.preProvInfo.cmpFileId'"
                           [bindLabel]="'viewName'"
                           [bindValue]="'id'"
                           [items]="firmwareFileOptionsList"
                           [ngDisabled]="isFirstConnectionInputDisabled()">
                </ac-single-select>
            </ac-input-container>

            <ac-input-container label="Firmware Version" [displayAsInfo]="true" *ngIf="additionalSettings.swVersion">
                <div>{{additionalSettings.swVersion}}</div>
            </ac-input-container>

            <ac-input-container label="Supported Products" [displayAsInfo]="true" *ngIf="supportingProducts?.length > 0">
                <div>
                    <ul class="ac-device-dialog-supporting-products">
                        <li *ngFor="let item of supportingProducts" class="supported-product-item">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </ac-input-container>

            <ac-input-container *ngIf="isEdit && audioCodesDevice.sbcInfo?.preProvInfo?.state" label="Status" [displayAsInfo]="true">
                <div class="statusLabel {{preProvStatuses[audioCodesDevice.sbcInfo?.preProvInfo?.state]?.class}}">
                    {{preProvStatuses[audioCodesDevice.sbcInfo?.preProvInfo?.state]?.viewName}}
                </div>
            </ac-input-container>
        </div>
    </ac-tabs>


</ac-form>
