import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class MessagesService {
    constructor() {}

    public buildSuccessMessage = (name, isEdit, entityName) => entityName + ' with name ' + name + ' was ' + (isEdit ? 'updated' : 'added') + ' successfully';

    public buildFailureMessage = (name, isEdit, entityName) => 'failed to ' + (isEdit ? 'update' : 'add') + ' the ' + entityName + ' with name ' + name;

    public getConfirmMessage = ({entityName, entitiesArray, actionName = undefined, messagePrefix = '', messagePostfix = '', forItem = undefined}) => {
        return messagePrefix +
            'Are you sure you want to ' + (actionName || 'delete') + ' ' + this.getEntitiesDescription(entitiesArray, entityName, forItem) + ' ?' +
             messagePostfix;
    };

    public buildMultipleSuccessActionMessage = (entityName, entitiesArray, actionName = 'unknown') => {
        const entityNameResult = _.startCase(_.toLower(entityName));
        const successMsg = (entitiesArray.length > 1) ? entityNameResult + 's with IDs: ' : entityNameResult + ' with ID: ';
        const ids = entitiesArray.map(x => x.id).join(', ');
        return 'action: "' + actionName + '" executed successfully on ' + successMsg + ids;
    };

    public buildFromRequestAPISuccessMessage = (requestAPI, status) => {
        const messageSuffix = status === 200 ? ' success' : ' failed';
        return requestAPI + messageSuffix;
    };

    public getFailedMessage = (items, entityName) => 'Failed to remove ' + this.getEntitiesDescription(items, entityName) + '.';

    private getEntitiesDescription = (items, entityName, forItem?) => {
        const singleMsg = (forItem ? 'for ' : 'the ') + entityName + ': "' + items[0].name + '"';
        const multipleMsg = (forItem ? 'for ' : '') + items.length + ' ' + entityName + 's';

        return items.length === 1 ? singleMsg : multipleMsg;
    };
}
