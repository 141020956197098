import {Injectable} from '@angular/core';

import {WSEntities} from 'ac-infra';
import {Rest} from '../../common/server-actions/rest';


@Injectable({providedIn: 'root'})
export class ActiveDirectoriesRestService extends Rest {

    constructor(public wsEntities: WSEntities) {
        super({url: 'callsMonitor/activeDirectories'});
    }

    public getHashedActiveDirectoriesByIds = (listOfIds) => this.wsEntities.getEntitiesArray('ads', listOfIds);

    public getActiveDirectories = (success?, failure?, parameters?) => {
        return this.get({parameters, extendParameters: true, skipPopulateFilter: true, requestAPI: 'ALL'}).then(success).catch(failure);
    };

    public getActiveDirectoriesByIds = (success, failure, parameters) => {
        this.get({parameters, extendParameters: true, skipPopulateFilter: true, requestAPI: 'IDS'}).then(success).catch(failure);
    };

    public getActiveDirectoryById = (success, failure, id) => {
        this.getById({success, failure, id, skipPopulateFilter: true, requestAPI: 'ID'});
    };

    public syncActiveDirectory = (success, failure, id) => {
        this.add(success, failure, {activeDirectories: [id]}, 'callsMonitor/activeDirectories/actions/syncUsers');
    };

    protected handleSuccess = (requestAPI, response) => {
        const defaultObject = {data: {activeDirectories: [], pages: {total: 0, totalElements: 0}}};
        return response.status === 204 ? defaultObject : response;
    };

}
