import moment from 'moment';

export enum FormatterType {
    date = 'date',
    time = 'time',
    timeNoSec = 'timeNoSec',
    timeChart = 'timeChart',
    dateAndTime = 'dateAndTime',
    dateAndTimeNoSec = 'dateAndTimeNoSec',
}

export const DateFormat: { [key in FormatterType]: string } = {
    [FormatterType.date]: 'DD-MMM-YY',
    [FormatterType.time]: 'HH:mm:ss',
    [FormatterType.timeNoSec]: 'HH:mm',
    [FormatterType.timeChart]: '%H:%M:%S',
    [FormatterType.dateAndTime]: 'DD-MMM-YY HH:mm:ss',
    [FormatterType.dateAndTimeNoSec]: 'DD-MMM-YY HH:mm'
};

export const dateTimeFormatter = (dateTime: any, formatterType: FormatterType = FormatterType.date) => {
    if (typeof dateTime === 'string' && dateTime.indexOf('Z') === -1 && dateTime.indexOf('+') === -1 && dateTime.indexOf('-') === -1) {
        dateTime += 'Z';
    }
    return moment(dateTime).format(DateFormat[formatterType]);
};
