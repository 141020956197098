<div *ngIf="svgModel"
     #svgParent
     [attr.title]="text?text:null"
     [innerHtml]="svgModel | async | acFunc: changeFillColorOnHTML | safe: 'html'"
     [style.height]="height"
     [style.transform]="rotate | acFunc : getTransformStyle : mirrorHor : mirrorVer | safe: 'style'"
     [style.width]="width"
     (mouseenter)="hoverFillColor && changeFillColor(hoverFillColor)"
     (mouseleave)="changeFillColor(_fillColor)"
     class="svg-container"
></div>

