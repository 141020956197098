import {Injectable, isDevMode} from '@angular/core';
import {SessionStorageService} from '../session-storage.service';

export enum HTTPMethods {
    GET = 'get',
    ADD = 'post',
    UPDATE = 'put',
    UPLOAD = 'put',
    REMOVE = 'delete'
};

@Injectable({
    providedIn: 'root'
})
export class CommunicationConfigurationService {

    // restServerURL:string = null;               // CONF FOR BUILD RELATED TO SERVER
    static communicationExceptionType = {
        400: {
            type: 'FailedActionException',
            message: null
        }, 401: {
            type: 'AuthenticationException',
            message: null
        },
        403: {
            type: 'AuthorizationException',
            message: 'Could not perform request due to inappropriate security level'
        },
        404: {
            type: 'CommunicationException',
            message: 'Could not find resource'
        },
        500: {
            type: 'InternalServerErrorException',
            message: 'An unexpected error happened on the server'
        },
        502: {
            type: 'BadGatewayException', // no listener
            message: ''
        },
        503: {
            type: 'ServerDownException',
            message: 'The server is down and could not perform the request'
        },
        default: {
            type: 'FailedActionException',
            message: null
        }
    };
    // restServerURL:string = "localhost:3000/";  // Dyson
    webSocketURLName = '/ovocWebsocket/';
    functionsObject: any = {};

    constructor() {

    }

    getRestServerURL() {
        return isDevMode() ? 'localhost:9000/api/' : null;
    }

    getWebSocketServerURL() {
        return isDevMode() ? 'localhost:915/ovocWebsocket/' : null;
    }


    setProtocol(protocol) {
        SessionStorageService.setData('communication.protocol', protocol);
    }

    getProtocol() {
        return SessionStorageService.getData('communication.protocol') || 'https:';
    }

    setShortenURLFn(shortenURL) {
        this.functionsObject.shortenURL = shortenURL;
    }

    getShortenURLFn() {
        return this.functionsObject.shortenURL || null;
    }

}
