<div class="allocation-bar-container flexColumnParent">
    <div *ngIf="verbose" [class.without-title]="verbose || hideAllocTitle"
         class="title-and-percent-container flexRowParent flexItem">
        <span *ngIf="!hideAllocTitle" class="allocation-bar-title flexItemBiggest">{{header}}</span>
        <span class="allocation-bar-percent flexItem">{{used | acFunc: generateVerboseBarTitle}}</span>
    </div>
    <div  [class.license-exceeded]="used | acFunc: isLicenseExceeded" class="allocation-bar flexItem">
        <div class="allocation-bar-inner" [class.dark]="dark" [ngStyle]="{'width': used | acFunc: calculatePercentage}"></div>
    </div>
    <div *ngIf="verbose" class="allocation-bar-details flexRowParent flexItem">
        <div class="flexItemBiggest">
            <span class="allocation-bar-total">Total: {{total | number}}</span>
            <span [hidden]="!used" class="allocation-bar-allocated">Allocated: {{used | number}} </span>
        </div>
        <span class="allocation-bar-free flexItem">Free: {{used | acFunc: calculateFreeAllocation | number}} </span>
    </div>
</div>
