import {Action, createSelector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {isEqual} from 'lodash';

export namespace AcListEvents {
    export class Set {
        static readonly type = '[List Selection Changed] Set';
        constructor(public selection: any) {}
    }
}

export type AcListStateStateModel = any;

const AC_LIST_STATE_TOKEN = new StateToken<AcListStateStateModel>('listSelection');

@State({
    name: AC_LIST_STATE_TOKEN,
    defaults: {}
})
@Injectable({providedIn: 'root'})
export class AcListState {

    static selection(type: string) {
        return createSelector([AcListState], (state: string[]) => {
            return state[type];
        });
    }

    @Action(AcListEvents.Set)
    setSelection(ctx: StateContext<AcListStateStateModel>, {selection}: AcListEvents.Set) {
        if(!isEqual(ctx.getState()[selection.type], selection.data)) {
            ctx.patchState({[selection.type]: selection.data});
        }
    }
}
