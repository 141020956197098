import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';

import 'leaflet';
import 'leaflet-providers';
import './leaflet-Rrose/leaflet.rrose-src';
import 'leaflet.markercluster';
import 'leaflet-polylinedecorator';
import 'leaflet-rotatedmarker';
import 'leaflet-easybutton';
import 'leaflet.markercluster.freezable';

import * as turf from '@turf/helpers';
import * as turflineIntersect from '@turf/line-intersect';
import * as _ from 'lodash';

import {
    ACSetTimeout,
    CommonNotifiersService,
    GeneralService,
    RefreshTableProperties,
    SessionStorageService,
    statuses,
    ThrottleClass,
    WSEntities,
    WSMessage
} from 'ac-infra';

import {AcGeoEventsService} from './services/ac-geo-events.service';
import {AcGeoVisualsService} from './services/ac-geo-visuals.service';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntityActionsService} from '../../services/entity-actions.service';
import {
    FilterChangedProperties,
    FilterState
} from '../../../common/components/ac-filter/services/ac-filter-state.service';
import {LinkCreationService} from '../../maps/services/link-creation.service';
import {GeoSelectionService} from '../../maps/services/geo-selection.service';

declare let L: any;
declare let $: any;


const gridImagePath = 'assets/images/general/100_sq_grid.png';

@UntilDestroy()
@Component({
    selector: 'ac-geo',
    templateUrl: './ac-geo.component.html',
    styleUrls: ['./ac-geo.component.less', './ac-geo-devices.less', './ac-geo-NEW.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcGeoComponent {

    @Input() geoId = '';
    @Input() shapesFetchFunction: Function;
    @Input() linksFetchFunction: Function;
    @Input() dataItemToShapeMapper: any;
    @Input() dataItemToLinkMapper: any;
    @Input() isLatLngMap: any;
    @Input() showCreateLinkButton = false;
    @Input() allowDragMarkers = false;
    @Input() showSaveMapButton = false;
    @Output() mapShapesDragged = new EventEmitter<any>();

    @ViewChild('acGeoMap', {static: true}) elLeaflet: ElementRef;

    GeoConstants = {
        TILES_SERVER_URL: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        ATTRIB: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
    };

    MIN_ZOOM: number;
    MAX_ZOOM: number;
    MAP_MAX_LAT_X: number;
    MAP_MAX_LNG_Y: number;

    southWest: any;
    northEast: any;

    isShowGrid: boolean;
    isShowLabel: boolean;
    isSpreadMode: boolean;

    generalBtns;
    generalBtnsObj;
    clusterModeBtn;
    clusterModeBar;
    zIndexOffsetCounter = 9999;
    showMap = false;

    isLoading = true;
    isCalculatingLinks = false;

    allDeviceLayers = {};

    mapLinks: any;
    markerDragInProgress: any;
    markerSelectionInProgress: any;

    GEO_OPTIONS: any;

    source_arrow_1: any;
    source_arrow_2: any;

    ingress_arrow_1: any;
    ingress_arrow_2: any;

    egress_arrow_1: any;
    egress_arrow_2: any;

    latlngMapDefaultValues: any;
    xyMapDefaultValues: any;
    geoMapOptions: any;
    topoMapOptions: any;

    map: any = {};
    mappedDevices: any;
    cluster: any;

    searchName: any;
    lockedPopupVisible: any;

    devices: any;
    links: any;

    incomingDevicesAndLinks: any;

    linesLayer;
    linesLayerObjects = {};

    drawLinesDebounceTimer;
    drawLinesDebounceCounter = 0;

    intervalDrawLines: any;
    lastPopup: any;

    popupComponentRef: ComponentRef<any>;
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    linksRedrawTimeout;
    linksLayer;
    private addLinkTooltip;
    private refreshClusters$: ThrottleClass;
    private refreshLinks$: ThrottleClass;
    private getDevicesAndLinks$: ThrottleClass;
    private isMapInitialized = false;
    private zoomValue: any;
    private centerValue: any;

    constructor(private linkCreationService: LinkCreationService,
                private acGeoEventsService: AcGeoEventsService,
                private acGeoVisualsService: AcGeoVisualsService,
                private geoSelectionService: GeoSelectionService,
                private filterState: FilterState,
                private wsEntities: WSEntities,
                private generalService: GeneralService,
                private entityActionsService: EntityActionsService,
                private cdRef: ChangeDetectorRef) {

        this.refreshClusters$ = new ThrottleClass({
            callback: () => {
                if(this.cluster._currentShownBounds){
                    this.cluster.refreshClusters();
                }
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000,
            debounce: 250,
            maxDebounceTime: 500
        });
        this.refreshLinks$ = new ThrottleClass({
            callback: () => {
                this.doRedrawLines();
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000,
            debounce: 250,
            maxDebounceTime: 1250,
            oneAtATime: true
        });
        this.getDevicesAndLinks$ = new ThrottleClass({
            callback: () => {
                this.doGetDevicesAndLinks();
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000,
            debounce: 250,
            maxDebounceTime: 500,
            oneAtATime: true
        });
    }

    @HostListener('window:resize')
    onResize = () => {
        this.resizeMap();
        this.redrawLines(true);
    };

    ngOnInit() {
        this.MIN_ZOOM = this.isLatLngMap ? 2 : -20;
        this.MAX_ZOOM = this.isLatLngMap ? 18 : 20;
        this.MAP_MAX_LAT_X = this.isLatLngMap ? 85.05112877980659 : 1000;
        this.MAP_MAX_LNG_Y = this.isLatLngMap ? 180 : 1000;

        this.southWest = L.latLng(-this.MAP_MAX_LAT_X, -this.MAP_MAX_LNG_Y);
        this.northEast = L.latLng(this.MAP_MAX_LAT_X, this.MAP_MAX_LNG_Y);


        this.isShowGrid = SessionStorageService.getData(this.geoId + '.gridMode');
        this.isShowLabel = SessionStorageService.getData(this.geoId + '.LinkLabels');
        if(this.isShowLabel == null || this.isShowLabel === undefined) {
            this.isShowLabel = true;
        }
        this.isSpreadMode = SessionStorageService.getData(this.geoId + '.clusterMode');
        this.zoomValue = SessionStorageService.getData(this.geoId + '.currentZoom');
        this.centerValue = SessionStorageService.getData(this.geoId + '.currentCenter');

        this.GEO_OPTIONS = {
            gridUrl: this.isShowGrid ? gridImagePath : ''
        };

        this.source_arrow_1 = this.sourceArrow('28px');
        this.source_arrow_2 = this.sourceArrow('33px');

        this.ingress_arrow_1 = this.gressArrow('45%', '-270');
        this.ingress_arrow_2 = this.gressArrow('55%', '-270');

        this.egress_arrow_1 = this.gressArrow('45%', '90');
        this.egress_arrow_2 = this.gressArrow('55%', '90');

        this.latlngMapDefaultValues = L.tileLayer(this.GeoConstants.TILES_SERVER_URL, {
            minZoom: this.MIN_ZOOM,
            maxZoom: this.MAX_ZOOM,
            attribution: this.GeoConstants.ATTRIB
        });

        this.xyMapDefaultValues = L.tileLayer(this.GEO_OPTIONS.gridUrl, {
            maxNativeZoom: this.MAX_ZOOM,
            maxZoom: this.MAX_ZOOM,
            minZoom: this.MIN_ZOOM,
            tileSize: 200
        });

        this.geoMapOptions = {
            maxBounds: L.latLngBounds(this.southWest, this.northEast),
            preferCanvas: true,
            zoomControl: true
        };

        this.topoMapOptions = {
            crs: L.CRS.Simple,
            preferCanvas: true,
            zoomControl: true
        };


        this.geoSelectionService.initiateSelection();
        this.linkCreationService.turnOffLinkCreation();

        this.acGeoVisualsService.setDataItemToShapeMapper(this.dataItemToShapeMapper);
        this.acGeoVisualsService.setDataItemToLinkMapper(this.dataItemToLinkMapper);

        this.acGeoEventsService.setDataItemToShapeMapper(this.dataItemToShapeMapper);
        this.acGeoEventsService.setDataItemToLinkMapper(this.dataItemToLinkMapper);

        CommonNotifiersService.finishedDataAndFilteredUpdate$.pipe(untilDestroyed(this)).subscribe((refreshTableProperties?: RefreshTableProperties) => {
            if (refreshTableProperties.showLoader) {
                this.isLoading = true;
                this.cdRef.detectChanges();
                if (!this.userInteractionInProgress()) {
                    this.getDevicesAndLinks(true);
                }
            } else {
                // this.getDevicesAndLinks(); individual WS is now being handled
            }
        });

        this.filterState.filterChanged$.pipe(untilDestroyed(this)).subscribe((filterObj: FilterChangedProperties) => {
            if (filterObj.type === 'NetworkFilter') {
                this.isLoading = true;
                this.cdRef.detectChanges();
            }
        });

        this.geoSelectionService.refreshClusters$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.cluster) {
                this.refreshClusters$.tick();
            }
        });

        this.wsEntities.WSEntitiesUpdateFinished$.pipe(untilDestroyed(this)).subscribe((message: WSMessage) => {
            if (this.cluster && ['sites', 'devices'].includes(message.entityTypeName)) {
                switch (message.messageType) {
                    case statuses.Create:
                    case statuses.Update:
                        const success = (entities) => {
                            const layersToRemove = [];
                            entities.forEach((entity) => {
                                this.allDeviceLayers[entity.id] && layersToRemove.push(this.allDeviceLayers[entity.id]);
                            });
                            layersToRemove.length > 0 && this.cluster.removeLayers(layersToRemove);
                            const markers = this.createMarkers(entities);
                            this.addMarkersToCluster(markers, this.cluster);
                            this.redrawLines();
                        };
                        this.isLoading = true;
                        this.shapesFetchFunction(success, message.entitiesIds);
                        break;
                    case statuses.Delete:
                        message.entitiesIds.forEach((id) => {
                            this.cluster.removeLayer(this.allDeviceLayers[id]);
                        });
                }
                this.refreshClusters$.tick();
            } else if (message.entityTypeName === 'links') {
                switch (message.messageType) {
                    case statuses.Create:
                    case statuses.Update:
                        const success = (links) => {
                            links.forEach((link) => {
                                const idx = this.links.indexOf(this.links.find(x => x.id === link.id));
                                if (idx === -1) {
                                    this.links.push(link);
                                } else {
                                    this.links[idx] = link;
                                }
                            });
                            this.redrawLines();
                        };
                        this.isLoading = true;
                        this.linksFetchFunction(success, message.entitiesIds);
                        break;
                    case statuses.Delete:
                        message.entitiesIds.forEach((id) => {
                            const idx = this.links.indexOf(this.links.find(x => x.id === id));
                            if (idx > -1) {
                                this.links.splice(idx, 1);
                            }
                        });
                        this.redrawLines();
                }

            }
        });

        this.entityActionsService.networkTopologySearch$.pipe(untilDestroyed(this)).subscribe((searchObject) => {
            this.closeOpenPopups();
            if (searchObject === '') {
                this.centerMap();
            }
            this.searchName = searchObject;
            this.getDevicesAndLinks();
            this.isLoading = true;
            this.cdRef.detectChanges();
        });

        AcGeoEventsService.closeOpenTooltipsOnMap$.pipe(untilDestroyed(this)).subscribe(this.closeOpenPopups);

        this.initialization();
    }

    sourceArrow = (offset) => ({
        offset,
        repeat: 0,
        width: '1px',
        symbol: L.Symbol.arrowHead({
            headAngle: '90',
            pixelSize: 5,
            polygon: false,
            pathOptions: {stroke: true, weight: 2}
        })
    });

    gressArrow = (offset, headAngle) => ({
        offset,
        repeat: 0,
        width: '1px',
        symbol: L.Symbol.arrowHead({
            headAngle,
            pixelSize: 6,
            polygon: false,
            pathOptions: {stroke: true, weight: 2}
        })
    });

    userInteractionInProgress = () => this.linkCreationService.isLinkCreationModeEnabled() || this.markerDragInProgress || this.markerSelectionInProgress;

    centerMap = () => {
        if (this.cluster) {
            const bounds = this.cluster.getBounds();
            if (bounds._northEast) {
                this.map.fitBounds(bounds);
            }
        } else {
            this.map.setView([0, 0], 0);
        }
    };

    closeOpenPopups = () => {

        ACSetTimeout(this, () => {
            this.lockedPopupVisible = false;
        });
        this.map.closePopup();
        this.cancelLastPopupTimeout();
    };

    /** :: HELPER FUNCTION HERE :: **/

    addClusterButton = () => {
        this.clusterModeBtn = this.clusterModeBtn || this.createClusterModeBtns();
        this.clusterModeBar = this.clusterModeBar || L.easyBar(this.clusterModeBtn);

        const onMap = this.clusterModeBar._map;
        if (this.devices && this.devices.length <= 200) {
            if (!onMap) {
                if (this.isSpreadMode) {
                    this.clusterModeBtn[0].state('cluster-mode');
                } else {
                    this.clusterModeBtn[0].state('uncluster-mode');
                }
                this.clusterModeBar.addTo(this.map);
            }
        } else {
            if (this.isSpreadMode) {
                this.clusterModeBtn[0].state('cluster-mode');
            }
            if (onMap) {
                this.map.removeControl(this.clusterModeBar);
            }
        }
    };

    initializeMap = (el) => {
        const defaultMapValue = this.isLatLngMap ? this.geoMapOptions : this.topoMapOptions;

        this.map = L.map(el, defaultMapValue);
        this.linesLayer = L.layerGroup();
        this.linesLayer.addTo(this.map);
        this.linkCreationService.updateMap(this.map);
        this.map.zoomControl.setPosition('topright');
        this.map.addLayer(this.isLatLngMap ? this.latlngMapDefaultValues : this.xyMapDefaultValues);


        this.createBarButtons();
        this.updateSaveMapLocationsButton();


        L.easyBar(this.generalBtns).addTo(this.map).setPosition('topright');
        this.resizeMap();
        ACSetTimeout(this, () => {
            this.resizeMap();
            ACSetTimeout(this, () => {
                this.setMapEvents();
            });
        });
    };

    linkCreationTooltip = (e) => {
        if (!this.addLinkTooltip) {
            this.addLinkTooltip = new L.Rrose({
                offset: new L.Point(0, 0),
                closeButton: false,
                autoPan: false,
                southPadding: 10,
                northPadding: 10,
                className: 'linkCreationTooltip'
            });
            this.addLinkTooltip.setContent(
                '<div class=\'linkCreationTooltip\'>' +
                '<div>Click and drag from a device to create a link</div>' +
                '<div>Note: Auto refresh is disabled.</div>' +
                '</div>'
            );
        }
        if (e) {
            this.addLinkTooltip.setLatLng(e.latlng);
        }
        this.addLinkTooltip.openOn(this.map);
    };

    addLinkCreationTooltip = () => {
        this.map.on('mouseover mousemove', this.linkCreationTooltip);
    };

    removeLinkCreationTooltip = () => {
        this.map.off('mouseover mousemove', this.linkCreationTooltip);
        this.closeOpenPopups();
    };

    createClusterModeBtns = () => [
        L.easyButton({
            states: [{
                stateName: 'uncluster-mode',
                icon: 'fa-object-ungroup',
                title: 'No Clusters',
                onClick: (control) => {
                    ACSetTimeout(this, () => {
                        this.isLoading = true;
                        this.cdRef.detectChanges();
                        ACSetTimeout(this, () => {
                            this.isSpreadMode = true;
                            this.initCluster();
                            this.whenDataReadyCenterAndDraw();
                            control.state('cluster-mode');
                            SessionStorageService.setData(this.geoId + '.clusterMode', true);
                            this.geoSelectionService.clearSelection();
                        });
                    });
                }
            }, {
                stateName: 'cluster-mode',
                icon: 'fa-object-group',
                title: 'Show Clusters',
                onClick: (control) => {
                    ACSetTimeout(this, () => {
                        this.isLoading = true;
                        this.cdRef.detectChanges();
                        ACSetTimeout(this, () => {
                            this.isSpreadMode = false;
                            this.initCluster();
                            this.whenDataReadyCenterAndDraw();
                            control.state('uncluster-mode');
                            SessionStorageService.setData(this.geoId + '.clusterMode', false);
                            this.geoSelectionService.clearSelection();
                        });
                    });
                }
            }]
        })
    ];

    startLinkCreation = (control) => {
        this.linkCreationService.turnOnLinkCreation();
        control.state('add-links');
        this.addLinkCreationTooltip();
    };

    stopLinkCreation = (control, isFromLinkCreationService?) => {
        if (!isFromLinkCreationService) {
            this.linkCreationService.turnOffLinkCreation();
        }

        control.state('drag-markers');
        this.removeLinkCreationTooltip();
    };

    updateSaveMapLocationsButton = () => {
        if (this.dataItemToShapeMapper.isSaveMapLocationNeeded()) {
            this.generalBtnsObj.save.enable();
            this.generalBtnsObj.revert.enable();
        } else {
            this.generalBtnsObj.save.disable();
            this.generalBtnsObj.revert.disable();
        }
    };

    createBarButtons = () => {
        const buttons = {
            center: L.easyButton({
                states: [{
                    icon: 'fa-dot-circle-o',
                    onClick: this.centerMap,
                    title: 'Center Map'
                }]
            }),
            save: L.easyButton({
                states: [{
                    stateName: 'save-map',
                    icon: 'fa-floppy-o',
                    onClick: () => {
                        this.dataItemToShapeMapper.saveMapLocation();
                        this.generalBtnsObj.save.disable();
                        this.generalBtnsObj.revert.disable();
                    },
                    title: 'Save Local Changes to Server'
                }]
            }),
            revert: L.easyButton({
                states: [{
                    stateName: 'revert-map',
                    icon: 'fa-undo',
                    onClick: () => {
                        this.closeOpenPopups();
                        this.dataItemToShapeMapper.revertLocation(); // empty session storage
                        this.generalBtnsObj.revert.disable();
                        this.generalBtnsObj.save.disable();
                        this.getDevicesAndLinks(true);
                    },
                    title: 'Revert Local Changes'
                }]
            }),
            link: L.easyButton({
                states: [{
                    stateName: 'drag-markers',
                    icon: 'fa-arrows-h',
                    title: 'Create Links',
                    onClick: (control) => {
                        this.startLinkCreation(control);
                    }
                }, {
                    stateName: 'add-links',
                    icon: 'fa-hand-paper-o',
                    title: 'Drag & Drop Devices',
                    onClick: (control) => {
                        this.stopLinkCreation(control);
                    }
                }]
            }),
            grid: L.easyButton({
                states: [{
                    stateName: 'show-grid',
                    icon: 'fa-th',
                    title: 'Show Grid',
                    onClick: (control) => {
                        this.xyMapDefaultValues._url = gridImagePath;
                        this.xyMapDefaultValues.redraw();
                        control.state('hide-grid');
                        SessionStorageService.setData(this.geoId + '.gridMode', true);
                    }
                }, {
                    stateName: 'hide-grid',
                    icon: 'fa-square-o',
                    title: 'No Grid',
                    onClick: (control) => {
                        this.xyMapDefaultValues._url = '';
                        this.xyMapDefaultValues.redraw();
                        control.state('show-grid');
                        SessionStorageService.setData(this.geoId + '.gridMode', false);
                    }
                }]
            }),
            LinkLabels: L.easyButton({
                states: [{
                    stateName: 'hide-labels',
                    icon: 'fa-eye-slash',
                    title: 'Hide Link Labels',
                    onClick: (control) => {
                        control.state('show-labels');
                        this.isShowLabel = false;
                        SessionStorageService.setData(this.geoId + '.LinkLabels', false);
                        this.getDevicesAndLinks();
                    }
                }, {
                    stateName: 'show-labels',
                    icon: 'fa-eye',
                    title: 'Show Link Labels',
                    onClick: (control) => {
                        control.state('hide-labels');
                        this.isShowLabel = true;
                        SessionStorageService.setData(this.geoId + '.LinkLabels', true);
                        this.getDevicesAndLinks();
                    }
                }]
            })
        };

        if (this.isShowGrid) {
            buttons.grid.state('hide-grid');
        }
        if (!this.isShowLabel) {
            buttons.LinkLabels.state('show-labels');
        }

        if (this.isLatLngMap) {
            buttons.grid.disable();
        }

        if (!this.showCreateLinkButton || this.oneLiveMode) {
            buttons.link.disable();
        }

        if (!this.showSaveMapButton) {
            buttons.save.disable();
            buttons.revert.disable();
        }

        this.linkCreationService.turnOffLinkCreation$.pipe(untilDestroyed(this)).subscribe(() => {
            this.stopLinkCreation(buttons.link, true);
        });

        this.generalBtnsObj = buttons;
        this.generalBtns = [
            buttons.center,
            buttons.save,
            buttons.revert,
            buttons.link,
            buttons.grid,
            buttons.LinkLabels
        ];
    };

    updateCurrentMapCenterAndZoom = () => {
        this.map.setView(this.centerValue, this.zoomValue);
    };

    initializeZoomAndCenterMap = () => {
        if (this.isDefinedStateRecorderValues()) {
            this.updateCurrentMapCenterAndZoom();
        } else {
            this.centerMap();
        }
    };

    updateStateRecorderValues = () => {
        this.centerValue = this.map.getCenter();
        this.zoomValue = this.map.getZoom();
        SessionStorageService.setData(this.geoId + '.currentCenter', this.centerValue);
        SessionStorageService.setData(this.geoId + '.currentZoom', this.zoomValue);
    };

    getDevicesAndLinks = (force = false) => {
        if (force) {
            return this.getDevicesAndLinks$.forceTick();
        }
        this.getDevicesAndLinks$.tick();
    };

    doGetDevicesAndLinks = () => {
        const mainSuccess = (value, source) => {
            this.incomingDevicesAndLinks[source] = value;
            if (this.incomingDevicesAndLinks.devices && this.incomingDevicesAndLinks.links) {
                if (!this.userInteractionInProgress() && this.isMapAlive()) {
                    this.devices = this.incomingDevicesAndLinks.devices;
                    this.links = this.incomingDevicesAndLinks.links;
                    if (this.links.length > 500) {
                        this.isShowLabel = false;
                        this.generalBtnsObj.LinkLabels.state('show-labels');
                    }


                    this.refreshLinks$.updateOptions({maxRecurrentTime: this.links.length * 1.5 + 5000}); // https://www.desmos.com/calculator/g51ajxuqgx
                    this.refreshClusters$.updateOptions({maxRecurrentTime: this.devices.length * 0.3 + 5000});
                    this.getDevicesAndLinks$.updateOptions({maxRecurrentTime: (this.devices.length + this.links.length) * 0.4 + 5000});

                    this.mappedDevices = {};
                    this.devices.forEach((device) => {
                        this.mappedDevices[device.id] = device;
                    });

                    this.addClusterButton();
                    setTimeout(() => {
                        this.whenDataReadyCenterAndDraw();
                    });
                }
                this.getDevicesAndLinks$.oneAtATimeFinished();
            }
        };
        setTimeout(() => {
            this.incomingDevicesAndLinks = {};
            this.initializeScopeDevices(mainSuccess);
            this.initializeScopeLinks(mainSuccess);
        });
    };

    initializeScopeDevices = (mainSuccess) => {
        const onSuccess = (value) => {
            mainSuccess(value, 'devices');
        };

        this.isLoading = true;
        this.shapesFetchFunction(onSuccess);
    };

    initializeScopeLinks = (mainSuccess) => {
        const onSuccess = (value) => {
            mainSuccess(value, 'links');
        };

        this.isLoading = true;
        this.linksFetchFunction(onSuccess);
    };

    isDefinedStateRecorderValues = () => this.zoomValue !== null && this.centerValue !== null;

    whenDataReadyCenterAndDraw = () => {
        if (this.cluster) {
            this.cluster.clearLayers();
        } else {
            this.initCluster();
        }

        this.createClusterLayer(this.devices);

        this.mapHighlightLinks(this.links);

        this.initializeZoomAndCenterMap();

        this.isMapInitialized = true;

        this.redrawLines();

        this.cluster.on('animationend', () => {
            this.redrawLines(true);
        });
    };

    syncSelections = () => {
        this.geoSelectionService.reselectAllSelected(this.allDeviceLayers, this.linesLayer);
    };

    mapHighlightLinks = (links) => {
        this.mapLinks = {};

        links.forEach((link) => {
            this.updateMapLinkObjByParameter(link, 'from');
            this.updateMapLinkObjByParameter(link, 'to');
        });
    };

    updateMapLinkObjByParameter = (link, prameter) => {
        if (this.mapLinks[link[prameter]]) {
            this.mapLinks[link[prameter]].push(link);
        } else {
            this.mapLinks[link[prameter]] = [link];
        }
    };

    createClusterLayer = (devices) => {
        const markers = this.createMarkers(devices);

        this.addMarkersToCluster(markers, this.cluster);

        this.linkCreationService.updateCluster();
    };

    buildMarkerClusterGroupObject = () => ({
        removeOutsideVisibleBounds: true,
        zoomToBoundsOnClick: true,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: true,
        maxClusterRadius: 80,
        polygonOptions: {
            color: 'transparent',
            fill: true,
            fillColor: '#525252',
            fillOpacity: 0.5,
            opacity: 1,
            className: 'cluster-bounds'
        },
        iconCreateFunction: (cluster) => {
            const childMarkers = cluster.getAllChildMarkers();
            const markerIds = {};
            childMarkers.forEach((marker) => markerIds[marker.device.id] = true);

            const childCount = cluster.getChildCount();
            const maxSeverityLevels = this.getMaxSeverityLevel(childMarkers);
            const classNameCluster = ' marker-cluster-';
            const innerLinks = this.links.filter((link) => markerIds[link.from] && markerIds[link.to]);
            cluster.innerLinks = innerLinks;


            let isHighlight = this.dataItemToShapeMapper.isHighlighted(childMarkers);
            if (!isHighlight) {
                isHighlight = this.containsHighlightLink(markerIds);
            }

            const isFiltered = _.isFunction(this.dataItemToShapeMapper.isClusterFiltered) ? this.dataItemToShapeMapper.isClusterFiltered(childMarkers) : false;

            const isSelected = this.geoSelectionService.isItemSelected(cluster);
            const isInnerLinksSelected = this.geoSelectionService.isItemSelected(innerLinks);

            this.setClusterClassName(childCount, classNameCluster);
            const icon = L.divIcon(this.acGeoVisualsService.createClusterDivIcon(childCount, innerLinks, maxSeverityLevels, isHighlight, isFiltered, isSelected, isInnerLinksSelected));
            return icon;
        }
    });

    containsHighlightLink = (markerIds) => {
        const deviceMap = {};
        Object.getOwnPropertyNames(markerIds).forEach((markerId) => {
            if (this.mapLinks && this.mapLinks[markerId] && this.containLink(this.mapLinks[markerId], deviceMap)) {
                return true;
            }
        });
        return false;
    };

    containLink = (linkIds, deviceMap) => {
        for (let i = 0; i < linkIds.length; i++) {
            if (linkIds[i] && this.dataItemToShapeMapper.isHighlighted(linkIds[i])) {
                if (deviceMap[linkIds[i].id]) {
                    return true;
                } else {
                    deviceMap[linkIds[i].id] = 1;
                }
            }
        }
    };

    getDeviceLatLon = (device) => {
        const lat_x = this.dataItemToShapeMapper.getLatitude(device);
        const lon_y = this.dataItemToShapeMapper.getLongitude(device);
        return this.isLatLngMap ? L.latLng(lat_x, lon_y, true) : this.xy(lat_x, lon_y);
    };

    createMarkers = (devices) => {
        const markers = devices.map((device) => {

            const pointLatLon = this.getDeviceLatLon(device);
            const severityLevel = this.dataItemToShapeMapper.getStatus(device);
            const adminState = (this.dataItemToShapeMapper.getAdminState(device)).toLowerCase();
            const divIcon = L.divIcon(this.acGeoVisualsService.createMarkerDivIcon(severityLevel, adminState, device));

            const marker = L.marker(pointLatLon, {
                icon: divIcon,
                draggable: this.allowDragMarkers
            });

            this.allDeviceLayers[device.id] = marker;

            const a = this.dataItemToShapeMapper.getLabelText(device);
            marker.bindTooltip((a), {
                permanent: true,
                direction: 'top',
                className: 'ac-geo-devices-tooltip' + (device.filtered ? '' : ' filtered'),
                offset: L.point(0, -15)
            });

            marker.on('mouseover', (e) => {
                if (!this.userInteractionInProgress()) {
                    this.openPopupForShape(marker, 'dataItemToShapeMapper', 'getCompiledTooltip', device, true, e.latlng, e);
                }
            });

            marker.on('click', (event) => {
                if (this.dataItemToShapeMapper.isFiltered(marker.device)) {
                    this.acGeoEventsService.mouseClickHandler(event, marker);
                }
            });

            marker.on('drag', (event) => {
                this.markerDragInProgress = true;
                this.closeOpenPopups();
            });

            marker.on('dragstart', (event) => {
                this.zIndexOffsetCounter = this.zIndexOffsetCounter + 1000;
                event.target.setZIndexOffset(this.zIndexOffsetCounter);
                event.target.getTooltip().setOpacity(0);
            });

            marker.on('dragend', (event) => {
                event.target.getTooltip().setOpacity(1);
                this.markerDragInProgress = false;
                updateMarkerPositionOnDrag(event, true);
                this.updateSaveMapLocationsButton();
            });

            const updateMarkerPositionOnDrag = (event, isFinished) => {
                this.closeOpenPopups();
                const targetMarker = event.target;
                const position = marker.getLatLng();
                if (!this.isLatLngMap || draggedOutMapBounds(position, targetMarker, device)) {
                    ACSetTimeout(this, () => {
                        this.redrawLines(true);
                    });
                    updateMarkerPosition(targetMarker, position.lat, position.lng);

                    if (isFinished) {
                        const shape = createDraggedShapeInfo(event, position);
                        this.mapShapesDragged.emit(shape);
                    }
                }
            };

            const createDraggedShapeInfo = (event, position) => {
                const shapeToBroadcast: any = {};
                shapeToBroadcast.dataItem = event.target.device;

                if (this.isLatLngMap) {
                    shapeToBroadcast.lat = position.lat + '';
                    shapeToBroadcast.lon = position.lng + '';
                } else {
                    shapeToBroadcast.x = position.lng;
                    shapeToBroadcast.y = -position.lat;
                }

                return [shapeToBroadcast];
            };

            const draggedOutMapBounds = (position, _marker, _device) => {
                if (isBetweenRange(position.lat, this.MAP_MAX_LAT_X) && isBetweenRange(position.lng, this.MAP_MAX_LNG_Y)) {
                    return true;
                } else {
                    this.dataItemToShapeMapper.displayInfoMessage('shapeDraggedOutOfBounds');
                    updateMarkerPosition(_marker, _device.lat, _device.lon);
                    return false;
                }
            };

            const isBetweenRange = (num, parameter) => num >= -parameter && num <= parameter;

            const updateMarkerPosition = (_marker, lat, lng) => {
                _marker.setLatLng(L.latLng(lat, lng), {
                    draggable: this.allowDragMarkers
                });

                if (this.isLatLngMap) {
                    _marker.device.lat = lat;
                    _marker.device.lon = lng;
                } else {
                    _marker.device.x = lng;
                    _marker.device.y = -lat;
                }

            };

            marker.highlight = device.highlight;
            marker.geoPointId = device.id;
            marker.statusSeverityLevel = severityLevel;
            marker.type = 'device';
            marker.device = device;

            return marker;
        });
        return markers;
    };

    xy = (x, y) => {
        if (Array.isArray(x)) {
            return L.latLng(-x[1], x[0]);
        }
        return L.latLng(-y, x);
    };

    addMarkersToCluster = (markers, cluster) => {
        cluster.addLayers(markers);
    };

    focusOnSearchNameIfNeeded = () => {
        const tLayer = new L.FeatureGroup();
        if (this.searchName && this.devices && this.links) {
            this.devices.forEach((device) => {
                if (device.highlight) {
                    const pointLatLon = this.getDeviceLatLon(device);
                    L.marker(pointLatLon).addTo(tLayer);
                }
            });

            this.links.forEach((link) => {
                if (link.highlight) {
                    const fromDevice = this.devices.find((x) => x.id === link.from);
                    const toDevice = this.devices.find((x) => x.id === link.to);
                    const pointLatLon1 = this.getDeviceLatLon(fromDevice);
                    const pointLatLon2 = this.getDeviceLatLon(toDevice);
                    L.polyline([pointLatLon1, pointLatLon2]).addTo(tLayer);
                }
            });

            if (this.searchName && tLayer.getLayers().length) {
                this.map.fitBounds(tLayer.getBounds());
            }
            this.searchName = undefined;
        }
    };

    setChildLatLonId = (childMarkers, visiblePointsMap, latLonId) => {
        childMarkers.forEach((marker) => {
            visiblePointsMap[marker.geoPointId] = +latLonId;
        });
    };

    getLines = (visibleLinks, visiblePoints, cluster, counter, links) => {
        let lineStatus = '';
        const lines = {};
        cluster._nonPointGroup.clearLayers();

        if (!this.isMapAlive()) {
            return;
        }
        const bounds = this.getBounds();
        const screenLine = [
            [bounds.getNorth(), bounds.getWest()],
            [bounds.getNorth(), bounds.getEast()],
            [bounds.getSouth(), bounds.getEast()],
            [bounds.getSouth(), bounds.getWest()],
            [bounds.getNorth(), bounds.getWest()],
        ];

        const screenPoly = turf.lineString(screenLine);

        Object.values(visibleLinks).forEach((visibleLink: any) => {

            if (counter !== this.drawLinesDebounceCounter) {
                return;
            }

            const line = visibleLink;

            const lineColor = this.dataItemToLinkMapper.getStatusColor(line.innerLinks);

            lineStatus = line.statusSeverityLevel;
            if (line.innerLinks.length > 1) {
                line.innerLinks.forEach((link) => {
                    lineStatus = lineStatus > link.statusSeverityLevel ? lineStatus : link.statusSeverityLevel;
                });
            }
            lineStatus = 'status' + lineStatus;

            let fromDeviceObj = line.from;
            let toDeviceObj = line.to;

            let fromPoint;
            let toPoint;

            const lineFromId = line.from && (line.from.id || line.from);
            const lineToId = line.to && (line.to.id || line.to);

            if (links.length >= 1000) {
                if (!visiblePoints[lineFromId] && !visiblePoints[lineToId]) {
                    return;
                }
            }

            if (visiblePoints[lineFromId]) {
                fromDeviceObj = visiblePoints[lineFromId];
                fromPoint = lineFromId ? fromDeviceObj._latlng : 0;
            } else {
                fromPoint = this.isLatLngMap ? new L.LatLng(fromDeviceObj.lat, fromDeviceObj.lon) : new L.LatLng(-fromDeviceObj.y, fromDeviceObj.x);
            }

            if (visiblePoints[lineToId]) {
                toDeviceObj = visiblePoints[lineToId];
                toPoint = lineToId ? toDeviceObj._latlng : 0;
            } else {
                toPoint = this.isLatLngMap ? new L.LatLng(toDeviceObj.lat, toDeviceObj.lon) : new L.LatLng(-toDeviceObj.y, toDeviceObj.x);
            }

            if (links.length < 1000 && line.outOfMap) {
                const poly2 = turf.lineString([[fromPoint.lat, fromPoint.lng], [toPoint.lat, toPoint.lng]]);
                let intersection;
                try {
                    intersection = turflineIntersect.default(screenPoly, poly2);
                } catch (e) {
                    intersection = {features: {length: 1}};
                }

                if (intersection.features.length === 0) {
                    return;
                }
            }

            let linkLine = null;

            let lineTooltip;
            const toolTip: any = {
                permanent: true,
                interactive: true,
                className: ''
            };

            const isFiltered = line.innerLinks.reduce((acc, cur) => acc || cur.filtered, false);


            if (line.innerLinks.length > 1) {
                lineTooltip = this.acGeoVisualsService.getLinkTooltip(line.innerLinks, false, this.isShowLabel);
                linkLine = L.polyline([fromPoint, toPoint], this.acGeoVisualsService.getLinkPolyline(line, line.type, lineStatus, undefined, isFiltered));
                linkLine.innerLinks = line.innerLinks;
                toolTip.className += lineStatus + ' linkClusterContainer';
            } else {
                lineTooltip = this.acGeoVisualsService.getLinkTooltip(line.innerLinks[0], true, this.isShowLabel);
                linkLine = L.polyline([fromPoint, toPoint], this.acGeoVisualsService.getLinkPolyline(line, line.type, lineStatus, lineTooltip, isFiltered));
                toolTip.direction = 'bottom';
                toolTip.className = 'ac-geo-links-tooltip';
                if (!isFiltered) {
                    toolTip.className += ' filtered';
                }
                if (line.innerLinks[0].name) {
                    toolTip.className += ' name-' + this.generalService.generateIdForText(line.innerLinks[0].name);
                }

                const patterns = [];

                if (line.innerLinks[0].direction === 'INGRESS') {
                    this.updateColorAndPush(this.ingress_arrow_1, this.ingress_arrow_2, patterns, lineColor, isFiltered);
                } else if (line.innerLinks[0].direction === 'EGRESS') {
                    this.updateColorAndPush(this.egress_arrow_1, this.egress_arrow_2, patterns, lineColor, isFiltered);
                }
                this.updateColorAndPush(this.source_arrow_1, this.source_arrow_2, patterns, lineColor, isFiltered);

                L.polylineDecorator(linkLine, {
                    patterns
                }).addTo(cluster._nonPointGroup);
            }

            linkLine.filtered = !!isFiltered;

            const isSelected = this.geoSelectionService.isItemSelected(line.innerLinks);
            if (isSelected) {
                toolTip.className += ' selected';
            }
            if (!isFiltered) {
                toolTip.className += ' filtered';
            }
            if (lineTooltip) {
                linkLine.bindTooltip(lineTooltip, toolTip);
            }


            this.linkLineMouseOver(linkLine, line.innerLinks);
            linkLine.link = line.innerLinks[0];
            this.attachLinkLineClick(linkLine);

            this.sortBySeverity(line.innerLinks);

            lines[linkLine.link.id] = linkLine;
        });

        return lines;
    };

    updateColorAndPush = (arrow_1, arrow_2, patterns, lineColor, isFiltered) => {
        const arrow_1t = _.cloneDeep(arrow_1);
        const arrow_2t = _.cloneDeep(arrow_2);

        arrow_1t.symbol.options.pathOptions.color = lineColor;
        arrow_1t.symbol.options.pathOptions.opacity = this.acGeoVisualsService.getLinkOpacity(isFiltered);

        arrow_2t.symbol.options.pathOptions.color = lineColor;
        arrow_2t.symbol.options.pathOptions.opacity = this.acGeoVisualsService.getLinkOpacity(isFiltered);

        patterns.push(arrow_1t);
        patterns.push(arrow_2t);
    };

    linkLineMouseOver = (linkLine, links) => {
        linkLine.on('mouseover', (e) => {
            const toElement = e.originalEvent.toElement || e.originalEvent.originalTarget || e.toElement || e.relatedTarget || e.target || function() {
                throw new Error('Failed to attach an event target!');
            };

            if (!this.userInteractionInProgress()) {
                let pointll;
                let hoverOnLink = false;
                if ($(toElement).hasClass('leaflet-tooltip') || $(toElement).parents('.leaflet-tooltip').length > 0) {
                    hoverOnLink = true;
                    pointll = L.latLng((linkLine._latlngs[0].lat + linkLine._latlngs[1].lat) / 2, (linkLine._latlngs[0].lng + linkLine._latlngs[1].lng) / 2, true);
                } else {
                    pointll = e.latlng;
                }

                if (links.length === 1) {
                    this.openPopupForShape(linkLine, 'dataItemToLinkMapper', 'getCompiledTooltip', links[0], false, pointll, e, hoverOnLink);
                } else {
                    this.openPopupForShape(linkLine, 'dataItemToLinkMapper', 'getCompiledLinksTooltip', links, false, pointll, e);
                }
            }

        });
    };

    doLinkSelection = (e, linkObject) => {
        const event = e.originalEvent || e;
        if (event.ctrlKey) {
            this.geoSelectionService.toggleItem(linkObject);
        } else {
            this.geoSelectionService.clearSelection(true);
            this.geoSelectionService.selectLink(linkObject);
        }
    };

    linkLineClick = (e, linkLine, preventClear?) => {
        const event = e.originalEvent || e;
        if (!preventClear && !event.ctrlKey && !event.altKey) {
            this.geoSelectionService.clearSelection();
        }

        if (linkLine.innerLinks) {
            linkLine.innerLinks.forEach((link) => {
                const linkObject = Object.assign({}, linkLine);
                linkObject.link = link;
                this.doLinkSelection(e, linkObject);
            });
        } else {
            this.doLinkSelection(e, linkLine);
        }
    };

    attachLinkLineClick = (linkLine) => {
        if (linkLine.filtered) {
            linkLine.on('click', (e) => {
                this.linkLineClick(e, linkLine);
            });
        }
    };

    cancelLastPopupTimeout = () => {
        if (this.lastPopup) {
            clearInterval(this.lastPopup);
        }
    };

    openPopupForShape = (marker, mapperFn, getTooltipFn, shapeObj, isMarker, popupPosition, event, hoverOnLink?) => {
        if (!_.isFunction(this[mapperFn][getTooltipFn])) {
            return;
        }
        if (this.lockedPopupVisible || this.userInteractionInProgress()) {
            return;
        }

        const hover_bubble = new L.Rrose({
            offset: new L.Point(0, 0),
            closeButton: false,
            autoPan: false,
            southPadding: isMarker ? 10 : 0,
            northPadding: isMarker ? 10 : (hoverOnLink ? 30 : 0)
        });

        this.popupComponentRef = this[mapperFn][getTooltipFn](shapeObj, isMarker);
        const element = event.layer ? $(event.layer.getElement()) : $(event.originalEvent.srcElement || event.originalEvent.target);
        const mapContainer = this.map.getContainer();

        this.cancelLastPopupTimeout();
        this.lastPopup = ACSetTimeout(this, () => {
            hover_bubble.setContent(this.popupComponentRef.location.nativeElement);
            hover_bubble.setLatLng(popupPosition);

            hover_bubble.openOn(this.map);
            const popup = hover_bubble.getElement();

            const mouseLeftPopup = (e) => {
                const toElement = e.originalEvent.toElement || e.toElement || e.relatedTarget || e.target || function() {
                    throw new Error('Failed to attach an event target!');
                };
                if ($(toElement).is($(mapContainer)) || !$.contains(mapContainer, toElement)) {
                    this.closeOpenPopups();
                }
            };
            $(popup).one('mouseleave', mouseLeftPopup);

            $(popup).one('click', (e) => {
                this.lockedPopupVisible = true;
                $(popup).off('mouseleave', mouseLeftPopup);
            });
        }, 300);

        const onElementLeave = (e) => {
            const toElement = e.originalEvent.toElement || e.toElement || e.relatedTarget || e.target || function() {
                throw new Error('Failed to attach an event target!');
            };
            if ($(toElement).is($(mapContainer)) || !$.contains(mapContainer, toElement)) {
                this.closeOpenPopups();
                element.off('mouseout');
            }
        };

        element.on('mouseout', onElementLeave);

    };

    sortBySeverity = (objs) => {
        objs.sort((a, b) => b.statusSeverityLevel - a.statusSeverityLevel);
    };

    getMaxSeverityLevel = (childMarkers) => {
        let maxSeverity = -10;
        childMarkers.forEach((obj) => {
            if (obj.statusSeverityLevel > maxSeverity) {
                maxSeverity = obj.statusSeverityLevel;
            }
        });
        return maxSeverity;
    };

    initialization = () => {
        L.Map.mergeOptions({boxZoom: false}); // Disable circle zoom (by shift and dragging)(causes all click events to be disabled after close circle with ESC)
        this.initializeMap(this.elLeaflet.nativeElement);

        this.onResize();
        this.getDevicesAndLinks();
    };

    initalizeClusterState = () => {
        if (this.cluster) {
            if (this.isSpreadMode) {
                this.cluster.options.removeOutsideVisibleBounds = false;
                this.cluster.freezeAtZoom(200);

            } else {
                this.cluster.options.removeOutsideVisibleBounds = true;
                this.cluster.enableClustering();
            }
        }
    };

    resizeMap = () => {
        ACSetTimeout(this, () => {
            if (this.isMapAlive()) {
                this.map.invalidateSize();
                this.showMap = true;
            }
        });
    };

    setClusterClassName = (childCount, classNameCluster) => {
        if (childCount < 10) {
            return classNameCluster += 'small';
        } else if (childCount < 100) {
            return classNameCluster += 'medium';
        } else {
            return classNameCluster += 'large';
        }
    };

    setLines = (newLink, line, visiblePoints, acc, outOfMap) => {
        if (!line) {

            line = {
                type: null,
                from: newLink.from,
                to: newLink.to,
                status: newLink.status,
                statusSeverityLevel: newLink.statusSeverityLevel,
                innerLinks: [newLink],
                outOfMap
            };

            line.type = (visiblePoints[line.from] && !visiblePoints[line.from].device || visiblePoints[line.to] && !visiblePoints[line.to].device) ? 'semi' : 'real';

            acc[newLink.fromto] = line;
        } else {
            line.innerLinks.forEach((link) => {

                if (link.statusSeverityLevel > line.statusSeverityLevel) {
                    line.status = link.status;
                    line.statusSeverityLevel = link.statusSeverityLevel;
                }
            });
            line.innerLinks.push(newLink);
        }
    };

    getVisibleLinks = (links, visiblePoints, visiblePointsMap) => links.reduce((acc, link) => {
        const newLink = _.cloneDeep(link);

        newLink.from = visiblePointsMap[link.from];
        newLink.to = visiblePointsMap[link.to];

        let min = Math.min(newLink.from, newLink.to);
        let max = Math.max(newLink.from, newLink.to);

        const outOfMap = (newLink.from === undefined && newLink.to === undefined);

        if (newLink.from === undefined || newLink.to === undefined) {
            if (newLink.from === undefined && newLink.to === undefined) {
                newLink.from = this.mappedDevices[link.from];
                newLink.to = this.mappedDevices[link.to];

                if (!newLink.from || !newLink.to) {
                    return acc;
                }

                min = Math.min(newLink.from.id, newLink.to.id);
                max = Math.max(newLink.from.id, newLink.to.id);
            } else if (newLink.from === undefined) {
                newLink.from = this.mappedDevices[link.from];

                if (!newLink.from) {
                    return acc;
                }

                min = Math.min(newLink.from.id, newLink.to);
                max = Math.max(newLink.from.id, newLink.to);
            } else if (newLink.to === undefined) {
                newLink.to = this.mappedDevices[link.to];

                if (!newLink.to) {
                    return acc;
                }

                min = Math.min(newLink.from, newLink.to.id);
                max = Math.max(newLink.from, newLink.to.id);
            }
        }

        if (newLink.from === newLink.to) {
            return acc;
        }

        newLink.fromto = '' + min + '-' + max;

        newLink.statusSeverityLevel = this.getStatus(newLink.highlight ? 'HIGHLIGHT' : newLink.status);

        const line = acc[newLink.fromto];
        this.setLines(newLink, line, visiblePoints, acc, outOfMap);

        return acc;
    }, {});

    getStatus = (newObjectStatus) => {
        const status = {
            UNMONITORED: '-10',
            OK: '0',
            WARNING: '10',
            ERROR: '20',
            HIGHLIGHT: '30'
        };
        return status[newObjectStatus] || '0';
    };

    getVisiblePointsMap = (visiblePoints) => {

        const visiblePointsMap = {};
        Object.entries(visiblePoints).forEach(([latLonId, visiblePoint]: any[]) => {

            if (visiblePoint.device) {
                visiblePointsMap[visiblePoint.geoPointId] = +latLonId;
            } else {
                const childMarkers = visiblePoint.getAllChildMarkers();
                this.setChildLatLonId(childMarkers, visiblePointsMap, latLonId);
            }
        });

        return visiblePointsMap;
    };

    ngOnDestroy() {
        this.cluster && this.cluster.clearLayers();
        this.linksLayer && this.linksLayer.clearLayers();
        this.map.off();
        this.map.remove();
        this.cancelLastPopupTimeout();
        clearInterval(this.intervalDrawLines);
        clearTimeout(this.drawLinesDebounceTimer);
    }

    private doDrawLines = (lines, cluster, currentCounter) => {


        this.isCalculatingLinks = true;
        ACSetTimeout(this, () => {
            if (!this.linksLayer) {
                this.linksLayer = L.layerGroup();
                this.linksLayer.addTo(this.map);
            }
            this.linksLayer.clearLayers();


            Object.getOwnPropertyNames(lines).forEach((lineId: string) => {
                const line = lines[lineId];
                const oldLine = this.linesLayerObjects[line.link.id];

                if (oldLine && (!_.isEqual(oldLine._latlngs, line._latlngs) || !_.isEqual(oldLine.link, line.link))) {
                    oldLine.removeFrom(this.linesLayer);
                    this.linesLayerObjects[line.link.id] = line;
                } else if (!oldLine) {
                    this.linesLayerObjects[line.link.id] = line;
                }

                lines[lineId].addTo(this.linksLayer);
            });

            this.stopDrawingLines();
        });
    };

    private redrawLines = (force = false) => {
        if (force) {
            return this.refreshLinks$.forceTick();
        }
        this.refreshLinks$.tick();
    };

    private doRedrawLines = () => {
        if (this.links && this.cluster) {
            this.drawLines(this.links, this.cluster);
            this.focusOnSearchNameIfNeeded();
        } else {
            this.refreshLinks$.oneAtATimeFinished();
            this.refreshLinks$.forceTick(true);
        }
    };

    private drawLines = (links, cluster) => {
        if (this.linksRedrawTimeout) {
            return;
        }
        const currentCounter = ++this.drawLinesDebounceCounter;
        this.isLoading = true;

        ACSetTimeout(this, () => {

            const visiblePoints = cluster._featureGroup._layers;

            const visiblePointsMap = this.getVisiblePointsMap(visiblePoints);
            const visibleLinks = this.getVisibleLinks(links, visiblePoints, visiblePointsMap);

            const lines = this.getLines(visibleLinks, visiblePoints, cluster, currentCounter, links);

            if (this.drawLinesDebounceTimer) {
                clearTimeout(this.drawLinesDebounceTimer);
            }
            if (lines && Object.getOwnPropertyNames(lines).length > 0) {
                this.drawLinesDebounceTimer = ACSetTimeout(this, () => {
                    this.doDrawLines(lines, cluster, currentCounter);
                }, 0);
            } else if (this.linesLayer) {
                this.linksLayer && this.linksLayer.clearLayers();
                this.linesLayer.clearLayers();
                this.stopDrawingLines();
            }
        });
    };

    private stopDrawingLines = () => {
        this.isCalculatingLinks = false;
        this.syncSelections();
        clearInterval(this.intervalDrawLines);
        this.isLoading = false;
        this.cdRef.detectChanges();
        this.refreshLinks$.oneAtATimeFinished();
    };

    private setMapEvents() {
        this.map.on('popupclose', (e) => {
            this.lockedPopupVisible = null;
        });

        this.map.on('dragend', (event) => {
            this.redrawLines(true);
        });

        this.map.on('click', (e) => {
            if (e.originalEvent.shiftKey) {
                return;
            }

            if (this.lockedPopupVisible === null) {
                this.lockedPopupVisible = false;
            } else {
                this.acGeoEventsService.mapClickHandler(e);
            }
        });

        this.map.on('zoomend moveend', (event) => {
            this.isMapInitialized && this.updateStateRecorderValues();
        });

        this.map.on('zoomend', (event) => {
            this.isLoading = true;
            this.cdRef.detectChanges();
        });

        this.map.on('zoomstart', (event) => {
            this.closeOpenPopups();
        });
    }

    private isMapAlive = () => this.map._panes.length === undefined || this.map._panes.length > 0;

    private initCluster() {
        if (this.cluster) {
            this.cluster.clearLayers();
            this.cluster.removeFrom(this.map);
        }
        this.cluster = L.markerClusterGroup(this.buildMarkerClusterGroupObject());
        this.linkCreationService.cluster = this.cluster;
        this.cluster.on('clustermouseover', (e) => {
            this.openPopupForShape(this.cluster, 'dataItemToShapeMapper', 'getCompiledClusterTooltip', e.layer, true, e.layer.getLatLng(), e);
        });
        this.cluster._getExpandedVisibleBounds = function() {
            if (!this.options.removeOutsideVisibleBounds) {
                return this._mapBoundsInfinite;
            } else {
                return this._map.getBounds();
            }
        };
        this.initalizeClusterState();
        this.cluster.addTo(this.map);
    }

    private getBounds(): any {
        if (this.devices.length > 0) {
            return this.map.getBounds();
        } else {
            return {
                getNorth: () => 0,
                getSouth: () => 0,
                getEast: () => 0,
                getWest: () => 0
            };
        }
    }
}
