import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';

import {DynamicTabsService} from './dynamic-tabs.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcFuncPipe} from '../../../pipes/func.pipe';
import {GeneralService} from '../../../services/general.service';

@UntilDestroy()
@Component({
    selector: 'ac-nav-dynamic',
    templateUrl: './ac-nav-dynamic.component.html',
    styleUrls: ['./ac-nav-dynamic.component.less']
})
export class AcNavDynamicComponent {

    @ViewChild('openButton', {static: true}) openButton: ElementRef;
    @ViewChild('tabsContainer', {static: true}) tabsContainer: ElementRef;

    // @ContentChildren(AcNavButtonComponent) dynTabs: QueryList<AcNavButtonComponent>;
    urlToId;
    colors = GeneralService.classColors;

    constructor(public dynamicTabsService: DynamicTabsService,
                private elRef: ElementRef) {
        this.urlToId = AcFuncPipe.urlToId;
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dynamicTabsService.dynamicTabs$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateView();
            });
        this.updateView();
    }

    @HostListener('window:resize')
    calculateVisible = () => {
        if (this.tabsContainer) {
            const newArr = [];
            const children = this.tabsContainer.nativeElement.children;
            this.dynamicTabsService.anyHidden = false;
            for (let i = 0; i < children.length - 1; i++) {
                const ele = children[i];
                const isVisible = (ele.offsetLeft + ele.offsetWidth) < this.tabsContainer.nativeElement.offsetWidth;
                if (!isVisible) {
                    const prevEle = children[i - 1];
                    if (prevEle && (prevEle.offsetLeft + prevEle.offsetWidth) > (this.tabsContainer.nativeElement.offsetWidth - this.openButton.nativeElement.offsetWidth - 5)) {
                        newArr[i - 1] = false;
                    }
                    this.dynamicTabsService.anyHidden = true;
                }
                newArr[i] = isVisible;
            }
            newArr.forEach((value, index) => {
                this.dynamicTabsService.setVisible(index, value);
            });
        }
    };

    updateActive = (value, dtab, isActive) => {
        dtab.active = isActive;
        this.dynamicTabsService.updateHiddenTabsActive();
        return value;
    };

    private updateView() {
        setTimeout(() => {
            this.calculateVisible();
        });
    }
}
