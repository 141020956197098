import {WorkspaceResolver} from './resolver.model';
import {WorkspaceMigration} from '../workspace-migration.model';

export class BaseResolverClass extends WorkspaceMigration implements WorkspaceResolver {

    export = (...args) => args[0];
    import = (...args) => args[0];

    migrate = (...args) => args[0];
}
