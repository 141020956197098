import {Component, Inject, SkipSelf, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig, SchemaHelperService} from 'ac-infra';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {MetadataService} from '../../../metadata/metadata.service';
import {NetworkValidationsService} from '../../services/network-validations.service';
import {HttpConnectivityComponent} from '../../components/http-connectivity/http-connectivity.component';

@AcDialog({
    cancelButtonText: 'Close',
    id: 'audio-codes-device-dialog',
    width: 580,
    height: 480
})
@Component({
    selector: 'ac-device-dialog',
    templateUrl: './ac-device-dialog.component.html',
})
export class AcDeviceDialogComponent {

    FIRMWARE_FILES = ['CMP_TYPE', 'RMT_TYPE', 'RMS_TYPE'];
    CONF_FILES = ['CLI_SCRIPT_TYPE', 'CONF_TYPE', 'INI_STAND_ALONE_TYPE'];
    softwareFileList;

    preProvStatuses = {
        FAIL: {class: 'fail-status', viewName: 'Failed to Download'},
        SUCCESS: {class: 'success-status', viewName: 'Downloaded Successfully'},
        IN_PROGRESS: {class: 'in-progress-status', viewName: 'In Progress'},
        NOT_PERFORMED: {class: 'not-performed-status', viewName: 'Not Performed'}
    };

    firmwareFileOptionsList = [];
    configurationFileOptionsList: any;

    supportingProducts;
    selectedTenantFullObject: any;
    audioCodesDevice: any;

    isEdit: boolean;
    isMultiple: boolean;
    firstInitialConnectionState: boolean;
    productType: string;
    schema: any;
    deviceAddSchema = require('../../../../schema/post/devicesprocessor.json');
    deviceEditSchema = require('../../../../schema/put/devicesprocessor.json');
    audioCodesDevicesList;
    additionalSettings: any;
    configuredByItems = [
        {text: 'IP Address', value: 'ipAddress'},
        {text: 'FQDN', value: 'deviceFqdn'},
        {text: 'Serial Number', value: 'serialNum'},
    ];

    configuredByMultipleItems = [
        {text: 'IP Address Range', value: 'ipAddressRange'},
        {text: 'IP Address List', value: 'ipAddressList'},
        {text: 'Serial Number List', value: 'serialNumberList'},
    ];

    deviceSBATypesItems = [
        {text: 'IP Address', value: 'ipAddress'},
        {text: 'FQDN', value: 'fqdnName'},
    ];
    @ViewChild('httpElement') httpElement: HttpConnectivityComponent;

    constructor(private schemaHelperService: SchemaHelperService,
                private tenantsRestService: TenantsRestService,
                private networkValidationsService: NetworkValidationsService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                @SkipSelf() private acDialogComponent: AcDialogComponent) {
    }

    ngOnInit() {
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        this.schema = this.dialogConfig.dialogData.isEdit ? _.cloneDeep(this.deviceEditSchema) : _.cloneDeep(this.deviceAddSchema);
        this.schema.required = this.schema.required || [];

        this.isMultiple = this.dialogConfig.dialogData.isMultiple;

        this.dialogConfig.title = !this.isMultiple ? 'AC Device Details' : 'Multiple AC Devices Details';

        this.isEdit = this.dialogConfig.dialogData.isEdit;

        this.dialogConfig.preSubmit = () => this.preSubmit();

        this.audioCodesDevice = this.dialogConfig.dialogData.entity;
        this.additionalSettings = this.dialogConfig.dialogData.additionalSettings;
        this.additionalSettings = _.assign(this.additionalSettings, _.cloneDeep(this.audioCodesDevice));
        this.additionalSettings.sbaConfiguredBy = 'ipAddress';
        this.additionalSettings.tenantId = undefined;

        if(!this.isEdit){
            this.additionalSettings.configuredBy = this.isMultiple ? 'ipAddressRange' : 'ipAddress';
        }

        this.productType = this.audioCodesDevice.productType;

        this.firstInitialConnectionState = this.additionalSettings.initialConnectionEnabled;

        this.audioCodesDevice.location = this.audioCodesDevice.location || {};


        this.initializeFirmwareVersionAndSupportingProducts();
        this.initializeOptionListArrays(this.dialogConfig.dialogData);

        if (this.isEdit && this.audioCodesDevice.sbcInfo.SBAsInfo) {
            this.audioCodesDevice.sbcInfo.SBAsInfo = _.pickBy(this.audioCodesDevice.sbcInfo.SBAsInfo, (value) => value !== null && value !== '');
        }

        this.acDialogComponent.optionsChanged();
    }

    updateFirmwareVersionAndSupportingProducts = (fileId) => {
        const file = this.firmwareFileOptionsList.find((option) => option.id === fileId);

        if (file) {
            this.additionalSettings.swVersion = file.swVersion;

            this.supportingProducts = [];
            _.forOwn(file.productTypes, (productType) => {
                this.supportingProducts.push(this.audioCodesDevicesList[productType].viewName);
            });
        }
    };

    tenantChanged = (tenantId) => {
        const selectedTenant = this.tenantsRestService.getEntityById(tenantId);
        this.handleTenantChange();
        this.updateFilesAccordingToTenantChange(selectedTenant);
        this.initializeFirmwareVersionAndSupportingProducts();
        this.initializeOptionListArrays(this.dialogConfig.dialogData);
    };

    configuredByChanged = (value) => {
        this.additionalSettings.configuredBy = value;

        (value === 'deviceFqdn' || value === 'serialNum') && delete this.audioCodesDevice.ipAddress;


        if (value === 'deviceFqdn' || value === 'ipAddress') {
            delete this.audioCodesDevice.sbcInfo.serialNum;
            delete this.audioCodesDevice.sbcInfo.secondSerialNum;
        }

        (value === 'ipAddress' || value === 'serialNum') && delete this.audioCodesDevice.sbcInfo.deviceFqdn;
    };

    sbaConfiguredByChanged = (value) => {
        if (this.additionalSettings.sbaEnabled) {
            this.additionalSettings.sbaConfiguredBy = value;
            delete this.audioCodesDevice.sbcInfo.SBAsInfo[value === 'fqdnName' ? 'ipAddress' : 'fqdnName'];
        }
    };

    isSbaCanBeConfigured = (configName) => this.isEdit || this.additionalSettings.sbaConfiguredBy !== configName || !this.additionalSettings.sbaEnabled;

    validateIpRangeFields = (errors) => {
        const fromIPv4 = this.schemaHelperService.validateIPAddress(this.additionalSettings.fromIpAddress, 'ipv4');
        const toIPv4 = this.schemaHelperService.validateIPAddress(this.additionalSettings.toIpAddress, 'ipv4');

        const fromIPv6 = this.schemaHelperService.validateIPAddress(this.additionalSettings.fromIpAddress, 'ipv6');
        const toIPv6 = this.schemaHelperService.validateIPAddress(this.additionalSettings.toIpAddress, 'ipv6');

        if ((fromIPv4 && toIPv4) || (fromIPv6 && toIPv6)) {
            const split = this.schemaHelperService.split(this.additionalSettings.fromIpAddress, this.additionalSettings.toIpAddress, fromIPv4 ? '.' : ':');

            if (split.fromStart !== split.toStart || parseInt(split.fromEnd, 10) > parseInt(split.toEnd, 10)) {
                errors.push(this.schemaHelperService.buildErrorItem({
                    instancePath: '/fromIpAddress',
                    inputName: 'additionalSettings.fromIpAddress',
                    keyword: 'IPRangeAux'
                }));
                errors.push(this.schemaHelperService.buildErrorItem({
                    instancePath: '/toIpAddress',
                    inputName: 'additionalSettings.toIpAddress',
                    keyword: 'IPRangeAux'
                }));
            }
        } else if(fromIPv4 || toIPv4 || fromIPv6 || toIPv6){
            (toIPv4 || toIPv6) && errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/fromIpAddress',
                inputName: 'additionalSettings.fromIpAddress',
                keyword: 'IPvIdentical'
            }));
            (fromIPv4 || fromIPv6) && errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/toIpAddress',
                inputName: 'additionalSettings.toIpAddress',
                keyword: 'IPvIdentical'
            }));
        } else {
            errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/fromIpAddress',
                inputName: 'additionalSettings.fromIpAddress',
                keyword: 'IPv4or6Aux'
            }));
            !toIPv4 && !toIPv4 && errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/toIpAddress',
                inputName: 'additionalSettings.toIpAddress',
                keyword: 'IPv4or6Aux'
            }));
        }
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (this.isMultiple) {

            this.additionalSettings.configuredBy === 'ipAddressRange' && this.validateIpRangeFields(errors);

            if (this.additionalSettings.configuredBy === 'ipAddressList' && this.additionalSettings.ipAddressList.length > 0) {
                _.each(this.additionalSettings.ipAddressList, (ip, index) => {
                    if (!this.schemaHelperService.validateIPAddress(ip, 'both')) {
                        const error = this.schemaHelperService.buildErrorItem({
                            instancePath: '/ipAddressList/' + index, inputName: 'additionalSettings.ipAddressList',
                            isArray: true, index, keyword: 'IPv4or6Aux'
                        });
                        errors.push(error);
                    }
                });
            }

            if (this.additionalSettings.configuredBy === 'serialNumberList' && this.additionalSettings.serialNumberList.length > 0) {
                _.each(this.additionalSettings.serialNumberList, (serial, index) => {
                    const error = this.schemaHelperService.buildErrorItem({
                        instancePath: '/serialNumberList/' + index, inputName: 'additionalSettings.serialNumberList',
                        isArray: true, index, keyword: 'SerialLimitsAux'
                    });

                    ((serial.length < 6 || serial.length > 50) || !this.schemaHelperService.validateSerialNumber(serial)) && errors.push(error);
                });
            }
        } else {
            this.networkValidationsService.validateNetworkEntityName('device', this.additionalSettings.name)(errors, auxModel, formModel);
            if (this.additionalSettings.configuredBy === 'ipAddress' && this.audioCodesDevice.ipAddress !== undefined && !this.schemaHelperService.validateIPAddress(this.audioCodesDevice.ipAddress, 'both')) {
                const error = this.schemaHelperService.buildErrorItem({
                    instancePath: '/ipAddress',
                    inputName: 'audioCodesDevice.ipAddress',
                    keyword: 'IPv4or6Aux'
                });
                errors.unshift(error);
            }
        }
    };

    requiredsAuxiliaryForm = () => {
        const requiredFields = [];
        const configBy = this.additionalSettings.configuredBy;

        if (this.isMultiple) {
            configBy === 'ipAddressRange' && requiredFields.push('additionalSettings.fromIpAddress');
            configBy === 'ipAddressRange' && requiredFields.push('additionalSettings.toIpAddress');
            configBy !== 'ipAddressRange' && requiredFields.push('additionalSettings.' + configBy);
        } else if (!this.isEdit) {
            requiredFields.push('audioCodesDevice.' + (configBy === 'ipAddress' ? 'ipAddress' : ('sbcInfo.' + configBy)));
            this.additionalSettings.sbaEnabled && requiredFields.push('audioCodesDevice.sbcInfo.SBAsInfo.' + this.additionalSettings.sbaConfiguredBy);
        }

        return requiredFields;
    };

    snmpChanged = () => {
        if(this.audioCodesDevice?.snmpInfo?.snmpVersion){
            if(this.audioCodesDevice?.snmpInfo?.snmpVersion === 'SNMP_VERSION_2C'){
                delete this.audioCodesDevice?.snmpInfo?.snmpUserProfileInfo;
            }else{
                delete this.audioCodesDevice?.snmpInfo?.snmpReadCommunity;
                delete this.audioCodesDevice?.snmpInfo?.snmpWriteCommunity;
            }
        }
    };


    initialConnectionEnabledChanged = () => {
        if (this.additionalSettings.initialConnectionEnabled) {
            this.audioCodesDevice.sbcInfo.preProvInfo = this.audioCodesDevice.sbcInfo.preProvInfo || {};
            this.updatePreProvInfoProperties();
        } else {
            this.additionalSettings.swVersion = undefined;
            this.supportingProducts = undefined;
            delete this.audioCodesDevice.sbcInfo.preProvInfo;
        }
    };

    sbaEnabledChanged = ($event) => {
        if ($event) {
            this.audioCodesDevice.sbcInfo.SBAsInfo = {};
        } else {
            delete this.audioCodesDevice.sbcInfo.SBAsInfo;
        }
    };

    isFirstConnectionCheckboxDisabled = () => {
        if (this.productType === 'UNKNOWN' && this.firstInitialConnectionState === false) {
            return false;
        }

        return this.isEdit;
    };

    isFirstConnectionInputDisabled = () => {
        if(!this.additionalSettings.initialConnectionEnabled){
            return true;
        }

        if (this.audioCodesDevice.sbcInfo.preProvInfo !== undefined && this.audioCodesDevice.sbcInfo.preProvInfo.state === 'NOT_PERFORMED') {
            return false;
        }

        return this.firstInitialConnectionState || (this.isEdit && this.productType !== 'UNKNOWN');
    };

    preSubmit = () => {
        if (this.firstInitialConnectionState && this.isEdit) {
            delete this.audioCodesDevice.sbcInfo.preProvInfo;
        }

        if(this.isEdit && this.audioCodesDevice.snmpInfo?.snmpVersion === 'SNMP_VERSION_3' && _.isEmpty(this.audioCodesDevice.snmpInfo?.snmpUserProfileInfo)){
            delete this.audioCodesDevice.snmpInfo.snmpVersion;
        }

        if (this.additionalSettings.initialConnectionEnabled && this.audioCodesDevice.sbcInfo.preProvInfo) {
            this.audioCodesDevice.sbcInfo.preProvInfo.cmpVersion = this.additionalSettings.swVersion ? this.additionalSettings.swVersion : '';
        }
    };

    handleTenantChange = () => {
        if (this.additionalSettings.tenantId !== -1 && !this.isEdit) {
            this.selectedTenantFullObject = this.tenantsRestService.getEntityById(this.additionalSettings.tenantId);

            setTimeout(() => {// tenantChange fired before httpObject is ready
                if (!this.isEdit && this.selectedTenantFullObject?.snmpProfile && this.selectedTenantFullObject?.httpProfile && this.httpElement?.originalHTTPObject) {
                    this.httpElement.originalHTTPObject.gatewayUser = this.selectedTenantFullObject.httpProfile.gatewayUser;
                    this.httpElement.originalHTTPObject.gatewayPassword = this.selectedTenantFullObject.httpProfile.gatewayPassword;
                    this.httpElement.originalHTTPObject.httpsConnection = this.selectedTenantFullObject.httpProfile.httpsConnection;
                }
            })
        } else {
            this.selectedTenantFullObject = undefined;
        }
    };

    initializeOptionListArrays = (optionsListObject) => {
        this.softwareFileList = optionsListObject.softwareFileList;

        if(this.isEdit || !this.httpElement?.useDefaultHTTPSettings){
            this.audioCodesDevice.sbcInfo.gatewayUser = this.audioCodesDevice.sbcInfo.gatewayUser || 'Admin';
        }

        if (this.isMultiple) {
            this.additionalSettings.ipAddressList = this.additionalSettings.ipAddressList || [];
            this.additionalSettings.serialNumberList = this.additionalSettings.serialNumberList || [];
        }

        this.audioCodesDevice.sbcInfo.preProvInfo && this.updateFirmwareVersionAndSupportingProducts(this.audioCodesDevice.sbcInfo.preProvInfo.cmpFileId);
    };

    updateFilesAccordingToTenantChange = (selectedTenant) => {
        this.firmwareFileOptionsList = this.filterFilesByTypeAndTenant(selectedTenant, this.FIRMWARE_FILES);
        this.firmwareFileOptionsList.unshift({id: -1 , viewName: ''});
        this.configurationFileOptionsList = this.filterFilesByTypeAndTenant(selectedTenant, this.CONF_FILES);

        this.audioCodesDevice.sbcInfo.preProvInfo && this.updatePreProvInfoProperties();
    };

    updatePreProvInfoProperties = () => {
        this.audioCodesDevice.sbcInfo.preProvInfo.iniFileId = this.audioCodesDevice.sbcInfo.preProvInfo.iniFileId ||
            (this.configurationFileOptionsList.length > 0 ? this.configurationFileOptionsList[0].id : undefined);

        this.audioCodesDevice.sbcInfo.preProvInfo.cmpFileId = this.audioCodesDevice.sbcInfo.preProvInfo.cmpFileId ||
            (this.firmwareFileOptionsList.length > 0 ? this.firmwareFileOptionsList[0].id : undefined);
    };

    filterFilesByTypeAndTenant = (selectedTenant, type) => {
        return this.softwareFileList.filter((file) => {
            const tenantOrSystem = (file.tenantId === -1 || !selectedTenant) ? 'System' : selectedTenant.name;
            file.viewName = file.name + ' (' + tenantOrSystem + ')';
            return (type.indexOf(file.fileType) >= 0) && (file.tenantId === -1 || file.tenantId === selectedTenant.id);
        });
    };

    initializeFirmwareVersionAndSupportingProducts = () => {
        this.additionalSettings.swVersion = undefined;
        this.supportingProducts = undefined;
    };

    iniFileIdChanged = (ev) => {
        this.audioCodesDevice.sbcInfo.preProvInfo = this.audioCodesDevice.sbcInfo.preProvInfo || {};
        this.audioCodesDevice.sbcInfo.preProvInfo.iniFileId = ev;
    };

    cmpFileIdChanged = (ev) => {
        this.audioCodesDevice.sbcInfo.preProvInfo = this.audioCodesDevice.sbcInfo.preProvInfo || {};
        this.audioCodesDevice.sbcInfo.preProvInfo.cmpFileId = ev;
        this.updateFirmwareVersionAndSupportingProducts(ev);
    };
}
