import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';

@Injectable({providedIn: 'root'})
export class LicensePoolRestService extends Rest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'licensePool/allocations',
            entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter', entityType: 'allocations'});
    }

    public getLicensePool = (success, failure, parameters) => {
        this.get({parameters, extendParameters: true, filteredEntity: 'licensePool'}).then(success).catch(failure);
    };

    public getLicensePoolById = (success, failure, licensePoolId) => {
        this.getById({success, failure,id: licensePoolId, skipPopulateFilter: true});
    };

    public editLicensePool = (success, failure, licensePool) => {
        const licenseId = licensePool.id;
        this.edit(success, failure, licensePool, licenseId);
    };

    public editMultiLicensePool = (success, failure, selectedItems, licensePool) => {
        licensePool.devices = this.createDevicesArray(selectedItems);
        this.put(success, failure, licensePool);
    };

    public updateLicensePool = (success, failure, selectedItems) => {
        this.sendAddRequest(success, failure, selectedItems, 'topology/actions/updateLicensePool');
    };

    public refreshLicensePool = (success, failure, selectedItems) => {
        this.sendAddRequest(success, failure, selectedItems, 'topology/actions/refreshLicensePool');
    };

    public applyLicensePool = (success, failure, selectedItems) => {
        this.sendAddRequest(success, failure, selectedItems, 'topology/actions/applyHitlessLicensePool');
    };

    public updatePriorityLevel = (success, failure, selectedItems, priority: string) => {
        this.sendAddRequest(success, failure, selectedItems, 'floatingLicense/actions/flexPool/priority', priority);
    };

    sendAddRequest = (success, failure, selectedItems, url, priority?) => {
        const devices = this.createDevicesArray(selectedItems);
        const objectToServer: any = {devices};
        if (priority) {
            objectToServer.priority = priority;
        }
        this.add(success, failure, objectToServer, url);
    };

    private createDevicesArray = (selectedItems) => selectedItems.map((item) => item.regionId + '/' + item.id);
}
