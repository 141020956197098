<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="additionalSettings"
         [formModel]="link"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="link">

    <div id="link-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Name">
                <ac-textfield [(acModel)]="link.name"
                              [attr.alternativeName]="'link.name'"
                              textFieldId="link-details-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container label="Direction">
                <ac-single-select [(acModel)]="link.direction"
                           [attr.alternativeName]="'link.direction'"
                           [bindLabel]="'viewName'"
                           [bindValue]="'name'"
                           [items]="directionOptionsList"
                           [ngDisabled]="selectedSrcDevice && selectedSrcDevice.productType && selectedSrcDevice.productType.includes('LYNC')">
                </ac-single-select>
            </ac-input-container>
        </div>

        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Source Device">
                <ac-single-select [acModel]="link.linkSrcId || additionalSettings.linkSrcId"
                           (acModelChange)="link.linkSrcId = $event; updateCategoryTypeOptionList($event)"
                           [attr.alternativeName]="'link.linkSrcId'"
                           [bindLabel]="'name'"
                           [bindValue]="'id'"
                           [items]="srcDeviceTypeOptionsList"
                           [ngDisabled]="isEdit">
                </ac-single-select>
            </ac-input-container>

            <ac-input-container label="Destination Device">
                <ac-single-select [acModel]="link.linkDstId || additionalSettings.linkDstId"
                           (acModelChange)="link.linkDstId = $event;linkDstDeviceChanged($event)"
                           [attr.alternativeName]="'link.linkDstId'"
                           optionTitle="destination device can not be the same as source device"
                           [bindLabel]="'name'"
                           [bindValue]="'id'"
                           [items]="dstDeviceTypeOptionsList"
                           [ngDisabled]="isEdit">
                </ac-single-select>
            </ac-input-container>
        </div>

        <ac-input-container label="Category Type">
            <ac-single-select [acModel]="(link.type && link.type.categoryType) || additionalSettings.type.categoryType"
                       (acModelChange)="link.type.categoryType = $event; onCategoryTypeChange()"
                       [attr.alternativeName]="'link.type.categoryType'"
                       [bindLabel]="'viewName'"
                       [sortItems]="true"
                       [bindValue]="'name'"
                       [items]="linkTypeOptionsList"
                       [ngDisabled]="isEdit || additionalSettings.linkSrcId === -1">
            </ac-single-select>
        </ac-input-container>

        <ac-input-container *ngIf="!categoriesValuesAreReady" [hideLabel]="true"
                            class="categories-values-progress-container">
            <ac-progress size="small"></ac-progress>
        </ac-input-container>

        <div class="flexRowParentEqualChildren">
            <ac-input-container [label]="categoryValueText" *ngIf="categoryValueText && categoriesValuesAreReady">
                <ac-single-select [(acModel)]="additionalSettings.categoryValue"
                           (acModelChange)="onCategoryValueChange($event)"
                           [addCustomTags]="true"
                           [attr.alternativeName]="'additionalSettings.categoryValue'"
                           [useFullObjectInModel]="true"
                           [items]="categoryValueOptions"
                           [ngDisabled]="isEdit || disableCategoryValue">
                </ac-single-select>
            </ac-input-container>

            <ac-input-container [label]="subCategoryValueText" *ngIf="isSecondaryCategoryValueNeeded() && categoriesValuesAreReady">
                <ac-single-select  [(acModel)]="additionalSettings.categorySecondaryValue"
                            (acModelChange)="categorySecondaryValueChanged($event)"
                            [addCustomTags]="true"
                            [attr.alternativeName]="'additionalSettings.categorySecondaryValue'"
                            [useFullObjectInModel]="true"
                            [ngDisabled]="isEdit || disableSubCategoryValue"
                            [items]="subCategoryValueOptions">
                </ac-single-select>
            </ac-input-container>
        </div>

        <ac-input-container *ngIf="showLdapGroup()" label="AD Group">
            <ac-textfield [(acModel)]="link.ldapGroupName"
                          [attr.alternativeName]="'link.ldapGroupName'"
                          [ngDisabled]="!authorizedForAdmins"
                          placeholderText="Only for Monitor Links Operators">
            </ac-textfield>
        </ac-input-container>
    </div>
</ac-form>
