import {Injectable} from '@angular/core';
import {SchemaHelperService, WSEntities} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class NetworkValidationsService {

    previousName;
    previousParentId;
    error;
    entity;

    constructor(private wsEntitiesService: WSEntities,
                private schemaHelperService: SchemaHelperService) {}

    validateNetworkEntityName = (entityType, originalNameInEdit, parentId?,parentField?) => (errors, AuxModel, formModel) => {
        const modelName = entityType === 'device' ? 'audioCodesDevice' : entityType;
        this.error = this.schemaHelperService.buildErrorItem({instancePath: '/name', inputName: modelName + '.name', message: entityType + ' with this name already exist'});

        if(this.previousName !== formModel.name || this.previousParentId !== parentId){
            this.entity = this.wsEntitiesService.getEntitiesArray(entityType + 's').filter((entity) => {
                let identicalEntityFound = (entity.name === formModel.name) && (!parentField || (entity[parentField] === parentId));

                if(entityType === 'device'){
                    identicalEntityFound = identicalEntityFound && (entity.familyType === 'ACL' || entity.familyType === 'UNKNOWN');
                }

                if(originalNameInEdit && originalNameInEdit !== ''){
                    return identicalEntityFound && originalNameInEdit !== formModel.name;
                }else{
                    return identicalEntityFound;
                }
            });
        }

        if(this.entity && this.entity.length === 1){
            errors.push(this.error);
        }

        this.previousName = formModel.name;
        this.previousParentId = parentId;
    };
}
