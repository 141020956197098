import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig, SessionService} from 'ac-infra';
import {Router} from '@angular/router';

@AcDialog({
    id: 'ws-connection-lost-dialog',
    title: 'Connection Lost',
    width: 400,
})
@Component({
    selector: 'ws-connection-lost-dialog',
    templateUrl: './ws-connection-lost-dialog.component.html',
    styleUrls: ['./ws-connection-lost-dialog.component.less'],
})
export class wsConnectionLostDialogComponent {

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private sessionService: SessionService,
                private router: Router) {
        this.dialogConfig.onCancel = this.userClosed;
    }

    userClosed = () => {
        this.sessionService.endSession();
        this.router.navigateByUrl('login');
    };
}


