<ac-form *ngIf="dialogConfig.ready"
         [formModel]="deviceConfiguration"
         formModelName="deviceConfiguration">

    <span class="device-lock-message">{{confirmMsg}}</span>

    <div class="radio-button-lock-by-fwd-type-container">
        <ac-radio-buttons-group [(acModel)]="deviceConfiguration.lockBy"
                                [items]="lockByItems"
                                class="vertical" radioButtonId="radio-button-lock-by-fwd-type">
        </ac-radio-buttons-group>
    </div>
</ac-form>

<ng-template #spinner>
    <ac-input-container label="seconds" [upperPadding]="false">
        <ac-num-spinner [(acModel)]="selectedDevice.lockBySeconds"
                        [minValue]="0"
                        [ngDisabled]="deviceConfiguration.lockBy !== 'gracefulLockWithSeconds'"
                        numSpinnerId="lock-device-by-second-selection">
        </ac-num-spinner>
    </ac-input-container>
</ng-template>
