import {Component, Input, TemplateRef} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'ovoc-splash-logo',
    templateUrl: './ovoc-splash-logo.component.html',
    styleUrls: ['./ovoc-splash-logo.component.less'],
})
export class OvocSplashLogoComponent {
    constructor(public generalService: GeneralService) {
    }
}



