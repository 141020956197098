<div (click)="$event.stopPropagation()">
    <input *ngIf="false" type="text"/>
    <div [class.visibilityHidden]="hideRanges != undefined && !acModel[rangeEnableName]"
         [ngStyle]="{'width': width?width + 'px': ''}"
         id="ac-range-container">

        <label class="range-box-container">
            <div class="arrow-container">
                <ac-svg name="right-arrow" fillColor="#26BC06" width="16px" height="16px"></ac-svg>
            </div>
            <ac-input-container [borderAppearance]="true" [hideLabel]="true" [upperPadding]="false" [sideMargin]="false">
                <ac-num-spinner [(acModel)]="acModel[rangeStartName]" [isFloatNumber]="isFloatNumber"
                                [maxValue]="maxRange !== undefined ? maxRange: undefined"
                                [minValue]="minRange !== undefined ? minRange : undefined"
                                [ngDisabled]="ngDisabled" [step]="step"
                                numSpinnerId="range-start-{{rangeId}}">
                </ac-num-spinner>
            </ac-input-container>
            <div class="arrow-container">
                <ac-svg name="right-arrow" fillColor="#FF9C08" width="16px" height="16px"></ac-svg>
            </div>
        </label>

        <label class="range-box-container">
            <div class="arrow-container">
                <ac-svg name="right-arrow" fillColor="#FF9C08" width="16px" height="16px"></ac-svg>
            </div>
            <ac-input-container [borderAppearance]="true" [hideLabel]="true" [upperPadding]="false" [sideMargin]="false">
                <ac-num-spinner [(acModel)]="acModel[rangeEndName]" [isFloatNumber]="isFloatNumber"
                                [maxValue]="maxRange !== undefined ? maxRange: undefined"
                                [minValue]="minRange !== undefined ? minRange : undefined"
                                [ngDisabled]="ngDisabled" [step]="step"
                                numSpinnerId="range-end-{{rangeId}}">
                </ac-num-spinner>
            </ac-input-container>

            <div class="arrow-container">
                <ac-svg name="right-arrow" fillColor="#f05656" width="16px" height="16px"></ac-svg>
            </div>
        </label>

        <label *ngIf="!hideCheckbox" class="checkbox-container">
            <ac-checkbox [acModel]="acModel && acModel[rangeEnableName]"
                         (acModelChange)="rangeEnableChanged()"
                         [ngDisabled]="ngDisabled ? true : null"
                         [id]="rangeId"
                         class="range-checkbox">
            </ac-checkbox>

            <div class="bell-container">
                <ac-svg fillColor="#ef5350" name="bell"></ac-svg>
            </div>
        </label>
    </div>
</div>


