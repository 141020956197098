import {Injectable, SecurityContext} from '@angular/core';
import {GeneralService} from 'ac-infra';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class AcGeoVisualsService {


    LINE_WEIGHT = 3;
    dataItemToShapeMapper: any;
    dataItemToLinkMapper: any;

    constructor(private generalService: GeneralService, private domSanitizer: DomSanitizer) {

    }

    setDataItemToShapeMapper = (mapper) => {
        this.dataItemToShapeMapper = mapper;
    };

    setDataItemToLinkMapper = (mapper) => {
        this.dataItemToLinkMapper = mapper;
    };

    createMarkerDivIcon = (severityLevel, adminStatus, device) => {
        const markerType = this.dataItemToShapeMapper.getMarkerType(device);
        const isFilteredDevice = this.dataItemToShapeMapper.isFiltered(device);
        const isHighlight = this.dataItemToShapeMapper.isHighlighted(device);

        const classes = [
            'device',
            'status' + severityLevel,
            adminStatus,
            'id-' + device.id,
            'name-' + (device.name && this.generalService.generateIdForText(device.name)),
            'marker-type-' + markerType,
            !isFilteredDevice && 'not-filtered',
            isHighlight && 'highlighted',
        ];

        return {
            className: classes.join(' '),
            iconSize: [32, 32]
        };
    };

    getFilteredLinks = (innerLinks) => {
        const anyFiltered = innerLinks.find((link) => link.filtered);
        return anyFiltered ? '' : ' filtered';
    };

    createClusterDivIcon = (childCount, innerLinks, maxSeverityLevelsMarkers, isHighlighted, isFiltered, isSelected, isInnerLinksSelected) => {
        let html = '<div class="clusterSuperContainer">' +
            '<div class="clusterNumber">';
        html += '<div class="clusterContainer">' +
            '<div>' + childCount + '</div>';
        if (innerLinks.length > 0) {

            html += '<div class="clusterLinksInnerDiv leaflet-tooltip leaflet-tooltip-round-box' + this.getSelected(isInnerLinksSelected) + this.getFilteredLinks(innerLinks) + '">' +
                '<span class="clusterLinksInnerSpanNumber" style="background-color: ' + this.dataItemToLinkMapper.getStatusColor(innerLinks) + ';">' + innerLinks.length + '</span>' +
                '</div>';
        }

        html += '</div>' +
            '</div>' +
            '</div>';

        return {
            html,
            className: 'cluster marker-cluster status' + maxSeverityLevelsMarkers + this.getHighlight(isHighlighted) + this.getFiltered(isFiltered) + this.getSelected(isSelected),
            iconSize: [40, 40]
        };
    };

    getLinkOpacity = (isFiltered) => isFiltered ? 0.7 : 0.2;

    getLinkPolyline = (line, lineType, lineStatus, lineTooltip, isFiltered) => {
        const lineOpacity = this.getLinkOpacity(isFiltered);
        const linkColor = this.dataItemToLinkMapper.getStatusColor(line.innerLinks);

        const semiLine = {
            color: linkColor,
            weight: this.LINE_WEIGHT,
            opacity: lineOpacity,
            smoothFactor: 10,
            lineJoin: 'round'
        };
        const realLine = {
            color: linkColor,
            weight: this.LINE_WEIGHT,
            opacity: lineOpacity,
            smoothFactor: 10,
            lineJoin: 'round',
            tooltip: lineTooltip
        };

        return (lineType === 'semi') ? semiLine : realLine;
    };

    getLinkTooltip = (dataItem, isSingleLink, isShowLabel) => {
        if (!isSingleLink) {
            return '<div class="innerLinkNumberContainer">' +
                '<span class="innerLinkNumber">' + dataItem.length + '</span>' +
                '</div>';
        }
        if (!isShowLabel) {
            return;
        }
        const labelValue = this.dataItemToLinkMapper.getLabelText(dataItem);

        return this.domSanitizer.sanitize(SecurityContext.HTML,'<div class="tooltipDivContainer">' + labelValue + '<span class="extender"></span></div>');
    };

    getHighlight = (highlight) => highlight ? ' cluster-highlight' : '';

    getFiltered = (filtered) => filtered ? ' cluster-filtered' : '';

    getSelected = (selected) => selected ? ' selected' : '';
}
