import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DragDropModule} from '@angular/cdk/drag-drop';

import {AcButtonModule} from '../ac-button/ac-button.module';
import {AcRadioButtonsGroupModule} from '../ac-radio-buttons-group/ac-radio-buttons-group.module';
import {AcCheckboxModule} from '../ac-checkbox/ac-checkbox.module';
import {AcDialogComponent} from './ac-dialog.component';

import {AcDialogHostComponent} from './ac-dialog-host/ac-dialog-host.component';
import {ConfirmDialogComponent} from './ac-dialogs/confirm-dialog/confirm-dialog.component';
import {FailDialogComponent} from './ac-dialogs/failed-dialog/failed-dialog.component';
import {AboutDialogComponent} from './ac-dialogs/about-dialog/about-dialog.component';
import {InfoDialogComponent} from './ac-dialogs/info-dialog/info-dialog.component';
import {ErrorDialogComponent} from './ac-dialogs/error-dialog/error-dialog.component';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcDialogActionsDirective} from './directives/ac-dialog-actions.directive';
import {AcDialogTitleDirective} from './directives/ac-dialog-title.directive';
import {AcDialogCloseDirective} from './directives/ac-dialog-close.directive';
import {AcProgressModule} from '../ac-progress/ac-progress.module';

const acDialogs = [ConfirmDialogComponent, AboutDialogComponent, FailDialogComponent, InfoDialogComponent, ErrorDialogComponent];
const directives = [AcDialogActionsDirective, AcDialogTitleDirective, AcDialogCloseDirective];

@NgModule({
    declarations: [AcDialogComponent, AcDialogHostComponent, ...directives, ...acDialogs],
    entryComponents: [AcDialogComponent, AcDialogHostComponent, ...acDialogs],
    imports: [
        CommonModule,
        DragDropModule,
        AcButtonModule,
        AcRadioButtonsGroupModule,
        AcCheckboxModule,
        AcIconsModule,
        AcProgressModule,
        InfraPipesModule,
    ],
    exports: [AcDialogComponent, AcDialogHostComponent, ...directives, ...acDialogs]
})
export class AcDialogModule {
}
