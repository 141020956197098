import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    NgZone, Optional,
    Output, SkipSelf,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import {Store} from '@ngxs/store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcPaging, AcPagingEvent} from '../ac-pagination/ac-paging.interface';
import {
    AC_TABLE_CONFIG,
    AcTableColumn,
    AcTableConfig,
    AcTableDispatchEvent,
    AcTableEvent,
    AcTableRow,
    AcTableSort,
    AcTableSorter,
    ELayoutType,
    ESelectBehavior,
    LayoutType,
    RefreshTableProperties,
    SelectBehavior
} from './ac-table.interface';
import {AcTableActions} from './state/ac-table.actions';

import {AcTableState} from './state/ac-table.state';
import {AcPaginationItemsTemplateType} from '../ac-pagination/ac-pagination.component';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {AC_TABLE_STATE_TOKEN, AcTableStateModels} from './state/ac-table-state.models';
import {AcTableDataState} from './state/ac-table-data/ac-table-data.state';
import {AcTableDataActions} from './state/ac-table-data/ac-table-data.actions';
import {debounceTime, filter} from 'rxjs/operators';
import {fromEvent, Observable, Subject} from 'rxjs';
import {AcTableService} from './ac-table.service';
import {ThrottleClass} from '../../utils/throttle.class';
import {GeneralService} from '../../services/general.service';
import {AcTabDirective} from '../ac-tabs/ac-tab.directive';

@UntilDestroy()
@Component({
    selector: 'ac-table',
    templateUrl: './ac-table.component.html',
    styleUrls: ['./ac-table.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcTableComponent {
    static readonly AC_TABLE_STATE_AND_CONFIGS = 'tableState';

    @ContentChild('infiniteScrollBufferLoader') infiniteScrollBufferLoader: TemplateRef<any>;
    @ViewChild('tableContainer') tableContainer: ElementRef;
    headerRow: ElementRef;

    @ViewChild('acTableHeader') set HeaderRow(acTableHeader) {
        if (this.headerRow) {
            return;
        }
        this.headerRow = acTableHeader?.headerRow;
    }

    @ViewChild(VirtualScrollerComponent, {read: ElementRef}) vsElementRef: ElementRef;
    @ViewChild(VirtualScrollerComponent) vsComponent: VirtualScrollerComponent;

    @Input() set columns(columns: Array<AcTableColumn>) {
        this.setColumns(columns);
        setTimeout(() => this.onResizeColumnsWidthUpdate());
    }

    @Input() set rows(rows) {
        rows = rows && rows.map((row) => ({id: row[this.idField], data: row}));

        this.store.dispatch(
            this._rows
                ? new AcTableDataActions.UpdateRows(this.tableId, rows)
                : new AcTableDataActions.SetRows(this.tableId, rows)
        );
    }

    @Input() set sorting(sorting: Array<AcTableSorter>) {
        this._sorting = sorting || [];
        this.updateSortingMap();
    }

    @Input() tableId = '';
    @Input() refresh$: Observable<RefreshTableProperties>;

    // MODING
    @Input() idField = 'id';
    @Input() virtualScrollerBufferAmount = 10;
    @Input() defaultLayout: LayoutType = ELayoutType.byFit;
    @Input() dblClickResizeIncludeHeader = true;
    @Input() footer = false;
    @Input() multiSort = false;
    @Input() selection: any = {};
    @Input() multiSelection = true;
    @Input() selectBehavior: SelectBehavior = ESelectBehavior.select;
    @Input() forceSelection = false;
    @Input() collapsedGroups: { [key: string]: boolean } = {};
    @Input() groupBy: ((...args) => string) | string;

    // PAGINATION
    @Input() paginator = true;
    @Input() cursorPaginator = false;
    @Input() pageSelector = true;
    @Input() pageIndex = 1;
    @Input() totalElements = 0;
    @Input() pagingSizeOption: number[] | boolean = [25, 50, 100];
    @Input() pageSize;
    @Input() pageItemsDisplayTemplate: TemplateRef<any>;
    @Input() itemsDisplayType: AcPaginationItemsTemplateType = 'itemsRange';
    @Input() infiniteScroll = false;

    // EVENTS

    @Output() initialized = new EventEmitter<AcTableEvent>();
    @Output() updatePaging = new EventEmitter<AcTableEvent>();
    @Output() updateData = new EventEmitter<AcTableEvent>();
    @Output() infiniteScrollUpdate = new EventEmitter<AcTableEvent>();
    @Output() rowsUpdate = new EventEmitter<AcTableEvent>();
    @Output() sortChange = new EventEmitter<AcTableEvent>();
    @Output() collapsedGroupsChanged = new EventEmitter<AcTableEvent>();
    @Output() selectionChanged = new EventEmitter<AcTableEvent>();
    @Output() tableUpdate = new EventEmitter<AcTableEvent>();

    updateColumnsWidthSubject = new Subject();

    _columns: AcTableColumn[];
    _rows: AcTableRow[];
    _sorting: Array<AcTableSorter> = [];
    groupedRows;
    sortingMap: AcTableSort = {};
    loading = false;
    userSelectState = true;
    doSelect = true;
    selectionAnchor;
    viewportScrollBarWidth = 0;
    isBodyVisible = false;
    isHeaderVisible = false;
    filters = {};
    viewPortVisible = false;
    bufferingInfiniteScroll = false;
    showInfiniteScrollBufferLoader = false;
    isFocused = false;

    // COLUMN WIDTH
    @Input() minCellWidth = 40;
    headerTableWidthChanges = 0;
    tableCellContextIndex = -1;
    private originalClientX;
    private originalHeaderWidth;
    customColumnsWidth = false;


    constructor(public acTableService: AcTableService,
                protected cdRef: ChangeDetectorRef,
                protected store: Store,
                private zone: NgZone,
                private elementRef: ElementRef,
                private g: GeneralService,
                @Inject(DOCUMENT) private document: Document,
                @Inject(AC_TABLE_CONFIG) private forRootAcTableConfig: AcTableConfig,
                @Optional() @SkipSelf() private acTabDirective: AcTabDirective) {

        GeneralService.updateGlobalConfig(this, forRootAcTableConfig);
    }

    ngOnInit() {
        if (!this.tableId) {
            throw new Error('Table ID must be provided');
        }
        this.resetPagingOnLoad();
    }

    ngAfterViewInit() {
        this.pageSize = this.pageSize || this.pagingSizeOption?.[0] || 25;

        this.initializeRefresh();
        this.updateColumnsWidthSubject.pipe(debounceTime(200)).subscribe(() => this.dispatchColumnsWidth());

        this.initializeStoreSnapshot();
        this.initializeStoreUpdated();

        this.store.select(AcTableDataState.rowsUpdated(this.tableId))
            .pipe(untilDestroyed(this))
            .subscribe((rows) => {
                rows && this.setRows(rows);
            });

        this.initializeScrollPositionUpdate();
        setTimeout(() => this.updateTableHeaderMargin(), 100);

        this._columns && this.initializeComplete();
    }

    ngOnDestroy() {
        this.store.dispatch(new AcTableDataActions.ClearRows(this.tableId));
    }

    resetPagingOnLoad() {
        const tablesState = this.store.selectSnapshot<AcTableStateModels>(AC_TABLE_STATE_TOKEN);

        if (tablesState.resetPagingOnLoad) {
            this.store.dispatch(new AcTableActions.ResetPaging(this.tableId));
        }
    }

    initializeRefresh() {
        this.refresh$?.pipe(untilDestroyed(this)).subscribe((tableProperties: RefreshTableProperties) => {
            tableProperties = {...tableProperties};

            // TODO: when selection do not emit update data (circular).
            if (tableProperties.selection) {
                tableProperties.selection = tableProperties.selection.map((row) => ({id: row[this.idField], data: row}));
                this.executeSelection(tableProperties.selection, null);
                return;
            }

            tableProperties.gotoPage && this.onPageChange({
                type: 'userEvent',
                page: tableProperties.gotoPage,
                size: this.pageSize
            }, false);

            this.initialized.closed && this.dispatchAcTableEvent({
                type: 'updateData',
                emitter: this.updateData,
                loadingState: !!tableProperties.showLoader
            });
        });
    }

    initializeStoreUpdated() {
        this.store.select(AcTableState.collapsedGroups(this.tableId)).pipe(untilDestroyed(this)).subscribe((collapsedGroups) => {
            if (!this.groupBy || !this.groupedRows) {
                return;
            }

            this.collapsedGroups = collapsedGroups;
            this.dispatchAcTableEvent({
                emitter: this.collapsedGroupsChanged,
                type: 'collapsedGroupsChanged',
                loadingState: false,
                tableUpdate: false,
            });
        });
    }

    internalSelect = (selection) => {
       this.dispatchAcTableEvent({
            emitter: this.selectionChanged,
            type: 'selectionChanged',
            loadingState: false,
            tableUpdate: false,
        });
    };

    initializeComplete() {
        this.dispatchAcTableEvent({
            emitter: this.initialized,
            type: 'initialized',
            loadingState: !this._rows
        });
        this.initialized.unsubscribe();
    }

    initializeStoreSnapshot() {
        const tableState = this.store.selectSnapshot<AcTableState>(AC_TABLE_STATE_TOKEN)[this.tableId];
        if (!tableState) {
            return;
        }

        this.collapsedGroups = tableState.collapsedGroups;
        this.pageIndex = tableState.paging?.page || this.pageIndex;
        this.pageSize = tableState.paging?.size || this.pageSize || this.pagingSizeOption?.[0];

        if (tableState?.sorting) {
            this.sorting = tableState?.sorting;
        }
    }

    setColumnsWidthGrow() {
        this._columns.forEach(column => {
            column.widthGrow = (column.width || column.widthByHeader) ? 0 : (column.widthGrow || 1);
        });
    }


    setColumnsWidth() {
        this.headerTableWidthChanges += 1;
        this.cdRef.markForCheck();

        let viewportWidth = this.vsElementRef?.nativeElement.getBoundingClientRect().width;
        if (viewportWidth === 0) {
            return;
        }

        this.viewPortVisible = true;

        if (this.customColumnsWidth || !this.headerRow?.nativeElement) {
            return;
        }

        const widthGroupMap = {};

        [...this.headerRow?.nativeElement.children].forEach((headerCell, cellIndex) => {
            const column = this._columns[cellIndex];
            const widthGroup = column.widthGroup;
            const columnWidth = column.width || Math.ceil(headerCell.getBoundingClientRect().width);

            if (column.minWidthByHeader && !column.minWidth) {
                column.minWidth = columnWidth;
            }

            if (!column.widthGrow) {
                viewportWidth -= columnWidth;
            }

            if (!this._columns[cellIndex].width && widthGroup) {
                widthGroupMap[widthGroup] = Math.max(widthGroupMap[widthGroup] || 0, columnWidth);
            }
            this.setColumnWidth(cellIndex, columnWidth);
        });

        const widthGrowMap = {};
        let widthGrowSum = this._columns.reduce((acc, cur) => acc + (cur.widthGrow || 0), 0);
        this._columns.forEach((column, index) => {
            if (!column.widthGrow) {
                return;
            }
            const columnWidth = viewportWidth * (column.widthGrow / widthGrowSum);
            if (column.minWidth > columnWidth) {
                widthGrowMap[column.field] = true;
                viewportWidth -= column.minWidth;
                widthGrowSum -= column.widthGrow;
                this.setColumnWidth(index, column.minWidth);
            }
        });

        this._columns.forEach((column, index) => {
            let columnWidth = 0;
            if (widthGroupMap[column.widthGroup]) {
                columnWidth = widthGroupMap[column.widthGroup];
            } else if (widthGrowMap[column.field]) {
                return;
            } else if (column.widthGrow && this.defaultLayout === ELayoutType.byFit) {
                columnWidth = viewportWidth * (column.widthGrow / widthGrowSum);
            }
            this.setColumnWidth(index, columnWidth);
        });
    }

    initializeScrollPositionUpdate() {
        this.zone.runOutsideAngular(() => {

            const scrollObservable = fromEvent(this.vsElementRef.nativeElement, 'scroll').pipe(untilDestroyed(this));

            scrollObservable.pipe(debounceTime(200)).subscribe(({target}: Event) => {
                this.store.dispatch(new AcTableActions.UpdateScrollPosition(this.tableId, {
                    [this.pageIndex]: (target as HTMLElement).scrollTop
                }));
            });

            fromEvent(this.document, 'mousemove').pipe(
                untilDestroyed(this),
                filter(() => this.tableCellContextIndex >= 0)
            ).subscribe(this.tableMousemove);
        });
    }

    @HostListener('window:blur', ['$event'])
    @HostListener('document:mouseup', ['$event'])
    tableMouseup = ($event?: MouseEvent) => {
        $event?.stopPropagation();
        if (this.tableCellContextIndex >= 0) {
            (this.getTableCellContext().offsetWidth !== this.originalHeaderWidth) && this.updateColumnsWidthSubject.next(null);
            this.tableCellContextIndex = -1;
        }
    };

    @HostListener('window:resize')
    onResizeColumnsWidthUpdate() {
        if (!this.initialized.closed) {
            return;
        }

        this.setColumnsWidth();
        this.updateTableHeaderMargin();
        this.vsComponent.refresh();
    }

    updateTableHeaderMargin() {
        if (!this.vsElementRef?.nativeElement) {
            return;
        }

        const viewportProxy = {
            width: this.vsElementRef?.nativeElement.getBoundingClientRect().width,
            clientWidth: this.vsElementRef?.nativeElement.clientWidth,
            scrollHeight: this.vsElementRef?.nativeElement.scrollHeight,
            offsetHeight: this.vsElementRef?.nativeElement.offsetHeight,
        };

        this.viewportScrollBarWidth = viewportProxy.scrollHeight > viewportProxy.offsetHeight ?
            Math.floor(viewportProxy.width - viewportProxy.clientWidth) : 0;
    }

    tableMousedown($event: any) {
        if ($event.target.classList.contains('resize-handle') && this.tableContainer?.nativeElement.contains($event.target)) {
            this.tableCellContextIndex = $event.target.parentElement.cellIndex;
            this.originalClientX = $event.clientX;
            this.originalHeaderWidth = this.getTableCellContext().offsetWidth;
        }
    }

    tableMousemove = ($event: MouseEvent) => {
        if (this.tableCellContextIndex < 0) {
            return;
        }
        this.zone.run(() => {
            const clientDelta = ($event.clientX - this.originalClientX);
            this.setColumnWidth(this.getTableCellContext().cellIndex, this.originalHeaderWidth + clientDelta, {dispatchWidths: true});
        });
    };

    getHeaderColumnWidth(headerRow, columnIndex) {

        const resizableElement: any = headerRow.children[columnIndex].firstElementChild;
        const originalWidth = resizableElement.style.width;

        resizableElement.style.width = 'max-content';
        const maxWidth = this.dblClickResizeIncludeHeader ? resizableElement.getBoundingClientRect().width : 0;

        resizableElement.style.width = originalWidth;

        return maxWidth;
    }

    dblClick($event: any, body: any, headerContainer: HTMLElement) {
        if (!$event.target.classList.contains('resize-handle')) {
            return;
        }

        const columnIndex = $event.target.parentElement.cellIndex;
        let maxWidth = this.getHeaderColumnWidth(this.headerRow.nativeElement, columnIndex);

        body.rowsElementsRef.forEach(({nativeElement: tableRow}, rowIndex) => {
            const isColspan = Object.values(this._rows[rowIndex]).some((cell: any) => cell && typeof cell === 'object' && ('colspan' in cell));
            const cellElement = tableRow.children[columnIndex];

            if (isColspan || !cellElement) {
                return;
            }

            const originalDisplay = cellElement.firstElementChild.style.display;
            cellElement.firstElementChild.style.display = 'inline-block';

            const elementAbsoluteWidth = cellElement.firstElementChild.getBoundingClientRect().width || 0;
            cellElement.firstElementChild.style.display = originalDisplay;

            if (elementAbsoluteWidth > maxWidth) {
                maxWidth = elementAbsoluteWidth;
            }
        });

        headerContainer.scrollLeft = this.vsElementRef.nativeElement.scrollLeft;
        this.setColumnWidth(columnIndex, Math.ceil(maxWidth), {dispatchWidths: true});
        setTimeout(() => this.cdRef.markForCheck());
    }

    dispatchColumnsWidth() {
        const columnsWidth = this._columns.reduce((acc, cur) => {
            acc[cur.field || cur.title] = cur.colWidth;
            return acc;
        }, {});

        this.customColumnsWidth && this.store.dispatch(new AcTableActions.UpdateColumnsWidth(this.tableId, columnsWidth));
    }

    setColumnWidth(columnIndex: number, width: number, {dispatchWidths = false} = {}) {
        if (!width || this._columns[columnIndex].colWidth === width) {
            return;
        }
        const minWidth = this._columns[columnIndex].minWidth || this.minCellWidth;

        this._columns[columnIndex].colWidth = Math.max(width, minWidth);
        if (dispatchWidths) {
            this.customColumnsWidth = true;
            this.updateColumnsWidthSubject.next(null);
        }
        this.headerTableWidthChanges += 1;
        this.cdRef.markForCheck();
    }

    dispatchPaging(pagingState: AcPaging) {
        this.dispatchAcTableEvent({
            type: 'updatePaging',
            emitter: this.updatePaging,
            paging: pagingState,
        });
    }

    onPageChange(pageEvent?: AcPagingEvent, dispatchAcEvent = true) {
        this.isBodyVisible = false;
        if (pageEvent?.type === 'pageSize' || pageEvent?.type === 'userEvent') {
            pageEvent.page = 1;
            this.clearScrollPosition();
        }
        const pagingState = this.getPaging(pageEvent);
        this.pageIndex = pagingState.page;
        this.pageSize = pagingState.size;
        this.store.dispatch(new AcTableActions.UpdatePaging(this.tableId, pagingState));
        dispatchAcEvent && this.dispatchPaging(pagingState);
    }

    onColumnSort(column: AcTableColumn) {
        const sorter = this.sortingMap[column.field]; // this.getSorterByCol(column);

        if (!this.multiSort) {
            this._sorting = [];
        }

        const sorterIndex = sorter?.order - 1;
        const sortItem: AcTableSorter = {
            field: column.field,
            sorter: column.sorter,
            sortField: column.sortField
        };
        if (!sorter) {
            sortItem.dir = 'asc';
            this._sorting.push(sortItem);
        } else if (sorter.dir === 'asc') {
            sortItem.dir = 'desc';
            this._sorting.splice(sorterIndex, 1, sortItem);
        } else if (sorter.dir === 'desc') {
            this._sorting.splice(sorterIndex, 1);
        }

        this.updateSortingMap();
        this.store.dispatch(new AcTableActions.UpdateSorting(this.tableId, this._sorting));
        this.dispatchAcTableEvent({emitter: this.sortChange, type: 'sortChange'});
    }

    selectRow($event, row: any, {rowIndex, anchorIndex}: any = {}) {
        if ($event.target.classList.contains('resize-handle') || !this.selection) {
            return;
        }

        let candidateAnchor = row.id;
        let rows = [row];
        let update = false;

        if ($event.shiftKey && this.multiSelection) {
            anchorIndex = anchorIndex || this.acTableService.getRowIndexById(this._rows, this.selectionAnchor);
            rowIndex = rowIndex || this.acTableService.getRowIndexById(this._rows, row.id);
            const [startIndex, endIndex] = anchorIndex <= rowIndex ? [anchorIndex, rowIndex] : [rowIndex, anchorIndex];

            rows = this._rows.slice(startIndex, endIndex + 1).filter((selectionRow) => !!selectionRow.id && !selectionRow._groupId);
            candidateAnchor = undefined;
            update = $event.ctrlKey;
            if (!this.doSelect) {
                this.doSelect = !$event.ctrlKey;
            }
        } else if ($event.ctrlKey || this.selectBehavior === ESelectBehavior.toggle) {

            update = this.doSelect = !this.selection[row.id];
        } else {
            this.doSelect = true;
        }

        this.executeSelection(rows, candidateAnchor, this.doSelect, update);
    }

    getActiveCollapsedGroups() {
        if (!this.groupedRows) {
            return;
        }

        return Object.getOwnPropertyNames(this.groupedRows).reduce((activeGroups, group) => {
            activeGroups[group] = this.collapsedGroups[group];
            return activeGroups;
        }, {});
    }

    setRowsGroups(rows) {
        this.groupedRows = rows.reduce((acc, currRow) => {
            const group = (typeof this.groupBy === 'string') ? currRow.topicId : this.groupBy(currRow.data);
            if (!acc[group]) {
                acc[group] = [currRow];
            } else {
                acc[group].push(currRow);
            }
            return acc;
        }, {});
    }

    toggleGroupCollapsedState(tableRowGroup: any) {
        this.collapsedGroups[tableRowGroup._groupId] = !this.collapsedGroups[tableRowGroup._groupId];
        this.store.dispatch(new AcTableActions.UpdateCollapsedGroups(this.tableId, {
            [tableRowGroup._groupId]: this.collapsedGroups[tableRowGroup._groupId]
        }));
    }

    onWheelUpdateHorizontalScroller(wheelEvent: WheelEvent, horizontalScroller: HTMLDivElement) {
        horizontalScroller?.scrollBy({left: wheelEvent.shiftKey ? wheelEvent.deltaY : wheelEvent.deltaX});
    }

    updateHorizontalScroll(scrollEvent: Event, containers: HTMLDivElement[]) {
        const scrollLeft = (scrollEvent.target as HTMLElement).scrollLeft;

        containers.forEach((container) => {
            if (container) {
                container.scrollLeft = scrollLeft;
            }
        });
        this.vsElementRef.nativeElement.scrollLeft = scrollLeft;
    }

    calcHorizontalScrollWidth = (headerTable, viewportScrollBarWidth) => {
        this.headerTableWidthChanges = 0;
        return this.acTableService.widthToPixels(headerTable.offsetWidth - viewportScrollBarWidth);
    };

    rowsToViewPortData = (rows) => {
        if (!rows) {
            return;
        }

        this.loading = false;
        return this.getGroupedRows(this.getFilteredRows(rows));
    };

    onColumnFilterChanged($event: string, column: AcTableColumn) {
        this.filters = {...this.filters};
        if (!$event || $event === '') {
            delete this.filters[column.field];
        } else {
            this.filters[column.field] = {query: $event.toLowerCase()};
        }
    }

    getBodyScrollTop = () => {
        const tableState = this.store.selectSnapshot<AcTableState>(AC_TABLE_STATE_TOKEN)[this.tableId];
        return tableState?.scrollPosition?.[this.pageIndex] || 0;
    };

    getTableCellContext = () => {
        if (this.tableCellContextIndex < 0) {
            return null;
        }
        return this.headerRow.nativeElement.children[this.tableCellContextIndex];
    };

    protected clearScrollPosition() {
        this.store.dispatch(new AcTableActions.UpdateScrollPosition(this.tableId, {}, false));
    }

    protected clearSelection() {
        this.store.dispatch(new AcTableActions.ClearSelection(this.tableId));
    }

    protected setColumns(columns: Array<AcTableColumn>) {
        if (!columns) {
            return;
        }

        this._columns = this.filterActiveColumns(columns);
        this.setColumnsWidthGrow();

        const tableColumnsWidthState = this.store.selectSnapshot<AcTableState>(AC_TABLE_STATE_TOKEN)[this.tableId]?.columnsWidth;

        this.customColumnsWidth = !!tableColumnsWidthState;
        if (tableColumnsWidthState) {
            this._columns.forEach((column, index) => {
                this.setColumnWidth(index, tableColumnsWidthState[column.field || column.title] || column.width);
            });
        }

        setTimeout(() => {
            this.setColumnsWidth();
            !this.initialized.closed && this.initializeComplete(); // show columns before rows sets
            this.isHeaderVisible = true;
        });
    }

    protected setRows(rows) {
        this._rows = rows;

        this._rows && this.updateSelectionByRows(this._rows);
        if (this._rows?.length > 0) {

            if (this.groupBy) {
                this.setRowsGroups(this._rows);
                this.store.dispatch(new AcTableActions.UpdateCollapsedGroups(this.tableId, this.getActiveCollapsedGroups()));
            }

            this.dispatchAcTableEvent({
                emitter: this.rowsUpdate,
                type: 'updateData',
                loadingState: false,
                tableUpdate: false,
            });

            if (this.forceSelection && Object.getOwnPropertyNames(this.selection).length <= 0) {
                this.executeSelection([this._rows[0]], this._rows[0].id);
            }

            this.bufferingInfiniteScroll = false;
            this.showInfiniteScrollBufferLoader = false;

            if (!this.isBodyVisible) {
                setTimeout(() => {
                    this.vsComponent.scrollToPosition(this.getBodyScrollTop(), 0);
                    this.updateTableHeaderMargin();
                    this.isBodyVisible = true;
                    this.cdRef.markForCheck();
                });
                return;
            }
        } else if (this._rows?.length <= 0 && this.pageIndex > 1) {
            this.pageIndex--;
            this.onPageChange();
        }
        if (this._rows) {
            this.isBodyVisible = true;
        }
        this.cdRef.detectChanges();
    }

    protected dispatchAcTableEvent(
        {
            emitter,
            type,
            paging = undefined,
            loadingState = true,
            additionalData = undefined,
            tableUpdate = true,
        }: AcTableDispatchEvent
    ) {
        if (!this.loading) {
            this.loading = loadingState;
        }

        const acEventObject = {
            type,
            paging: this.getPaging(paging),
            sorting: this._sorting.map(({field, sortField, ...args}) => {
                return {field: (sortField || field), ...args};
            }),
            selection: {...this.selection},
            collapsedGroups: this.getActiveCollapsedGroups(),
            ...additionalData
        };
        emitter.emit(acEventObject);
        tableUpdate && this.tableUpdate.emit(acEventObject);
    }

    protected getPaging = (paging?: AcPagingEvent | AcPaging): AcPaging => {
        if (paging) {
            delete (paging as AcPagingEvent).type;
            return paging;
        }

        const page = typeof this.pageIndex === 'string' ? parseInt(this.pageIndex, 10) : this.pageIndex;
        const size = typeof this.pageSize === 'string' ? parseInt(this.pageSize, 10) : this.pageSize;
        return {page, size};
    };

    private updateSelectionByRows(rows) {
        if (!this.selection || !rows) {
            return;
        }
        const selection = this.store.selectSnapshot<AcTableState>(AcTableState.selection(this.tableId, false));
        const selectedRows = rows.filter((row) => selection[row.id]);

        this.executeSelection(selectedRows, null, true, false);
    }

    externalSelection = new ThrottleClass({
        callback: (select, selection, candidateAnchor, update) => {
            this.store.dispatch(new AcTableActions.Select(this.tableId, selection, candidateAnchor, update));
        },
        destroyComponentOperator: untilDestroyed(this),
        debounce: this.acTableService.selectDebounceTime,
        maxRecurrentTime: this.acTableService.selectDebounceTime,
    });

    private executeSelection(selection, candidateAnchor, select = true, update = false) {
        update = this.multiSelection && update;
        select = select || this.forceSelection;

        if (select || this.forceSelection) {
            const selectionState = GeneralService.ArrayToObjTransform(selection, {}, (acc, row) => {
                acc[row.id] = true;
            });
            this.selection =  update ? {...this.selection, ...selectionState} : selectionState;
        } else {
            selection.forEach((rowSelection) => delete this.selection[rowSelection.id]);
        }
        this.selectionAnchor = candidateAnchor || this.selectionAnchor;

        this.internalSelect(this.selection);
        this.externalSelection.tick(select, this.selection, candidateAnchor, select && update);
    }

    private filterActiveColumns = (columns: Array<AcTableColumn>): Array<AcTableColumn> => {
        return columns.filter((column) => column.isActive !== false);
    };

    private updateSortingMap() {
        this.sortingMap = this._sorting.reduce((acc, cur, index) => {
            acc[cur.field] = {...cur, order: index + 1};
            return acc;
        }, {}) as AcTableSort;
    }

    private getGroupedRows(rows) {
        if (!this.groupBy) {
            return rows;
        }

        this.setRowsGroups(rows);
        return Object.getOwnPropertyNames(this.groupedRows).reduce((acc, curr) => {
            const rowGroupHeader = {
                _groupId: curr,
                groupCount: this.groupedRows[curr].length,
                isCollapsed: !!this.collapsedGroups[curr]
            };

            acc.push(rowGroupHeader, ...(rowGroupHeader.isCollapsed ? [] : this.groupedRows[curr]));
            return acc;
        }, []);
    }

    private getFilteredRows(rows) {
        const filteredColumnsFields = Object.getOwnPropertyNames(this.filters);
        if (filteredColumnsFields.length <= 0) {
            return rows;
        }

        return rows.filter(row => {
            return filteredColumnsFields.every((filteredColumnField) => {
                return row?.data?.[filteredColumnField]?.toLowerCase().includes(this.filters[filteredColumnField].query);
            });
        });
    }

    updateInfiniteScroll = ({endIndex}) => {
        if (!this.infiniteScroll || this.bufferingInfiniteScroll || !this._rows || !this.collapsedGroups) {
            return;
        }
        const rowCount = this._rows.length;
        const groupCount = Object.keys(this.collapsedGroups).length;
        const tableRowsEndIndex = this.toPositive(rowCount - 1) + this.toPositive(groupCount);
        if (endIndex === tableRowsEndIndex) {
            this.bufferingInfiniteScroll = true;
            this.showInfiniteScrollBufferLoader = true;

            this.initialized.closed && this.dispatchAcTableEvent({
                type: 'infiniteScrollUpdate',
                emitter: this.infiniteScrollUpdate,
                loadingState: false,
            });
        }
    };

    toPositive = (aa: number): number => {
        return Math.max(0, aa);
    };

    toggleFocus(state: boolean) {
        this.isFocused = state;
    }
}
