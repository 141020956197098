import {Component, Inject} from '@angular/core';


import {AC_DIALOG_ANNOTATION, AcDialog, DIALOG_CONFIG, DialogConfig} from '../../ac-dialog.models';
import {GeneralService} from '../../../../services/general.service';

@AcDialog({
    id: 'about-dialog',
    title: 'About',
    formSettings: {valid: true, touched: true},
    cancelButtonText: 'OK',
    width: 550,
    colorPalette: 'blue'
})
@Component({
    selector: 'about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.less'],
})
export class AboutDialogComponent {
    currentYear;
    version = '';

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                public generalService: GeneralService) {
    }

    ngOnInit() {
        this.currentYear = (new Date()).getUTCFullYear();
    }
}


