import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'allocation',
    templateUrl: './allocation.component.html',
    styleUrls: ['./allocation.component.less'],
})

export class AllocationComponent {
    @Input() containerLabel = '';
    @Input() hideLabel = false;
    @Input() hideAllocTitle = false;
    @Input() allocationBarWithInput = false;
    @Input() showSpinner = true;
    @Input() inputModel: any;
    @Input() inputMinValue = 0;
    @Input() inputMaxValue: number;
    @Input() barTitle = '';
    @Input() barTotal: number;
    @Input() barUsed: number;
    @Input() alternativeName = '';
    @Output() spinnerChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    inputChanged(event) {
        this.spinnerChanged.emit(event);
    }


}



