import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';

@Component({
    selector: 'ac-radio-buttons-group',
    templateUrl: './ac-radio-buttons-group.component.html',
    styleUrls: ['./ac-radio-buttons-group.component.less'],
})

export class AcRadioButtonsGroupComponent {
    @Input() acModel: any;
    @Output() acModelChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() items: any;
    @Input() radioButtonId = '';
    @Input() ngDisabled: boolean;

    constructor(@Optional() public acFormComponent: AcFormComponent) {
    }

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }

    modelChanged = (event) => {
        this.acModelChange.emit(event);
    };
}



