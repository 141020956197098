import {Injectable} from '@angular/core';

import {isNil} from 'lodash';
import {saveAs} from 'file-saver/FileSaver';

import {dateTimeFormatter, FormatterType} from '../utils/time-formatter';
import {BuildUrlsService} from './utilities/build-urls.service';
import {SessionService} from './session.service';
import {SessionStorageService} from './session-storage.service';
import {LocalStorageService} from './local-storage.service';

type LogLevel = 'ALL' | 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL' | 'NONE';
type LogSeverity = { [logLevel in LogLevel]?: number };

interface LogState {
    curr: LogLevel;
    prev?: LogLevel;
}

const DEFAULT_LOG_LEVEL: LogState = {curr: 'INFO'};

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    public static LogSeverity: LogSeverity = {
        ALL: 0,
        TRACE: 250,
        DEBUG: 500,
        INFO: 750,
        WARN: 1000,
        ERROR: 1250,
        FATAL: 1500,
        NONE: 1750
    };
    private toggledLevel: LogLevel;
    private _logLevel: LogLevel;

    lastTimestamp;
    lastTimestampCounter = 0;

    constructor(private buildUrlsService: BuildUrlsService,
                private sessionService: SessionService) {
        const sessionLogLevel: LogState = SessionStorageService.getData('LOG_LEVEL') || DEFAULT_LOG_LEVEL;

        this.toggledLevel = sessionLogLevel.prev;
        this.setLogLevel(sessionLogLevel.curr);
    }

    public trace = (message, exception?) => {
        this.log('TRACE', message, exception);
    };

    public debug = (message, exception?) => {
        this.log('DEBUG', message, exception);
    };

    public info = (message, exception?) => {
        this.log('INFO', message, exception);
    };

    public warn = (message, exception?) => {
        this.log('WARN', message, exception);
    };

    public error = (message, exception?) => {
        this.log('ERROR', message, exception);
    };

    public getExceptionToServer = (exception) => {
        let serverObject;
        let message = '';

        if (exception) {
            message += exception.type ? exception.type + ': ' : '';
            message += exception.message || '';
        }

        const record = this.getRecord('ERROR', message, exception);
        const messageToSend = this.formatRecord(record);
        const url = this.buildUrlsService.buildURL('settings/logger');

        if (this.sessionService.activeSession) {
            serverObject = {uri: url, data: {message: messageToSend}};
        } else {
            console.error(message);
        }

        return serverObject;
    };

    isDebugMode = (): boolean => this._logLevel === 'DEBUG';

    setLogLevel = (level: LogLevel = 'INFO', toggled = false) => {
        if (toggled) {
            this.toggledLevel = this._logLevel;
        }
        this._logLevel = level;
        SessionStorageService.setData('LOG_LEVEL', {curr: this._logLevel, prev: this.toggledLevel});
    };

    toggleDebugMode = (isDebugMode) => {
        this.setLogLevel(isDebugMode ? 'DEBUG' : this.toggledLevel , true);
    };

    public saveToFile(filename) {
        const text = this.getLogContent();
        const blob = new Blob([text], {type: 'text/plain;charset=utf-8'});

        saveAs(blob, filename);
    }

    private getLogContent() {
        LocalStorageService.cleanOldRecords();
        const records = LocalStorageService.getAllRecords();
        let text = '';

        const recordsCount = records.length;

        for (let i = 0; i < recordsCount; i++) {
            text += this.formatRecord(records[i]) + '\n';
        }

        return text;
    }

    private formatRecord(record) {
        const formattedTimestamp = dateTimeFormatter(record.timestamp, FormatterType.dateAndTime);

        const formattedUsername = record.username || '';
        const formattedSessionId = record.sessionId || '';

        let message = formattedTimestamp + ' [' + formattedUsername + '] [' + formattedSessionId + '] ' +
            record.severity + ' - ' + record.message;

        if (record.exception && record.exception.stack) {
            message = message + '\n' + record.exception.stack.toString();
        }

        return message;
    }

    private log = (level, message, exception?) => {
        if (LoggerService.LogSeverity[level] >= LoggerService.LogSeverity[this._logLevel]) {
            this.addRecord(level, message, exception);
        }
    };

    private addRecord(severity, message, exception) {
        const record: any = this.getRecord(severity, message, exception);

        this.updateCounterAccordingToTimestamp(record.timestamp);

        const key = record.timestamp + ':' + (record.username ? record.username : 'userLoggedout');

        LocalStorageService.setData(key, record);
    }

    private  updateCounterAccordingToTimestamp(timestamp) {
        if (this.lastTimestamp === timestamp) {
            this.lastTimestampCounter++;
        } else {
            this.lastTimestamp = timestamp;
            this.lastTimestampCounter = 0;
        }
    }

    private getRecord(severity = undefined, message = undefined, exception = undefined) {
        const record: any = {};
        record.severity = severity;
        record.message = message;
        record.exception = exception;

        const activeSession = this.sessionService ? this.sessionService.activeSession : undefined;
        record.timestamp = Date.now();

        if (!isNil(activeSession)) {
            record.sessionId = activeSession.sessionId;
            record.username = activeSession.username;
        }

        return record;
    }
}
