import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {UntilDestroy} from '@ngneat/until-destroy';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';

@UntilDestroy()
@Component({
    selector: 'ac-drop-down',
    templateUrl: './ac-dropdown.component.html',
    styleUrls: ['./ac-dropdown.component.less']
})
export class AcDropDownComponent {
    @ViewChild('parent', {static: true}) parent: ElementRef;
    @ViewChild('tooltip', {static: true}) tooltip: ElementRef;
    @ViewChild('menuTrigger', {static: false}) menuTrigger: MatMenuTrigger;
    @ViewChild('menu', {static: true}) menu: MatMenu;

    @Input() item: any;
    @Input() parentClass = '';
    @Input() matTriggerId = '';
    @Input() closeOnUpdate = false;
    @Input() parentSelector: any = false;
    @Input() openDropDownAfterButton = false;
    @Input() overlapTrigger;
    @Input() upperCaseContent = false;

    @Output() openStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    isOpen = false;

    menuOpened() {
        this.openStatusChanged.emit(true);
        this.isOpen = true;
    }

    menuClosed() {
        this.openStatusChanged.emit(false);
        this.isOpen = false;
    }

    open = () => {
        this.menuTrigger?.openMenu();
    };

    close = () => {
        this.menuTrigger?.closeMenu();
    };
}
