import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceInjector} from './common/services/service-injector';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {ExceptionHandlerService, InfraModule} from 'ac-infra';
import {OVOCServicesModule} from './ovoc.services.module';
import {WebSocketService} from './common/services/ws/ws.service';
import {PipesModule} from './common/pipes/pipes.module';
import {LogoDisplayModule} from './common/components/brand-logo/logo-display.module';
import {OVOCCommonModule} from "./common/ovoc-common.module";
import {AcSplashScreenModule} from "./common/components/ac-splash-screen/ac-splash-screen.module";

@NgModule()
class BeforeAllModule {
    constructor(private injector: Injector) {
        ServiceInjector.injector = injector;
    }
}

@NgModule({
    declarations: [
        AppComponent
        // DashboardComponent,
        // CallflowComponent,
        // AdministrationComponent,
    ],
    imports: [
        BeforeAllModule,
        BrowserModule,
        BrowserAnimationsModule,
        InfraModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            maxAge: 10,
        }),
        AppRoutingModule,
        PipesModule,
        LogoDisplayModule,
        OVOCServicesModule,
        AcSplashScreenModule
    ],
    exports: [],
    providers: [{provide: ErrorHandler, useClass: ExceptionHandlerService}, WebSocketService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
