<ac-form *ngIf="dialogConfig.ready"
         [formModel]="teamsDevice"
         [formSchema]="isEdit ? deviceEditSchema : deviceAddSchema"
         [isEdit]="isEdit"
         id="teams-device-dialog"
         formModelName="teamsDevice">
    <div id="teams-device-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Name">
                <ac-textfield [(acModel)]="teamsDevice.name"
                              [attr.alternativeName]="'teamsDevice.name'"
                              maxLength="100"
                              textFieldId="teams-device-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container label="Description">
                <ac-textfield [(acModel)]="teamsDevice.description"
                              [attr.alternativeName]="'teamsDevice.description'"
                              maxLength="100"
                              textFieldId="teams-device-description">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="teamsDevice"
                                         [additionalSettings]="additionalSettings"
                                         [isEdit]="isEdit"
                                         [ngDisabledTenant]="isEdit"
                                         [ngDisabledRegion]="isEdit"
                                         [regionAlternativeName]="'teamsDevice.regionId'"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container label="Azure Tenant ID">
            <ac-textfield [acModel]="teamsDevice.teamsInfo.msTenantId || additionalSettings.teamsInfo.msTenantId"
                          (acModelChange)="teamsDevice.teamsInfo.msTenantId = $event"
                          [attr.alternativeName]="'teamsDevice.teamsInfo.msTenantId'"
                          [ngDisabled]="isEdit"
                          maxLength="250"
                          textFieldId="teams-device-ms-tenant-id">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container label="Client ID">
            <ac-textfield [(acModel)]="teamsDevice.teamsInfo.clientId"
                          [attr.alternativeName]="'teamsDevice.teamsInfo.clientId'"
                          maxLength="250"
                          textFieldId="teams-device-client-id">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container editModePrefix="Change" label="Client Secret">
            <ac-password [(acModel)]="teamsDevice.teamsInfo.clientSecret"
                         [attr.alternativeName]="'teamsDevice.teamsInfo.clientSecret'"
                         maxLength="250"
                         passwordId="teams-device-client-secret">
            </ac-password>
        </ac-input-container>

        <ac-input-container label="Address">
            <ac-geo-location (acChanged)="teamsDevice.location=$event"
                             [acModel]="teamsDevice.location"
                             [attr.alternativeName]="'teamsDevice.location'"></ac-geo-location>
        </ac-input-container>
    </div>
</ac-form>
