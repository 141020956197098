import {Component, ContentChildren, ElementRef, HostBinding, HostListener, Input, QueryList, TemplateRef, ViewChildren} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AcTableColumn, AcTableRow} from '../ac-table.interface';
import {AcTableComponent} from '../ac-table.component';
import {AcTableService} from '../ac-table.service';
import {GeneralService} from 'ac-infra';


@UntilDestroy()
@Component({
    selector: '[ac-table-body]',
    templateUrl: './ac-table-body.component.html',
    styleUrls: ['./ac-table-body.component.less'],
})
export class AcTableBodyComponent {
    @ViewChildren('rowsElementsRef') rowsElementsRef: QueryList<ElementRef>;
    @Input() columns: AcTableColumn[];
    @Input() rows: AcTableRow[];
    @Input() showBufferLoader = false;
    @Input() templates: { [key: string]: TemplateRef<any> } = {};
    public generalService = GeneralService;

    @HostBinding('class.no-user-selection') isUserSelecting = false;

    constructor(public acTableComponent: AcTableComponent,
                public acTableService: AcTableService) {
    }

    getRowColSpan = (column, row) => {
        // !!!!!!!!!!!! reduceCells removes columns width  colspan = 0 !!!!!!!!!
        if (row._groupId) {
            return this.columns.length;
        }
        return row.data[column.field]?.colspan || 1;
        // !!!!!!!!!!!! reduceCells removes columns width  colspan = 0 !!!!!!!!!
    };

    reduceCells = (columns, row) => {
        if (row._groupId) {
            return [{field: 'group'}];
        }
        return columns.filter((column) => this.isCellExist(row.data, column.field));
    };

    isCellExist = (row, field) => {
        const cellData = row[field];
        return isNaN(cellData?.colspan) || cellData.colspan > 0;
    };

    isOddRow = (startIndex: number, rowIndex: number) => {
        return (startIndex + rowIndex) % 2 === 1;
    };


    @HostListener('document:keydown', ['$event'])
    disableUserSelect($event: KeyboardEvent) {
        if (!this.acTableComponent.selection) {
            return;
        }
        if ($event.key === 'Shift' && !this.isUserSelecting) {
            this.isUserSelecting = true;
        }
        if (this.acTableComponent.isFocused && !$event.ctrlKey) {
            this.selectNextRow($event);
        }
    }

    private selectNextRow($event: KeyboardEvent) {

        const rows = this.acTableComponent._rows;
        const anchorIndex = this.acTableService.getRowIndexById(rows, this.acTableComponent.selectionAnchor);
        const rowIndex = this.getRowNextRowIndex($event, rows, anchorIndex);
        const nextRow = rows[rowIndex];
        if (nextRow) {
            this.acTableComponent.selectRow($event, nextRow, {rowIndex, anchorIndex});

            setTimeout(() => {
                const {startIndex, endIndex} = this.acTableComponent.vsComponent.viewPortInfo;
                if (rowIndex <= startIndex) {
                    this.acTableComponent.vsComponent.scrollInto(nextRow, true, 0, 0);
                } else if (rowIndex >= endIndex) {
                    this.acTableComponent.vsComponent.scrollInto(nextRow, false, 60, 0);
                }
                this.acTableComponent.vsComponent.refresh();
            });
        }
    }


    private getRowNextRowIndex($event: KeyboardEvent, rows: AcTableRow[], anchorIndex: number): number {
        let rowIndex = -1;
        const firstSelectedIndex = rows.findIndex((row) => !!this.acTableComponent.selection[row.id]);
        const lastSelectedIndex = rows.length - 1 - [...rows].reverse().findIndex((row) => !!this.acTableComponent.selection[row.id]);

        switch ($event.code) {
            case 'KeyS':
            case 'ArrowDown':
                rowIndex = this.nextRowKeyDownAction(rows, anchorIndex, firstSelectedIndex, lastSelectedIndex);
                break;
            case 'KeyW':
            case 'ArrowUp':
                rowIndex = this.nextRowKeyUpAction(rows, anchorIndex, firstSelectedIndex, lastSelectedIndex);
                break;
        }
        return rowIndex;
    }

    nextRowKeyDownAction = (rows: AcTableRow[], anchorIndex: number, rowStartIndex, rowEndIndex): number => {
        if (anchorIndex > rowStartIndex) {
            return rowStartIndex + 1;
        } else if (anchorIndex <= rowEndIndex) {
            return rowEndIndex + 1;
        }
        return rowEndIndex;
    };

    nextRowKeyUpAction = (rows, anchorIndex, rowStartIndex, rowEndIndex) => {
        if (anchorIndex < rowEndIndex) {
            return rowEndIndex - 1;
        } else if (anchorIndex >= rowStartIndex) {
            return rowStartIndex - 1;
        }
        return rowStartIndex;
    };


    @HostListener('document:keyup', ['$event'])
    enableUserSelect($event: KeyboardEvent) {
        if ($event.key === 'Shift') {
            this.isUserSelecting = false;
        }
    }
}
