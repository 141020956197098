import {Injectable} from '@angular/core';
import {BuildUrlsService, CachedConnection} from 'ac-infra';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class ServerInfoRestService extends Rest {

    cachedConnection;
    communicationName = 'ServerInfo';
    lockedUrl;

    constructor(cachedConnection: CachedConnection,
                buildURLsService: BuildUrlsService) {
        super({url: 'status/server'});
        this.cachedConnection = cachedConnection;
        this.lockedUrl = {
            url: buildURLsService.buildURL('status/server'),
            group: this.communicationName,
            eventName: this.communicationName
        };
    }

    public getServerInfo = (success, failure) => {
        const onSuccess = (value) => {
            success(this.extractObject(value));
        };
        this.get({skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };

    public startAutoRefresh = () => {
        this.cachedConnection.addLockedUrl(this.lockedUrl);
    };

    public stopAutoRefresh = () => {
        this.cachedConnection.removeLockedUrl(this.lockedUrl);
    };

    public forceRefresh = () => {
        this.cachedConnection.forceRefreshLockedUrl(this.lockedUrl.group);
    };
}
