import {ChangeDetectionStrategy, Component, forwardRef, Input,} from '@angular/core';

import {UntilDestroy} from '@ngneat/until-destroy';
import {AcTableComponent} from '../ac-table.component';
import {AcPagingEvent} from '../../ac-pagination/ac-paging.interface';
import {AcTableRow, AcTableSorters, SorterFunc} from '../ac-table.interface';

@UntilDestroy()
@Component({
    selector: 'ac-table-client',
    templateUrl: '../ac-table.component.html',
    styleUrls: ['../ac-table.component.less'],
    providers: [{provide: AcTableComponent, useExisting: forwardRef(() => AcTableClientComponent)}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcTableClientComponent extends AcTableComponent {

    _allRows: AcTableRow[];
    viewInitialized = false;

    @Input() sorters: AcTableSorters;

    ngOnInit() {
        this.sorters = {
            number: this.numberCompare,
            string: this.stringCompare,
            boolean: this.booleanCompare,
            ...this.sorters
        };
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.viewInitialized = true;
        this._allRows && this.setRows([...this._allRows]);
    }

    onPageChange(paging?: AcPagingEvent) {
        super.onPageChange(paging);
        this.setRows();
    }

    onColumnSort(column: any) {
        super.onColumnSort(column);
        this.setRows();
    }

    setRows(rows?: AcTableRow[]) {
        if (rows) {
            this._allRows = rows;
            this.totalElements = this._allRows?.length || 0;
            this.loading = true;
        }

        if (!this.viewInitialized) {
            return;
        }

        const sortedRows: AcTableRow[] = this.getSortedRows();

        super.setRows(this.paginator ? sortedRows.slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize) : sortedRows);
    }

    private getSortedRows(): AcTableRow[] {
        if (this._sorting?.length <= 0 || !this._allRows) {
            return this._allRows || [];
        }

        const sortedRows: AcTableRow[] = [...this._allRows];
        sortedRows.length > 0 && this._sorting.forEach((sorterState) => {
            const sorterType = typeof sortedRows[0].data[sorterState.field];
            const sorter: SorterFunc = this.sorters[sorterState.sorter || sorterType] || this.sorters.string;

            sorter && sortedRows.sort(({data: row1}, {data: row2}) => {
                return sorter(row1[sorterState.field], row2[sorterState.field], sorterState.dir);
            });
        });
        return sortedRows;
    }

    private numberCompare: SorterFunc = (exp1, exp2, dir) => {
        let compareResult;
        if (exp2 < 0) {
            compareResult = -1;
        } else if (exp1 < 0){
            compareResult = 1;
        } else {
            compareResult = exp1 - exp2 ;
        }

        return dir === 'asc' ? compareResult : (compareResult * -1);
    };

    private stringCompare: SorterFunc = (string1 = '', string2 = '', dir) => {
        const compareResult = (string1.toLowerCase()).localeCompare(string2.toLowerCase());
        return dir === 'asc' ? compareResult : (compareResult * -1);
    };

    private booleanCompare: SorterFunc = (exp1, expr2, dir) => {
        const el1 = (exp1 === true || exp1 === 'true' || exp1 === 'True' || exp1 === 1) ? 1 : 0;
        const el2 = expr2 === true || expr2 === 'true' || expr2 === 'True' || expr2 === 1 ? 1 : 0;

        return dir === 'asc' ? el1 - el2 : el2 - el1;
    };


}
