import {ComponentRef, Directive, Input, SecurityContext, ViewContainerRef} from '@angular/core';
import {GeneralService} from '../../services/general.service';
import {DomSanitizer} from '@angular/platform-browser';

@Directive({
    selector: '[acTableCell]',
})
export class AcTableCellDirective {

    @Input() acTableCell;
    @Input('acTableCellColumn') column;
    @Input('acTableCellFormatter') formatter;
    @Input('acTableCellTemplate') template;

    private _row;
    @Input('acTableCellRow') set row(row: any) {
        this._row = row;
        this.viewContainerRef.clear();
        if (!this.formatter) {
            this.template && this.viewContainerRef.createEmbeddedView(this.template, {row: this._row, column: this.column});
            return;
        }

        const content: any | ComponentRef<any> = this.backwardCompFormatter(this.column, this._row?.data) || '';
        if (typeof content === 'string' || typeof content === 'number') {
            this.acTableCell.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, content);
        } else {
            this.viewContainerRef.insert(content.hostView);
        }
    }

    constructor(public viewContainerRef: ViewContainerRef,
                private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        if (!this._row) {
            this.row = null;
        }
    }

    // REMOVE BACKWARD COMPATIBILITY


    backwardCompFormatter = (column, row): (AcTableCell) => any => {
        if (row) {
            return this.formatter({
                getValue: () => GeneralService.byString(row, column.field),
                getRow: () => row,
                getField: () => column.field,
            });
        } else {
            return this.formatter(column);
        }
    };
    // END REMOVE BACKWARD COMPATIBILITY
}
