import {Injectable} from '@angular/core';
import {DevicesRestService} from '../apis/devices-rest.service';
import {SitesRestService} from '../apis/sites-rest.service';
import {LinksRestService} from '../apis/links-rest.service';
import * as _ from 'lodash';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {LinkDialogComponent} from '../../dialogs/link-dialog/link-dialog.component';
import {Actions} from '../../../common/server-actions/actions';
import {GeneralService, LoggerService} from 'ac-infra';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {LinkCreationService} from '../../maps/services/link-creation.service';
import {RestResponseSuccess} from "../../../common/server-actions/rest";

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class LinksActionsService extends Actions {

    linkEmptyObj = require('../../dialogs/link-dialog/link.empty.json');

    visibleFieldsForLinkInfo = ['direction', 'linkSrcId', 'linkDstId', 'type.categoryType', 'type.categoryValue', 'type.categorySecondaryValue', 'tenantId', 'regionId', 'ldapGroupName'];

    constructor(public loggerService: LoggerService,
                public generalService: GeneralService,
                public devicesRestService: DevicesRestService,
                public sitesService: SitesRestService,
                public linksRestService: LinksRestService,
                public linkCreationService: LinkCreationService,
                public tenantsRestService: TenantsRestService) {
        super({
            entityName: 'link',
            entityService: linksRestService,
            isWsEntity: true
        });

        this.linkCreationService.addLinkObservable$?.pipe(untilDestroyed(this)).subscribe((obj) => {
            this.addLink(obj.srcDevice, obj.dstDevice);
        });
    }

    addLink = (srcEntity?, dstEntity?) => {
        const dialogRef = this.acDialogService.open();
        const srcId = srcEntity ? srcEntity.id : -1;
        const dstId = dstEntity ? dstEntity.id : -1;
        const dataObject = {srcId, dstId};

        this.openLinkDetailsDialog(dataObject, {}, dialogRef);
    };

    editLink = (link) => {
        const dialogRef = this.acDialogService.open();
        const success = (response: RestResponseSuccess) => {
            this.openLinkDetailsDialog({}, response.data, dialogRef);
        }
        this.linksRestService.getById({success, extendParameters: true, skipPopulateFilter: true, id: link.id});
    };

    deleteLink = (linksArray: any[]) => {
        const dstDevices: any = this.devicesRestService.getEntitiesByIds(linksArray.map(link => link.linkDstId));
        const genericDeviceIncluded = !!dstDevices.find(device => device.familyType === 'GENERIC');

        let optionsModel;
        if (genericDeviceIncluded) {
            optionsModel = {checkboxModelName: 'includeGenericDestinations', checkboxModel: {value: true, label: 'Include generic destinations?'}};
        }

        this.delete({entityArray: linksArray, optionsModel});
    };

    linkSrcOrDstIdToName = (srcOrDstId: number | string) => {
        let entity = this.devicesRestService.getEntityById(srcOrDstId);
        entity = entity || this.sitesService.getEntityById(srcOrDstId);

        return entity && entity.name;
    };

    private openLinkDetailsDialog = (dataObject, link, dialogRef) => {
        dataObject.devices = this.devicesRestService.getAllEntities();
        dataObject.sites = this.sitesService.getAllEntities();

        const isEdit = !this.isObjectEmpty(link);

        const id = link.id;

        const linkToSend = isEdit ? _.cloneDeep(link) : _.cloneDeep(this.linkEmptyObj);
        if (isEdit) {
            this.cleanEmptyValues(linkToSend);
        }

        if (dataObject.devices.length + dataObject.sites.length < 2) {
            this.acDialogService.info('Can not add link when there are not enough devices and sites');
            return;
        }

        const onClose = () => this.linkCreationService.clearLinkVisualizationOfCreatedLink();

        const dialogData = {link: linkToSend, isEdit};
        _.assign(dialogData, dataObject);
        const serverCallback = (onSuccess, onFailure) => {
            this.linkCreationService.turnOffLinkCreation();

            if (isEdit) {
                this.linksRestService.edit(onSuccess, onFailure, dialogData.link, id);
            } else {
                this.linksRestService.add(onSuccess, onFailure, dialogData.link);
            }
        };

        this.genericAction({dialogComponentType: LinkDialogComponent, serverCallback, dialogRef, dialogData, dialogConfig: {onClose}});
    };

    private isObjectEmpty = (value) => _.isEqual(value, {});
}
