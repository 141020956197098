<mat-expansion-panel *ngIf="authorized" [expanded]="expanded" (closed)="closed.emit($event)" (opened)="opened.emit($event)" class="{{classes}}">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span *ngIf="header">{{header}}</span>
            <ng-template *ngIf="headerTemplate" [ngTemplateOutletContext]="{object: headerObject}" [ngTemplateOutlet]="headerTemplate"></ng-template>
        </mat-panel-title>

        <mat-panel-description>
            <span *ngIf="description">{{description}}</span>
            <ng-template *ngIf="descriptionTemplate" [ngTemplateOutletContext]="{object: descriptionObject}" [ngTemplateOutlet]="descriptionTemplate"></ng-template>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-content></ng-content>
</mat-expansion-panel>
