<div [id]="radioButtonId" [class.ac-disable]="ngDisabled" class="radio-buttons-container">
    <mat-radio-group [(ngModel)]="acModel"
                     (ngModelChange)="modelChanged($event)"
                     [disabled]="ngDisabled"
                     color="primary">
        <ng-container  *ngFor="let item of items">
            <ng-template *ngIf="item.prefixTemplate" [ngTemplateOutlet]="item.prefixTemplate" [ngTemplateOutletContext]="{item: item}"></ng-template>

            <mat-radio-button *ngIf="item.visible === undefined || item.visible === true" [attr.title]="item.title || null"
                    [value]="item.value"
                    [disabled]="item.disabled"
                    class="{{item.class}}">
                <span *ngIf="item.text" class=" ac-radio-button-label-text flexItem">{{item.text}}</span>
                <ng-template *ngIf="item.postfixTemplate" [ngTemplateOutlet]="item.postfixTemplate" [ngTemplateOutletContext]="{item: item}"></ng-template>
            </mat-radio-button>

            <span *ngIf="item.subText" class="ac-radio-button-sub-text flexItem">{{item.subText}}</span>
        </ng-container>
    </mat-radio-group>
    <ng-content></ng-content>
</div>


