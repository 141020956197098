<mat-accordion>
    <ac-expansion-panel [classes]="'operator-connect-expansion-panel ' + (cardHasError ? 'cardHasError' : '')"
                        [expanded]="true"
                        [headerTemplate]="headerTemplate"
                        [descriptionTemplate]="removeButton"
                        [descriptionObject]="{index: index, type: 'offers'}">
        <div class="flexColumnParent">

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Offer Type">
                    <ac-single-select [(acModel)]="offerObject.offerType"
                               (acModelChange)="offerTypeWasSelected.emit($event)"
                               [attr.alternativeName]="'tenant.operatorConnectInfo.offers.' + index + '.offerType'"
                               [items]="offersTypesList"
                               bindLabel="viewName" bindValue="name">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container label="Offer Markets">
                    <ac-single-select [(acModel)]="offerObject.allowed"
                               [attr.alternativeName]="'tenant.operatorConnectInfo.offers.' + index + '.allowed'"
                               [items]="allowedList">
                    </ac-single-select>
                </ac-input-container>
            </div>

            <div>
                <ac-input-container label="Markets">
                    <ac-select [(acModel)]="offerObject.countryCodes"
                               [attr.alternativeName]="'tenant.operatorConnectInfo.offers.' + index + '.countryCodes'"
                               [items]="countryCodesTypeList"
                               [multiple]="true"
                               bindLabel="viewName" bindValue="name">
                    </ac-select>
                </ac-input-container>
            </div>
        </div>
    </ac-expansion-panel>

    <ng-template #headerTemplate>
        <div class="oc-panel-header">
            {{'Offer ' + (index + 1)}}
        </div>
    </ng-template>
</mat-accordion>
