<div class="ac-filter flexColumnParent" [class.readOnly]="readOnly">
    <div class="flexItem" *ngIf="!readOnly">
        <ac-drop-down (openStatusChanged)="onFilterDialogStatus($event)"
                      [parentClass]="'ac-filter ' + filterClass"
                      [upperCaseContent]="true"
                      [openDropDownAfterButton]="true"
                      class="ac-filter-dropdown"
                      #filterDropDown>


            <ac-button (acClick)="openFilter()"
                       *ngIf="types.length > 0"
                       [buttonValue]="'Add filter' | uppercase"
                       class="add-filter-button ac-button-full ac-dropdown-toggle"
                       parentAfterButton>
            </ac-button>

            <div class="ac-filter-content flexColumnParent" tooltip>
                <ng-content select="ac-filter-type"></ng-content>

                <div class="flexItem">
                    <ac-button (acClick)="applyFilterChanges(); filterDropDown?.close()"
                               [ngDisabled]="!hasChanges() || !isAllTypesAreValid()"
                               buttonValue="Apply"
                               class="add-filter-apply-button ac-button-full">
                    </ac-button>
                </div>
            </div>
        </ac-drop-down>
    </div>


    <ng-content></ng-content>


    <div class="flexItemBiggest filter-items overflowAuto">
        <ul class="filter-item-list" #visibleFilterItems>
            <ng-container *ngFor="let type of filterTypesArr">

                <li *ngIf="filter[type.filterName] && (filter[type.filterName].pinned || checkIfItemIsNotEmpty(filter[type.filterName].unpinned, type))"
                    class="filter-item"
                    [class.both]="filter[type.filterName].pinned && checkIfItemIsNotEmpty(filter[type.filterName].unpinned, type)">
                    <ac-filter-item *ngIf="filter[type.filterName].pinned"
                                    (itemRemove)="removeItem($event.itemName, $event.itemType); filterDropDown?.close()"
                                    [itemName]="type.filterName"
                                    [filterObject]="type"
                                    itemType="pinned">
                    </ac-filter-item>

                    <ac-filter-item *ngIf="filter[type.filterName].unpinned | acFunc : checkIfItemIsNotEmpty : type"
                                    (itemRemove)="removeItem($event.itemName, $event.itemType); filterDropDown?.close()"
                                    [itemName]="type.filterName"
                                    [filterObject]="type"
                                    itemType="unpinned">
                    </ac-filter-item>
                </li>

            </ng-container>
        </ul>
    </div>

</div>
