import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../ac-form/ac-form.component';


@Component({
    selector: 'ac-checkbox-group',
    styleUrls: ['./ac-checkbox-group.component.less'],
    templateUrl: './ac-checkbox-group.component.html',
})

export class AcCheckboxGroupComponent {

    @Input() parentModel: any;
    @Input() items: any;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @Input() checkboxGroupId = '';
    @Input() withoutHeader: boolean;
    @Input() disableCheckboxGroup = false;
    @Input() searchMode = false;
    @Input() fullHeight = false;
    @Input() searchName: string;
    @Input() getFilterObj: Function;
    @Input() showIcon = false;
    @Input() iconClasses = 'fa-circle';
    @Input() iconType = '';
    @Input() textClass = '';
    @Input() checkboxGroupLabel;
    @Input() isHorizontal = false;

    dataItems: any;
    placeholderText = 'Search by name';


    constructor(@Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.disableCheckboxGroup = true;
        }
        this.dataItems = this.items;
    }

    searchNameChanged(search) {
        this.dataItems = this.getFilterObj(this.items, search);
    }

    setAll = function setAll(value) {
        const self = this;
        self.items.forEach(function(item) {
            if (!item.disabled) {
                self.parentModel[item.name] = !!value;
            }
        });
    };

    invertAll = function invertAll() {
        const self = this;
        self.items.forEach(function(item) {
            if (!item.disabled) {
                self.parentModel[item.name] = !self.parentModel[item.name];
            }
        });
    };

    allItemsAreDisabled = function allItemsAreDisabled() {
        const self = this;
        let allItemsAreDisabled = true;

        self.items.forEach(function(item) {
            if (!item.disabled) {
                allItemsAreDisabled = false;
            }
        });

        return allItemsAreDisabled;
    };

    parentModelChanged(item) {
        this.acChanged.emit(item);

    }
}



