import {Component, Inject} from '@angular/core';
import {AcDialog, AuthorizationService, DIALOG_CONFIG, DialogConfig, SchemaHelperService} from 'ac-infra';
import * as _ from 'lodash';
import {MetadataService} from '../../../metadata/metadata.service';
import {DevicesRestService} from '../../services/apis/devices-rest.service';
import {authGroup} from '../../../common/utilities/session-helper.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Subject} from 'rxjs';

@AcDialog({
    cancelButtonText: 'Close',
    id: 'link-details-dialog',
    title: 'Link Details',
    width: 600,
})
@Component({
    selector: 'link-dialog',
    templateUrl: './link-dialog.component.html',
})
export class LinkDialogComponent {

    link: any = {};
    linkAddSchema = require('../../../../schema/post/linksprocessor.json');
    linkEditSchema = require('../../../../schema/put/linksprocessor.json');
    schema: any;
    isEdit = false;

    MAX_LENGTH_FOR_DIGITS = 10;
    MAX_LENGTH_FOR_FQDN_TYPE = 100;
    MAX_LENGTH_FOR_REGEX_TYPES = 255;

    TYPE_DISPLAY_NAMES = {
        IPGROUP_TYPE: 'IP Group',
        TRUNKGROUP_TYPE: 'Trunk Group',
        MEDIA_REALM_TYPE: 'Media Realm',
        MEDIA_SERVER_TYPE: 'Media Server',
        SUB_MEDIA_REALM_TYPE: 'Remote Media Subset'
    };

    LYNC_OPTIONS = ['FQDN_TYPE'];

    categoryTypesServerMap = {
        TRUNKGROUP_TYPE: 'getDeviceTrunkGroups',
        IPGROUP_TYPE: 'getDeviceIpGroups',
        MEDIA_REALM_TYPE: 'getDeviceMediaRealms',
        MEDIA_SERVER_TYPE: 'getDeviceMediaServers'
    };

    categoryValuesList;
    subCategoryValuesList;
    mediaDecompositionDevicesList;
    linkTypeList;
    linkDirectionList;

    srcDeviceTypeOptionsList = [];
    dstDeviceTypeOptionsList = [];
    linkTypeOptionsList = [];
    directionOptionsList = [];

    categoryValueOptions = [];
    subCategoryValueOptions = [];
    categoryValueText: string;
    subCategoryValueText: string;
    categoriesValuesAreReady = true;
    devices: any;
    DeviceProductTypeMapper: any;
    disableCategoryValue = false;
    disableSubCategoryValue = false;
    authorizedForAdmins = AuthorizationService.validFor(authGroup.admin);
    additionalSettings: any;
    selectedSrcDevice: any;

    constructor(private schemaHelperService: SchemaHelperService,
                private devicesRestService: DevicesRestService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
        this.categoryValuesList = MetadataService.getType('CategoryValues', true);
        this.subCategoryValuesList = MetadataService.getType('SubCategoryValues', true);
        this.mediaDecompositionDevicesList = MetadataService.getType('MediaDecompositionDevices', true);
        this.DeviceProductTypeMapper = MetadataService.getType('DeviceProductTypeMapper', true);
        this.linkTypeList = MetadataService.getType('LinkType');
        this.linkDirectionList = MetadataService.getType('LinkDirection');
    }

    ngOnInit() {
        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.schema = this.isEdit ? _.cloneDeep(this.linkEditSchema) : _.cloneDeep(this.linkAddSchema);
        this.link = this.dialogConfig.dialogData.link;
        this.devices = this.devicesRestService.getAllEntitiesHashed();

        this.initializeOptionListArrays(this.dialogConfig.dialogData);

        this.link.linkSrcId = this.link.linkSrcId || this.dialogConfig.dialogData.srcId;
        this.link.linkDstId = this.link.linkDstId || this.dialogConfig.dialogData.dstId;

        this.link.type = this.link.type || {};

        this.additionalSettings = _.cloneDeep(this.link);
        this.additionalSettings.type.categoryType = this.link.type.categoryType;

        if (this.dialogConfig.dialogData.srcId !== -1) {
            this.updateCategoryTypeOptionList(this.link.linkSrcId);
        }

        if(this.isEdit){
            this.additionalSettings.categoryValue = this.link?.type?.categoryValue ?
                {value: this.link.type.categoryValue, text: this.link.type.categoryValue} : undefined;
            this.additionalSettings.categorySecondaryValue = this.link?.type?.categorySecondaryValue ?
                {value: this.link.type.categorySecondaryValue, text: this.link.type.categorySecondaryValue} : undefined;
        }
    }

    linkDstDeviceChanged = (newLinkDstId) => {
        const selectedDstDevice: any = this.devicesRestService.getEntityById(newLinkDstId);

        if (selectedDstDevice && selectedDstDevice.productType.includes('LYNC') && _.isEqual(this.link.type.categoryType, 'FQDN_TYPE')) {// FQDN will never be a select only text field therefore categoryValueOptions will be empty for this type
            this.link.type.categorySecondaryValue = selectedDstDevice.lyncInfo && selectedDstDevice.lyncInfo.FQDN || '';
            this.additionalSettings.categorySecondaryValue = {value: this.link.type.categorySecondaryValue, text: this.link.type.categorySecondaryValue};
            this.disableSubCategoryValue = true;
        } else {
            if(this.link.type.categorySecondaryValue === '' || _.isNil(this.link.type.categorySecondaryValue)){
                delete this.link.type.categorySecondaryValue;
            }
            this.disableSubCategoryValue = false;
        }
    };

    updateCategoryTypeOptionList = (newLinkSrcId) => {
        this.additionalSettings.linkSrcId = newLinkSrcId;
        this.selectedSrcDevice = this.devicesRestService.getEntityById(newLinkSrcId);

        if (!this.selectedSrcDevice) {
            return;
        }

        this.initializeSecondaryValueInformationForAddMode();
        this.linkTypeOptionsList = [];
        this.linkTypeOptionsList = this.buildLinkTypeOptionList();

        const previousCategoryType = this.additionalSettings.type.categoryType;
        this.link.type.categoryType = this.getSelectedCategoryType();
        this.onCategoryTypeChange();

        this.link.direction = this.selectedSrcDevice.productType.includes('LYNC') ? 'BI_DIRECTIONAL' : this.link.direction;

        if(previousCategoryType && previousCategoryType !== this.additionalSettings.type.categoryType){
            this.additionalSettings.categoryValue = undefined;
            this.additionalSettings.categorySecondaryValue = undefined;
        }
    };

    onCategoryTypeChange = () => {
        this.additionalSettings.type.categoryType = this.link.type.categoryType;

        if(this.categoryTypesServerMap[this.additionalSettings.type.categoryType] && !!this.selectedSrcDevice.id && this.isDeviceConnected(this.selectedSrcDevice)){
            if(this.additionalSettings.type.categoryType !== 'MEDIA_SERVER_TYPE' || (this.additionalSettings.type.categoryType === 'MEDIA_SERVER_TYPE' &&
            this.mediaDecompositionDevicesList.includes(this.selectedSrcDevice.productType) === 'SC_SBC')){
                this.fetchCategoryValues();
            }
        }else{
            if(this.additionalSettings.type.categoryType === 'SUB_MEDIA_REALM_TYPE') {
                this.fetchCategoryValues('MEDIA_REALM_TYPE');
            } else{
                this.setCategoryValue(this.additionalSettings.type.categoryType, this.selectedSrcDevice);
                this.categoryValueOptions = [];
            }
        }

        this.getCategoryValueLabel();
    };

    onCategoryValueChange = (selectedCategory) => {

        if (this.link.type) {
            this.link.type.categoryValue = typeof selectedCategory === 'string' ? selectedCategory : (selectedCategory?.value || selectedCategory?.text || '-1');

            const isNotNumber = isNaN(parseInt(this.link.type.categoryValue, 10));

            if (this.link.type.categoryValue && this.link.type.categoryValue !== -1 && !isNotNumber && this.link.linkSrcId &&
                _.isEqual(this.additionalSettings.type.categoryType, 'SUB_MEDIA_REALM_TYPE')) {
                this.fetchSubMediaRealmsData(this.link.type.categoryValue);
            }
        }
    };

    categorySecondaryValueChanged = (selectedCategorySecondaryValue) => {
        this.link.type.categorySecondaryValue = selectedCategorySecondaryValue ? (selectedCategorySecondaryValue.value || selectedCategorySecondaryValue.text || selectedCategorySecondaryValue) : '-1';
    };

    isSecondaryCategoryValueNeeded = () =>
        (this.additionalSettings.type.categoryType === 'SUB_MEDIA_REALM_TYPE' || this.additionalSettings.type.categoryType === 'FQDN_TYPE');


    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (!this.isEdit) {
            if (formModel.linkSrcId !== undefined && formModel.linkDstId !== undefined && formModel.linkSrcId !== -1 && formModel.linkDstId !== -1 && formModel.linkSrcId === formModel.linkDstId) {
                errors.push({instancePath: '/linkSrcId', keyword: 'equalValue', inputName: 'link.linkSrcId', message: 'destination and source devices are the same'});
                errors.push({instancePath: '/linkDstId', keyword: 'equalValue', inputName: 'link.linkDstId', message: 'destination and source devices are the same'});
            }

            const categoriesValueMaxLength = this.getCategoryValueLength();
            if(this.additionalSettings.categoryValue && this.additionalSettings.categoryValue.length > categoriesValueMaxLength){
                errors.push(this.schemaHelperService.buildErrorItem({instancePath: '/additionalSettings/categoryValue', keyword: 'maxLength', inputName: 'additionalSettings.categoryValue', limit: categoriesValueMaxLength}));
            }

            if(this.additionalSettings.categorySecondaryValue && this.additionalSettings.categorySecondaryValue.length > categoriesValueMaxLength){
                errors.push(this.schemaHelperService.buildErrorItem({instancePath: '/additionalSettings/categorySecondaryValue',
                    keyword: 'maxLength', inputName: 'additionalSettings.categorySecondaryValue', limit: categoriesValueMaxLength}));
            }
        }
    };

    getCategoryValueLength = () => this.TYPE_DISPLAY_NAMES[this.additionalSettings.type.categoryType] ? this.MAX_LENGTH_FOR_DIGITS :
        (this.additionalSettings.type.categoryType === 'FQDN_TYPE' ? this.MAX_LENGTH_FOR_FQDN_TYPE : this.MAX_LENGTH_FOR_REGEX_TYPES);

    getCategoryValueLabel = () => {
        this.categoryValueText = this.categoryValuesList[this.additionalSettings.type.categoryType] || 'Category Value';
        this.subCategoryValueText = this.subCategoryValuesList[this.additionalSettings.type.categoryType] || 'Secondary Category Value';
    };

    getCategoriesTypesList = () => {
        if(this.selectedSrcDevice.entityType === 'site'){
            return {operator: true, list: ['IP_PREFIX_TYPE', 'PHONE_PREFIX_TYPE', 'MEDIA_IP_PREFIX_TYPE']};
        }else if(this.selectedSrcDevice.productType.includes('LYNC')){
            return {operator: true, list: this.LYNC_OPTIONS};
        }else if(this.mediaDecompositionDevicesList.includes(this.selectedSrcDevice.productType)){
            return {operator: false, list: this.LYNC_OPTIONS};
        }else{
            return {operator: false, list: ['FQDN_TYPE', 'MEDIA_SERVER_TYPE']};
        }
    };

    initializeOptionListArrays = (dialogData) => {
        this.srcDeviceTypeOptionsList = dialogData.devices.filter((item) => !['VAIC', 'TEAMS', 'NONE_ACL_DEVICE'].includes(item.productType));

        this.dstDeviceTypeOptionsList = dialogData.devices.filter((item) => item.productType !== 'VAIC').concat(dialogData.sites);

        _.forOwn(this.linkDirectionList, (linkDirection) => {
            this.directionOptionsList.push(linkDirection);
        });
    };

    fetchSubMediaRealmsData = (MediaRealmId) => {
        this.initializeSecondaryValueInformationForAddMode();

        const onSuccess = (value: RestResponseSuccess) => {
            this.onDeviceSubMediaRealmsSuccess(value.data || {items: undefined});
        };

        this.devicesRestService.getDeviceSubMediaRealms(onSuccess, onSuccess, this.link.linkSrcId, MediaRealmId);
    };

    onDeviceSubMediaRealmsSuccess = (value) => {
        this.subCategoryValueOptions = value.items ? this.createOptionArr(this.generateSubCategoryValueOptions(value.items)) : [];

        if(this.subCategoryValueOptions.length > 0){
            this.link.type.categorySecondaryValue = this.subCategoryValueOptions[0].value;
            this.additionalSettings.categorySecondaryValue = this.subCategoryValueOptions[0];
        }
    };

    generateSubCategoryValueOptions = (subCategoryValueOptions) => {
        subCategoryValueOptions.forEach((subCategoryValueOption) => {
            if (subCategoryValueOption && subCategoryValueOption.id) {
                subCategoryValueOption.id = subCategoryValueOption.id.substr(subCategoryValueOption.id.indexOf('.') + 1);
                subCategoryValueOption.isSubCategory = true;
            }
        });

        return subCategoryValueOptions;
    };

    createOptionArr = (categoriesValues, result = []) => {
        categoriesValues.forEach((item)=> result.push({value: item.id.toString(), text: this.categoryTypesValueToLabel(item)}));

        return result;
    };

    setCategoryValue = (categoryTypeName, selectedSrcDevice?) => {
        this.disableCategoryValue = false;

        if (this.isEdit && this.isNotEmptyArray(this.categoryValueOptions) && this.TYPE_DISPLAY_NAMES[this.additionalSettings.type.categoryType]) {
            _.forOwn(this.categoryValueOptions, (category) => {
                if (this.link.type && category.value === this.link.type.categoryValue) {
                    this.additionalSettings.categoryValue = category;
                }
            });

            this.additionalSettings.categoryValue = this.additionalSettings.categoryValue || '';
            this.onCategoryValueChange(this.additionalSettings.categoryValue);
        } else if (this.isNotEmptyArray(this.categoryValueOptions)) {
            this.additionalSettings.categoryValue = this.categoryValueOptions[0];
            this.link.type.categoryValue = this.additionalSettings.categoryValue.value || this.additionalSettings.categoryValue.text;
            this.fetchSubMediaRealmsDataIfNeeded(categoryTypeName);
        } else if (_.isEqual(categoryTypeName, 'FQDN_TYPE') && selectedSrcDevice && selectedSrcDevice.productType.includes('LYNC')) {// FQDN will never be a select only text field therefore categoryValueOptions will be empty for this type
            this.link.type.categoryValue = selectedSrcDevice.lyncInfo && selectedSrcDevice.lyncInfo.FQDN || '';
            this.additionalSettings.categoryValue = {value: this.link.type.categoryValue, text: this.link.type.categoryValue};
            this.disableCategoryValue = true;
        } else if (!this.isEdit) {
            delete this.link.type.categoryValue;
            delete this.additionalSettings.categoryValue;
            delete this.link.type.categorySecondaryValue;
            delete this.additionalSettings.categorySecondaryValue;
        }
    };

    fetchSubMediaRealmsDataIfNeeded = (categoryTypeName) => {
        if (_.isEqual(categoryTypeName, 'SUB_MEDIA_REALM_TYPE') && this.categoryValueOptions[0].value) {
            this.fetchSubMediaRealmsData(this.categoryValueOptions[0].value);
        }
    };

    isDeviceConnected = (device) => device && device.applicationsStatus && device.applicationsStatus.management &&
        device.applicationsStatus.management.connectionState && device.applicationsStatus.management.connectionState.toLowerCase() === 'connected';

    fetchCategoryValues = (type?) => {
        const restPropertyName = this.categoryTypesServerMap[type || this.additionalSettings.type.categoryType];
        this.categoriesValuesAreReady = false;
        const onSuccess = (value) => {
            this.onSuccessCategoryValues({data: value.data || undefined});
            this.categoriesValuesAreReady = true;
        };

        this.devicesRestService[restPropertyName](onSuccess, onSuccess, this.selectedSrcDevice.id, restPropertyName);
    };

    onSuccessCategoryValues = ({data = undefined}) => {
        this.categoryValueOptions = this.createOptionArr(data && data.items || []);
        this.setCategoryValue(this.additionalSettings.type.categoryType, this.selectedSrcDevice);
    };

    createArrayLinkOptionList = (linkObj) => {
        const listArr = [];
        _.forOwn(linkObj, (obj) => {
            listArr.push(obj);
        });

        return listArr;
    };

    buildLinkTypeOptionList = () => {
        const categoryObject = this.getCategoriesTypesList();
        if(categoryObject){
            return this.createArrayLinkOptionList(this.populateLyncTypeArray(categoryObject.list, categoryObject.operator));
        }

        return this.createArrayLinkOptionList(this.linkTypeList);
    };

    populateLyncTypeArray = (filterValue, operator) => Object.getOwnPropertyNames(this.linkTypeList).filter(this.byDeviceType(filterValue, operator))
        .map((item) => this.linkTypeList[item]);

    getSelectedCategoryType = () => {
        let linkType;
        if(this.link.type.categoryType){
            linkType = this.linkTypeOptionsList.find((linkType) => linkType.name === this.link.type.categoryType);
        }
        return (linkType && linkType.name) || this.linkTypeOptionsList[0].name;
    };

    categoryTypesValueToLabel = (item) => {
        if(item && item.id){
            if(item.edited || !!item.name){
                return item.edited ? item.id : item.name;
            }

            const prefix = item.isSubCategory ? this.TYPE_DISPLAY_NAMES.SUB_MEDIA_REALM_TYPE : this.TYPE_DISPLAY_NAMES[this.additionalSettings.type.categoryType];
            return prefix + ' #' + item.id;
        }

        return '';
    };

    requiredsAuxiliaryForm = () => {
        if(!this.isEdit){
            const result = ['additionalSettings.categoryValue', 'link.linkSrcId', 'link.linkDstId'];

            if (this.isSecondaryCategoryValueNeeded()) {
                result.push('additionalSettings.categorySecondaryValue');
            }

            return result;
        }

        return [];
    };

    byDeviceType = (filterValue, operator) => function byDeviceTypeFn(item) {
        return (filterValue.indexOf(item) >= 0) === operator;
    };

    initializeSecondaryValueInformationForAddMode = () => {
        if (!this.isEdit) {
            this.additionalSettings.categorySecondaryValue = '';
            this.subCategoryValueOptions = [];
        }
    };

    isNotEmptyArray = (array) => _.isArray(array) && array.length > 0;

    showLdapGroup = () => {
        if (!this.additionalSettings.linkSrcId || this.additionalSettings.linkSrcId < 0) {
            return false;
        }

        const srcProductType = this.devices[this.additionalSettings.linkSrcId].productType;

        return this.DeviceProductTypeMapper.SBC.includes(srcProductType);
    };
}


