<div class="splash-container" [class.ready]="ready">
    <div class="splash-content-container">
        <div class="splash-content-container-inner">
            <div *ngIf="pageTitle" class="title">{{pageTitle}}</div>
            <div class="form-and-error-container">
                <ng-content select="[form]"></ng-content>
                <div [class.visibilityHidden]="!errorMessage" class="splash-message">
                    <span>{{errorMessage}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="splash-logo-container">
        <ovoc-splash-logo *ngIf="generalService.serverInfo?.systemType==='OVOC' && generalService.serverInfo?.branding === null"></ovoc-splash-logo>
        <other-splash-logo *ngIf="generalService.serverInfo?.systemType!=='OVOC'"></other-splash-logo>
    </div>
</div>
