import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';

@Injectable({providedIn: 'root'})
export class DevicesRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({
            url: 'topology/devices', entityType: 'devices', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter',
            urlToFilterCallingApi: {
                'topology/devices': 'devices',
                'floatingLicense/devices': 'deviceFloatingLicense',
                'topology/statistics/devices': 'devicesTopologyStatistics',
                // same request /entities with UnitIds for table and /devicesEntities for stats(all device filters)
                'callsMonitor/statistics/entities': 'devicesMonitorStatistics',
                'callsMonitor/statistics/devicesEntities': 'devicesCallsStatistics'
            }
        });

    }

    public startServlet = (success, failure, url) => {
        this.getServlet(success, failure, url);
    };

    public registerToCloudService = (success, failure) => {
        this.add(success, failure, undefined, 'floatingLicense/actions/cloudService/register');
    };

    public saveDataUsage = (success, failure, actionObject) => {
        this.download(success, failure, actionObject, 'floatingLicense/actions/saveUsageReportData');
    };

    public restoreDeviceWithFile = (success, failure, device, file) => {
        const restoreObject = {devicesAndFiles: [{deviceId: device.id, fileName: file.name}]};
        this.add(success, failure, restoreObject, 'topology/actions/restore');
    };

    public receiveFile = (success, failure, deviceId, fileType) => {
        const requestData = {deviceId, fileType};
        this.download(success, failure, requestData, 'topology/actions/receiveFile', false, fileType.toLowerCase().includes('zip') ? 'blob' : 'text');
    };

    public getDeviceIpGroups = (success, failure, deviceId, singletonGroupName) => {
        this.get({
            url: 'topology/devices/' + deviceId + '/configurations/ipGroups',
            singletonGroupName,
            extendParameters: true
        }).then(success).catch(failure);
    };

    public getDeviceTrunkGroups = (success, failure, deviceId, singletonGroupName) => {
        this.get({url: 'topology/devices/' + deviceId + '/configurations/trunkGroups', singletonGroupName, extendParameters: true}).then(success).catch(failure);
    };

    public getDeviceMediaServers = (success, failure, deviceId, singletonGroupName) => {
        this.get({url: 'topology/devices/' + deviceId + '/configurations/mediaServers', singletonGroupName, extendParameters: true}).then(success).catch(failure);
    };

    public getDeviceMediaRealms = (success, failure, deviceId) => {
        this.get({url: 'topology/devices/' + deviceId + '/configurations/mediaRealms', extendParameters: true, skipErrorHandler: true}).then(success).catch(failure);
    };

    public getDeviceSubMediaRealms = (success, failure, deviceId, mediaRealmsId) => {
        this.get({url: 'topology/devices/' + deviceId + '/configurations/mediaRealms/' + mediaRealmsId + '/subMediaRealms', extendParameters: true, skipErrorHandler: true}).then(success).catch(failure);
    };

    public getFloatingLicenseDevices = (success, failure, parameters) => {
        this.get({url: 'floatingLicense/devices', parameters, extendParameters: true}).then(success).catch(failure);
    };

    getTLSContext(id: any, skipErrorHandler = true) {
        return this.get({url: 'topology/devices/' + id + '/configurations/tlsContexts', skipErrorHandler});
    }
    getRdpSessionURL = (id) => {
        return this.get({url: 'topology/actions/openRdpSession/' + id});
    };

    public doRestAction = (success, failure, devices, parameters = undefined, type = '') => {
        const deviceList = {devices: devices.map(this.multiActionDeviceFormat)};
        const object = _.extend(deviceList, parameters);

        this.add(success, failure, object, 'topology/actions/' + type);
    };

    private multiActionDeviceFormat = (device) => device.regionId + '/' + device.id;
}
