<div class="ac-table flexColumnParent"
     [class.visibilityHidden]="!viewPortVisible"
     [class.finishLoading]="!loading"
     [attr.id]="tableId"
     (wheel)="onWheelUpdateHorizontalScroller($event, horizontalScroller)"
     [attr.tabindex]="0"
     (focus)="toggleFocus(true)"
     (blur)="toggleFocus(false)"
     #tableContainer>

    <div class="header-table flexItem" #headerContainer>
        <table [class.visibilityHidden]="!isHeaderVisible || !initialized.closed"
               [style.padding-right]="viewportScrollBarWidth | acFunc : acTableService.widthToPixels"
               (mousedown)="tableMousedown($event)"
               (dblclick)="dblClick($event, container, headerContainer)"
               #headerTable>

            <thead ac-table-header
                   [columns]="_columns"
                   (columnSort)="onColumnSort($event)"
                   #acTableHeader
            ></thead>
        </table>
    </div>

    <virtual-scroller class="body-table virtual-scroll flexItemBiggest"
                      [class.visibilityHidden]="!isHeaderVisible || !isBodyVisible"
                      [items]="_rows | acFunc : rowsToViewPortData: collapsedGroups: filters"
                      [enableUnequalChildrenSizes]="true"
                      [bufferAmount]="virtualScrollerBufferAmount"
                      (vsEnd)="updateInfiniteScroll($event)"
                      #vsComponent>

        <table (mousedown)="tableMousedown($event)"
               (dblclick)="dblClick($event, container, headerContainer)">

            <colgroup>
                <col *ngFor="let column of _columns"
                     [style.width]="column.colWidth | acFunc : acTableService.widthToPixels : minCellWidth">
            </colgroup>
            <!-- #container is saved word for virtual scroller -->
            <tbody ac-table-body
                   [columns]="_columns"
                   [rows]="vsComponent.viewPortItems"
                   [showBufferLoader]="showInfiniteScrollBufferLoader"
                   [templates]="{infiniteScrollBufferLoader: infiniteScrollBufferLoader}"
                   #container
            ></tbody>
        </table>

    </virtual-scroller>

    <div class="flexItem horizontal-scroller"
         (scroll)="updateHorizontalScroll($event, [headerContainer, footerContainer])"
         #horizontalScroller>
        <div class="horizontal-scroller-item"
             [style.width]="headerTable | acFunc : calcHorizontalScrollWidth: viewportScrollBarWidth : headerTableWidthChanges"
        ></div>
    </div>

    <div class="footer-table flexItem" #footerContainer [hidden]="!footer">
        <table *ngIf="footer" [style.padding-right]="viewportScrollBarWidth | acFunc : acTableService.widthToPixels">

            <tfoot ac-table-footer [columns]="_columns"></tfoot>
        </table>
    </div>

    <ac-pagination class="flexItem"
                   *ngIf="paginator"
                   [pageIndex]="pageIndex"
                   [pageSize]="pageSize"
                   [itemsDisplayTemplate]="pageItemsDisplayTemplate"
                   [itemsDisplayType]="itemsDisplayType"
                   [pageSelector]="pageSelector"
                   [totalElements]="totalElements"
                   [pageSizeOptions]="pagingSizeOption"
                   (pageIndexChange)="onPageChange($event)"
                   (pageSizeChange)="onPageChange($event)">
    </ac-pagination>

    <ac-progress [hidden]="!loading && (isHeaderVisible || isBodyVisible)" class="ac-table-loader background"
                 [center]="true"></ac-progress>
</div>
