<ac-input-container *ngIf="showHost" label="White Label FQDN">
    <ac-textfield [(acModel)]="appRegistrationObject.portalFqdn"
                  [attr.alternativeName]="'appRegistrationObject.portalFqdn'">
    </ac-textfield>
</ac-input-container>

<ac-checkbox *ngIf="showHost"
             [(acModel)]="appRegistrationObject.isEnable"
             labelText="Enable Custom App Registration"
             [attr.alternativeName]="'appRegistrationObject.isEnable'">
</ac-checkbox>

<div class="flexRowParentEqualChildren">

    <ac-input-container label="Security Azure Hostname">
        <ac-textfield [(acModel)]="appRegistrationObject.cloudUrl"
                      [attr.alternativeName]="'appRegistrationObject.cloudUrl'"
                      [ngDisabled]="true">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container label="Azure AD Path Type File">
        <ac-single-select [(acModel)]="appRegistrationObject.urlMode"
                   [attr.alternativeName]="'appRegistrationSettings.urlMode'"
                   [bindLabel]="'text'" [bindValue]="'value'"
                   [items]="urlModeOptions"
                   [ngDisabled]="!authorizedToUpdate || appRegistrationObject.isEnable===false">
        </ac-single-select>
    </ac-input-container>
</div>
<div class="flexRowParentEqualChildren">
    <ac-input-container label="Azure Tenant ID">
        <ac-textfield [acModel]="appRegistrationObject.msTenantId"
                      (acModelChange)="appRegistrationObject.msTenantId = $event"
                      [attr.alternativeName]="'appRegistrationSettings.msTenantId'"
                      [ngDisabled]="!authorizedToUpdate || appRegistrationObject.isEnable===false">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container label="Azure Client ID">
        <ac-textfield [(acModel)]="appRegistrationObject.clientId"
                      [attr.alternativeName]="'appRegistrationSettings.clientId'"
                      [ngDisabled]="!authorizedToUpdate || appRegistrationObject.isEnable===false">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container label="Azure Client Secret" editModePrefix="Change">
        <ac-password [(acModel)]="appRegistrationObject.clientSecret"
                     [attr.alternativeName]="'appRegistrationSettings.clientSecret'"
                     [ngDisabled]="!authorizedToUpdate || appRegistrationObject.isEnable===false">
        </ac-password>
    </ac-input-container>
</div>
