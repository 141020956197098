<ac-form *ngIf="dialogConfig.ready" [formSettings]="dialogConfig.formSettings">
    <ac-input-container [label]="entitySelectionObject.selectLabel">
        <ac-single-select [sortItems]="true"
                   [acModel]="entitySelectionObject.entity"
                   (acModelChange)="entitySelectionObject.entity = $event"
                   bindLabel="name"
                   bindValue="id"
                   [useFullObjectInModel]="true"
                   [items]="entitySelectionObject.items">
        </ac-single-select>
    </ac-input-container>
</ac-form>
