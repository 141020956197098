import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {LicensePoolRestService} from '../apis/license-pool-rest.service';
import {CommonNotifiersService, ConfirmDialogData, LoggerService} from 'ac-infra';
import {LicensePoolDataService} from '../data/license-pool-data.service';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {LicensePoolDialogComponent} from '../../dialogs/license-pool-dialog/license-pool-dialog.component';
import {FlexPoolUpdatePriorityLevelDialogComponent} from '../../dialogs/flex-pool-update-priority-level-dialog/flex-pool-update-priority-level-dialog.component';
import {SaveDataToFileService} from '../../../common/utilities/save-data-to-file.service';
import {MetadataService} from '../../../metadata/metadata.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {Actions} from '../../../common/server-actions/actions';

@Injectable({providedIn: 'root'})
export class LicensePoolActionsService extends Actions {

    audioCodesDevicesList;
    multiLicensePoolAllocations: any = { managed: false, totalCBPbxUsers: {PoolAllocation: -1}, totalCBUsers: {PoolAllocation: -1},
        totalCBVoicemailAccounts: {PoolAllocation: -1}, totalCBAnalogDevices: {PoolAllocation: -1}, sbcSignaling: {PoolAllocation: -1},
        sbcTranscoding: {PoolAllocation: -1}, sbcSessions: {PoolAllocation: -1}, sbcRegistrations: {PoolAllocation: -1}
    };
    licenseProperties = [
        'deviceName', 'managed', 'licensePoolStatus', 'lastRequestTime', 'tenantName', 'regionName',
        'productType', 'serialNum', 'secondSerialNum', 'swVersion', 'ipAddress', 'isHA', 'sbcRegistrations_pool',
        'sbcRegistrations_local', 'sbcRegistrations_actual', 'sbcSessions_pool', 'sbcSessions_local', 'sbcSessions_actual',
        'sbcSignaling_pool', 'sbcSignaling_local', 'sbcSignaling_actual', 'sbcTranscoding_pool',
        'sbcTranscoding_local', 'sbcTranscoding_actual', 'totalCBAnalogDevices_pool', 'totalCBAnalogDevices_local', 'totalCBAnalogDevices_actual',
        'totalCBPbxUsers_pool', 'totalCBPbxUsers_local', 'totalCBPbxUsers_actual', 'totalCBUsers_pool',
        'totalCBUsers_local', 'totalCBUsers_actual', 'totalCBVoicemailAccounts_pool', 'totalCBVoicemailAccounts_local', 'totalCBVoicemailAccounts_actual'
    ];

    keyToMetaDataList = {productType: 'audioCodesDevicesList'};

    constructor(private licensePoolRestService: LicensePoolRestService,
                public loggerService: LoggerService,
                private saveDataToFileService: SaveDataToFileService,
                private licensePoolDataService: LicensePoolDataService,
                private networkFilterFormatterService: NetworkFilterFormatterService) {
        super({entityName: 'device'});
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
    }

    editLicensePool = (selectedLicensePool) => {
        const onSuccess = (response: RestResponseSuccess) => {
            this.multiLicensePoolAllocations.tenantId = selectedLicensePool[0].tenantId;
            _.extend(selectedLicensePool[0], response.data);
            this.openLicensePoolDialog(_.cloneDeep(selectedLicensePool));
        };

        this.licensePoolRestService.getLicensePoolById(onSuccess, () => {}, selectedLicensePool[0].id);
    };

    updateLicensePool = (selectedLicensePool) => {
        const onSuccess = () => this.refreshAndInsertToLog('updated license pool started');
        this.licensePoolRestService.updateLicensePool(onSuccess, () => {}, selectedLicensePool);
    };

    applyLicensePool = (selectedLicensePool) => {
        const confirmMessage = 'Are you sure you want to apply runtime license on this MG?';
        const successMessage = 'apply license pool started';
        const serverCallback = (onSuccess, onFailure) => this.licensePoolRestService.applyLicensePool(onSuccess, onFailure, selectedLicensePool);
        const dialogData: ConfirmDialogData = {confirmMessage, successMessage};
        this.genericConfirmAction({serverCallback, dialogData, dialogConfig: {submitButtonText: 'apply'}});
    };

    refreshLicensePool = (selectedLicensePool) => {
        const onSuccess = () => this.refreshAndInsertToLog('refresh license pool started');
        this.licensePoolRestService.refreshLicensePool(onSuccess, () => {}, selectedLicensePool);
    };

    updatePriorityLevel = (devices) => {
        const dialogData = {entity: devices[0], successMessage: 'refresh license pool started'};
        const serverCallback = (onSuccess, onFailure) => this.licensePoolRestService.updatePriorityLevel(onSuccess, onFailure, devices, dialogData.entity.flexPool.priority);
        this.genericAction({
            dialogComponentType: FlexPoolUpdatePriorityLevelDialogComponent,
            serverCallback,
            dialogData,
        });
    };

    downloadLicensePoolToFile = () => {
        const moduleFilter = this.networkFilterFormatterService.getFilterParameter('licensePool', 'NetworkFilter');
        this.callToFetchLicensePoolList(moduleFilter);
        this.acDialogService.info(['Started gathering data for CSV file.', 'For module: License Pool Table'].join('<br>'));
    };

    refreshAndInsertToLog = (message) => {
        CommonNotifiersService.updateFinishedDataAndFiltered({showLoader: true});
        this.logger.info(message);
    };

    private openLicensePoolDialog = (selectedDevices) => {
        const successMessage = 'update license pool started';
        const deviceType = this.audioCodesDevicesList[selectedDevices[0].productType].familyType;
        const licensePool = selectedDevices.length === 1 ? _.cloneDeep(selectedDevices[0]) : _.cloneDeep(this.multiLicensePoolAllocations);
        const dialogData = {licensePool, entity: {}, deviceType, selectedDevices, successMessage};
        const serverCallback = (onSuccess, onFailure) => {
            if (selectedDevices.length > 1) {
                this.licensePoolRestService.editMultiLicensePool(onSuccess, onFailure, dialogData.selectedDevices, {allocations: dialogData.entity});
            } else {
                this.licensePoolRestService.editLicensePool(onSuccess, onFailure, dialogData.entity);
            }
        };
        this.genericAction({
            dialogComponentType: LicensePoolDialogComponent,
            serverCallback,
            dialogData,
        });
    };

    private updateFilterFromMetadata = (moduleFilter) => {
        const productTypeList = [];
        if (moduleFilter && moduleFilter.productType) {
            moduleFilter.productType.forEach((productType) => {
                productTypeList.push(this.audioCodesDevicesList[productType].viewName || productType);
            });

            moduleFilter.productType = productTypeList;
        }
    };

    private callToFetchLicensePoolList = (moduleFilter) => {
        const sentParameters = {filter: _.cloneDeep(moduleFilter), sort: null, pager: {limit: 1000}};

        const onSuccess = (licensePool) => {
            const licenseToFile = _.cloneDeep(licensePool);
            _.forOwn(licenseToFile.allocations, (license) => {
                this.flattenLPObject(license);
            });
            this.updateFilterFromMetadata(moduleFilter);
            this.saveDataToFileService.createCSVFile('fixedLicensePool', moduleFilter, licenseToFile.allocations, this.licenseProperties);
        };

        this.licensePoolDataService.getLicensePoolForFile(onSuccess, () => {}, sentParameters);
    };

    private flattenLPObject = (license) => {
        const licenseMap = {PoolAllocation: 'pool', LocalAllocation: 'local', actual: 'actual'};
        const transferObjToParent: any = ['serialNum', 'secondSerialNum'];

        const changeObjValueWithMetaData = ['productType'];
        const idsToValuesObject = {id: 'name', regionId: 'regionName', tenantId: 'tenantName'};

        _.forOwn(idsToValuesObject, (name, id) => {
            license[name] = license[name] + '(' + license[id] + ')';
        });

        license.deviceName = license.name;

        _.forOwn(license, (licenseValue, licenseKey) => {
            if (licenseValue && typeof (licenseValue) === 'object') {
                _.forOwn(licenseValue, (value, key) => {
                    if (licenseMap[key]) {
                        license[licenseKey + '_' + licenseMap[key]] = value;
                    } else if (transferObjToParent.includes(key)) {
                        license[key] = value;
                    } else if (changeObjValueWithMetaData.includes(licenseKey)) {
                        license[key] = this[this.keyToMetaDataList[key]][value].viewName;
                    }

                });

                delete license[licenseKey];
            } else if (changeObjValueWithMetaData.includes(licenseKey)) {
                const viewName = this[this.keyToMetaDataList[licenseKey]] && this[this.keyToMetaDataList[licenseKey]][licenseValue].viewName;
                license[licenseKey] = viewName || licenseValue;
            }
        });
    };
}
