import {Component, Input} from '@angular/core';

import * as _ from 'lodash';

import {PMProfilesService} from '../../../statistics/services/PMProfilesService';
import {AuthorizationService, GeneralService} from 'ac-infra';
import {authGroup} from '../../../common/utilities/session-helper.service';
import {AcGeoEventsService} from '../ac-geo/services/ac-geo-events.service';
import {LinksActionsService} from '../../services/actions/links-actions.service';
import {SitesActionsService} from '../../services/actions/sites-actions.service';
import {DevicesActionsService} from '../../services/actions/devices-actions.service';
import {EntityActionsService} from '../../services/entity-actions.service';
import {RegionsActionsService} from '../../services/actions/regions-actions.service';
import {TenantsActionsService} from '../../services/actions/tenants-actions.service';
import {PageService} from '../../../common/services/routing/page.service';
import {DevicesExtendedActionsService} from '../../services/actions/devices-extended-actions.service';

@Component({
    selector: 'network-entity-popup',
    templateUrl: './network-entity-popup.component.html',
    styleUrls: ['./network-entity-popup.component.less'],

})
export class NetworkEntityPopupComponent {

    public _item;

    @Input() executeTopologyTreeDropDownClose: Function;
    @Input() set item(item) {
        this._item = item;
        this.preparePopup();
    }

    public _entityType;

    @Input() set entityType(entityType) {
        this._entityType = entityType;
        this.preparePopup();
    }

    actions: any;
    visibleFields = [];
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;

    constructor(public entityActionsService: EntityActionsService,
                private pmProfilesService: PMProfilesService,
                public linksActionsService: LinksActionsService,
                public sitesActionsService: SitesActionsService,
                public regionsActionsService: RegionsActionsService,
                public tenantsActionsService: TenantsActionsService,
                public devicesActionsService: DevicesActionsService,
                public devicesExtendedActionsService: DevicesExtendedActionsService,
                public pageService: PageService,
                private generalService: GeneralService) {
    }

    preparePopup = () => {
        this.fillVisibleFields();
        this.handleActionVisibility();
    };

    fillVisibleFields = () => {
        switch (this._entityType) {
            case 'device':
                this.visibleFields = this.devicesActionsService.visibleFieldsForDeviceInfo;
                break;
            case 'site':
                this.visibleFields = this.sitesActionsService.visibleFieldsForSiteInfo;
                break;
            case 'link':
                this.visibleFields = this.linksActionsService.visibleFieldsForLinkInfo;
                break;
            case 'tenant':
                this.visibleFields = ['tenantAddressSpace.subnetMasks', 'elementsCount', 'isDefault', 'applicationsStatus.other.activeDirectoryStatus'];
                break;
            case 'region':
                this.visibleFields = ['autoDetectionAddressSpace.subnetMasks', 'elementsCount', 'tenantId'];
                break;
            case 'ad':
                this.visibleFields = ['tenantId', 'status', 'scheduler.numOfUsers', 'activeDirectory.host', 'activeDirectory.port', 'activeDirectory.SSL', 'activeDirectory.domainName', 'activeDirectory.baseObject'
                    , 'scheduler.syncIntervalHours', 'scheduler.lastSyncTime', 'scheduler.fullSyncTimeMinutes', 'scheduler.fullSyncIntervalDays', 'scheduler.lastFullSyncTime'];
                break;
        }
    };

    onAction = () => {
        this.executeTopologyTreeDropDownClose && this.executeTopologyTreeDropDownClose();
        AcGeoEventsService.executeCloseOpenTooltipsOnMap();
    };


    handleActionVisibility = () => {
        const authorizedForSystemAdmin = AuthorizationService.validFor(authGroup.SYSTEM_ADMIN);
        const authorizedForSystemAndTenantAdminAndOperators = AuthorizationService.isAdminOrOperatorLevel();

        this.actions = {};

        switch (this._entityType) {
            case 'device':
                this.actions.editDevice = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.showDevice = this.entityActionsService.isShowButtonEnabled(this._item);
                this.actions.syncLinks = this._item && this.entityActionsService.isActionAvailable([this._item], 'syncLinks') && authorizedForSystemAndTenantAdminAndOperators;
                this.actions.lockDevice = this._item && this.entityActionsService.isActionAvailable([this._item], 'lockDevice') && authorizedForSystemAndTenantAdminAndOperators;
                this.actions.unlockDevice = this._item && this.entityActionsService.isActionAvailable([this._item], 'unlockDevice') && authorizedForSystemAndTenantAdminAndOperators;
                this.actions.softwareUpgrade = this._item && this.entityActionsService.isActionAvailable([this._item], 'softwareUpgrade') && authorizedForSystemAndTenantAdminAndOperators;
                this.actions.uploadConfigFile = this._item && this.entityActionsService.isActionAvailable([this._item], 'uploadConfigFile') && authorizedForSystemAndTenantAdminAndOperators;
                this.actions.deleteDevice = authorizedForSystemAndTenantAdminAndOperators;

                if (this.pmProfilesService.isDeviceHasPMInfo(this._item)) {
                    this._item.pmStatus = this.pmProfilesService.getPMStatus(this._item);
                }
                break;
            case 'site':
                this.actions.editSite = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.deleteSite = authorizedForSystemAndTenantAdminAndOperators;
                break;
            case 'link':
                this.actions.showLink = !this.oneLiveMode;
                this.actions.editLink = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.deleteLink = authorizedForSystemAndTenantAdminAndOperators;
                break;
            case 'tenant':
                this.actions.addRegion = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.editTenant = authorizedForSystemAdmin;
                this.actions.deleteTenant = authorizedForSystemAdmin;
                this.actions.downloadOCJSON = this.oneLiveMode && this._item?.operatorConnectEnabled && this._item?.generatedJson;
                break;
            case 'region':
                this.actions.addDevice = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.addSite = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.editRegion = authorizedForSystemAndTenantAdminAndOperators;
                this.actions.deleteRegion = authorizedForSystemAndTenantAdminAndOperators;
                break;
        }
        this.actions.any = _.find(this.actions, (x) => x);
    };
}
