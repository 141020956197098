<div *ngIf="!loading else loadingTemplate"
     [attr.id]="listId"
     class="ac-list">

    <mat-card *ngFor="let item of items; trackBy: trackById let isOdd=odd; let isEven=even"
              class="ac-list-item"
              [class.selected]="item.selected"
              (click)="onClick(item)">
        <ng-template [ngTemplateOutlet]="templates?.itemTemplate || itemTemplate"
                     [ngTemplateOutletContext]="{$implicit: item, templates}"
        ></ng-template>
    </mat-card>

    <ng-template #itemTemplate let-item let-templates="templates">

        <mat-card-title class="ac-list-item-title">
            <ng-template [ngTemplateOutlet]="templates?.itemTitleTemplate || itemTitleTemplate"
                         [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
        </mat-card-title>

        <mat-card-subtitle *ngIf="templates?.itemSubtitleTemplate"
                           class="ac-list-item-subtitle">
            <ng-template [ngTemplateOutlet]="templates.itemSubtitleTemplate"
                         [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
        </mat-card-subtitle>

        <mat-card-content class="ac-list-item-content">
            <ng-template [ngTemplateOutlet]="templates?.itemContentTemplate || itemContentTemplate"
                         [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
        </mat-card-content>

        <mat-card-actions  *ngIf="templates?.itemActionsTemplate"
                           class="ac-list-item-actions">
            <ng-template [ngTemplateOutlet]="templates?.itemActionsTemplate"
                         [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
        </mat-card-actions>
    </ng-template>

    <ng-template #itemTitleTemplate let-item>
        {{item.data.name}}
    </ng-template>

    <ng-template #itemContentTemplate let-item>
        {{item.data.value}}
    </ng-template>
</div>

<ng-template #loadingTemplate>
    <ac-progress class="loading"></ac-progress>
</ng-template>
