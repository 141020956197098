<ac-form *ngIf="dialogConfig.ready"
         [formModel]="licensePool"
         [formSchema]="schema"
         [formSettings]="dialogConfig.formSettings"
         formModelName="licensePool">

    <div id="license-pool-details-dialog-content">

        <ac-checkbox (acModelChange)="licensePool.managed = $event;unManaged()"
                     [acModel]="licensePool.managed"
                     labelText="Enable License Pool">
        </ac-checkbox>

        <div class="license-pool-group-container" [class.sbc-and-cb-view]="SBCAndCBView">
            <ac-form-group *ngIf="showSBCMenu()" titleText="SBC"
                           [class.ac-disable]="!licensePool.managed || !isSbc"
                           class="license-pool-allocation-content">
                <div *ngFor="let key of licensePoolAllocations.sbcAllocationsParameters" (click)="!_isPoolAllocation[key];poolAllocationChanged(key)" class="license-pool-allocation-container">
                    <ac-checkbox (acModelChange)="_isPoolAllocation[key] = $event;poolAllocationChanged(key)"
                                 [acModel]="_isPoolAllocation[key]"
                                 [ngDisabled]="!licensePool.managed || !isSbc">
                    </ac-checkbox>

                    <ac-input-container label="{{sbcAlloctions[key]}}" class="license-pool-allocation-input">
                        <ac-num-spinner (click)="_isPoolAllocation[key] = true"
                                        [(acModel)]="licensePool[key].PoolAllocation"
                                        [minValue]="0"
                                        [ngDisabled]="!_isPoolAllocation[key]"
                                        numSpinnerId="">
                        </ac-num-spinner>
                    </ac-input-container>
                </div>

            </ac-form-group>

            <ac-form-group *ngIf="showCBMenu()" titleText="CB"
                           [class.ac-disable]="!licensePool.managed || !isCloudBond"
                           class="license-pool-allocation-content">
                <div (click)="!_isPoolAllocation[key];poolAllocationChanged(key);" *ngFor="let key of licensePoolAllocations.cbAllocationsParameters" class="license-pool-allocation-container">
                    <ac-checkbox (acModelChange)="_isPoolAllocation[key] = $event;poolAllocationChanged(key)"
                                 [acModel]="_isPoolAllocation[key]"
                                 [ngDisabled]="!licensePool.managed || !isCloudBond">
                    </ac-checkbox>

                    <ac-input-container label="{{cbAlloctions[key]}}" class="license-pool-allocation-input">
                        <ac-num-spinner (click)="_isPoolAllocation[key] = true"
                                        [(acModel)]="licensePool[key].PoolAllocation"
                                        [minValue]="0"
                                        [ngDisabled]="!_isPoolAllocation[key]"
                                        numSpinnerId="">
                        </ac-num-spinner>
                    </ac-input-container>
                </div>
            </ac-form-group>
        </div>

    </div>
</ac-form>
