import {Component, Inject} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@Component({
    selector: 'select-pm-profile-dialog',
    templateUrl: './select-pm-profile-dialog.component.html'
})
export class SelectPmProfileDialogComponent {

    formSettings = {valid: true, touched: true};
    selectedProfile: any;
    profileSelectionOptions: any;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {

        this.selectedProfile = this.dialogConfig.dialogData.selectedProfile;
        this.profileSelectionOptions = this.dialogConfig.dialogData.profiles;

        this.selectedProfile.value = this.selectedProfile.value || this.profileSelectionOptions[0].id;
    }
}


