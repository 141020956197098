import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {LoggerService} from 'ac-infra';
import {LicenseRestService} from '../apis/license-rest.service';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {RegionsRestService} from '../apis/regions-rest.service';
import {DevicesRestService} from '../apis/devices-rest.service';
import {LicensePoolRestService} from '../apis/license-pool-rest.service';
import {RestResponseFailure, RestResponseSuccess} from '../../../common/server-actions/rest';
import {MetadataService} from '../../../metadata/metadata.service';
import {MiscellaneousJSUtilsService} from '../../../common/services/miscellaneous-JS-utils.service';
import {DecimalPipe} from '@angular/common';
import {CellTemplatesService} from '../../../common/utilities/cell-templates.service';


@Injectable({providedIn: 'root'})
export class LicensePoolDataService {

    private licenseAllocationsList: any;

    constructor(private loggerService: LoggerService,
                private miscellaneousJSUtilsService: MiscellaneousJSUtilsService,
                private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private devicesRestService: DevicesRestService,
                private licenseRestService: LicenseRestService,
                private decimalPipe: DecimalPipe,
                private cellTemplatesService: CellTemplatesService,
                private licensePoolRestService: LicensePoolRestService) {

        this.licenseAllocationsList = MetadataService.getType('LicenseAllocations', true);
    }

    public buildLicensePoolColumns = (userSecurityLevel, callback) => {
        const onSuccess = (value: RestResponseSuccess) => {
            callback(this.buildColumnsArray(value.data, userSecurityLevel));
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logFailureAndCallCallback(error.error, callback);
        };

        if (userSecurityLevel.includes('SYSTEM')) {
            this.licenseRestService.getLicense(onSuccess, onFailure);
        } else {
            this.licenseRestService.getLicenseAllocations(onSuccess, onFailure);
        }
    };

    public getLicensePoolForTable = (parameters, callback) => {
        const onSuccess = (value: RestResponseSuccess) => {
            this.getTopologyDataIfNeeded(value, callback);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logFailureAndCallCallback(error, callback);
        };

        this.licensePoolRestService.getLicensePool(onSuccess, onFailure, parameters);
    };

    public getLicensePoolForFile = (success, failure, parameters) => {
        const onSuccess = (value: RestResponseSuccess) => {
            this.getTopologyDataIfNeeded(value, success);
        };
        const onFailure = (error: RestResponseFailure) => {
            this.logFailureAndCallCallback(error, failure);
        };

        this.licensePoolRestService.getLicensePool(onSuccess, onFailure, parameters);
    };

    public getAllocationTemplate = (cell, fieldName = undefined) => {
        const rowData = cell.getRow();
        const data =(fieldName ? rowData[fieldName] : rowData);

        const poolAllocation = data.PoolAllocation > -1 ? data.PoolAllocation : 0;
        const localAllocation = data.LocalAllocation > -1 ? data.LocalAllocation : 0;
        const titleOfText = this.isOverAllocation(data, poolAllocation, localAllocation) ?
            'Provisioned pool value is higher than device capacity' : undefined;
        const textClass = 'over-allocation';
        const text = fieldName ? this.decimalPipe.transform(poolAllocation) : (poolAllocation === 0 ? '-' : this.decimalPipe.transform(poolAllocation));
        const componentInputs: any = {text};

        if(titleOfText){
            componentInputs.titleOfText = titleOfText;
            componentInputs.textClass = textClass;
        }

        return this.cellTemplatesService.createIconAndTextTemplate(componentInputs);
    };

    private isOverAllocation = (rowData, poolAllocation, localAllocation) => {
        return (rowData.actual > -1) &&
            ((poolAllocation + localAllocation) > rowData.actual);
    };

    private buildColumnsArray = (license, userSecurityLevel) => {
        if (userSecurityLevel.includes('SYSTEM')) {
            return this.buildFromConstant(license);
        } else {
            return this.buildFromResponse(license);
        }
    };

    private buildFromConstant = (license) => {
        const resultNames = [];
        _.forOwn(this.licenseAllocationsList, (field, fieldName) => {
            if (!resultNames.includes(fieldName) && license?.licensePoolFeaturesAllocated?.[fieldName] > 0) {
                resultNames.push(fieldName);
            }
        });

        return resultNames;
    };

    private buildFromResponse = (license) => {
        const resultNames = [];

        license.licenseAllocations.forEach((licenseAllocation) => {
            _.forOwn(licenseAllocation.licensePoolFeaturesAllocated).forEach((fieldName) => {
                if (!resultNames.includes(fieldName) && this.hasLicenseAllocation(licenseAllocation, fieldName)) {
                    resultNames.push(fieldName);
                }
            });
        });

        return resultNames;
    };

    private hasLicenseAllocation = (licenseAllocation, fieldName) => {
        return !['totalSBCSubscribers', 'totalDevices'].includes(fieldName) &&
            licenseAllocation.licensePoolFeaturesAllocated[fieldName] > 0;
    };

    private getTopologyDataIfNeeded = (response: RestResponseSuccess, callback) => {
        const data = response.data;
        const devicesIds = data.allocations.map((item) => item.id);

        if (_.isArray(devicesIds) && devicesIds.length > 0) {
            this.getNetworkData(devicesIds, data, callback);
            return;
        }

        callback(data);
    };

    private getNetworkData = (devicesIds, data, callback) => {
        const mappedTenants: any = this.tenantsRestService.getAllEntitiesHashed();
        const mappedRegions: any = this.regionsRestService.getAllEntitiesHashed();
        const devices = this.devicesRestService.getEntitiesByIds(devicesIds);
        const devicesMap = this.miscellaneousJSUtilsService.arrayToObjectByKey(devices, 'id');

        data.allocations.forEach((allocation: any) => {
            allocation = _.extend(allocation, (devicesMap[allocation.id] || {}));

            allocation.licensePoolStatus = this.checkLicensePoolStatus(allocation);

            allocation.isHA = (allocation.sbcInfo && allocation.sbcInfo.isHA) || false;
            allocation.tenantName = (mappedTenants[allocation.tenantId] || {}).name || '';
            allocation.regionName = (mappedRegions[allocation.regionId] || {}).name || '';
        });

        callback(data, devicesMap);
    };

    private checkLicensePoolStatus = (licenseItem) => licenseItem.licensePoolStatus === 'LP_STATUS_APPLY_NEEDED' ?
        this.setApplyNeededLicensePoolStatus(licenseItem) : licenseItem.licensePoolStatus;

    private setApplyNeededLicensePoolStatus = (licensePool) => {
        return ['sbcRegistrations', 'sbcSessions', 'sbcSignaling'].map((item) => {
            const active = (licensePool[item] && licensePool[item].active > -1) ? licensePool[item].active : 0;
            const actual = (licensePool[item] && licensePool[item].actual > -1) ? licensePool[item].actual : 0;

            return licensePool[item] ? (actual < active ? 'LP_STATUS_FAIL' : 'LP_STATUS_APPLY_NEEDED') : 'LP_STATUS_APPLY_NEEDED';
        }).reduce((acc, cur) => (acc === 'LP_STATUS_FAIL' || cur === 'LP_STATUS_FAIL') ? 'LP_STATUS_FAIL' : 'LP_STATUS_APPLY_NEEDED');
    };


    private logFailureAndCallCallback = (response: RestResponseFailure, callback) => {
        const failureMsg = (response && response.error && response.error.description) || 'General Error';

        this.loggerService.error(failureMsg);
        callback();
    };
}
