import {Pipe, PipeTransform} from '@angular/core';
import {AuthorizationService} from 'ac-infra';

@Pipe({name: 'authorization'})
export class AuthorizationPipe implements PipeTransform {

    transform(securityGroups: string | string[]): boolean {
        return AuthorizationService.validFor(securityGroups);
    }
}
