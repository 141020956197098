import {Component, Input, TemplateRef} from '@angular/core';
import {GeneralService} from 'ac-infra';

@Component({
    selector: 'ac-splash-screen',
    templateUrl: './ac-splash-screen.component.html',
    styleUrls: ['./ac-splash-screen.component.less'],
})
export class AcSplashScreenComponent {
    @Input() pageTitle = '';
    @Input() errorMessage = '';
    @Input() ready = true;
    @Input() logoTemplate: TemplateRef<any>;
    @Input() companyLogoTemplate: TemplateRef<any>;
    @Input() backgroundTemplate: TemplateRef<any>;

    constructor(public generalService: GeneralService) {}
}



