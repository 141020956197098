import {merge} from 'lodash';
import moment from 'moment';
import {GeneralService} from '../services/general.service';

export class HashedObjectMap {

    private entityMap: any = {};
    private filteredEntitiesIds: any = {};
    private addEntityType: boolean;

    constructor({addEntityType = true}) {
        this.addEntityType = addEntityType;
    }

    // difference = (objectA, baseA) => {
    //     const changes = (object: any, base: any) => _.transform(object, function(result, value, key) {
    //         if (!_.isEqual(value, base[key])) {
    //             result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
    //         }
    //     });
    //     return changes(objectA, baseA);
    // };

    setData = (paramName, entities, fieldName, partialUpdate?) => {
        entities = GeneralService.oneToMany(entities);
        this.entityMap[paramName] = this.entityMap[paramName] || {};
        entities.forEach((entity: any) => {
            if (this.addEntityType) {
                entity.entityType = paramName.slice(0, -1);
            }
            if (entity.lastChanged && this.entityMap[paramName][entity[fieldName]] &&
                this.entityMap[paramName][entity[fieldName]].lastChanged &&
                moment(this.entityMap[paramName][entity[fieldName]].lastChanged).isAfter(entity.lastChanged)) {
                return;
            }

            // if (this.entityMap[paramName][entity[fieldName]]) {
            //     console.log(paramName, this.difference(this.entityMap[paramName][entity[fieldName]], entity));
            // }

            if (partialUpdate) {
                merge(this.entityMap[paramName][entity[fieldName]] || {}, entity);
            } else {
                this.entityMap[paramName][entity[fieldName]] = entity;
            }
        });
    };

    deleteEntities(paramName, entitiesIds: any[]) {
        entitiesIds.forEach((id: any) => {
            delete this.entityMap[paramName][id];
        });
    }

    getEntitiesArray = (paramName, ids: any = null) => {
        if (!this.entityMap[paramName]) {
            return [];
        }

        if (ids === null) {
            return Object.values(this.entityMap[paramName]);
        } else if (!ids) {
            ids = [];
        }

        ids = Array.isArray(ids) ? ids : [ids];
        return ids.map((id) => this.entityMap[paramName][id]).filter((entity) => !!entity);
    };

    getEntitiesHashed = (paramName, ids: number | number[]) => GeneralService.oneToMany<number>(ids).reduce((acc, cur) => {
        const entity = this.entityMap[paramName][cur];
        if (entity) {
            acc[cur] = entity;
        }
        return acc;
    }, {});

    getEntities = (paramName) => {
        return this.entityMap[paramName] || {};
    };

    getEntity = (paramName, id: any) => {
        return (this.entityMap[paramName] || {})[id];
    };

    getAnyEntitiesByIds = (ids: any, hashed = false, paramName?) => {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        const entitiesObj = {};
        const entitiesArr = [];

        const paramNames = paramName ? [paramName] : Object.getOwnPropertyNames(this.entityMap);
        for (let i = 0; i < ids.length; i++) {
            for (let j = 0; j < paramNames.length; j++) {
                if (this.entityMap[paramNames[j]][ids[i]]) {
                    if (hashed) {
                        entitiesObj[ids[i]] = this.entityMap[paramNames[j]][ids[i]];

                        if (Object.keys(entitiesObj).length === ids.length) {
                            return entitiesObj;
                        }
                    } else {
                        entitiesArr.push(this.entityMap[paramNames[j]][ids[i]]);
                        if (entitiesArr.length === ids.length) {
                            return entitiesArr;
                        }
                    }
                }
            }
        }

        return hashed ? entitiesObj : entitiesArr;
    };

    setFilteredIds = (paramName, entities, fieldName) => {
        this.filteredEntitiesIds[paramName] = [];
        entities.forEach((entity: any) => {
            this.filteredEntitiesIds[paramName].push(entity[fieldName]);
        });
    };

    updateFilteredIds = (paramName, entities, fieldName, originalIdList) => {
        this.filteredEntitiesIds[paramName] = this.filteredEntitiesIds[paramName] || [];
        const arrayToRemove = originalIdList.filter((originaId) => !entities.some((entity) => entity[fieldName] === originaId));
        arrayToRemove.forEach((idToRemove) => {
            const idx = this.filteredEntitiesIds[paramName].indexOf(idToRemove);
            if (idx > -1) {
                this.filteredEntitiesIds[paramName].splice(idx, 1);
            }
        });

        const arrayToAdd = originalIdList.filter((originaId) => this.filteredEntitiesIds[paramName].indexOf(originaId) === -1 && entities.some((entity) => entity[fieldName] === originaId));
        this.filteredEntitiesIds[paramName] = this.filteredEntitiesIds[paramName].concat(arrayToAdd);
    };

    getFilteredIds = (paramName) => {
        return this.filteredEntitiesIds[paramName] || [];
    };

    getFilteredEntitiesHashed = (paramName, ids) => {
        if (!ids) {
            ids = this.filteredEntitiesIds[paramName];
        }

        const hashed = {};
        ids.forEach((id) => {
            if (this.filteredEntitiesIds[paramName].includes(id)) {
                hashed[id] = this.entityMap[paramName][id];
            }
        });
        return hashed;
    };

    reset = (paramNames?) => {
        if (paramNames) {
            const params = Array.isArray(paramNames) ? paramNames : [paramNames];
            params.forEach((param) => {
                delete this.entityMap[param];
            });
        } else {
            this.entityMap = {};
        }
    };

}
