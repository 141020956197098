import * as _ from 'lodash';

import {ServiceInjector} from '../../../common/services/service-injector';
import {WorkspaceVersions} from '../../workspace-versions.model';
import {BaseResolverClass} from '../base-resolver.class';
import {WSEntities} from 'ac-infra';

export class TopologyFilterResolver extends BaseResolverClass {

    static migrations = {};
    WSEntities;

    constructor() {
        super();
        this.WSEntities = ServiceInjector.injector.get(WSEntities);
    }

    export = (topologyFilter) => _.forOwn(topologyFilter, (entities, entityType) => {
        return topologyFilter[entityType] = topologyFilter[entityType]?.map(entity => entity.id);
    });

    import = (importedTopologyFilter, importVersion) => {
        const migratedTopologyFilter = this.migrate(importedTopologyFilter, importVersion);

        const resolvedTopologyFilter = migratedTopologyFilter;
        _.forOwn(migratedTopologyFilter, (entitiesIds, paramName) => {
            resolvedTopologyFilter[paramName] = this.WSEntities.getEntitiesArray(paramName + 's', entitiesIds);
        });

        return resolvedTopologyFilter;
    };

    migrate = (importedTopologyFilter, importVersion) => {
        let migratedTopologyFilter = {...importedTopologyFilter};

        let migrationCounter = 0;
        while (migratedTopologyFilter && importVersion < WorkspaceVersions.filtersVersion) {
            const migrationFunction = TopologyFilterResolver.migrations[importVersion + 1];
            if (migrationFunction) {
                migratedTopologyFilter = migrationFunction(migratedTopologyFilter);
                migrationCounter++;
            }
            importVersion++;
        }

        return migratedTopologyFilter;
    };
}
