import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcDatePickerComponent} from './ac-date-picker.component';
import {AcDropdownModule} from '../ac-dropdown/ac-dropdown.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcDropdownModule
    ],
    declarations: [
        AcDatePickerComponent,
    ],
    entryComponents: [
        AcDatePickerComponent,
    ],
    exports: [
        AcDatePickerComponent,
    ]
})
export class AcDatePickerModule {
}
