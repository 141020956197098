import {Injectable} from '@angular/core';
import {IDTypeDictionary, ITreeModel, ITreeNode} from '@circlon/angular-tree-component/lib/defs/api';
import {Subject} from 'rxjs';
import {GeneralService} from '../../services/general.service';
import {SessionStorageService} from '../../services/session-storage.service';

type TreeFilter = (node: ITreeNode, search: string) => boolean;

@Injectable({
    providedIn: 'root'
})
export class AcTreeService {

    private topologyTreeDropDownClickedSubject: Subject<any> = new Subject<any>();
    topologyTreeDropDownClicked$ = this.topologyTreeDropDownClickedSubject.asObservable();

    executeTopologyTreeDropDownClicked = (treeObj) => {
        this.topologyTreeDropDownClickedSubject.next(treeObj);
    };

    setExpandNodeIds(treeModel: ITreeModel, expandedNodesIds: IDTypeDictionary, loadFromState = false) {
        this.setTreeStateField(treeModel, 'expandedNodeIds', expandedNodesIds, loadFromState);
    }

    setActiveNodeIds(treeModel: ITreeModel, activeNodeIds: IDTypeDictionary) {
        this.setTreeStateField(treeModel, 'activeNodeIds', activeNodeIds);
    }


    setSelectedLeafNodeIds(treeModel: ITreeModel, selectedLeafsIds: IDTypeDictionary, loadFromState = false) {
        this.setTreeStateField(treeModel, 'selectedLeafNodeIds', selectedLeafsIds, loadFromState);
    }

    propagateExpandToParent(node, state: boolean, parentsIds: IDTypeDictionary) {
        if (!node || !node.realParent) {
            return;
        }

        (state !== parentsIds[node.realParent.id]) && Object.assign(parentsIds, {[node.realParent.id]: state});
        this.propagateExpandToParent(node.realParent, state, parentsIds);
    }

    updateSelectNodes(nodes: ITreeNode | ITreeNode[], state: boolean, selectedLeafNodeIds: any, useTriState = true) {
        GeneralService.oneToMany<ITreeNode>(nodes).forEach((node) => {
            if (useTriState && node.children && node.children.length > 0) {
                this.updateSelectNodes(node.children, state, selectedLeafNodeIds, useTriState);
                return;
            }
            this.addNodeToSelected(selectedLeafNodeIds, node, state);
        });
    }

    filterTree(treeModel: ITreeModel, search: string, filter: TreeFilter, session_key: string) {
        if (!search && search !== '') {
            return;
        }

        const expandNodesStateIds = {};
        treeModel.filterNodes((node) => {
            const isNodeFiltered = (filter && filter(node, search)) || this.filterByName(node, search, treeModel.options.displayField);
            const isParentFiltered = !!(node.realParent && node.realParent.data.isFiltered);

            if (isNodeFiltered && node.realParent && !(expandNodesStateIds[node.realParent.id])) {
                this.propagateExpandToParent(node, true, expandNodesStateIds);
            }

            node.data.isFiltered = isNodeFiltered || isParentFiltered;
            return node.data.isFiltered;
        }, false);

        if (search === '') {
            session_key && this.setExpandNodeIds(treeModel, SessionStorageService.getData(session_key) || {});
        } else {
            this.setExpandNodeIds(treeModel, expandNodesStateIds, true);
        }
    }

    filterByName(node: ITreeNode, filter: string, displayField = 'name'): boolean {
        return ((node.data[displayField] || node.data.text).toLowerCase().includes(filter.toLowerCase()));
    }

    sortNodes = (nodes: any, recursive = false, displayField = 'name', sortCompare?) => {
        if (!nodes) {
            return;
        }

        if (!sortCompare) {
            sortCompare = (source, target) => {
                const sourceText = (source[displayField] || source.text).toLowerCase();
                const targetText = (target[displayField] || target.text).toLowerCase();

                if (sourceText < targetText) {
                    return -1;
                }
                if (sourceText > targetText) {
                    return 1;
                }
                return 0;
            };
        }

        recursive && nodes.forEach((node) => node.children && this.sortNodes(node.children, recursive, displayField));

        nodes.sort(sortCompare);
    }

    private addNodeToSelected = (selectedLeafNodeIds, nodes: ITreeNode | ITreeNode[], state, recursive = false) => {
        const idsState = {};
        GeneralService.oneToMany<ITreeNode>(nodes).forEach((node) => {
            idsState[node.id] = state;
            if (recursive && node.children && node.children.length > 0) {
                this.addNodeToSelected(selectedLeafNodeIds, node.children, state, recursive);
            }
        });
        Object.assign(selectedLeafNodeIds, idsState);
    };

    private setTreeStateField = (treeModel: ITreeModel, field: string, value: IDTypeDictionary, loadFromState = false) => {
        const state = treeModel.getState();

        if (loadFromState) {
            value = {...state[field], ...value};
        }
        treeModel.setState({...state, [field]: value});
    };
}
