<ac-form *ngIf="dialogConfig.ready"
         [formModel]="customer"
         [formSchema]="schema"
         [isEdit]="isEdit"
         formModelName="customer">

    <div id="customer-details-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="customer Name" [displayAsInfo]="true">
                <ac-textfield [(acModel)]="additionalSettings.name"
                              [ngDisabled]="true"
                              [attr.alternativeName]="'additionalSettings.name'"
                              maxLength="30" textFieldId="customer-name">
                </ac-textfield>
            </ac-input-container>


            <ac-input-container label="Customer Source" [displayAsInfo]="true">
                <ac-textfield [(acModel)]="additionalSettings.customerSource"
                              [ngDisabled]="true"
                              [attr.alternativeName]="'additionalSettings.customerSource'"
                              textFieldId="customer-customerSource">
                </ac-textfield>
            </ac-input-container>
        </div>

        <ac-input-container label="Description">
            <ac-textarea [(acModel)]="customer.description"
                         [attr.alternativeName]="'customer.description'"
                         textAreaId="customer-description">
            </ac-textarea>
        </ac-input-container>

        <ac-input-container *ngIf="serverConfigurationActionService?.meteringConfiguration?.meteringEnabled &&
                                    supportedCustomerSourceTypes.includes(additionalSettings.customerSource)"
                            label="Live Setup ID">
            <ac-textfield [(acModel)]="customer.meteringTag"
                          [attr.alternativeName]="'customer.meteringTag'"
                          textFieldId="audio-codes-customer-metering-tag">
            </ac-textfield>
        </ac-input-container>

        <ac-input-container label="Azure AD Group: Object ID">
            <ac-textfield [(acModel)]="customer.adGroupId"
                          [attr.alternativeName]="'customer.adGroupId'"
                          textFieldId="audio-codes-customer-adGroupId">
            </ac-textfield>
        </ac-input-container>
    </div>
</ac-form>
