import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';

import $ from 'jquery';
import {isNil} from 'lodash';

import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcTabDirective} from '../ac-tabs/ac-tab.directive';

@Component({
    selector: 'ac-input-container-old',
    templateUrl: './ac-input-container-old.component.html',
    styleUrls: ['./ac-input-container-old.component.less']
})
export class AcInputContainerOldComponent {
    @Input() label: any = '';
    @Input() hideLabel = false;
    @Input() dontShowLabel = false;
    @Input() contentFlex = true;
    @Input() isFocusOnFirst = true;
    @Input() showCheckboxGap = true;
    @Input() alwaysShowError = false;
    @Input() labelBold = false;
    @Input() checkboxModel: boolean;
    @Input() radioModel: string;
    @Input() editModePrefix = '';
    @Input() preventLabelClick = false;
    @Input() ignoreRequired = false;
    @Input() radioButtonDisabled = false;
    @Input() fullWidthContent = false;
    @Input() checkboxDisabled = false;
    @Input() radioSelectedValue: string;
    @Input() radioButtonName: string;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('acInputChildElement', {static: false}) acInputChildElementObj: ElementRef;
    @Input() labelTemplate: TemplateRef<any>;
    private _alternativeName;
    @Input() set alternativeName(alternativeName) {
        this._alternativeName = alternativeName;
        this.updateForm();
    }

    componentName = '';
    elementType = '';
    isDisabled = false;

    constructor(@Optional() public formHost: AcFormComponent, @Optional() private tabHost: AcTabDirective, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.updateForm();
    }

    private updateForm = () => {
        const children = this.acInputChildElementObj && this.acInputChildElementObj.nativeElement.children;

        if (!children || children.length <= 0) {
            return;
        }

        const alternativeName = $(this.acInputChildElementObj.nativeElement).children().attr('alternativeName') || $(this.acInputChildElementObj.nativeElement).children().children().attr('alternativeName');
        this.componentName = this._alternativeName || alternativeName;

        if (this.formHost) {
            this.elementType = children[0].nodeName.toLowerCase();
            this.registerForm();
        }
        this.cdRef.detectChanges();
    };

    showError() {
        if (isNil(this.componentName) || this.componentName === '') {
            return;
        }

        if (this.formHost && this.formHost.formValidator && this.formHost.formValidator[this.componentName] && this.formHost.formValidator[this.componentName].errors) {
            return this.formHost.formValidator[this.componentName].errors.message;
        }

        return;
    }

    preventLabelClickFunc(event) {
        if (this.preventLabelClick) {
            event.preventDefault();
        }
    }

    checkboxModelChanged($event) {
        this.acChanged.emit($event);
    }

    radioChanged($event) {
        this.acChanged.emit($event);
    }

    private registerForm = () => {

        this.isDisabled = !this.formHost.isAuthorized;

        if (this.formHost.formValidator && this.componentName && this.componentName !== '') {
            this.formHost.formValidator[this.componentName] = this.formHost.formValidator[this.componentName] || {};
            this.formHost.formValidator[this.componentName].type = this.elementType;
        }


        if (this.tabHost && this.tabHost.id && this.componentName) {
            const tabId = this.tabHost.id;

            this.formHost.tabsValidationObj[tabId] = this.formHost.tabsValidationObj[tabId] || {inputs: []};
            this.formHost.tabsValidationObj[tabId].inputs.push(this.formHost.formValidator[this.componentName]);
        }
    };
}



