<div class="ac-geo">
    <ac-geo [allowDragMarkers]="isAuthorized"
            [linksFetchFunction]="linksFetchFunction"
            [dataItemToLinkMapper]="dataItemToLinkMapper"
            [dataItemToShapeMapper]="dataItemToShapeMapper"
            [geoId]="mapName"
            [isLatLngMap]="isLatlngMap"
            [shapesFetchFunction]="shapesFetchFunction"
            [showCreateLinkButton]="isAuthorized"
            [showSaveMapButton]="isAuthorized"
            (mapShapesDragged)="updateDraggedShapes($event)">
    </ac-geo>
</div>

<div [hidden]="true">
    <ng-template #host></ng-template>
</div>
