import {Injectable} from '@angular/core';

import {Rest, RestResponseSuccess} from '../common/server-actions/rest';

export interface Workspace {
    id?: string | number;
    name?: string;
    description?: string;
    isActive?: boolean;
    autoSave?: boolean;
    data?: any;
}

@Injectable({
    providedIn: 'root'
})
export class WorkspaceRestService extends Rest {

    constructor() {
        super({
            url: 'settings/workspaces'
        });
    }

    createWorkspace(workspace: Workspace, {success = (...args) => null, failure = (...args) => null}) {
        this.add((res) => success(res.data), failure, workspace, this.url);
    }

    updateWorkspaceById(id, workspace: Workspace, {success = (...args) => null, failure = (...args) => null} = {}) {
        return this.edit(success, failure, workspace, id);
    }

    getWorkspaceById(workspaceId, success = (...args) => null, failure = (...args) => null) {
        return this.getById({
            id: workspaceId,
            success: (res) => success(res.data),
            failure,
        });
    }

    getWorkspaceStateByFilter({filter = undefined, success = (...args) => null, failure = (...args) => null} = {}) {
        this.get({
            parameters: {
                filter,
                fields: ['name', 'isActive', 'autoSave', 'description'],
            },
        }).then((res: RestResponseSuccess) => success(res.data.workspaces)).catch(failure);
    }
}
