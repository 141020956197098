import {Component, Inject} from '@angular/core';
import * as _ from 'lodash';
import {MetadataService} from '../../../metadata/metadata.service';
import {
    AcDialogService,
    AcTableState,
    DIALOG_CONFIG,
    DialogConfig,
    PromiseService,
    promiseType,
    RefreshTableProperties
} from 'ac-infra';
import {Select} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {deviceFileDetails} from '../../services/actions/devices-actions.service';
import {DevicesRestService} from '../../services/apis/devices-rest.service';

@UntilDestroy()
@Component({
    selector: 'software-upgrade-dialog',
    templateUrl: './software-upgrade-dialog.component.html'
})
export class SoftwareUpgradeDialogComponent {

    @Select(AcTableState.selection('software-upgrade-grid')) selection$: Observable<any>;
    SWManagerFileTypesList;
    selectedFile = [];
    FilesList = [];
    tenants;
    disabledMTCFields;
    defaultTLS = {value: '0', text: 'Default (Index: 0)'};

    TLS;

    forwardingType = {
        options: [
            {value: 'FULL_WITH_VALIDATION', text: 'Full with validation'},
            {value: 'FULL_WITHOUT_VALIDATION', text: 'Full without validation'},
            {value: 'INCREMENTAL', text: 'Incremental'}
        ]
    };

    softwareUpgradeColumns = [
        {field: 'fileTypeDescription', title: 'Type', widthGrow: 1, headerSort: true},
        {field: 'swVersion', title: 'SW Version', widthGrow: 1, headerSort: true},
        {field: 'name', title: 'Name', widthGrow: 1, headerSort: true},
        {field: 'protocolType', title: 'Protocol', widthGrow: 1, headerSort: true},
        {field: 'owner', title: 'Owner', widthGrow: 2, headerSort: true}
    ];
    supportedTypes;
    isDownloadMode: boolean;
    iniOptions: any;
    deviceDetails: deviceFileDetails;

    hitlessOptions = [];
    tableRows;

    MTCOptions = [
        {text: 'Cluster Manager', value: 'WITHOUT_MTC'},
        {text: 'Cluster Manager MTs', value: 'WITH_MTC', disabled: false},
    ];

    private softwareUpgradeTableRefreshSubject: Subject<RefreshTableProperties> = new Subject<RefreshTableProperties>();
    softwareUpgradeTableRefresh$ = this.softwareUpgradeTableRefreshSubject.asObservable();
    private isLoading = false;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                private devicesRestService: DevicesRestService,
                private acDialogService: AcDialogService) {
    }

    ngOnInit() {
        this.supportedTypes = MetadataService.getType('SupportedTypes');
        this.SWManagerFileTypesList = MetadataService.getType('SWManagerFileTypes', true);

        this.dialogConfig.title = this.dialogConfig.dialogData.isDownloadMode ? 'Update Auxiliary File' : 'Update Firmware';
        this.isDownloadMode = this.dialogConfig.dialogData.isDownloadMode;

        this.TLS = this.dialogConfig.dialogData.TLS;

        this.iniOptions = this.dialogConfig.dialogData.iniOptions;
        this.deviceDetails = this.dialogConfig.dialogData.deviceDetails;
        this.updateHitlessOptions();

        this.MTCOptions[1].disabled = this.deviceDetails.mcTypeList.length !== 1 || (this.deviceDetails.mcTypeList.length === 1 && this.deviceDetails.mcTypeList[0] === 'unknown');

        this.dialogConfig.height = this.isDownloadMode ? 580 : 540;

        this.tenants = this.dialogConfig.dialogData.tenants;
        this.selectedFile = this.dialogConfig.dialogData.selectedFile;
        this.setDialogButtonDisableState();
        this.FilesList = this.dialogConfig.dialogData.filesWithSoftwareType;

        this.softwareUpgradeTableRefreshSubject.next({showLoader: true});

        this.selection$.pipe(untilDestroyed(this)).subscribe((selection) => {
            this.selectionChanged(selection);
        });

        if(this.dialogConfig.dialogData.isDownloadMode){
            this.dialogConfig.preSubmit = this.preSubmit;
        }
    }

    preSubmit = () => {
        const promise: promiseType = PromiseService.defer();

        const message = 'Are you sure you want to update configuration package to all selected devices?</br>' +
                'Note: Upon update configuration package, Device will reset.';

        this.acDialogService.confirm(message, {
            onSubmit: () => {
                promise.resolve();
                return true;
            },
            onCancel: () => {
                this.dialogConfig.submitButtonProcessing = false;
                promise.reject();
            }
        });

        return promise.promise;
    };

    selectionChanged = (selections) => {
        this.selectedFile.length = 0;

        (selections || []).forEach((item) => {
            this.selectedFile.push(item);
        });

        this.setDialogButtonDisableState();

        if (this.deviceDetails.isAllDevicesHA && (this.selectedFile.length === 0 || (this.selectedFile.length === 1 && this.selectedFile[0].fileTypeDescription !== 'CMP'))) {
            this.iniOptions.hitless = undefined;
        }

        if (this.deviceDetails.isAllDevicesHA && this.selectedFile.length === 1 && this.selectedFile[0].fileTypeDescription === 'CMP') {
            this.iniOptions.hitless = this.iniOptions.hitless || 'reset';
        }

        this.TLS.allSelectionTLS = this.isAllSelectedFilesAreTLS(selections);
        if (this.TLS.allSelectionTLS) {
            this.isLoading = true;
            this.getTLSContexts(this.dialogConfig.dialogData.entitiesArray).then((context) => {
                this.TLS.TLSContextSelected = '0';
                this.TLS.TLSContexts = context;
            });
        }
    };

    getTLSContexts = (devices) => {
        const defer = PromiseService.defer();
        const promises = [];
        devices.forEach((device) => {
            promises.push(this.devicesRestService.getTLSContext(device.id));
        });
        Promise.all(promises).then((resArray) => {
            const contexts = [];
            resArray.forEach((res) => {
                res.data.items.forEach((context) => {
                    if (contexts[context.id] !== undefined) {
                        contexts[context.id] = 'Index: ' + context.id + ' (Multiple names found)';
                    } else {
                        contexts[context.id] = 'Index: ' + context.id + ', Name: ' + context.description;
                    }
                });
            });

            const result: any = Object.entries(contexts).map(([id, text]) => ({value: id, text}));
            if(result.length===0) {
                result.push(this.defaultTLS);
            }
            defer.resolve(result);
        }).catch(() => {
            defer.resolve([this.defaultTLS]);
        });
        return defer.promise;
    };

    updateHitlessOptions = () => {
        if (this.deviceDetails.isAllDevicesHA && this.deviceDetails.clusterManagerRadioGroup.value !== 'WITH_MTC') {
            this.hitlessOptions = [{value: 'hitless', text: 'Hitless'}, {value: 'reset', text: 'Reset'}];
        } else if (!this.isDownloadMode && this.deviceDetails.isAllDevicesSWVersionAbove7_3 && this.deviceDetails.isAllDevicesInSBCSupportedList) {
            this.hitlessOptions = [{value: 'hitless', text: 'Hitless'}, {value: 'immediate', text: 'Immediate'}];
        }
    };

    setDialogButtonDisableState() {
        this.dialogConfig.submitButtonDisabled = this.selectedFile.length !== 1;
    }

    softwareUpgradeFetchFn = () => {
        let filteredFiles = [];
        if (this.isDownloadMode || !this.deviceDetails.isAllDevicesSWVersionAbove7_3 || !this.deviceDetails.isAllDevicesInSBCSupportedList
            || this.deviceDetails.clusterManagerRadioGroup.value === 'WITHOUT_MTC') {
            if (this.isMcActive()) {
                filteredFiles = this.getFilteredVMCFiles();
            } else {
                filteredFiles = _.cloneDeep(this.FilesList);
            }
        } else { // WITH_MTC
            if (this.isMcActive()) {
                switch (this.deviceDetails.mcTypeList[0]) {
                    case 'VMC':
                        filteredFiles = this.getFilteredVMCFiles();
                        break;
                    case 'MT':
                        filteredFiles = this.FilesList.filter((file) => file.productTypes.some((productType) => this.supportedTypes.M4K_SUPPORTED_PRODUCTS_TYPES.includes(productType)));
                        break;
                }
            }
        }

        this.extendFetchedFilesData(filteredFiles, (files) => {
            this.tableRows = files;
        });
    };

    isMcActive = () => this.deviceDetails.mcTypeList.length === 1 && this.deviceDetails.mcTypeList[0] !== 'unknown';

    getFilteredVMCFiles = () => this.FilesList.filter((file) => file.productTypes.some((productType) => this.supportedTypes.VMT_SUPPORTED_PRODUCTS_TYPES.includes(productType)));

    extendFetchedFilesData = (files, success) => {
        if (_.isArray(files)) {
            files.forEach((file) => {
                file.fileTypeDescription = this.getFileTypeDescription(file);
                file.owner = file.tenantId === -1 ? 'System' : this.getTenantName(file, this.tenants);
            });
        }

        success(files);
    };

    getFileTypeDescription = (file) => {
        const fileType = this.SWManagerFileTypesList.find((metaDataFileType) => metaDataFileType.type === file.fileType);

        return fileType ? fileType.description : file.fileType;
    };

    getTenantName = (file, tenants) => {
        const tenant = tenants.find((item) => file.tenantId === item.id);

        return tenant ? 'Tenant: ' + tenant.name : '';
    };

    clusterManagerRadioGroupChanged = (ev) => {
        this.deviceDetails.clusterManagerRadioGroup.value = ev;

        this.updateHitlessOptions();

        this.softwareUpgradeTableRefreshSubject.next({gotoPage: 1, showLoader: true});

        setTimeout(() => {
            if (this.deviceDetails.clusterManagerRadioGroup.value === 'WITHOUT_MTC') {
                this.iniOptions.hitless = undefined;
                this.iniOptions.gracefulTimeout = undefined;
            } else {
                this.iniOptions.hitless = 'hitless';
                this.iniOptions.gracefulTimeout = 0;
            }
        }, 100);

    };

    isAllSelectedFilesAreTLS(selectedFiles: any[]) {
        return selectedFiles.length > 0 && selectedFiles.every((selectedFile) => {
            return ['X509_PRIVATE_KEY_TYPE', 'X509_CERTIFICATE_TYPE', 'X509_TRUSTED_ROOT_CERTIFICATE_TYPE'].includes(selectedFile?.fileType);
        });
    }
}


