import {Component, EventEmitter, Input, Output} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';


@UntilDestroy()
@Component({
    selector: 'ac-search',
    templateUrl: './ac-search.component.html',
    styleUrls: ['./ac-search.component.less'],
})
export class AcSearchComponent {
    @Input() componentId: string;
    @Input() inlineStyle = false;
    @Input() instant = true;
    @Input() hideSearchButton = false;
    @Input() acSearched: Function;
    @Input() search = '';
    @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
    inputHasFocus = false;
    private readonly DEFAULT_PLACEHOLDER = 'Search by name';
    private update$: Subject<any>;

    _placeHolder = this.DEFAULT_PLACEHOLDER;

    @Input() set placeHolder(placeholder) {
        this._placeHolder = placeholder || this.DEFAULT_PLACEHOLDER;
    }

    constructor() {
        this.update$ = new Subject<any>();
        this.update$.pipe(
            debounceTime(400),
            untilDestroyed(this),
        ).subscribe(this.doSearch);
    }

    ngOnDestroy() {

    }

    clearContent() {
        this.search = '';
        this.onSearch('');
    }

    onSearch = (event) => {
        this.search = event;
        this.update$.next(null);
    };

    private doSearch = () => {
        if (this.acSearched) {
            this.acSearched(this.search);
        }
        this.searchChange.emit(this.search);
    };
}
