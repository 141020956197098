<div class="flexRowParent" (click)="isFocused=true" [attr.id]="selectId">
    <div class="flexItemBiggest value-container">
        <div *ngIf="labelTemplate && selected && !isFocused" class="label-template-container">
            <ng-template [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{$implicit: selected}"></ng-template>
        </div>

        <div class="input-container">
            <ng-template *ngIf="displayImage && selected" [ngTemplateOutlet]="displayImageTemp" [ngTemplateOutletContext]="{item: selected}"></ng-template>

            <input type="text"
                   #autocompleteInput
                   matInput
                   class="ac-single-select-input"
                   [class.visibilityHidden]="labelTemplate && !isFocused"
                   (keyup.enter)="addCustomTags ? handleNewTag() : triggerAutocompleteInput.closePanel()"
                   (keydown.Tab)="addCustomTags && handleNewTag()"
                   (focusout)="focusOut()"
                   (focusin)="focusIn()"
                   [disabled]="isSelectDisabled()"
                   [placeholder]="placeholder"
                   [matAutocomplete]="auto"
                   [ngModel]="_acModel"
                   (ngModelChange)="filterItems($event);addCustomTags ? addTag($event) : checkIfValueIsInItems($event)">
        </div>
    </div>

    <mat-icon *ngIf="allowClear" (click)="clear()" class="icon clear-icon flexItem">
        <ac-svg name="clear"></ac-svg>
    </mat-icon>

    <mat-icon class="icon flexItem"><ac-svg name="arrow" [fillColor]="acInputContainerComponent.error ? colors.systemRed : colors.grayStatus"></ac-svg></mat-icon>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" (opened)="onOpen.emit($event)">
        <mat-option *ngIf="!filteredItems || filteredItems.length === 0" disabled>
            No items found
        </mat-option>
        <mat-option *ngFor="let item of filteredItems" [value]="useFullObjectInModel ? item : item[bindValue]"
                    class="ac-single-select-option {{dropDownOptionsClass}}"
                    [attr.title]="item.disabled ? optionTitle : null"
                    [class.ac-disable]="item.disabled || optionTitle===''" (onSelectionChange)="addCustomTags && addTag(item)">
            <ng-template *ngIf="displayImage && item" [ngTemplateOutlet]="displayImageTemp" [ngTemplateOutletContext]="{item: item}"></ng-template>
            <span *ngIf="!itemTemplate" [attr.id]="item | acFunc: getTextValue | acFunc: generalService.generateIdForText">{{item[bindLabel]}}</span>
            <ng-template *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-template>
        </mat-option>
    </mat-autocomplete>
</div>


<ng-template #displayImageTemp let-item="item">
    <div class="select-Image">
        <img *ngIf="item?.image" [src]="item.image" class="selectedImage" height="15" width="15"/>
        <ac-svg *ngIf="item?.svgName" [height]="item.svgHeight" [name]="item.svgName"
                [width]="item.svgWidth"></ac-svg>
    </div>
</ng-template>
