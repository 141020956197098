import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {MatIconModule} from '@angular/material/icon';
import {AcSingleSelectComponent} from "./ac-single-select.component";
import {InfraPipesModule} from '../../pipes/infra.pipes.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AcIconsModule,
        MatIconModule,
        InfraPipesModule
    ],
    declarations: [
        AcSingleSelectComponent,
    ],
    entryComponents: [
        AcSingleSelectComponent,
    ],
    exports: [
        AcSingleSelectComponent,
    ]
})
export class AcSingleSelectModule {
}
