import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AcDialogService, AuthorizationService} from 'ac-infra';
import {authGroup} from '../../../common/utilities/session-helper.service';

declare let $: any;

declare let L: any;

@Injectable({
    providedIn: 'root'
})
export class LinkCreationService {

    cluster;
    map;
    creationModeEnabled = false;

    linkCreationInProgress: any = false;
    linkVisualization = false;
    linkVisualizationOfCreatedLink = false;

    private turnOffLinkCreationSubject: Subject<any> = new Subject<any>();
    turnOffLinkCreation$ = this.turnOffLinkCreationSubject.asObservable();

    private addLinkSubject: Subject<any> = new Subject<any>();
    addLinkObservable$ = this.addLinkSubject.asObservable();


    constructor(private acDialogService: AcDialogService) {
    }

    isLinkCreationModeEnabled = () => this.creationModeEnabled;

    clearLinkVisualizationOfCreatedLink = () => {
        if (this.cluster && this.linkVisualizationOfCreatedLink) {
            this.cluster.removeLayer(this.linkVisualizationOfCreatedLink);
            this.linkVisualizationOfCreatedLink = false;
        }
    };

    updateCluster = () => {
        this.clearLinkSelection();
        this.linkCreationInProgress = false;
        if (this.creationModeEnabled) {
            this.turnOnLinkCreation();
        }
    };

    updateMap = (newMap) => {
        this.clearLinkSelection();
        this.linkCreationInProgress = false;
        this.map = newMap;
        if (this.creationModeEnabled) {
            this.turnOnLinkCreation();
        }
    };

    turnOnLinkCreation = () => {
        if (this.map && this.cluster) {
            this.map.closePopup();
            this.map.on('mouseup', this.mapMouseUp);
            this.map.on('mousemove', this.mapMouseMove);

            this.cluster.on('mousedown', this.mouseDown);
            this.cluster.on('mouseup', this.mouseUp);
            this.creationModeEnabled = true;
        }
    };

    turnOffLinkCreation = () => {
        if (this.map && this.cluster) {
            this.clearLinkSelection();
            this.map.off('mouseup', this.mapMouseUp);
            this.map.off('mousemove', this.mapMouseMove);

            this.turnOffLinkCreationSubject.next(null);

            this.cluster.off('mousedown', this.mouseDown);
            this.cluster.off('mouseup', this.mouseUp);
            this.creationModeEnabled = false;
        }
    };

    private mapMouseUp = () => {
        this.clearLinkSelection();
        if (this.linkCreationInProgress) {
            this.linkCreationInProgress.dragging.enable();
            this.linkCreationInProgress = false;
        }
    };

    private mapMouseMove = (e) => {
        if (this.linkCreationInProgress) {
            this.cluster.removeLayer(this.linkVisualization);
            this.linkVisualization = L.polyline([this.linkCreationInProgress.getLatLng(), [e.latlng.lat, e.latlng.lng]]);
            this.cluster.addLayer(this.linkVisualization);
        }
    };

    private clearLinkSelection = () => {
        if (this.map) {
            this.map.dragging.enable();
        }
        if (this.cluster) {
            this.cluster.removeLayer(this.linkVisualization);
        }
    };

    private mouseDown = (e) => {
        if ($(e.originalEvent.srcElement || e.originalEvent.target).hasClass('leaflet-marker-icon')) {
            this.map.dragging.disable();

            const marker = e.layer;
            marker.dragging.disable();

            if (this.linkCreationInProgress) {
                this.linkCreationInProgress.dragging.enable();
            }
            this.linkCreationInProgress = marker;
        }
    };

    private mouseUp = (e) => {
        if (this.linkCreationInProgress) {
            if ($(e.originalEvent.srcElement || e.originalEvent.target).hasClass('leaflet-marker-icon')) {
                if (e.layer !== this.linkCreationInProgress) {
                    this.linkVisualizationOfCreatedLink = L.polyline([this.linkCreationInProgress.getLatLng(), e.layer.getLatLng()]);
                    this.cluster.addLayer(this.linkVisualizationOfCreatedLink);

                    if (!AuthorizationService.isAdminOrOperatorLevel()) {
                        this.acDialogService.info('Operator with Monitor security level can not add a link');
                    } else if (!this.linkCreationInProgress.device.productType) {
                        this.acDialogService.info('Can not create a link from a site', {onCancel: this.clearLinkVisualizationOfCreatedLink});
                    } else if ((this.linkCreationInProgress.device.productType && this.linkCreationInProgress.device.productType === 'NONE_ACL_DEVICE')) {
                        this.acDialogService.info('Can not create a link from a generic device', {onCancel: this.clearLinkVisualizationOfCreatedLink});
                    } else if ((this.linkCreationInProgress.device.productType && this.linkCreationInProgress.device.productType === 'TEAMS')) {
                        this.acDialogService.info('Can not create a link from a TEAMS customer', {onCancel: this.clearLinkVisualizationOfCreatedLink});
                    } else {
                        this.addLinkSubject.next({
                            srcDevice: this.linkCreationInProgress.device,
                            dstDevice: e.layer.device
                        });
                    }

                    this.clearLinkSelection();
                }
            }
        }
    };

}
