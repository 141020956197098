<ac-form *ngIf="dialogConfig.ready" #tenantForm
         [auxiliaryModel]="licenseAllocations"
         [formModel]="tenant"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         formModelName="tenant"
         class="tenant-dialog-form">


    <ac-tabs tabsId="tenant-tab-set">
        <div *acTab="'General';  id:'general-tenant-section-tab'">

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Tenant Name">
                    <ac-textfield [(acModel)]="tenant.name"
                                  [attr.alternativeName]="'tenant.name'"
                                  textFieldId="tenant-name">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Description">
                    <ac-textarea [(acModel)]="tenant.description"
                                 [attr.alternativeName]="'tenant.description'"
                                 textAreaId="tenant-description">
                    </ac-textarea>
                </ac-input-container>
            </div>


            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Is Default">
                    <ac-single-select [(acModel)]="tenant.isDefault"
                               [attr.alternativeName]="'tenant.isDefault'"
                               [items]="isDefaultOptions">
                    </ac-single-select>
                </ac-input-container>

                <ac-input-container *ngIf="tenantAdminOperatorsIds" label="HTTP Operator (License Pool)">
                    <ac-single-select [(acModel)]="tenant.licensePoolOperatorId"
                               [attr.alternativeName]="'tenant.licensePoolOperatorId'"
                               [bindLabel]="'name'"
                               [bindValue]="'id'"
                               [items]="tenantAdminOperatorsIds">
                    </ac-single-select>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Users URI Regexp">
                    <ac-textfield [(acModel)]="tenant.uriRegExp"
                                  [attr.alternativeName]="'tenant.uriRegExp'"
                                  placeholderText="None"
                                  textFieldId="tenant-uriRegExp">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Azure Tenant ID">
                    <ac-textfield [acModel]="tenant.msTenantId"
                                  (acModelChange)="tenant.msTenantId = $event"
                                  [attr.alternativeName]="'tenant.msTenantId'"
                                  textFieldId="tenant-msTenantId">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <ac-input-container [ignoreRequired]="true" label="Subnet (CIDR Notation)">
                <ac-select [acModel]="tenant.tenantAddressSpace?.subnetMasks"
                           (acModelChange)="tenant?.tenantAddressSpace ? tenant.tenantAddressSpace.subnetMasks = $event : null"
                           [addCustomTags]="true"
                           [attr.alternativeName]="'tenant.tenantAddressSpace.subnetMasks'"
                           [bindLabel]="null" [bindValue]="null"
                           [multiple]="true"
                           selectionName="tenant.tenantAddressSpace.subnetMasks">
                </ac-select>
            </ac-input-container>

            <logo-selection (logoIdChanged)="tenant.logoId = $event"
                            [sideMargin]="true"
                            label="Tenant Logo"
                            *ngIf="isEdit"
                            [fixedFiles]="noLogo"
                            [logoObject]="logoObject"
                            [tenantId]="additionalSettings.id">
            </logo-selection>

            <ac-input-container label="Masked Digits Number">
                <ac-num-spinner [(acModel)]="tenant.maskedDigitsNumber"
                                [attr.alternativeName]="'tenant.maskedDigitsNumber'"
                                [maxValue]="100"
                                [minValue]="0"
                                numSpinnerId="range-start-maskedDigitsNumber">
                </ac-num-spinner>
            </ac-input-container>

            <div class="flexColumnParent" *ngIf="oneLiveMode">
                <ac-checkbox [(acModel)]="tenant.complianceRecordingEnabled"
                             [attr.alternativeName]="'tenant.complianceRecordingEnabled'"
                             labelText="Compliance recording"
                             checkboxId="complianceRecordingEnabled">
                </ac-checkbox>

                <ac-checkbox [(acModel)]="tenant.deviceManagerEnabled"
                             [attr.alternativeName]="'tenant.deviceManagerEnabled'"
                             labelText="Device Manager"
                             checkboxId="deviceManagerEnabled">
                </ac-checkbox>

                <ac-input-container label="Source Email">
                    <ac-textfield [(acModel)]="tenant.dmSourceEmail"
                                  [attr.alternativeName]="'tenant.dmSourceEmail'"
                                  [textFieldId]="'tenant.dmSourceEmail'">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container *ngIf="serverConfigurationActionService?.meteringConfiguration?.meteringEnabled" label="Live Setup ID">
                    <ac-textfield [(acModel)]="tenant.meteringTag"
                                  [attr.alternativeName]="'tenant.meteringTag'"
                                  textFieldId="tenant-metering-tag">
                    </ac-textfield>
                </ac-input-container>
            </div>
        </div>
        <snmp *acTab="'SNMP';  id:'snmp-section-tab'"
              [isEdit]="isEdit"
              [snmpObject]="tenant.snmpProfile"
              (acChanged)="tenant.snmpProfile = $event"
              componentType="tenant"
              [hasDefaultObject]="true"
              [hasCheckboxForV3Edit]="true"
              snmpName="tenant.snmpProfile"
              snmpTrapFieldName="snmpTrapCommunity"
              snmpV2ProfileName="snmpV2DefaultProfile"
              snmpV3ProfileName="snmpV3DefaultProfile"
              viewType="separators">
        </snmp>
        <http-connectivity *acTab="'HTTP'; id:'http-section-tab'"
                           [httpObject]="tenant.httpProfile"
                           (acChanged)="tenant.httpProfile = $event"
                           httpObjectName="tenant.httpProfile"
                           componentType="tenant"
                           [hasDefaultObject]="true"
                           [deleteWholeHTTPObject]="true"
                           [hasEditHttpCheckbox]="true"
                           [isEdit]="isEdit">
        </http-connectivity>

        <div *acTab="'Operators';  id:'Operators-section-tab'">
            <div *ngIf="!oneLiveMode || isEdit">
                <ac-input-container label="Local Authentication: Assigned Operators" [isMaterial]="false">
                    <div class="operator-container">
                        <ac-select [(acModel)]="tenant.assignedOperators" class="assignedOperators"
                                   [attr.alternativeName]="'tenant.assignedOperators'"
                                   [items]="tenantOperatorsOption"
                                   [multiple]="true"
                                   selectionName="tenant.assignedOperators">
                        </ac-select>
                        <span style="width:31px">
                            <ac-svg name="operator" (click)="addNewOperator()" title="Add New Operator"
                                    class="addNewOperator"
                                    fillColor="red" [hoverFillColor]="colors.darkPrimary"></ac-svg>
                        </span>

                    </div>
                </ac-input-container>
            </div>
            <ac-input-container label="AD Authentication: Group Name">
                <ac-textfield [(acModel)]="tenant.ldapGroupName"
                              [attr.alternativeName]="'tenant.ldapGroupName'"
                              textFieldId="audio-codes-tenant-group-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container label="Azure AD Group: Object ID">
                <ac-textfield [(acModel)]="tenant.adGroupId"
                              [attr.alternativeName]="'tenant.adGroupId'"
                              textFieldId="audio-codes-tenant-adGroupId">
                </ac-textfield>
            </ac-input-container>
        </div>
        <tenant-allocation *acTab="'License';  id:'License-section-tab'"
                           [originalTenantLicenseAllocation]="originalTenantLicenseAllocation"
                           [ovocMainLicense]="generalLicense"
                           [tenantLicenseAllocation]="licenseAllocations">
        </tenant-allocation>

        <ng-container *ngIf="oneLiveMode">
            <div *acTab="'ZOOM';  id:'zoom-tenant-section-tab'">

                <ac-checkbox labelText="ZOOM Enabled"
                             [(acModel)]="tenant.zoomEnabled"
                             (acModelChange)="zoomEnabledChanged()"
                             checkboxId="tenant.zoomEnabled">
                </ac-checkbox>


                <ac-input-container label="Zoom carrier code">
                    <ac-textfield [acModel]="tenant.zoomInfo?.masterAccountId"
                                  (acModelChange)="tenant?.zoomInfo ? tenant.zoomInfo.masterAccountId = $event : null"
                                  [attr.alternativeName]="'tenant.zoomInfo.masterAccountId'"
                                  [ngDisabled]="!tenant.zoomEnabled"
                                  textFieldId="tenant-zoomInfo-masterAccountId">
                    </ac-textfield>
                </ac-input-container>

                <div class="flexRowParentEqualChildren">
                    <ac-input-container label="Client ID" *ngIf="false">
                        <ac-textfield [acModel]="tenant.zoomInfo?.clientId"
                                      (acModelChange)="tenant?.zoomInfo ? tenant.zoomInfo.clientId = $event : null"
                                      [attr.alternativeName]="'tenant.zoomInfo.clientId'"
                                      [ngDisabled]="!tenant.zoomEnabled"
                                      textFieldId="tenant-zoomInfo-clientId">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container label="Client Secret" *ngIf="false">
                        <ac-password [acModel]="tenant.zoomInfo?.clientSecret"
                                     (acModelChange)="tenant?.zoomInfo ? tenant.zoomInfo.clientSecret = $event : null"
                                     [attr.alternativeName]="'tenant.zoomInfo.clientSecret'"
                                     [ngDisabled]="!tenant.zoomEnabled"
                                     passwordId="tenant-zoomInfo-clientSecret">
                        </ac-password>
                    </ac-input-container>
                </div>
            </div>

            <div *acTab="'Operator Connect';  id:'oc-tenant-section-tab'">
                <div class="flexColumnParent">
                    <!--                    <div class="flexItem" *ngIf="isEdit">-->
                    <!--                        <network-operator-connect-action-buttons [tenant]="additionalSettings"></network-operator-connect-action-buttons>-->
                    <!--                    </div>-->

                    <div class="flexItem operator-connect-inputs-container">
                        <div (click)="operatorConnectEnabledChanged();$event.preventDefault();$event.stopPropagation()">
                            <ac-checkbox labelText="Operator Connect Enabled"
                                         [(acModel)]="tenant.operatorConnectEnabled"
                                         checkboxId="tenant.operatorConnectEnabled">
                            </ac-checkbox>
                        </div>

                        <div class="flexRowParentEqualChildren">
                            <ac-input-container label="Azure Tenant ID"
                                                [infoIconText]="tenant.operatorConnectEnabled ?
                                                'When left empty, value will be taken from general tab &quot;Azure Tenant ID&quot; field.' : ''">
                                <ac-textfield [acModel]="tenant.operatorConnectInfo?.azureTenantId"
                                              (acModelChange)="tenant?.operatorConnectInfo ? tenant.operatorConnectInfo.azureTenantId = $event : null"
                                              [attr.alternativeName]="'tenant.operatorConnectInfo.azureTenantId'"
                                              [ngDisabled]="!tenant.operatorConnectEnabled"
                                              textFieldId="tenant-operatorConnectInfo-azureTenantId">
                                </ac-textfield>
                            </ac-input-container>

                            <ac-input-container label="Landing Page URL">
                                <ac-textfield [acModel]="tenant.operatorConnectInfo?.landingPageUrl"
                                              (acModelChange)="tenant?.operatorConnectInfo ? tenant.operatorConnectInfo.landingPageUrl = $event : null"
                                              [attr.alternativeName]="'tenant.operatorConnectInfo.landingPageUrl'"
                                              [ngDisabled]="!tenant.operatorConnectEnabled"
                                              textFieldId="tenant-operatorConnectInfo-landingPageUrl">
                                </ac-textfield>
                            </ac-input-container>
                        </div>

                        <div class="flexRowParentEqualChildren">
                            <ac-input-container label="App Registration Client ID">
                                <ac-textfield [acModel]="tenant.operatorConnectInfo?.appRegistrationClientId"
                                              (acModelChange)="tenant?.operatorConnectInfo ? tenant.operatorConnectInfo.appRegistrationClientId = $event : null"
                                              [attr.alternativeName]="'tenant.operatorConnectInfo.appRegistrationClientId'"
                                              [ngDisabled]="!tenant.operatorConnectEnabled"
                                              textFieldId="tenant-operatorConnectInfo-appRegistrationClientId">
                                </ac-textfield>
                            </ac-input-container>

                            <ac-input-container label="App Registration Client Secret" editModePrefix="Change">
                                <ac-password [acModel]="tenant.operatorConnectInfo?.appRegistrationClientSecret"
                                             (acModelChange)="tenant?.operatorConnectInfo ? tenant.operatorConnectInfo.appRegistrationClientSecret = $event : null"
                                             [attr.alternativeName]="'tenant.operatorConnectInfo.appRegistrationClientSecret'"
                                             [ngDisabled]="!tenant.operatorConnectEnabled"
                                             passwordId="tenant-operatorConnectInfo-appRegistrationClientSecret">
                                </ac-password>
                            </ac-input-container>
                        </div>
                    </div>

                    <operator-connect-menu #operatorConnectMenu class="flexItemBiggest"
                                           [tenantFormSettings]="tenantForm.formSettings"
                                           [model]="tenant"
                                           [authorized]="tenant.operatorConnectEnabled"
                                           [errors]="errors"
                                           parentName="operatorConnectInfo">
                    </operator-connect-menu>
                </div>
            </div>

            <ng-container *ngIf="oneLiveMode">
                <div *acTab="'App Registration';  id:'app-reg-tenant-section-tab'">
                    <azure-app-registration [appRegistrationObject]="tenant?.appRegistrationSettings"
                                            [authorizedToUpdate]="true"
                    ></azure-app-registration>
                </div>
            </ng-container>
        </ng-container>

    </ac-tabs>

</ac-form>
