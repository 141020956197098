import {AuthorizationService, GeneralService, SessionService} from 'ac-infra';
import {Injectable} from '@angular/core';

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

export enum authGroup {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    SYSTEM_OPERATOR = 'SYSTEM_OPERATOR',
    SYSTEM_MONITORING = 'SYSTEM_MONITORING',
    TENANT_ADMIN = 'TENANT_ADMIN',
    TENANT_OPERATOR = 'TENANT_OPERATOR',
    TENANT_MONITORING = 'TENANT_MONITORING',
    TENANT_MONITORING_LINKS = 'TENANT_MONITORING_LINKS',
    GROUP_MONITORING = 'GROUP_MONITORING',
    GROUP_OPERATOR = 'GROUP_OPERATOR',
    MULTIGROUP_ADMIN = 'MULTIGROUP_ADMIN',
    admin = 'admin',
    system_admin_operator = 'system_admin_operator',
    system_users = 'system_users',
    operator = 'operator',
    tenant_operator = 'tenant_operator',
    tenant_user = 'tenant_user',
    admin_operator = 'admin_operator',
    monitor = 'monitor',
    channel_operator = 'channel_operator',
    end_customer_operator = 'end_customer_operator'
}

@Injectable({providedIn: 'root'})
export class SessionHelperService {

    static authGroups = {
        [authGroup.SYSTEM_ADMIN]: [authGroup.SYSTEM_ADMIN],
        [authGroup.SYSTEM_OPERATOR]: [authGroup.SYSTEM_OPERATOR],
        [authGroup.SYSTEM_MONITORING]: [authGroup.SYSTEM_MONITORING],
        [authGroup.TENANT_ADMIN]: [authGroup.TENANT_ADMIN],
        [authGroup.TENANT_OPERATOR]: [authGroup.TENANT_OPERATOR],
        [authGroup.TENANT_MONITORING]: [authGroup.TENANT_MONITORING],
        [authGroup.TENANT_MONITORING_LINKS]: [authGroup.TENANT_MONITORING_LINKS],
        [authGroup.GROUP_MONITORING]: [authGroup.GROUP_MONITORING],
        [authGroup.GROUP_OPERATOR]: [authGroup.GROUP_OPERATOR],
        [authGroup.MULTIGROUP_ADMIN]: [authGroup.MULTIGROUP_ADMIN],
        [authGroup.admin]: [authGroup.SYSTEM_ADMIN, authGroup.TENANT_ADMIN],
        [authGroup.system_admin_operator]: [authGroup.SYSTEM_ADMIN, authGroup.SYSTEM_OPERATOR],
        [authGroup.system_users]: [authGroup.SYSTEM_ADMIN, authGroup.SYSTEM_OPERATOR, authGroup.SYSTEM_MONITORING],
        [authGroup.operator]: [authGroup.SYSTEM_OPERATOR, authGroup.TENANT_OPERATOR],
        [authGroup.tenant_operator]: [authGroup.TENANT_ADMIN, authGroup.TENANT_OPERATOR],
        [authGroup.tenant_user]: [authGroup.TENANT_ADMIN, authGroup.TENANT_OPERATOR, authGroup.TENANT_MONITORING],
        [authGroup.admin_operator]: [authGroup.SYSTEM_ADMIN, authGroup.TENANT_ADMIN, authGroup.SYSTEM_OPERATOR, authGroup.TENANT_OPERATOR],
        [authGroup.monitor]: [authGroup.SYSTEM_MONITORING, authGroup.TENANT_MONITORING, authGroup.TENANT_MONITORING_LINKS, authGroup.GROUP_MONITORING],
        [authGroup.channel_operator]: [authGroup.MULTIGROUP_ADMIN],
        [authGroup.end_customer_operator]: [authGroup.GROUP_MONITORING, authGroup.GROUP_OPERATOR]
    };
    static SECURITY_LEVEL_MAP = {
        GROUP_MONITORING: 'Customer Monitoring',
        GROUP_OPERATOR: 'Customer Operator',
        MULTIGROUP_ADMIN: 'Channel',
        TENANT_MONITORING: 'Tenant Monitoring',
        TENANT_OPERATOR: 'Tenant Operator',
        TENANT_ADMIN: 'Tenant Administrator',
        TENANT_MONITORING_LINKS: 'Tenant Monitoring Links',
        SYSTEM_MONITORING: 'System Monitoring',
        SYSTEM_OPERATOR: 'System Operator',
        SYSTEM_ADMIN: 'System Administrator',
        SERVICE_PROVIDER_MONITORING: 'Service Provider Monitoring',
        SERVICE_PROVIDER_OPERATOR: 'Service Provider Operator',
        SERVICE_PROVIDER_ADMIN: 'Service Provider Administrator',
    };
    static AUTHENTICATION_MAP = {
        LOCAL_AUTH: 'Local Authentication',
        RADIUS: 'RADIUS Authentication',
        LDAP: 'LDAP Authentication',
        AAD: 'Azure Authentication',
        SAML: 'SAML Authentication'
    };

    constructor(private generalService: GeneralService) {}

    public enrichSessionObject = (activeSession) => {
        if (activeSession) {
            GeneralService.testMode = activeSession.testMode;
            if (!activeSession.enriched) {
                activeSession.enriched = true;
                activeSession.username = activeSession.name;
                activeSession.uuid = activeSession.sessionId || this.token();

                const operatorType = activeSession.isSystemOperator ? 'SYSTEM' : 'TENANT';

                activeSession.originalSecurityLevel = activeSession.securityLevel;
                activeSession.securityLevel =  (activeSession.operatorType || operatorType) + '_' + activeSession.securityLevel;

                activeSession.sessionLeasingTimeStamp = 0;
                if (activeSession.sessionLeasingDurationHours > 0) {
                    activeSession.sessionLeasingTimeStamp = Date.now() + (activeSession.sessionLeasingDurationHours * MILLISECONDS_IN_HOUR);
                }
            }
        }
        SessionService.activeSession = activeSession;
        this.generalService.enforcePrivacyMode = activeSession.privacyMode && !AuthorizationService.validFor([authGroup.SYSTEM_ADMIN, authGroup.TENANT_ADMIN]);
    };

    private token() {
        return rand() + rand(); // to make it longer

        function rand() {
            return Math.random().toString(36).substr(2); // remove `0.`
        }
    }
}

