import {Injectable} from '@angular/core';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';


@Injectable({providedIn: 'root'})
export class SitesRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {

        super({url: 'topology/sites', entityType: 'sites', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter',
            urlToFilterCallingApi: {
                'topology/sites': 'sites',
                'callsMonitor/statistics/entities': 'sitesMonitorStatistics',
                'callsMonitor/statistics/sitesEntities': 'sitesCallsStatistics',
                'topology/statistics/sites': 'sitesTopologyStatistics'
            }});
    }
}
