import {Injectable} from '@angular/core';

import {SitesRestService} from '../apis/sites-rest.service';
import {LinksRestService} from '../apis/links-rest.service';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {RegionsRestService} from '../apis/regions-rest.service';
import {DevicesRestService} from '../apis/devices-rest.service';
import {EndpointsRestService} from '../apis/endpoints-rest.service';

import * as _ from 'lodash';
import {AuthorizationService, GeneralService, WSEntities} from 'ac-infra';
import {GroupsRestService} from '../apis/groups-rest.service';
import {NetworkSearchService} from '../network-search.service';
import {ActiveDirectoriesRestService} from '../../../users/apis/active-directories-rest.service';
import {MetadataService} from '../../../metadata/metadata.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {MiscellaneousJSUtilsService} from '../../../common/services/miscellaneous-JS-utils.service';
import {ChannelsRestService} from '../apis/channels-rest.service';

@Injectable({providedIn: 'root'})
export class NetworkDataService {

    private entityContainerArrayIndex: any;
    private audioCodesDevicesList: any;
    private linkTypeList: any;

    constructor(private miscellaneousJSUtilsService: MiscellaneousJSUtilsService,
                private generalService: GeneralService,
                private sitesService: SitesRestService,
                private linksRestService: LinksRestService,
                private groupsRestService: GroupsRestService,
                private channelsRestService: ChannelsRestService,
                private networkSearchService: NetworkSearchService,
                private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private devicesRestService: DevicesRestService,
                private endpointsRestService: EndpointsRestService,
                private activeDirectoriesRestService: ActiveDirectoriesRestService,
                private wsEntities: WSEntities) {

        this.entityContainerArrayIndex = {device: 0, site: 1, link: 2};
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        this.linkTypeList = MetadataService.getType('LinkType');

    }

    // For Regions Tree
    public getTenantsTreeData = () => {

        const tenants = _.cloneDeep(this.tenantsRestService.getAllEntitiesHashed());
        const regions = this.regionsRestService.getAllEntities();

        regions.forEach((region) => {
            if (tenants[region.tenantId]) {
                tenants[region.tenantId].children = tenants[region.tenantId].children || [];
                tenants[region.tenantId].children.push(region);
            }
        });
        return Object.values(tenants);
    };

    // For Topology Tree (Filters)
    public getTopologyTreeData = (success, parameters, validation, entitiesNamesList, filterTreeEntitiesCallback?, excludeArtificialNodes?) => {
        if (!entitiesNamesList) {
            success([]);
        }

        const tenantId = parameters.filter && !_.isEmpty(parameters.filter.id) ? parameters.filter.id[0] : null;
        const outIrrelevantTenants = (tenant) => tenant.id === tenantId;
        const tenantValidator = tenantId ? {tenants: outIrrelevantTenants} : null;
        const dataObject: any = {};

        _.forOwn(entitiesNamesList, (entityName) => {
            const entities = this.addEntityToObject(entityName, entityName === 'tenants' ? tenantValidator : validation);
            if (filterTreeEntitiesCallback) {
                dataObject[entityName] = filterTreeEntitiesCallback(entities, entityName);
            } else {
                dataObject[entityName] = entities;
            }
        });

        this.buildTopologyTreeDataAndCallSuccess(dataObject, entitiesNamesList, success, excludeArtificialNodes);
    };

    // For Network Topology / Map Shapes (Devices & Sites)
    public getDevicesAndSitesForMap = (success, search, ids?) => {

        const dataObject: any = {
            filteredDevices: this.devicesRestService.getFilteredEntitiesHashed(ids),
            filteredSites: this.sitesService.getFilteredEntitiesHashed(ids),
            devices: _.cloneDeep(this.devicesRestService.getAllEntities(ids)),
            sites: _.cloneDeep(this.sitesService.getAllEntities(ids))
        };

        dataObject.devices.forEach((device: any) => {
            device.filtered = !!dataObject.filteredDevices[device.id];
        });

        dataObject.sites.forEach((site: any) => {
            site.filtered = !!dataObject.filteredSites[site.id];
        });

        this.networkSearchService.searchEntity(dataObject.devices, search, 'devices');
        this.networkSearchService.searchEntity(dataObject.sites, search, 'sites');

        success({
            devices: dataObject.devices,
            sites: dataObject.sites
        });
    };

    // For Network Topology / Map Connections (Links)
    public getLinksForMap = (success, search, ids) => {

        const dataObject: any = {
            filteredLinks: this.linksRestService.getFilteredEntitiesHashed(ids),
            links: this.linksRestService.getAllEntities(ids)
        };

        dataObject.links.forEach((link: any) => {
            link.filtered = !!dataObject.filteredLinks[link.id];
        });

        this.networkSearchService.searchEntity(dataObject.links, search, 'links');

        success({links: dataObject.links});
    };

    // For Device Page (Devices - Managed Table)
    public getDevicesForGrid = (parameters, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            const devices = response.data.devices;
            if (devices.length === 0) {
                return callback(response.data);
            }
            const filterIds = {
                filter: {
                    entityId: devices.map((device) => device.id)
                }
            };

            this.devicesRestService.getMonitorStatisticsByUnitID(
                (entityStatistics: RestResponseSuccess) => {
                    const mappedStatistics: any = this.miscellaneousJSUtilsService.arrayToObjectByKey(entityStatistics.data.entityStatistics, 'id');

                    devices.forEach((device: any) => {
                        const productTypeObject = this.audioCodesDevicesList[device.productType] || {};

                        if (['ACL', 'CLOUDBOND'].indexOf(productTypeObject.familyType) > -1) {
                            device.type = 'ACL';
                        } else if (['LYNC'].indexOf(productTypeObject.familyType) > -1) {
                            device.type = 'LYNC';
                        }

                        device.productTypeName = productTypeObject.viewName;

                        device.isHA = (device.sbcInfo && device.sbcInfo.isHA) || false;
                        device.licenseStatus = this.flattenApplicationStatuses(device, 'license', 'licenseStatus') || 'UNMONITORED';
                        device.administrationState = this.flattenApplicationStatuses(device, 'management', 'administrationState') || '';
                        device.managementStatus = this.flattenApplicationStatuses(device, 'management', 'managementStatus') || 'UNMONITORED';
                        device.voiceQualityStatus = this.flattenApplicationStatuses(device, 'voiceQuality', 'voiceQualityStatus') || 'UNMONITORED';

                        _.extend(device, (mappedStatistics[device.id] || {}));
                    });

                    callback(response.data);
                },
                onFailure,
                filterIds
            );
        };

        const onFailure = () => {
            callback({devices: [], pages: {total: 0, totalElements: 0}});
        };

        this.devicesRestService.getFilteredEntitiesForTable(parameters, onSuccess, onFailure);
    };

    // For Links Page (Links - Table)
    public getLinksForGrid = (parameters, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            const links = response.data.links;
            if (links.length === 0) {
                return callback(response.data);
            }
            const filterIds = {
                filter: {
                    entityId: links.map((link) => link.id)
                }
            };

            this.linksRestService.getMonitorStatisticsByUnitID(
                (entityStatistics: RestResponseSuccess) => {
                    const mappedStatistics: any = this.miscellaneousJSUtilsService.arrayToObjectByKey(entityStatistics.data.entityStatistics, 'id');

                    links.forEach((link: any) => {
                        link.category = _.isObject(link.type) ? this.buildLinkCategory(link) : '';
                        _.extend(link, (mappedStatistics[link.id] || {}));
                    });

                    callback(response.data);
                },
                onFailure,
                filterIds
            );
        };

        const onFailure = () => {
            callback({links: [], pages: {total: 0, totalElements: 0}});
        };

        this.linksRestService.getFilteredEntitiesForTable(parameters, onSuccess, onFailure);
    };

    public getChannelForGrid = (parameters, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            const emptyObj: any = {pages: {total: 0, totalElements: 0}, hash: {}, channels: []};
            if (!response.data || !response.data.channels || response.data.channels.length === 0) {
                return callback(_.isNil(response) ? response : emptyObj);
            }

            callback(response.data);
        };

        const onFailure = () => {
            callback({channels: [], pages: {total: 0, totalElements: 0}});
        };

        this.channelsRestService.getFilteredEntitiesForTable(parameters, onSuccess, onFailure, 'channel');
    };

    public getGroupsForGrid = (parameters, type, callback, url) => {
        let entityType = type === 'CUSTOMER' ? 'customers' : 'groups';
        const onSuccess = (response: RestResponseSuccess) => {
            const emptyObj = {pages: {total: 0, totalElements: 0}, hash: {}};
            emptyObj[entityType] = [];
            if (!response.data || !response.data[entityType] || response.data[entityType].length === 0) {
                return callback(_.isNil(response) ? response : emptyObj);
            }

            callback(response.data);
        };

        const onFailure = () => {
            callback({groups: [], pages: {total: 0, totalElements: 0}});
        };

        this.groupsRestService.getFilteredEntitiesForTable(parameters, onSuccess, onFailure, type.toLowerCase() + 'Groups', url, entityType);
    };

    // For Sites Page (Sites - Table)
    public getSitesForGrid = (parameters, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            const sites = response.data.sites;
            if (sites.length === 0) {
                return callback(response.data);
            }
            const filterIds = {
                filter: {
                    entityId: sites.map((site) => site.id)
                }
            };

            this.getMonitorStatistics(this.sitesService, sites, callback, response, onFailure, filterIds);
        };

        const onFailure = () => {
            callback({sites: [], pages: {total: 0, totalElements: 0}});
        };

        this.sitesService.getFilteredEntitiesForTable(parameters, onSuccess, onFailure);
    };

    // For Endpoints Page (Endpoints - Table)
    public getEndpointsForGrid = (parameters, callback) => {
        const onSuccess = (response: RestResponseSuccess) => {
            const endpoints = response.data.endpoints;
            const filterIds: any = {filter: {}};

            const ids = endpoints.map((endpoint) => endpoint.id);
            if (ids.length > 0) {
                filterIds.filter.entityId = ids;
            }

            this.getMonitorStatistics(this.endpointsRestService, endpoints, callback, response, onFailure, filterIds);
        };

        const onFailure = () => {
            callback({endpoints: [], pages: {total: 0, totalElements: 0}});
        };

        this.endpointsRestService.getFilteredEndpoints(parameters, onSuccess, onFailure, false);
    };

    private addEntityToObject = (entityName, validation) => {

        const entities = _.cloneDeep(this.wsEntities.getEntitiesArray(entityName));

        return validation && _.isFunction(validation[entityName]) ? entities.filter(validation[entityName]) : entities;
    };

    private buildTopologyTreeDataAndCallSuccess = (topologyItems, entitiesNamesList, success, excludeArtificialNodes?) => {
        const tenantIndex = {};
        const regionIndex = {};
        const deviceIndex = {};
        const siteIndex = {};
        _.forOwn(entitiesNamesList, (entityName) => {
            switch (entityName) {
                case 'tenants':
                    topologyItems.tenants.forEach(this.extendTenants(tenantIndex));
                    break;
                case 'regions':
                    topologyItems.regions.forEach(this.extendRegions(tenantIndex, regionIndex, excludeArtificialNodes));
                    break;
                case 'devices':
                    topologyItems.devices.forEach(this.extendSimpleEntity(regionIndex, deviceIndex, 'device', excludeArtificialNodes));
                    break;
                case 'sites':
                    topologyItems.sites.forEach(this.extendSimpleEntity(regionIndex, siteIndex, 'site', excludeArtificialNodes));
                    break;
                case 'links':
                    if (!AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
                        topologyItems.links.forEach(this.extendLinks(regionIndex, deviceIndex, siteIndex, excludeArtificialNodes));
                    }
                    break;
                case 'ads':
                    topologyItems.ads.forEach(this.extendAds(tenantIndex));
                    break;
            }
        });

        if (!excludeArtificialNodes) {
            this.clearEmptyContainers(topologyItems);
        }

        success(topologyItems.tenants);
    };

    private extendTenants = (tenantIndex) => (tenant) => {
        tenant.entity = 'tenant';
        tenant.children = [];

        tenantIndex[tenant.id] = tenant;
    };

    private extendRegions = (tenantIndex, regionIndex, excludeArtificialNodes) => (originalRegion) => {
        const tenant = tenantIndex[originalRegion.tenantId];
        const region: any = {
            id: originalRegion.id,
            text: originalRegion.name,
            tenantId: originalRegion.tenantId,
            entity: 'region',
            status: originalRegion.status,
        };

        if (excludeArtificialNodes) {
            region.children = [];
        } else {
            region.children = [
                {
                    id: 'devices' + originalRegion.id,
                    name: 'devices',
                    artificial: true,
                    entity: 'devices',
                    children: []
                },
                {id: 'sites' + originalRegion.id, name: 'sites', artificial: true, entity: 'sites', children: []},
                {id: 'links' + originalRegion.id, name: 'links', artificial: true, entity: 'links', children: []}
            ];
        }

        if (tenant) {
            tenant.children.push(region);
        }

        regionIndex[region.id] = region;
    };

    private extendSimpleEntity = (regionIndex, entityIndex, type, excludeArtificialNodes) => (entity) => {
        const region = regionIndex[entity.regionId];

        entity = _.extend({entity: type}, entity);

        if (region) {
            if (excludeArtificialNodes) {
                region.children.push(entity);
            } else {
                region.children[this.entityContainerArrayIndex[type]].children.push(entity);
            }
        }

        entityIndex[entity.id] = entity;
    };

    private extendAds = (tenantIndex) => (originalAd) => {
        const tenant = tenantIndex[originalAd.tenantId];
        const ad = _.cloneDeep(originalAd);
        ad.text = ad.name;
        ad.entity = 'ad';

        if (tenant) {
            tenant.children.push(ad);
        }
    };

    private extendLinks = (regionIndex, deviceIndex, siteIndex, excludeArtificialNodes) => (link) => {
        const deviceSrc = deviceIndex[link.linkSrcId] || _.cloneDeep(this.devicesRestService.getEntityById(link.linkSrcId));
        let deviceDst = deviceIndex[link.linkDstId] || siteIndex[link.linkDstId];

        if (!deviceDst) {
            deviceDst = _.cloneDeep(this.devicesRestService.getEntityById(link.linkDstId) || this.sitesService.getEntityById(link.linkDstId));
        }

        if (deviceSrc) {
            const srcParent = regionIndex[deviceSrc.regionId];

            link.linkSrcTenantId = srcParent.tenantId;

            if (deviceDst) {
                const dstParent = regionIndex[deviceDst.regionId];
                link.linkDstTenantId = dstParent.tenantId;
            }

            link.entity = 'link';
            if (srcParent) {
                if (excludeArtificialNodes) {
                    srcParent.children.push(link);
                } else {
                    srcParent.children[this.entityContainerArrayIndex.link].children.push(link);
                }
            }
        }
    };

    private clearEmptyContainers = (topologyItems) => {
        topologyItems.tenants.forEach((tenant) => {
            tenant.children.forEach((regionOrAd) => {
                if (regionOrAd.entity === 'region') {
                    regionOrAd.children = regionOrAd.children.filter((container) => container.children.length > 0);
                }
            });
        });
    };

    private getMonitorStatistics = (entityService, entities, callback, response, onFailure, filterIds) => {
        entityService.getMonitorStatisticsByUnitID(
            (entityStatistics: RestResponseSuccess) => {
                const mappedStatistics: any = this.miscellaneousJSUtilsService.arrayToObjectByKey(entityStatistics.data.entityStatistics, 'id');

                entities.forEach((entity: any) => {
                    entity.managementStatus = this.flattenApplicationStatuses(entity, 'management', 'managementStatus') || 'UNMONITORED';
                    entity.voiceQualityStatus = this.flattenApplicationStatuses(entity, 'voiceQuality', 'voiceQualityStatus') || 'UNMONITORED';

                    _.extend(entity, (mappedStatistics[entity.id] || {}));
                });

                callback(response.data);
            },
            onFailure,
            filterIds
        );
    };


    private flattenApplicationStatuses = (entity, categoryField, statusField) => entity.applicationsStatus &&
        entity.applicationsStatus[categoryField] &&
        entity.applicationsStatus[categoryField][statusField];

    private buildLinkCategory = (link: any) => {
        const linkType = (this.linkTypeList[link.type.categoryType] || {}).viewName || '';
        const linkValue = link.type.categorySecondaryValue ? ' ' + link.type.categorySecondaryValue : '';

        return linkType + ' ' + link.type.categoryValue + linkValue;
    };
}
