import {Component, Input} from '@angular/core';
import {LogoDisplayService} from '../../services/logo-display.service';

@Component({
    selector: 'logo-display',
    templateUrl: './logo-display.component.html',
    styleUrls: ['./logo-display.component.less']
})
export class LogoDisplayComponent {
    @Input() width: any;

    constructor(public logoDisplayService: LogoDisplayService) {
        logoDisplayService.updateLogos();
    }
}



