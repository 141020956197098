import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

import * as _ from 'lodash';

import {NetworkMapGeoComponent} from '../network/views/network-map/network-map-geo/network-map-geo.component';
import {FilterResolverFactory} from './workspace-resolvers/filters/filter-resolver-factory.class';
import {Workspace, WorkspaceRestService} from './workspace-rest.service';
import {
    AcDialogService,
    AcTableActions,
    AcTableComponent,
    GeneralService,
    AcNavAutoService,
    PromiseService,
    SessionService,
    SessionStorageService,
    ThrottleClass
} from 'ac-infra';
import {WorkspaceVersions} from './workspace-versions.model';
import {InfoPanelResolver} from './workspace-resolvers/info-panel.resolver';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FilterState} from '../common/components/ac-filter/services/ac-filter-state.service';
import {Store} from '@ngxs/store';
import {TableResolverClass} from './workspace-resolvers/table-resolver.class';


export const DEFAULT_WORKSPACE_ID = -1;

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class WorkspaceManagerService {

    private activeWorkspace: Workspace;
    private autoSaveThrottle: ThrottleClass;

    get sessionWorkspaceName(): string {
        return this.sessionService.activeSession.name + '_workspace';
    }

    get sessionActiveWorkspaceId(): number {
        return this.sessionService.activeSession?.activeWorkspaceId;
    }

    constructor(private workspaceRestService: WorkspaceRestService,
                private sessionService: SessionService,
                private ngLocation: Location,
                private acDialogService: AcDialogService,
                private generalService: GeneralService,
                private store: Store) {

        (window as any)._getExportData = this.getExportData;
        (window as any)._loadWorkspace = this.loadWorkspace;
    }

    initializeAutoSave() {
        if (this.autoSaveThrottle) {
            return;
        }

        this.autoSaveThrottle = new ThrottleClass({
            callback: () => {
                if (!this.activeWorkspace?.autoSave) {
                    return;
                }

                const exportCandidateData = this.getExportData();
                if (!_.isEqual(exportCandidateData, this.activeWorkspace.data)) {
                    this.activeWorkspace.data = exportCandidateData;
                    this.export({
                        workspaceId: this.activeWorkspace?.id,
                        exportData: exportCandidateData
                    });
                }
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 10000, // 30000
            debounce: 5000,
            maxDebounceTime: 15000,
        });

        SessionStorageService.setData$.subscribe(() => this.activeWorkspace?.id > 0 && this.autoSaveThrottle.tick());
    }

    import(workspaceId, refreshPage = false, loadToSessionStorage = true) {
        return this.workspaceRestService.getWorkspaceById(workspaceId, (workspace: Workspace) => {
            this.activeWorkspace = workspace;
            loadToSessionStorage && this.loadWorkspace(workspace.data || {}, refreshPage);
        });
    }

    export = ({
                  workspaceId = undefined,
                  success = (...args) => null,
                  exportData = undefined,
                  isActive = false
              } = {}) => {

        const data = exportData || this.getExportData();
        const activeWorkspaceId = workspaceId || this.activeWorkspace?.id;

        if (activeWorkspaceId > 0) {
            this.workspaceRestService.updateWorkspaceById(activeWorkspaceId, {data}, {success});
        } else {
            this.workspaceRestService.createWorkspace({name: this.sessionWorkspaceName, data}, {
                success: (workspace) => {
                    if (isActive) {
                        return this.activateWorkspace(workspace.id, false);
                    }
                    success(workspace);
                }
            });
        }
    };

    getExportData = () => {
        const parsedSessionStorage = Object.getOwnPropertyNames(sessionStorage).reduce((acc, cur) => {
            acc[cur] = JSON.parse(sessionStorage[cur]);
            return acc;
        }, {});

        const filtersExports = this.getFiltersExports(parsedSessionStorage);
        const routingExports = this.getRouteingExports(parsedSessionStorage);
        const mapExports = this.getMapExports(parsedSessionStorage);
        const tableExports = this.getTableExports(parsedSessionStorage);
        const infoPanelExports = this.getInfoPanel(parsedSessionStorage);
        const globalStates = this.getGlobalStates(parsedSessionStorage);

        return {
            filters: filtersExports && {ver: WorkspaceVersions.filtersVersion, ...filtersExports},
            routing: routingExports && {ver: WorkspaceVersions.routingVersion, ...routingExports},
            map: mapExports && {ver: WorkspaceVersions.mapVersion, ...mapExports},
            table: tableExports && {ver: WorkspaceVersions.tableVersion, ...tableExports},
            infoPanel: infoPanelExports && {ver: this.generalService.serverInfo.appVersion, ...infoPanelExports},
            globalStates: globalStates && {ver: null, ...globalStates},
        };
    };

    updateSessionActiveWorkspaceId = (workspaceId) => this.sessionService.assignToSession({activeWorkspaceId: workspaceId});

    loadWorkspace = ({
                         routing = undefined,
                         map = undefined,
                         table = undefined,
                         filters = undefined,
                         infoPanel = undefined,
                         globalStates = undefined,
                     }, refreshPage = false) => {

        refreshPage && this.emptyLocalWorkspace(false);

        filters && this.loadWorkspaceFilters(filters);

        routing && this.loadWorkspaceData(routing);
        map && this.loadWorkspaceData(map);
        table && this.loadWorkspaceTable(table);
        infoPanel && this.loadWorkspaceInfoPanel(infoPanel);
        globalStates && this.loadWorkspaceData(globalStates);

        refreshPage && this.refreshPageToRoute(routing.lastStaticState);
    };

    importWorkspace = (
        {
            workspaceId = undefined,
            refreshPage = false,
            loadToSessionStorage = true
        } = {}): Promise<any> => {
        const activeWorkspaceId = workspaceId || this.sessionActiveWorkspaceId;

        if (activeWorkspaceId > 0 && !GeneralService.testMode) {
            return this.import(activeWorkspaceId, refreshPage, loadToSessionStorage);
        }

        return Promise.resolve();
    };

    toggleWorkspaceAutoSaveById(workspaceId, autoSave: boolean, onSuccess: () => void) {
        this.workspaceRestService.updateWorkspaceById(workspaceId, {autoSave}, {
            success: () => {
                onSuccess();
                if (!this.activeWorkspace) {
                    return;
                }

                this.activeWorkspace.autoSave = autoSave;
                if (autoSave && workspaceId === this.activeWorkspace.id) {
                    this.autoSaveThrottle.tick();
                }
            }
        });
    }

    emptyLocalWorkspace(refreshPage = false) {
        const lastStaticState = SessionStorageService.getData(AcNavAutoService.LAST_STATIC_ROUTE);

        SessionStorageService.clearAllData();
        SessionStorageService.setData('session', this.sessionService.activeSession);

        refreshPage && this.refreshPageToRoute(lastStaticState);
    }


    activateWorkspace(workspaceId, needConfirm = true) {
        const defer = PromiseService.defer();
        if (this.activeWorkspace?.autoSave) {
            this.export({
                success: () => this._activateWorkspace(workspaceId)
            });
        } else if (needConfirm) {
            this.showConfirmDialog(defer).then(() => {
                this._activateWorkspace(workspaceId);
            });
        } else {
            this._activateWorkspace(workspaceId);
        }
        return defer.promise;
    }


    deactivateLoadedWorkspace(loadEmpty = true) {
        const activeWorkspaceId = this.activeWorkspace?.id;

        const deactivateWorkspaceUI = () => {
            this.updateSessionActiveWorkspaceId(DEFAULT_WORKSPACE_ID);
            loadEmpty && this.emptyLocalWorkspace(true);
        };

        if (activeWorkspaceId > 0) {
            this.workspaceRestService.updateWorkspaceById(activeWorkspaceId, {isActive: false}, {
                success: deactivateWorkspaceUI,
            });
        } else {
            deactivateWorkspaceUI();
        }
    }

    private _activateWorkspace(workspaceId) {
        if (workspaceId < 0) {
            this.deactivateLoadedWorkspace();
        } else if (workspaceId === this.activeWorkspace?.id) {
            this.importWorkspace({workspaceId, refreshPage: true});
        } else {
            this.workspaceRestService.updateWorkspaceById(workspaceId, {isActive: true}, {
                success: () => {
                    this.updateSessionActiveWorkspaceId(workspaceId);
                    this.importWorkspace({workspaceId, refreshPage: true});
                }
            });
        }
    }

    deleteWorkspace(workspaceId) {
        this.workspaceRestService.deleteById({
            id: workspaceId,
            success: () => {
                this.sessionService.assignToSession({activeWorkspaceId: -1});
                this.clearActiveWorkspace();
                this.deactivateLoadedWorkspace();
            }
        });
    }

    clearActiveWorkspace() {
        this.activeWorkspace = null;
    }

    showConfirmDialog = (defer) => {
        defer = defer || PromiseService.defer();
        this.acDialogService.confirm('Activating workspace will dismiss unsaved changes on active workspace', {
            onSubmit: defer.resolve,
            onClose: defer.reject,
        });
        return defer.promise;
    };

    private loadWorkspaceData({ver, ...data}: any) {
        _.forOwn(data, (value, key) => {
            if (value === null || value === undefined) {
                return;
            }
            SessionStorageService.setData(key, value);
        });
    }

    private loadWorkspaceFilters({ver, pinned: pinnedFilters, ...unpinnedFilters}: any) {
        const resolvedPinnedFilters = pinnedFilters && this.resolveFilters(pinnedFilters, false, ver);
        const resolvedUnpinnedFilters = this.resolverUnpinned(unpinnedFilters, false, ver);

        this.loadWorkspaceData({ver, pinned: resolvedPinnedFilters});
        this.loadWorkspaceData({ver, ...resolvedUnpinnedFilters});
    }

    private loadWorkspaceTable({ver, ...tableState}: any) {
        const resolvedTableState = tableState && this.resolveTableState(tableState, ver);
        this.store.dispatch(new AcTableActions.SetTableState(resolvedTableState.tableState));
    }

    private loadWorkspaceInfoPanel({ver, ...infoPanel}: any) {
        infoPanel = new InfoPanelResolver(this.generalService.serverInfo.appVersion).import(infoPanel, ver);
        this.loadWorkspaceData({ver, infoPanel});
    }

    private getRouteingExports(parsedSessionStorage) {
        return _.pickBy(parsedSessionStorage, (value, key) => key.startsWith(AcNavAutoService.ROUTE_PREFIX) || key === AcNavAutoService.LAST_STATIC_ROUTE);
    }

    private getMapExports(parsedSessionStorage) {
        return _.pickBy(parsedSessionStorage, (value, key) => key.startsWith(NetworkMapGeoComponent.TOPOLOGY_MAP) || key.startsWith(NetworkMapGeoComponent.GEO_MAP));
    }

    private getTableExports(parsedSessionStorage) {
        const tablesStates = {...parsedSessionStorage[AcTableComponent.AC_TABLE_STATE_AND_CONFIGS]};
        _.forOwn(parsedSessionStorage[AcTableComponent.AC_TABLE_STATE_AND_CONFIGS], (tableState, tableId) => {
            tablesStates[tableId] = _.pickBy(tableState, (value, key) => !['selection', 'anchor'].includes(key));
        });
        return {[AcTableComponent.AC_TABLE_STATE_AND_CONFIGS]: tablesStates};
    }

    private getInfoPanel = (parsedSessionStorage) => parsedSessionStorage.infoPanel;

    private getGlobalStates = (parsedSessionStorage) => {
        return {
            sessionNotificationDuration: parsedSessionStorage.sessionNotificationDuration
        };
    };

    private resolveFilters(filters: any, toExport: boolean, ver = 0) {
        const resolvedFilters = {};
        _.forOwn(filters, (resolvedFilter, filterType: string) => {
            const filterResolver = FilterResolverFactory.getFilterResolver(filterType);

            resolvedFilters[filterType] = toExport ? filterResolver.export(resolvedFilter) : filterResolver.import(resolvedFilter, ver);
        });
        return _.pickBy(resolvedFilters, (value) => !!value);
    }

    private resolveTableState(tableState: any, ver = 0) {
        return (new TableResolverClass()).migrate(tableState, ver);
    }

    private resolverUnpinned(unpinnedFilters, toExport = true, ver = 0) {

        _.forOwn(unpinnedFilters, (exportsString, unpinnedFilterKey) => {
            unpinnedFilters[unpinnedFilterKey] = this.resolveFilters(exportsString, toExport, ver);
        });

        return unpinnedFilters;
    }

    private getFiltersExports(parsedSessionStorage) {
        const unpinnedFilters = _.pickBy(parsedSessionStorage, (value, key) => key.startsWith(FilterState.UNPINNED_STATE));

        const unpinnedExports = this.resolverUnpinned(unpinnedFilters);
        const pinnedExports = parsedSessionStorage.pinned && this.resolveFilters(parsedSessionStorage.pinned, true);

        return {pinned: pinnedExports || {}, ...unpinnedExports};
    }

    private refreshPageToRoute(route) {
        location.pathname = (this.ngLocation as any)._baseHref + route;
    }
}
