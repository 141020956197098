<ac-form *ngIf="dialogConfig.ready"
         [formModel]="generalDevice"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         [deleteEmptyStringProperties]="['ipAddress']"
         formModelName="generalDevice">

    <div id="generic-device-dialog-content">
        <div class="flexRowParentEqualChildren">
            <ac-input-container label="Name">
                <ac-textfield [(acModel)]="generalDevice.name"
                              [attr.alternativeName]="'generalDevice.name'"
                              maxLength="100"
                              textFieldId="general-device-name">
                </ac-textfield>
            </ac-input-container>

            <ac-input-container label="IP">
                <ac-textfield [(acModel)]="generalDevice.ipAddress"
                              [attr.alternativeName]="'generalDevice.ipAddress'"
                              textFieldId="general-device-ip">
                </ac-textfield>
            </ac-input-container>
        </div>

        <network-tenant-region-selection [model]="generalDevice"
                                         [additionalSettings]="additionalSettings"
                                         [isEdit]="isEdit"
                                         [ngDisabledTenant]="isEdit"
                                         [ngDisabledRegion]="isEdit"
                                         [regionAlternativeName]="'generalDevice.regionId'"
                                         [horizontalView]="true">
        </network-tenant-region-selection>

        <ac-input-container label="Address">
            <ac-geo-location (acChanged)="generalDevice.location=$event"
                             [acModel]="generalDevice.location"
                             [attr.alternativeName]="'generalDevice.location'"></ac-geo-location>
        </ac-input-container>
    </div>
</ac-form>
