import {ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, SkipSelf, TemplateRef, ViewChild} from '@angular/core';
import $ from 'jquery';
import {NgSelectComponent} from '@ng-select/ng-select';
import {cloneDeep, isEmpty, isNumber, orderBy} from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcDialogComponent} from '../ac-dialog/ac-dialog.component';
import {GeneralService} from '../../services/general.service';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {AcInputContainerOldComponent} from '../ac-input-container-old/ac-input-container-old.component';

@UntilDestroy()
@Component({
    selector: 'ac-select',
    templateUrl: './ac-select.component.html',
    styleUrls: ['./ac-select.component.less']
})
export class AcSelectComponent {

    @Input() labelTemplate: TemplateRef<any>;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() bindLabel = 'text';
    @Input() bindValue = 'value'; // LIMITATION of the ng-select: allows only STRING/INT/... (not objects).
    @Input() multiple: boolean;
    @Input() openRight: boolean;
    @Input() placeholder = '';
    @Input() ngDisabled = false;
    @Input() overrideSystemViewMode = false;
    @Input() showSelectedCount = false;
    @Input() emptyModelIndicatesAllSelected = false;
    @Input() addCustomTags = false;
    @Input() selectionName = '';
    @Input() width: string;
    @Input() combinedMode = false; // both custom tags and tags from list
    @Input() searchable = true;
    @Input() maxSelectedItems: number;
    @Input() titleForSelectedItemsCallback: Function;
    @Input() groupBy: string | Function;
    @Input() updateItemsObservable: Observable<any>;
    @Input() optionTitle: string;
    @Input() allowClear = false;
    @Input() hideFilterButtons = false;
    @Input() displayImage = false;
    @Input() isOpen: any = undefined;
    @Input() direction: string;
    @Input() labelPostfix = '';
    @Input() svgFixedName;
    @Input() svgFixedHeight;
    @Input() svgFixedWidth;
    @Input() dropDownOptionsClass = '';
    @Input() selectId: string;
    @Input() selectFirstAfterSort = true;
    @Input() maxCustomLinesHeight: number;
    @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('ngSelectElement', {static: true}) ngSelectElement: NgSelectComponent;
    scrollEventFunctionRef;
    private isIEOrEdge: boolean;

    searchTerm;
    isFocused = false;
    _sortItems = false;
    _items: any[] = [];

    @Output() acModelChange: EventEmitter<any> = new EventEmitter<any>();

    _acModel;
    @Input() set acModel(acModel) {
        this._acModel = (acModel === undefined || acModel === null) ? '' : acModel;
        if (this._sortItems) {
            this.sortItemsAlphabetically();
        }
    }

    @Input() set sortItems(sortItems: boolean) {
        this._sortItems = sortItems;
        this._sortItems && this.sortItemsAlphabetically();
    }

    @Input() set items(items: any) {
        items = items?.map(item => {
            const itemType = typeof item;
            if (itemType === 'object' || itemType === 'function') {
                return item;
            }
            return {[this.bindLabel]: item, [this.bindValue]: item};
        });

        items?.forEach(item => {
            if (item[this.bindLabel]) {
                item[this.bindLabel] = item[this.bindLabel] + this.labelPostfix;
            }
        });

        this._items = items;
        if (this._sortItems) {
            this.sortItemsAlphabetically();
        }
    }

    acInputContainer;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acInputContainerOldComponent: AcInputContainerOldComponent,
                @Optional() public acFormComponent: AcFormComponent,
                @Optional() @SkipSelf() public acDialogComponent: AcDialogComponent,
                public generalService: GeneralService,
                private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.acInputContainer = this.acInputContainerOldComponent || this.acInputContainerComponent;

        if (this.acFormComponent && this.acFormComponent.isViewMode) {
            this.ngDisabled = !this.overrideSystemViewMode;
        }

        if(this.maxCustomLinesHeight && isNumber(this.maxCustomLinesHeight) && this.ngSelectElement){
            $(this.ngSelectElement.element).find('.ng-value-container')
                .attr('style', 'max-height: ' + this.maxCustomLinesHeight + 'px!important');
        }

        this.scrollEventFunctionRef = this.handleScrollEvent();
        this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

        if (this.updateItemsObservable) {
            this.updateItemsObservable.pipe(untilDestroyed(this)).subscribe((newItems) => {
                this._items = cloneDeep(newItems);
                if (this._sortItems) {
                    this.sortItemsAlphabetically();
                }
            });
        }

        this.acDialogComponent && this.acDialogComponent.dragStart$.subscribe(() => {
            this.ngSelectElement.close();
        });

        if (this.direction === 'right' || (!this.direction && $(this.ngSelectElement.element).parents('ac-filter-type').length > 0)) {
            this.openRight = true;
        }

        setTimeout(() => {
            const selectId = this.selectId || (this.acInputContainer?.componentName);
            if (selectId) {
                this.selectId = `ngSelect.${selectId}`;
            }
        });

        if (this.multiple) {
            this.searchable = true;
        }


        if (this.isOpen !== false) {
            this.ngSelectElement.handleMousedown = (() => { // ADD Click on arrow wrapper
                const cached_function = this.ngSelectElement.handleMousedown;
                return function($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    const target = ($event.target) as HTMLInputElement;
                    if (target.localName === 'input' || target.className === 'ng-arrow-wrapper' || target.className === 'ng-value-container' || target.className === 'ng-value' || target.className === 'ng-input' || target.className === 'ng-value-label') {
                        this.handleArrowClick();
                        return;
                    }
                    cached_function.apply(this, arguments);
                };
            })();
        }
        this.cdRef.detectChanges();
    }

    isEmpty = (value) => {
        return (typeof value !== 'boolean') && !isNumber(value) && isEmpty(value);
    };

    ngSelectChanged() {
        if (this.ngSelectElement.dropdownPanel) {
            setTimeout(() => {
                if (this.ngSelectElement.dropdownPanel) {
                    this.ngSelectElement.dropdownPanel.adjustPosition();
                }
            }, 10);
        }
    }

    validateItem(index) {
        if (!this.acFormComponent?.formValidator || !this.selectionName ||
            !this.acFormComponent?.formValidator[this.selectionName] ||
            !this.acFormComponent?.formValidator[this.selectionName].errors ||
            !this.acFormComponent?.formValidator[this.selectionName].errors.multiple) {
            return false;
        }

        return !!this.acFormComponent?.formValidator[this.selectionName].errors.multiple.hasOwnProperty(index);
    }

    sortItemsAlphabetically = () => {
        this._items = orderBy(this._items, [item => (item[this.bindLabel] || '').toLowerCase()], ['asc']);

        if (!this.multiple && this.selectFirstAfterSort && (this._acModel === '' || this._acModel === undefined) && this._items?.length > 0) {
            this.acModelChange.emit(this._items[0][this.bindValue]);
        }
    };

    decideCloseOnSelect = () => {
        if (!this.multiple) {
            return true;
        }

        if (this.maxSelectedItems && this._acModel && this.maxSelectedItems === this._acModel.length + 1) {
            return true;
        }
        return false;
    };

    addTag = (value) => {
        if (typeof value === 'string') {
            value = value.trim();
        }
        if (this.combinedMode && this.addCustomTags) {
            const returnedObj: any = {};
            returnedObj[this.bindLabel] = value;
            returnedObj.value = value;
            returnedObj.customTag = true;
            return returnedObj;
        } else if (this.addCustomTags) {
            let itemExist = false;
            this._items.forEach((item) => {
                if (item[this.bindValue] === value) {
                    itemExist = true;
                }
            });

            if (itemExist) {// if exist choose him else add him to items
                this.acModelChange.emit(value);
            } else {
                return value;
            }
        }
    };

    setAllFiltered() {
        let result;
        const filteredItems = cloneDeep(this.ngSelectElement.itemsList.filteredItems);
        if (this.bindValue === undefined) {
            result = filteredItems.filter(t => !t.children).map((t) => {
                if (this.bindValue) {
                    return t[this.bindValue];
                } else {
                    return t.value;
                }
            });

        } else {
            result = filteredItems.filter(t => !t.children).map((t) => t.value && t.value[this.bindValue]);
        }

        this._acModel = this._acModel && this._acModel.length > 0 ? this._acModel.concat(result) : result;

        this.acModelChange.emit(this._acModel);
        this.ngSelectElement.close();
    }

    setAll() {
        if (this.bindValue === undefined) {
            this._acModel = this._items.map((t) => t);
        } else {
            this._acModel = this._items.map((t) => t[this.bindValue]);
        }

        this.acModelChange.emit(this._acModel);
        this.ngSelectElement.close();
    }

    setNone() {
        this._acModel = [];
        this.acModelChange.emit([]);
        this.updateDropdownMenu();
    }

    invertAll() {
        const result = [];

        this._items.forEach((item) => {
            if (this.bindValue === undefined) {
                if (!this._acModel.includes(item)) {
                    result.push(item);
                }
            } else {
                if (!this._acModel.includes(item[this.bindValue])) {
                    result.push(item[this.bindValue]);
                }
            }
        });

        this._acModel = result;
        this.acModelChange.emit(result);
        this.updateDropdownMenu();
    }

    onOpenEvent() {
        this.onOpen.emit();
        if (!this.isIEOrEdge) {
            window.addEventListener('scroll', this.scrollEventFunctionRef, true); // Close on scroll
        }
    }

    onCloseEvent() {
        if (this.addCustomTags && this.ngSelectElement.searchTerm != null) {
            this.ngSelectElement.selectTag();
        }

        if (!this.isIEOrEdge) {
            window.removeEventListener('scroll', this.scrollEventFunctionRef, true);
        }
    }

    updateDropdownMenu = () => {
        setTimeout(() => {
            this.ngSelectElement.dropdownPanel && this.ngSelectElement.dropdownPanel.adjustPosition();
        }, 100);
    };

    showAllButton = () => !this.maxSelectedItems && !this.addCustomTags && this.ngSelectElement && this.ngSelectElement.itemsList && this.ngSelectElement.itemsList.filteredItems.length > 0;

    setClearButton = () => this._acModel && this._acModel.length > 0;

    showInvertButton = () => !this.maxSelectedItems && !this.addCustomTags && this._acModel && this._acModel.length > 0 && this.ngSelectElement && this.ngSelectElement.itemsList && this.ngSelectElement.itemsList.filteredItems.length > 0;

    showAllFilteredButton = () => !this.maxSelectedItems && !this.addCustomTags &&
        this.ngSelectElement &&
        this.ngSelectElement.searchTerm &&
        this.ngSelectElement.itemsList &&
        this.ngSelectElement.itemsList.filteredItems &&
        this.ngSelectElement.itemsList.filteredItems.length > 0;

    showFooter = () => this.multiple && (this.showSelectedCount || this.showAllButton() || this.setClearButton() || this.showInvertButton() || this.showAllFilteredButton());

    selectionChanged = ($event) => {
        this.acModelChange.emit($event);
    };

    getSelectedCount = () => {
        if (this._acModel && this._acModel.length > 0) {
            return this._acModel.length;
        }

        return this.emptyModelIndicatesAllSelected ? 'All' : 0;
    };

    getTextValue = (item, returnFakeValue = false) => {
        const result = item?.[this.bindLabel] || (typeof item === 'string' ? item : undefined) || undefined;

        return result || (returnFakeValue ? 'hide' : '');
    };

    isSelectDisabled() {
        return (this.ngDisabled || (this.acInputContainer && this.acInputContainer.isDisabled));
    }

    onBlur() {
        this.isOpen === false && this.ngSelectElement.closeEvent.emit();
    }

    noSearch = () => null;

    private handleScrollEvent = () => (e) => {
        if (this.ngSelectElement.isOpen && $.contains(e.target, this.ngSelectElement.element)) {
            this.ngSelectElement.close();
        }
    };
}



