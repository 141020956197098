<ac-form *ngIf="dialogConfig.ready"
         [formModel]="parameters"
         [formSettings]="dialogConfig.formSettings"
         formModelName="parameters">

    <div id="sync-links-dialog-content">
        <label>Select device channel types:</label>
        <ac-checkbox [(acModel)]="parameters.ipGroup" labelText="IP group"
                     (acModelChange)="updateParameters('ipGroup', $event)">
        </ac-checkbox>

        <ac-checkbox [(acModel)]="parameters.trunkGroup" labelText="Trunk group"
                     (acModelChange)="updateParameters('trunkGroup', $event)">
        </ac-checkbox>

        <ac-checkbox [(acModel)]="parameters.mediaRealm" labelText="Media realm"
                     (acModelChange)="updateParameters('mediaRealm', $event)">
        </ac-checkbox>

        <ac-checkbox *ngIf="!(parameters.mediaServer === undefined)"
                     [(acModel)]="parameters.mediaServer" labelText="Media server"
                     (acModelChange)="updateParameters('mediaServer', $event)">
        </ac-checkbox>
    </div>

</ac-form>
