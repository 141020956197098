<tr *ngFor="let row of rows; let rowIndex=index; trackBy: acTableService.trackById"
    class="body-table-row"
    [class.selected]="!row._groupId && acTableComponent.selection[row.id]"
    [class.odd]="acTableComponent.vsComponent?.viewPortInfo.startIndexWithBuffer | acFunc: isOddRow: rowIndex"
    (click)="row._groupId ? acTableComponent.toggleGroupCollapsedState(row) : acTableComponent.selectRow($event, row)" #rowsElementsRef>

    <td *ngFor="let column of columns | acFunc : reduceCells : row; trackBy: acTableService.trackByField"
        [attr.headers]="column.field"
        [attr.colspan]="column | acFunc : getRowColSpan: row"
        [class.group]="row._groupId"
        [class.left]="column.stickyLeft"
        #td>

        <div class="table-cell body-table-cell" [parentForTitle]="td" acLabel #cell>

            <ng-template [acTableCellTemplate]="row._groupId ? groupCellTemplate : defaultCellTemplate"
                         [acTableCell]="cell"
                         [acTableCellFormatter]="column.formatter"
                         [acTableCellColumn]="column"
                         [acTableCellRow]="row">
            </ng-template>

        </div>
    </td>
</tr>

<tr *ngIf="showBufferLoader">
    <td [attr.colspan]="columns.length">
        <div *ngIf="!templates?.infiniteScrollBufferLoader else templates.infiniteScrollBufferLoader"
             style="height: 48px">
            <ac-progress></ac-progress>
        </div>
    </td>
</tr>

<ng-template let-row="row" let-column="column" #defaultCellTemplate>
    <ng-container *ngIf="((row.data | ByStringPipe : column.field) || '') as data">
        {{data?.value || data}}
    </ng-container>
</ng-template>

<ng-template let-row="row" #groupCellTemplate>
    <ac-svg class="group-collapse"
            name="expander"
            [rotate]="row.isCollapsed ? 0 : 45"
    ></ac-svg>
    {{row._groupId + ' (' + acTableComponent.groupedRows[row._groupId]?.length + ' items)'}}
</ng-template>
