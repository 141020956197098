<div #tabsContainer class="demi dynamicLinksInner flexRowParent" style="visibility: hidden">
    <ac-nav-button *ngFor="let dynTab of dynamicTabsService.dynamicTabs$ | async"
                   [icon]="dynTab.icon"
                   [isLoading]="dynTab.isLoading"
                   [attr.dynamic]="true"
                   [dynamic]="true"
    >{{dynTab.text}}</ac-nav-button>
    <div #openButton class="openDynTabs button">
        <i class="fa fa-sort-down"></i>
    </div>
    <!--    <ac-nav-button class="openDynTabs" #openButton>-->

    <!--    </ac-nav-button>-->
</div>

<div class="real dynamicLinksInner flexRowParent">
    <ng-container *ngFor="let dynTab of dynamicTabsService.dynamicTabs$ | async">
        <ac-nav-button #rla="routerLinkActive"
                       [attr.id]="'dynamic-tab-'+(dynTab.url|acFunc:urlToId)"
                       (close)="dynamicTabsService.removeTab(dynTab, rla.isActive)"
                       (mouseup)="$event.button===1 && dynamicTabsService.removeTab(dynTab, rla.isActive)"
                       *ngIf="dynTab.visible"
                       [acDisableLink]="rla.isActive"
                       [isLoading]="dynTab.isLoading"
                       [routerLink]="dynTab.url"
                       [icon]="dynTab.icon"
                       [dynamic]="true"
                       [attr.dynamic]="true"
                       [wrapContent]="true"
                       [closeButton]="true"
                       routerLinkActive="active">{{dynTab.text}}</ac-nav-button>
    </ng-container>

    <ac-drop-down #dynamicTabDropdown *ngIf="dynamicTabsService.anyHidden" [upperCaseContent]="true"
                  class="openDynTabsParent" parentClass="dynamic-tabs-dropdown">
        <!--        <div [class.active]="dynamicTabsService.anyOfHiddenActive" class="openDynTabs button" parent>-->
        <!--            <i class="fa fa-sort-down"></i>-->
        <!--        </div>-->

        <ac-nav-button [class.active]="dynamicTabsService.anyOfHiddenActive" class="openDynTabs button" [dynamic]="true"
                       [attr.dynamic]="true" parent>
            <i class="fa fa-sort-down"></i>
        </ac-nav-button>
        <div class="dynamic-tab-dropdown-list" tooltip>
            <ng-container *ngFor="let dynTab of dynamicTabsService.dynamicTabs$ | async">
                <div *ngIf="!dynTab.visible"
                     [acDisableLink]="rlaD.isActive | acFunc: updateActive: dynTab:rlaD.isActive"
                     [routerLink]="dynTab.url"
                     (close)="dynamicTabsService.removeTab(dynTab, rlaD.isActive)"
                     (mouseup)="$event.button===1 && dynamicTabsService.removeTab(dynTab, rlaD.isActive)"
                     class="dynamic-tab-dropdown-item"
                     routerLinkActive="active"
                     #rlaD="routerLinkActive">

                    <ac-progress *ngIf="dynTab.isLoading" [center]="false" size="small"></ac-progress>

                    <div *ngIf="!dynTab.isLoading" class="flexRowParent dynamic-tab-dropdown-item-row">
                        <div *ngIf="dynTab.icon" class="icon-container">
                            <ac-svg [name]="dynTab.icon" [fillColor]="colors.gray" height="14px" width="14px"></ac-svg>
                        </div>

                        <div class="text"
                             parentSelectorForTitle=".dynamic-tab-dropdown-item"
                             (click)="dynamicTabDropdown.close()"
                             acLabel>{{dynTab.text}}</div>

                        <span (click)="dynamicTabsService.removeTab(dynTab, rlaD.isActive);"
                              class="close flexItem">x</span>
                    </div>

                </div>
            </ng-container>

        </div>
    </ac-drop-down>
</div>
