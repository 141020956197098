import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'ac-nav-button',
    templateUrl: './ac-nav-button.component.html',
    styleUrls: ['./ac-nav-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AcNavButtonComponent implements OnInit {

    @HostBinding('attr.dropdown') @Input() dropdown = false;
    @Input() dynamic = false;
    @Input() icon = '';
    @Output() close = new EventEmitter();
    @Input() isLoading = false;
    @Input() wrapContent = false;
    @Input() closeButton = false;
    @Input() parentClass = '';

    constructor() {
    }

    ngOnInit() {
    }

    onClose = () => {
        this.close.emit();
    };
}
