<div *ngIf="isMainButton" class="ac-page-dropdown-menu-main-button-container">
    <ac-button [matMenuTriggerFor]="menu.childMenu" [buttonId]="dropdownId || buttonText.split(' ').join('_') + '_dropdown_parent'" [ngDisabled]="ngDisabled || !(items | acFunc: atLeastOneChildIsEnabled)">
        <i *ngIf="icon" class="fa {{icon}} action-icon"></i>
        <span *ngIf="buttonText && buttonText !== ''">{{buttonText}}</span>
        <i class="fa fa-caret-down dropdown-icon"></i>
    </ac-button>
    <ac-drop-down-menu #menu [isMainButton]="false" [items]="items"></ac-drop-down-menu>
</div>

<!--dropdown children-->
<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="ac-dropdown-menu">
  <span *ngFor="let child of items" class="ac-dropdown-menu-items-container">
    <!-- Handle branch node menu items -->
    <span *ngIf="(child.enabled === undefined || !!child.enabled) && child.children && child.children.length > 0 && (child.children | acFunc: atLeastOneChildIsEnabled)">
       <button mat-menu-item [matMenuTriggerFor]="subMenu.childMenu" [attr.id]="child.id || child.displayName.split(' ').join('_').toLowerCase() + '_dropdown_button'">
           <label class="ac-dropdown-menu-item">
                <i *ngIf="child.icon" class="fa fa-{{child.icon}} action-icon"></i>
                <div *ngIf="child.svgName" class="menu-icon"><ac-svg [name]="child.svgName"></ac-svg></div>
                <span *ngIf="child.displayName && child.displayName !== ''" class="ac-dropdown-menu-item-display-name">{{child.displayName}}</span>
           </label>
        </button>
        <ac-drop-down-menu #subMenu [isMainButton]="false" [items]="child.children"></ac-drop-down-menu>
    </span>
      <!-- Handle leaf node menu items -->
    <span *ngIf="(child.enabled === undefined || !!child.enabled) && (!child.children || child.children.length === 0)">
      <button mat-menu-item (click)="child.onClick()" [attr.id]="child.id || child.displayName.split(' ').join('_').toLowerCase() + '_dropdown_button'">
          <label class="ac-dropdown-menu-item">
                <div *ngIf="child.svgName" class="menu-icon"><ac-svg [name]="child.svgName"></ac-svg></div>
                <i *ngIf="child.icon" class="fa fa-{{child.icon}} action-icon"></i>
                <span class="ac-dropdown-menu-item-display-name">{{child.displayName}}</span>
                <ng-template [ngTemplateOutletContext]="{item: child.templateData}" [ngTemplateOutlet]="child.template"></ng-template>
          </label>
      </button>
    </span>
  </span>
</mat-menu>
