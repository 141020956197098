import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';


@Component({
    selector: 'ac-expansion-panel',
    templateUrl: './ac-expansion-panel.component.html',
    styleUrls: ['./ac-expansion-panel.component.less'],
})
export class AcExpansionPanelComponent {

    @Input() expanded: boolean;
    @Input() authorized = true;
    @Input() classes: string;
    @Input() header: string;
    @Input() description: string;
    @Input() descriptionObject: any;
    @Input() descriptionTemplate: TemplateRef<any>;
    @Input() headerObject: any;
    @Input() headerTemplate: TemplateRef<any>;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() opened: EventEmitter<any> = new EventEmitter<any>();
}



