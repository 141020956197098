import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {AcTableActions, DynamicTabsService, GeneralService, SessionStorageService} from 'ac-infra';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {Store} from '@ngxs/store';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private navbarController: any;

    goTo = (stateName, extras?: NavigationExtras | any) => {
        this.router.navigate([stateName], extras);
    };

    constructor(private sessionStorageService: SessionStorageService,
                private router: Router,
                private store: Store,
                private generalService: GeneralService,
                private dynamicTabsService: DynamicTabsService,
                private filterState: FilterState) {

    }

    goToWithFilter = (stateName, filter) => {
        this.store.dispatch(new AcTableActions.SetResetPagingOnLoad());
        this.filterState.stateStartEventHandler(stateName, filter);

        // Reload page to make table refresh after filter change.
        this.router.navigate(['/drillDown'], {skipLocationChange: true}).then(() => {
            this.router.navigate([stateName]);
        });
    };

    redirectTo(uri: string){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([uri]));
    }

    goToDynamicTab = (pathsFragments: any | any[], title?, icon?) => {
        if (!Array.isArray(pathsFragments)) {
            pathsFragments = [pathsFragments];
        }
        this.dynamicTabsService.addTab(pathsFragments[0], title, icon);
        this.redirectTo(pathsFragments.join('/'));
    };

    gotoLastSavedState = () => {
        this.router.navigateByUrl('dashboard');
        // let lastState: any = this.getLastSavedState();
    };

    registerNavigationBar = (navbarCtrl) => {
        this.navbarController = navbarCtrl;
    };
}
