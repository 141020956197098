import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import * as _ from 'lodash';

import {NetworkDataService} from '../../../../network/services/data/network-data.service';
import {TopologyTreeComponent} from '../topology-tree.component';

import {CommonNotifiersService, GeneralService, ThrottleClass} from 'ac-infra';
import {TopologyTreeService} from '../topology-tree.service';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Func} from '../../../../../types/types';

@UntilDestroy()
@Component({
    selector: 'topology-tree-select',
    templateUrl: './topology-tree-select.component.html',
    styleUrls: ['./topology-tree-select.component.less', '../node-icon.less'],
})
export class TopologyTreeSelectComponent {

    @ViewChild('topologyTree', {static: true}) topologyTree: TopologyTreeComponent;

    @Output() selectEntityChange = new EventEmitter<any[]>();
    @Output() updateData = new EventEmitter();
    @Input() maxRecurrentTime = 30000;
    @Input() filterTreeEntitiesCallback: Func;
    @Input() excludeArtificialNodes = false;
    @Input() devicesOnly;
    @Input() restoreExpanded = true;
    @Input() searchBarPadding = false;
    @Input() useTreeState = true;
    @Input() fakeAllSelected = false;

    @Input() initialTopologySelection: any;
    @Input() validation;
    @Input() onCheck: () => any; // NOT IN USE
    @Input() treeFunctionsObject;
    @Input() ngDisabled = false;
    @Input() hideSearchButton = false;
    @Input() isAlarmForwarding;
    @Input() lazyWS = false;
    lazySwitched = false;
    topologyTreeData = [];
    snapshotTopologySelection;
    initialized = false;
    parameters: any = {};
    colors = GeneralService.statusColors;
    _tenantId: number;
    _entitiesNamesList: any;

    private refreshTree$: ThrottleClass;

    constructor(private networkDataService: NetworkDataService,
                private topologyTreeService: TopologyTreeService) {
        this.refreshTree$ = new ThrottleClass({
            callback: () => {
                this.topologyTreeFetchFunction();
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: this.maxRecurrentTime,
            debounce: 250,
            oneAtATime: true,
            maxDebounceTime: 500
        });
    }

    @Input() set tenantId(tenantId: any) {
        this._tenantId = tenantId;
        this.initialize();
    }

    @Input() set entitiesNamesList(entitiesNamesList: any) {
        this._entitiesNamesList = entitiesNamesList;
        this.initialized && this.refreshTree$.forceTick();
    }

    ngOnInit() {
        this.refreshTree$.updateOptions({maxRecurrentTime: this.maxRecurrentTime});
        this.setTreeUpdateMethods();
        this.initialize();
    }

    setTreeUpdateMethods() {
        if (this.lazyWS) {
            this.topologyTreeService.treeNodesUpdate$
                .pipe(untilDestroyed(this))
                .subscribe((tenantsIds: any[]) => {
                    if (this.lazyWS && this.topologyTreeData && tenantsIds && tenantsIds.length > 0) {
                        tenantsIds.forEach((tenantId) => {
                            this.topologyTreeService.updateTreeTenant(this.topologyTreeData, tenantId);
                        });
                        this.topologyTree.updateTree();
                    }
                });
        }

        CommonNotifiersService.finishedDataAndFilteredUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
            !this.lazyWS && this.refreshTree$.tick();
        });
    }

    initialize = (initialized = false) => {
        this.initialized = initialized;
        this.snapshotTopologySelection = initialized ? this.snapshotTopologySelection : this.initialTopologySelection;
        if (this.lazyWS) {
            this.topologyTreeData = null;
            setTimeout(() => this.topologyTreeFetchFunction());
        } else {
            this.refreshTree$.forceTick();
        }
    };

    topologyTreeFetchFunction() {
        if (!this._entitiesNamesList) {
            this.refreshTree$.oneAtATimeFinished();
            return;
        }

        if (this._tenantId && this._tenantId > -1) {
            this.parameters.filter = {id: [this._tenantId]};
        } else {
            delete this.parameters.filter;
        }

        const onSuccess = (topologyData) => {

            this.removeLinksNotFromTenantIfNeeded(topologyData);

            if (this.lazyWS) {
                this.topologyTreeService.setTreeNodes(topologyData);
                this.topologyTreeData = this.topologyTreeService.getTreeNodes(this.topologySelection);
            } else {
                this.topologyTreeData = _.cloneDeep(topologyData);
            }
            this.refreshTree$.oneAtATimeFinished();
        };

        if (this.lazyWS && this.topologyTreeService.initialized) {
            this.topologyTreeData = this.topologyTreeService.getTreeNodes(this.topologySelection);
            this.refreshTree$.oneAtATimeFinished();
        } else {
            this.networkDataService.getTopologyTreeData(onSuccess, this.parameters, this.validation, this._entitiesNamesList, this.filterTreeEntitiesCallback, this.excludeArtificialNodes);
        }
    }

    setSelect(state = true, loadFromState = false, overwriteSelection?) {

        const topologySelection = overwriteSelection || this.topologySelection;
        if (!topologySelection) {
            return;
        }

        const selectedNodes = !this.lazyWS ?
            this.fillInitialSelect(topologySelection, this.topologyTreeData) :
            this.lazyFillInitialSelect(topologySelection);

        this.topologyTree.setSelect(selectedNodes, state, loadFromState);
        this.initialized = true;
    }

    updateTopologySelection(topologySelection: any) {
        if (!topologySelection) {
            return;
        }

        _.forOwn(topologySelection, (selection, entityType) => {
            this.snapshotTopologySelection[entityType] = selection;
            if (this.snapshotTopologySelection[entityType + 's']) {
                delete this.snapshotTopologySelection[entityType + 's'];
            }
        });
    }


    takeSnapshotSelection(selectedNodes: any[]) {
        this.snapshotTopologySelection = selectedNodes.length > 0 ?
            selectedNodes.reduce((acc, cur) => {
                if (!acc[cur.entity]) {
                    acc[cur.entity] = [cur];
                } else {
                    acc[cur.entity].push(cur);
                }
                return acc;
            }, {}) : {tenant: []};
    }


    onUpdateData = ($event) => {
        this.topologyTreeData && this.topologyTreeData.length > 0 && this.setSelect();

        if (this.updateData.observers.length > 0) {
            this.updateData.next(this.topologyTreeData);
        }
    };

    onSelectChange(selectedNodes) {
        this.takeSnapshotSelection(selectedNodes);
        if (this.selectEntityChange.observers.length > 0) {
            if (this.lazyWS) {
                selectedNodes = selectedNodes.map(node => node.artificial ? this.topologyTreeService.getEntityChildren(node.id) : node);
            }
            this.selectEntityChange.next(selectedNodes);
        }
    }

    onSearchChange($event: string) {
        if ($event) {
            if (this.lazyWS) {
                this.setLazyWS(false);
            }
        } else {
            if (this.lazySwitched) {
                this.setLazyWS(true);
            }
        }
    }

    setLazyWS(state: boolean) {
        this.lazyWS = state;
        this.lazySwitched = !state;
        this.initialize(true);
    }

    iconForNode = (node: any) => node.artificial ? node.entity.slice(0, -1) : node.entity;

    getChildren = (treeNode) => this.topologyTreeService.getEntityChildren(treeNode.id);

    selectByEntityType(entityType: string, state) {
        this.setSelect(state, true, {[entityType]: null});
    }

    select(nodes, state: boolean) {
        this.topologyTree.setSelect(nodes, state);
    }

    resizeTree = () => this.topologyTree && this.topologyTree.resizeTree();

    get topologySelection() {
        return this.initialized ? this.snapshotTopologySelection : this.initialTopologySelection;
    }

    private removeLinksNotFromTenantIfNeeded = (topologyData) => {
        if (topologyData.length === 1 && topologyData[0].children && this._tenantId && this._tenantId !== -1) {

            _.forOwn(topologyData[0].children, (region) => {
                if (region.children) {
                    const links = region.children.find((child) => child.entity === 'links');
                    if (links && links.children) {
                        links.children = links.children.filter((link) => link.linkSrcTenantId === this._tenantId && link.linkDstTenantId === this._tenantId);

                        if (links.children.length === 0) {
                            const index = region.children.indexOf(links);
                            if (index !== -1) {
                                region.children.splice(index, 1);
                            }
                        }
                    }
                }

            });
        }
    };

    private fillSelectionFromTree(selectedEntities) {

        const entityHash = {};

        // tenants
        const tenants = selectedEntities.tenant;
        tenants && tenants.forEach((tenant) => {
            const treeTenantNode = this.topologyTreeData.find((treeTenant) => treeTenant.id === tenant.id);
            if (treeTenantNode) {
                tenant.children = treeTenantNode.children;
            }
        });

        // regions
        const regions = selectedEntities.region;
        regions && regions.forEach((region, index) => {
            let treeTenantNode = entityHash[region.tenantId];
            if (!treeTenantNode) {
                treeTenantNode = this.topologyTreeData.find((treeTenant) => treeTenant.id === region.tenantId);
                entityHash[treeTenantNode.id] = treeTenantNode;
            }

            treeTenantNode &&
            treeTenantNode.children.forEach((treeRegion) => {
                if (treeRegion.id === region.id) {
                    regions[index] = treeRegion;
                }
            });
        });

        // artificial
        const artificial = [selectedEntities.devices, selectedEntities.sites, selectedEntities.links];
        artificial.forEach((artificialNodes: any[]) => {
            artificialNodes && artificialNodes.forEach((artificialNode) => {
                const realEntity = artificialNode.children[0];
                let treeTenantNode = entityHash[realEntity.tenantId];

                if (!treeTenantNode) {
                    treeTenantNode = this.topologyTreeData.find((treeTenant) => treeTenant.id === realEntity.tenantId);
                    entityHash[treeTenantNode.id] = treeTenantNode;
                }

                const treeRegionNode = treeTenantNode.children.find((treeRegion) => treeRegion.id === realEntity.regionId);

                treeRegionNode.children.forEach(regionNode => {
                    if (regionNode.id === artificialNode.id) {
                        artificialNode.children = regionNode.children;
                    }
                });
            });
        });
    }

    private lazyFillInitialSelect(selectedEntities: any) {
        let isEmpty = false;
        const selectedItems = [];
        const lazyNodes = {};

        _.forOwn(selectedEntities, (selectedNodes: any[], entityType: string) => {
            if (!selectedNodes) {
                return;
            }

            isEmpty = true;
            selectedNodes.forEach((node, i) => {
                if (node.tenantId && !lazyNodes[node.tenantId]) { // add regions to tenant
                    const tenant = this.topologyTreeData.find(treeTenant => treeTenant.id === node.tenantId);
                    lazyNodes[node.tenantId] = tenant;
                }

                if (entityType === 'tenant') { // tanant
                    selectedNodes[i] = this.topologyTreeData.find(tenantNode => tenantNode.id === node.id);
                } else if (entityType === 'region' && lazyNodes[node.tenantId]) {
                    selectedNodes[i] = lazyNodes[node.tenantId].children.find(regionNode => regionNode.id === node.id);
                }

                selectedItems.push(...selectedNodes);
            });

        });
        return (selectedItems.length > 0 || isEmpty) ? selectedItems : null;
    }

    private fillInitialSelect = (selectedEntities, entities: any[]): Array<string | number> => {
        let isEmpty = false;
        const selectedItems = [];

        this.fillSelectionFromTree(selectedEntities);

        _.forOwn(selectedEntities, (nodes: any[], entityType: string) => {
            if (nodes) {
                isEmpty = true;
                selectedItems.push(...nodes);
            } else if (!nodes && entities) {
                const nodesByType = [];

                this.extractNodesByEntity(entities, entityType, nodesByType);
                selectedItems.push(...nodesByType);
            }
        });

        return (selectedItems.length > 0 || isEmpty) ? selectedItems : null;
    };

    private extractNodesByEntity(nodes: any[], entity: string, nodeList) {
        if (!nodes) {
            return;
        }

        nodes.forEach((node) => {
            if (node.entity === entity || node.entity === entity + 's') {
                nodeList.push(node);
            }

            if (node.artificial) {
                return;
            }

            this.extractNodesByEntity(node.children, entity, nodeList);
        });
    }
}
