import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'flex-pool-update-priority-level-dialog',
    title: 'Flex Pool Priority',
    formSettings: {valid: true, touched: true},
    submitButtonText: 'Select',
    cancelButtonText: 'Close',
    width: 440
})
@Component({
    selector: 'flex-pool-update-priority-level',
    templateUrl: './flex-pool-update-priority-level-dialog.component.html',
})
export class FlexPoolUpdatePriorityLevelDialogComponent {

    flexPool: any;
    priorityOptions = [
        {priority: 'Low'},
        {priority: 'Normal'},
        {priority: 'High'}
    ];

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.flexPool = this.dialogConfig.dialogData.entity.flexPool;
    }
}


