import {Injectable} from '@angular/core';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import {Validator} from 'ip-num/Validator';

@Injectable({
    providedIn: 'root'
})
export class SchemaHelperService {
    ipv4Format;
    ipv6Format;

    constructor() {
        const ajv: any = this.ajvInstance;
        addFormats(ajv);
        this.ipv4Format = ajv.formats.ipv4;
        this.ipv6Format = Validator.IPV6_RANGE_PATTERN;
    }

    get ajvInstance(){
        return new Ajv({allErrors: true, verbose: true, removeAdditional: true, unicodeRegExp: false, strictTypes: false, strict: false}); // options can be passed
    }

    split(fromIpAddress: any, toIpAddress: any, splitBy: string) {
        return {
            fromStart: fromIpAddress.substring(0, fromIpAddress.lastIndexOf(splitBy)),
            toStart: toIpAddress.substring(0, toIpAddress.lastIndexOf(splitBy)),
            fromEnd: fromIpAddress.substring(fromIpAddress.lastIndexOf(splitBy) + 1),
            toEnd: toIpAddress.substring(toIpAddress.lastIndexOf(splitBy) + 1)
        };
    }

    validateIPAddress = (ipAddress, validate: 'ipv4' | 'ipv6' | 'both' = 'ipv4') => {
        const isIPv4 = ['ipv4', 'both'].includes(validate) && this.ipv4Format.test(ipAddress);
        const isIPv6 = ['ipv6', 'both'].includes(validate) && this.ipv6Format.test(ipAddress);
        return isIPv4 || isIPv6;
    };

    validateSerialNumber = (serialNumber) => /^[0-9]*$/.test(serialNumber);

    buildErrorItem = ({
        instancePath,
        inputName,
        message = undefined,
        isArray = false,
        index = undefined,
        keyword = undefined,
        limit = undefined
    }) => ({
        instancePath,
        inputName,
        messageAux: message,
        isArray,
        index,
        keyword: keyword || 'aux',
        params: {limit}
    });
}
