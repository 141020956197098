import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {WSMessage} from '../../utils/ws-messages';
import {HashedObjectMap} from '../../utils/hashed-object-map';

@Injectable({
    providedIn: 'root'
})
export class WSEntities extends HashedObjectMap {

    private WSEntitiesUpdateFinishedSubject: Subject<WSMessage> = new Subject();
    WSEntitiesUpdateFinished$: Observable<WSMessage> = this.WSEntitiesUpdateFinishedSubject.asObservable();

    constructor() {
        super({addEntityType: true});
    }

    executeWSEntitiesUpdateFinished = (message) => {
        this.WSEntitiesUpdateFinishedSubject.next(message);
    };
}
