import {Component, Input, ViewChild} from '@angular/core';

import * as _ from 'lodash';

import {SitesRestService} from '../../services/apis/sites-rest.service';
import {TenantsRestService} from '../../services/apis/tenants-rest.service';
import {GroupsRestService} from '../../services/apis/groups-rest.service';
import {DevicesRestService} from '../../services/apis/devices-rest.service';
import {EndpointsRestService} from '../../services/apis/endpoints-rest.service';
import {ConstantStringsService} from '../../../common/utilities/constant-strings.service';
import {AuthorizationService} from 'ac-infra';
import {authGroup} from '../../../common/utilities/session-helper.service';
import {TopologyTreeSelectComponent} from '../topology-tree/topology-tree-select/topology-tree-select.component';
import {RegionsRestService} from '../../services/apis/regions-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {LinksRestService} from '../../services/apis/links-rest.service';


@Component({
    selector: 'tree-view-panel',
    templateUrl: './tree-view-panel.component.html',
    styleUrls: ['./tree-view-panel.component.less']
})
export class TreeViewPanelComponent {

    @ViewChild('topologyTreeSelect', {static: false}) topologyTreeSelect: TopologyTreeSelectComponent;
    @Input() model: any;
    @Input() additionalSettings: any;
    @Input() treeMode = true;// when false a link select box will be shown
    @Input() dialogConfig;
    @Input() filterTreeEntitiesCallback;
    @Input() type: 'threshold' | 'alarmRule' | 'topology';
    treeDetailsList;
    checkedItemsObject;
    isMonitor = AuthorizationService.isMonitor();
    linksList;
    devicesList;

    entityToArray = {
        device: 'attachedDevices',
        link: 'attachedLinks',
        site: 'attachedSites',
    };

    deviceOrCustomerTeams;
    linkOrCustomerProvider;

    constructor(private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                private sitesRestService: SitesRestService,
                private linksRestService: LinksRestService,
                private devicesRestService: DevicesRestService,
                private groupsRestService: GroupsRestService,
                private endpointsRestService: EndpointsRestService) {}

    ngOnInit(){
        this.deviceOrCustomerTeams = ConstantStringsService.deviceOrCustomerTeams;
        this.linkOrCustomerProvider = ConstantStringsService.linkOrCustomerProvider;
        this.linksList = this.linksRestService.getAllEntities();
        this.devicesList = this.devicesRestService.getAllEntities().filter((device) => device.familyType === 'TEAMS');

        this.checkedItemsObject = {
            device: this.devicesRestService.getAllEntities(this.model.attachedDevices || []),
            link: this.linksRestService.getAllEntities(this.model.attachedLinks || []),
            site: this.sitesRestService.getAllEntities(this.model.attachedSites || []),
        };
    }

    onTopologyDropDownOpen = ($event: boolean) => {
        $event && this.topologyTreeSelect.resizeTree();
    };

    qoeOnCheckFn = (topologySelection) => {
        this.defaultAttachments();

        this.fillRuleBySelection(topologySelection);

        if(this.treeMode && (this.type === 'alarmRule' || this.type === 'threshold')){
            this.addIncludedEndpoints();
        }
    };

    addIncludedEndpoints = () => {
        const attachedSites = this.model.attachedSites || this.additionalSettings.attachedSites;
        if (attachedSites && attachedSites.length > 0) {
            const filter = {siteId: _.cloneDeep(attachedSites)};

            if(this.type === 'threshold'){
                this.treeDetailsList.endpoints = 'fetch';
                this.dialogConfig.submitButtonProcessing = true;
            }else{// alarmRule
                this.model.attachedEndpoints = null;
                this.dialogConfig.submitButtonDisabled = true;
            }

            const onSuccess = (value: RestResponseSuccess) => {
                this.model.attachedEndpoints = value.data.endpoints.map((item) => item.id);
                if(this.type === 'threshold'){
                    this.treeDetailsList.endpoints = this.model.attachedEndpoints.length;
                    this.dialogConfig.submitButtonProcessing = false;
                }else{// alarmRule
                    this.dialogConfig.submitButtonDisabled = !this.model.attachedEndpoints;
                }
            };
            this.endpointsRestService.getFilteredEndpoints({filter}, onSuccess, () => null);
        }
    };

    defaultAttachments = () => {
        this.treeDetailsList = {devices: 0, links: 0};

        this.model.attachedDevices = [];
        this.model.attachedLinks = [];

        if(this.type === 'topology' || this.type === 'alarmRule'){
            this.treeDetailsList.sites = 0;
            this.model.attachedSites = [];
        }

        if(this.type === 'threshold' || this.type === 'alarmRule'){
            this.treeDetailsList.endpoints = 0;
            this.model.attachedEndpoints = [];
        }
    };

    fillRuleBySelection(topologySelection: any[]) {
        topologySelection.forEach((node) => {

            const arrayName = this.entityToArray[node.entity];
            if (arrayName) {
                (this.model[arrayName] || this.additionalSettings[arrayName]).push(node.id);

                if (this.type !== 'threshold' || (this.type === 'threshold' && node.entity !== 'site')) {
                    const TreeDetailsEntity = node.entity + 's';
                    this.treeDetailsList[TreeDetailsEntity]++;
                }

                if (this.type === 'alarmRule' && node.elementsCount) {
                    const endpointCount = node.elementsCount.endpoints || 0;
                    this.treeDetailsList.endpoints += endpointCount;
                }

            }

            node.children && this.fillRuleBySelection(node.children);
        });
    }
}



