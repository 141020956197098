import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as jquery from 'jquery';
import {SessionStorageService, ThrottleClass, WSEntities} from 'ac-infra';
import {Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class GeoSelectionService {

    isMapActive = false;
    selectedDevices = [];
    selectedLinks = [];
    selectedClusters = [];
    latestAllLinksLayers: any = {};
    private redrawLinks$: ThrottleClass;
    private refreshClustersSubject: Subject<any> = new Subject<any>();
    refreshClusters$ = this.refreshClustersSubject.asObservable();

    private networkSelectionChangedSubject = new Subject<any>();
    networkSelectionChanged$ = this.networkSelectionChangedSubject.asObservable();

    constructor(private wsEntities: WSEntities) {
        this.redrawLinks$ = new ThrottleClass({
            callback: () => {
                this.redrawLinks();
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000,
            debounce: 250,
            maxDebounceTime: 500,
            oneAtATime: true
        });
    }

    setMapStatus = (status) => {
        this.isMapActive = status;
    };

    reselectAllSelected = (allDeviceLayers, allLinksLayers) => {
        this.latestAllLinksLayers = {};
        this.forOwn(allLinksLayers.getLayers(), (layer) => {
            if (layer.getElement() !== undefined) {
                this.latestAllLinksLayers[layer.link.id] = layer;
            }
        });

        this.forOwn(this.selectedDevices, (device, index) => {
            const newDevice = allDeviceLayers[device.device.id];
            if (newDevice) {
                this.selectedDevices[index] = newDevice;
                this.selectMarker(newDevice, true);
            }
        });

        this.forOwn(this.selectedLinks, (link, index) => {
            const newLink = allLinksLayers[link.link.id];
            if (newLink) {
                this.selectedLinks[index] = newLink;
                this.selectLink(newLink, true);
            }
        });

        this.redrawClusterIcons();
        // this.broadcastSelection();
    };

    selectMarker = (marker, preventRefresh = undefined) => {
        if (!marker.device && marker.__parent.innerLinks && marker.__parent.innerLinks.length > 0) {
            marker.__parent.innerLinks.forEach((link) => {
                this.selectLink({link}, true);
            });
        }

        this.addSelection(marker, '_icon', this.selectedDevices);
        if (!preventRefresh) {
            this.broadcastSelection();
            this.redrawClusterIcons();
        }
    };

    unSelectMarker = (marker, preventRefresh = undefined) => {
        if (this.isMarkerSelected(marker)) {
            this.removeSelection(marker, '_icon', this.selectedDevices);
            if (!preventRefresh) {
                this.redrawClusterIcons();
                this.broadcastSelection();
            }
        }
    };

    selectLink = (link, preventRefresh = undefined) => {
        const innerLinks = link.innerLinks || [link.link];
        this.forOwn(innerLinks, (innerLink) => {
            if (innerLink.filtered) {
                this.addSelection({link: innerLink}, 'tooltip', this.selectedLinks);
            }
        });
        if (!preventRefresh) {
            this.broadcastSelection();
            this.redrawClusterIcons();
            this.redrawLinks$.forceTick();
        }
    };

    unSelectLink = (link, preventRefresh = undefined) => {
        if (this.isLinkSelected(link)) {
            this.removeLinkSelection(link);
            if (!preventRefresh) {
                this.broadcastSelection();
                this.redrawClusterIcons();
                this.redrawLinks$.forceTick();
            }
        }
    };

    isLinkSelected = (item) => {
        return this.selectedLinks.find((selectedLink) => selectedLink.link.id === item.link.id);
    };

    isMarkerSelected = (item) => {
        return this.selectedDevices.find((selectedDevice) => selectedDevice.device.id === item.device.id);
    };

    isClusterSemiSelected = (children) => {
        let isSelected = false;
        children.forEach((child) => {
            isSelected = isSelected || this.isItemSelected(child);
        });
        return isSelected;
    };

    isItemSelected = (item) => {
        switch (this.getType(item)) {
            case 'Cluster':
                return !!this.isClusterSemiSelected(item.getAllChildMarkers());
            case 'Array':
                return !!this.isClusterSemiSelected(item);
            case 'Marker':
                return !!this.isMarkerSelected(item);
            case 'Link':
                return !!this.isLinkSelected(item.link ? item : {link: item});
        }
    };

    toggleItem = (item) => {
        const selected = this.isItemSelected(item);
        switch (this.getType(item)) {
            case 'Link':
                item = item.link ? item : {link: item};
                return selected ? this.unSelectLink(item) : this.selectLink(item);
            default:
                return selected ? this.unSelectMarker(item) : this.selectMarker(item);
        }
    };

    getSelectedClusters = () => this.selectedClusters;

    clearSelection = (preventBroadcast = undefined) => {
        this.removeAllSelection(this.selectedDevices, '_icon');
        this.removeAllSelection(this.selectedClusters, '_icon');
        this.removeAllSelection(this.selectedLinks, '_tooltip');

        this.resetSelection();

        if (!preventBroadcast) {
            this.broadcastSelection();
        }

        this.redrawClusterIcons();
        this.redrawLinks$.forceTick();
    };

    checkItem = (item) => {
        const res: any = {};
        res.type = this.getType(item);
        res.selected = !!this.isItemSelected(item);
        return res;
    };


    forOwn = (arr, func) => {
        const keys = Object.getOwnPropertyNames(arr);
        const index = keys.indexOf('length');
        if (index > -1) {
            keys.splice(index, 1);
        }

        for (let i = 0; i <= keys.length - 1; i++) {
            func(arr[keys[i]], keys[i]);
        }
    };


    private removeLinkSelection = (link) => {
        const index = this.selectedLinks.findIndex((selected) => selected.link.id === link.link.id);

        this.toggleSelection(link, 'tooltip', false);
        this.selectedLinks.splice(index, 1);
    };

    /** :: HELPER FUNCTION HERE :: **/

    private getType = (item) => {
        if (item.getAllChildMarkers) {
            return 'Cluster';
        } else if (Array.isArray(item)) {
            return 'Array';
        } else if (item.device) {
            return 'Marker';
        } else {
            return 'Link';
        }
    };

    private broadcastSelection = () => {
        this.isMapActive && setTimeout(() => {
            const devices = _.cloneDeep(this.selectedDevices.map(this.mapByItemName('device')));
            const links = _.cloneDeep(this.selectedLinks.map(this.mapByItemName('link')));

            SessionStorageService.setData('geoSelection', {
                devices: devices.map(entity => entity.id),
                links: links.map(entity => entity.id)
            });
            this.networkSelectionChangedSubject.next({devices, links, selectedClusters: this.selectedClusters});
        });
    };

    private removeAllSelection = (selection, fieldName) => {
        selection.forEach((item) => {
            this.toggleSelection(item, fieldName, false);
        });
    };

    private addSelection = (selectedItem, fieldName, selection) => {
        this.toggleSelection(selectedItem, fieldName, true);

        if (!this.checkItem(selectedItem).selected) {
            selection.push(selectedItem);
        }
    };

    private removeSelection = (selectedItem, fieldName, selection) => {
        const device = fieldName === '_icon' ? 'device' : null;
        const index = selection.findIndex(this.byItemID(selectedItem, device));

        this.toggleSelection(selectedItem, fieldName, false);
        selection.splice(index, 1);
    };

    private toggleSelection = (item, fieldName, mode) => {
        try {
            if (item[fieldName]) {
                if (mode) {
                    jquery(item[fieldName]).addClass('selected');
                } else {
                    jquery(item[fieldName]).removeClass('selected');
                }
            }
        } catch (e) {
        }
    };

    private mapByItemName = (name) => (item) => item[name];


    private byItemID = (item, optional) => (value) => optional !== null ?
        value[optional].id === item[optional].id :
        value.id === item.id;

    private redrawClusterIcons = () => {
        this.refreshClustersSubject.next(null);
    };

    private redrawLinks = () => {
        this.forOwn(this.latestAllLinksLayers, (link) => {
            const linkId = link.link.id;
            const tooltip = this.latestAllLinksLayers[linkId].getTooltip();
            if (tooltip) {
                if (this.isItemSelected(this.latestAllLinksLayers[linkId].innerLinks || this.latestAllLinksLayers[linkId].link)) {
                    jquery(tooltip.getElement()).addClass('selected');
                } else if (tooltip) {
                    jquery(tooltip.getElement()).removeClass('selected');
                }
            }
        });
        this.redrawLinks$.oneAtATimeFinished();
    };

    private resetSelection = () => {
        this.selectedDevices = [];
        this.selectedLinks = [];
        this.selectedClusters = [];
    };

    initiateSelection() {
        const geoSelection = SessionStorageService.getData('geoSelection');
        if (!geoSelection || !geoSelection.devices?.length && !geoSelection.links?.length) {
            this.clearSelection();
        } else {
            this.selectedLinks = (this.wsEntities.getAnyEntitiesByIds(geoSelection.links) as any[]).map(link => ({link}));
            this.selectedDevices = (this.wsEntities.getAnyEntitiesByIds(geoSelection.devices) as any[]).map(device => ({device}));
            this.broadcastSelection();
        }
    }
}
