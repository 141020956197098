import {Injectable} from '@angular/core';

import * as _ from 'lodash';

import {AcDialogRef, LoggerService, PromiseService} from 'ac-infra';
import {PMRestService} from './pm-rest.service';
import {RestResponseFailure, RestResponseSuccess} from '../../common/server-actions/rest';
import {Actions} from '../../common/server-actions/actions';
import {PmProfilesDialogComponent} from '../dialogs/pm-profiles-dialog/pm-profiles-dialog.component';

@Injectable({providedIn: 'root'})
export class PerformanceMeasurementsActionsService extends Actions {

    fileSaver;

    constructor(private performanceMeasurementsService: PMRestService,
                public loggerService: LoggerService) {
        super({entityName: 'profile', entityService: performanceMeasurementsService});
        this.fileSaver = require('file-saver');
    }


    addProfile = (isTemplate) => {
        const dialogRef = this.acDialogService.open();
        this.performanceMeasurementsService.getDictionaries().then((fetchedData: any) => {
            this.buildDataForDialog(dialogRef, {dictionaries: fetchedData.data.dictionaries, isTemplate}, false);
        }).catch(()=>{
            dialogRef.close();
        });
    };

    editProfile = (profileId, isTemplate) => {
        const dialogRef = this.acDialogService.open();
        const dictionariesPromise = this.performanceMeasurementsService.getDictionaries();
        const profilePromise = this.performanceMeasurementsService.getProfileById(profileId);

        Promise.all([dictionariesPromise, profilePromise]).then(([dictionaries, profile]: any[]) => {
            this.buildDataForDialog(dialogRef, {dictionaries: dictionaries.data.dictionaries, profile: profile.data, isTemplate}, true);
        }).catch(() => dialogRef.close());
    };

    deleteProfiles = (profiles) => {
        this.delete({entityArray: profiles});
    };

    exportDevicePMData = (filter, deviceDetails) => {
        const pmDataFilter = {
            deviceId: deviceDetails.id,
            from: filter.timeRange.from,
            to: filter.timeRange.to,
            paramIds: [],
            indexes: []
        };

        _.forOwn(filter.deviceSingularPMs, (topic, topicId) => {
            pmDataFilter.paramIds = pmDataFilter.paramIds.concat(topic.parameters);

            if (topic.indexes && topic.indexes.length > 0) {
                pmDataFilter.indexes.push({topicId: parseInt(topicId, 10), topicIndexes: topic.indexes});
            }
        });


        const onSuccess = (response: RestResponseSuccess) => {
            const fileName = 'PM_Data_' + deviceDetails.name + '_' + Date.now() + '.xml';
            this.fileSaver.saveAs(new Blob([response.data]), fileName);

            this.logger.info('PM Data of device: ' + deviceDetails.id + ' saved successfully');
        };
        const onFailure = () => {
            this.logger.error('Failed to save PM Data of device: ' + deviceDetails.id);
        };

        this.performanceMeasurementsService.exportDevicePMData(onSuccess, onFailure, pmDataFilter);
    };

    getProfile = (success, failure, profileId) => {
        const onSuccess = (response: RestResponseSuccess) => {
            success(response.data, 'profile');
        };
        const onFailure = (error: RestResponseFailure) => {
            failure(error, 'profile');
        };

        this.performanceMeasurementsService.getProfileById(profileId, onSuccess, onFailure);
    };

    buildDataForDialog = (dialogRef, fetchedData, isEdit) => {
        fetchedData.dictionaries = this.isNonEmptyArray(fetchedData.dictionaries) ?
            fetchedData.dictionaries[0] : {dictionaryTopics: [], id: -1};

        let profile;

        if (isEdit) {
            const dictionaryTopics = fetchedData.dictionaries.dictionaryTopics;

            dictionaryTopics.forEach((topic) => {
                const oldTopic = fetchedData.profile.profileTopics.find((oTopic) => oTopic.id === topic.id);

                if (oldTopic) {
                    const originalDisplayName = topic.displayName;
                    _.merge(topic, oldTopic);
                    topic.displayName = originalDisplayName;
                } else {
                    topic.parameters.forEach((parameter) => {
                        parameter.isPolled = false;
                    });
                }
            });
            fetchedData.profile.profileTopics = dictionaryTopics;
            profile = fetchedData.profile;
        } else {
            profile = {
                name: '', description: '', attachedDevices: [], tenantId: undefined,
                dictionaryId: fetchedData.dictionaries.id, profileTopics: fetchedData.dictionaries.dictionaryTopics
            };
        }

        this.openProfileDialog(profile, isEdit, fetchedData.isTemplate, dialogRef);
    };

    openProfileDialog = (profile, isEdit, isTemplate, dialogRef: AcDialogRef) => {
        const id = profile.id;
        profile = _.cloneDeep(profile);

        const onSubmit = () => {
            const defer = PromiseService.defer();

            const newProfile = this.generateProfileObject(profile, isEdit, isTemplate);
            const onSuccess = () => {
                this.actionSuccessful(profile, isEdit, dialogRef);
                defer.resolve();
            };
            const onFailure = (error: RestResponseFailure) => {
                this.actionFailed(isEdit, error);
                defer.reject();
            };

            if (!isEdit) {
                this.performanceMeasurementsService.add(onSuccess, onFailure, newProfile);
            } else {
                this.performanceMeasurementsService.edit(onSuccess, onFailure, newProfile, id);
            }
            return defer.promise;
        };

        this.acDialogService.setDialogContent(dialogRef, PmProfilesDialogComponent, {
            onSubmit,
            dialogData: {entity: profile, isEdit, isTemplate}
        });
    };

    generateProfileObject = (profile, isEdit, isTemplate) => {
        const newProfileObject: any = {profileTopics: []};
        const attributesNeeded = [
            'id', 'name',
            'description', 'dictionaryId',
            'createDataFile', 'sendEventPerInterval',
            'isDefault'
        ];

        if (!isTemplate) {
            attributesNeeded.push('attachedDevices');
        }

        if (!isTemplate && !isEdit) {
            attributesNeeded.push('tenantId');
        }

        if (isTemplate && !isEdit) {
            newProfileObject.tenantId = -1;
        }

        attributesNeeded.forEach((attribute) => {
            if (!_.isUndefined(profile[attribute])) {
                newProfileObject[attribute] = profile[attribute];
            }
        });

        newProfileObject.profileTopics = this.returnFilteredTopicsArray(profile.profileTopics);

        return newProfileObject;
    };

    returnFilteredTopicsArray = (profileTopics) => {
        const newProfileTopics = [];

        profileTopics.forEach((topic) => {
            const newTopic: any = {id: topic.id, ruleType: topic.ruleType, parameters: []};

            if (topic.ruleType === 'Index' && topic.indexRule) {
                newTopic.indexRule = topic.indexRule;
            } else if (topic.ruleType === 'Index' && !topic.indexRule) {
                delete newTopic.ruleType;
            }

            if (topic.ruleType === 'Name' && topic.nameRule) {
                newTopic.nameRule = topic.nameRule;
            } else if (topic.ruleType === 'Name' && !topic.nameRule) {
                delete topic.ruleType;
            }

            topic.parameters.forEach((parameter) => {
                newTopic.parameters.push({
                    id: parameter.id,
                    isPolled: parameter.isPolled
                });
            });

            newProfileTopics.push(newTopic);
        });

        return newProfileTopics;
    };

    isNonEmptyArray = (item) => Array.isArray(item) && item.length > 0;

    actionSuccessful = (profile, isEdit, dialogRef: AcDialogRef) => {
        const logMessage = isEdit ? 'profile with id of ' + profile.id + ' edited successfully' : 'profile added successfully';

        this.acDialogService.closeDialog(dialogRef);
        this.refresh({showLoader: true});

        this.logger.info(logMessage);
    };

    actionFailed = (isEdit, error) => {
        const actionName = isEdit ? 'edit' : 'add';

        this.logger.error('Failed to ' + actionName + ' the profile');
        this.logger.error('Reason: ' + JSON.stringify(error.error));
    };
}
