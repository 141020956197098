<ac-form *ngIf="dialogConfig.ready"
         [formModel]="profile"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [isViewMode]="!isAuthorized"
         [ignoreRequiredPaths]="['.profileTopics']"
         formModelName="profile">

    <div class="pm-profiles-form">
        <div class="pm-upper-inputs" [class.no-side-panel]="isTemplate">
            <ac-layout-panel class="fullContainer">
                <div class="flexColumnParent">
                    <ac-input-container label="Name" [sideMargin]="false">
                        <ac-textfield [(acModel)]="profile.name"
                                      [attr.alternativeName]="'profile.name'"
                                      textFieldId="pm-name">
                        </ac-textfield>
                    </ac-input-container>

                    <ac-input-container label="Description" [sideMargin]="false">
                        <ac-textarea [(acModel)]="profile.description"
                                     [attr.alternativeName]="'profile.description'"
                                     textAreaId="pm-description">
                        </ac-textarea>
                    </ac-input-container>
                </div>
            </ac-layout-panel>
            <ac-layout-panel class="fullContainer">
                <div class="flexColumnParent pm-upper-checkboxes">
                    <ac-checkbox [(acModel)]="profile.isDefault" labelText="Default"
                                 [ngDisabled]="isExistingDefaultProfile"
                                 checkboxId="pm-default">
                    </ac-checkbox>

                    <ac-checkbox [(acModel)]="profile.createDataFile" labelText="Create Data File"
                                 checkboxId="pm-create-data">
                    </ac-checkbox>

                    <ac-checkbox [(acModel)]="profile.sendEventPerInterval" labelText="Send Event Per Interval"
                                 checkboxId="pm-send-event">
                    </ac-checkbox>
                </div>
            </ac-layout-panel>
            <ac-layout-panel class="fullContainer" *ngIf="!isTemplate" style="width: 250px">
                <div class="flexColumnParent">
                    <network-tenant-region-selection [model]="profile"
                                                     (acTenantChanged)="profile.tenantId = $event; onTenantsSelectionChange()"
                                                     tenantAlternativeName="profile.tenantId"
                                                     [ngDisabledTenant]="isEdit"
                                                     [additionalSettings]="additionalSettings"
                                                     [isEdit]="isEdit"
                                                     [ignoreTreeSelection]="true"
                                                     [showRegion]="false">
                    </network-tenant-region-selection>

                    <ac-form-group titleText="Attachments">
                        <ac-drop-down (openStatusChanged)="onTopologyDropDownOpen($event)" [parentClass]="'attachments-devices-tree'">

                            <a class="ac-dropdown-toggle" parent>Select Devices</a>
                            <div class="attachments-devices-tree-container" tooltip>
                                <topology-tree-select #topologyTreeSelect
                                                      (selectEntityChange)="topologyTreeOnCheckFn($event)"
                                                      [entitiesNamesList]="['tenants', 'regions', 'devices']"
                                                      [initialTopologySelection]="checkedItemsObject"
                                                      [ngDisabled]="!isAuthorized"
                                                      [searchBarPadding]="true"
                                                      [tenantId]="additionalSettings.tenantId || profile.tenantId"
                                                      [validation]="validationObj"
                                ></topology-tree-select>
                            </div>
                        </ac-drop-down>

                        <div class="number-of-attachments">Manual {{profile.attachedDevices.length || 0}}</div>
                    </ac-form-group>
                </div>
            </ac-layout-panel>
        </div>


        <statistics-pm-parameters [isDialog]="true"
                                  [isDisabled]="!isAuthorized"
                                  [isSingular]="true"
                                  [profileTopics]="profileTopics"
                                  [sidePanel]="isTemplate ? undefined : 'index'">
        </statistics-pm-parameters>
    </div>

</ac-form>
