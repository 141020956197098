import {Injectable} from '@angular/core';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {RegionsRestService} from '../apis/regions-rest.service';
import {FilterUtilsService} from '../filter-utils.service';
import {MessagesService} from '../../../common/utilities/messages.service';
import {NetworkRegionDialogComponent} from '../../dialogs/network-region-dialog/network-region-dialog.component';
import {Actions} from '../../../common/server-actions/actions';
import {RestResponseSuccess} from "../../../common/server-actions/rest";

@Injectable({providedIn: 'root'})
export class RegionsActionsService extends Actions {

    constructor(private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService,
                public messagesService: MessagesService,
                private filterUtilsService: FilterUtilsService) {
        super({entityName: 'region', entityService: regionsRestService, isWsEntity: true});
    }

    openRegionDialog = ({tenantId = undefined, regionId = undefined, isEdit = false}) => {
        if (this.tenantsRestService.getAllEntities().length === 0) {
            this.acDialogService.info('Can not add regions when there are no tenants.');
            return;
        }

        const dialogRef = this.acDialogService.open();

        if(isEdit){
            const success = (response: RestResponseSuccess) => {
                this.prepareRegionDialog(isEdit, response.data, dialogRef);
            }
            this.regionsRestService.getById({success, extendParameters: true, skipPopulateFilter: true, id: regionId});
        }else{
            const region = { tenantId, autoDetectionAddressSpace: { subnetMasks: [] } };
            this.prepareRegionDialog(isEdit, region, dialogRef);
        }
    };

    prepareRegionDialog = (isEdit, region, dialogRef) => {
        const id = region.id;
        const dialogData = {entity: region, isEdit, selectedTopologyItem: this.filterUtilsService.getSelectedTopologyItem()};
        const serverCallback = (onSuccess, onFailure) =>{
            if(isEdit){
                region.description =  region.description || '';
                this.regionsRestService.edit(onSuccess, onFailure, region, id);
            }else{
                this.regionsRestService.add(onSuccess, onFailure, region);
            }
        };
        const dialogComponentType = NetworkRegionDialogComponent;
        const dialogConfig = {
            cancelButtonText: 'Close',
            id: 'region-dialog',
            title: 'Region Details',
            width: 500,
        };

        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogConfig, dialogRef});
    }

    deleteRegion = (regionsArray) => {
        const confirmMessage = this.messagesService.getConfirmMessage({entityName: 'region', entitiesArray:  regionsArray,
            messagePostfix: '<br><br><b class="warning-note">Note: deleting region might have an impact on its devices traffic!<b>'});

        this.delete({entityArray: regionsArray, confirmMessage});
    };

}
