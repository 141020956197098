import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'sync-links-dialog',
    title: 'Populate Links',
    submitButtonText: 'Populate',
    cancelButtonText: 'Close',
    height: 130,
    width: 300,
})
@Component({
    selector: 'sync-links-dialog',
    templateUrl: './sync-links-dialog.component.html',
    styleUrls: ['./sync-links-dialog.component.less']
})
export class SyncLinksDialogComponent {
    parameters;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.dialogConfig.formSettings = {valid: true, touched: true};
        this.parameters = this.dialogConfig.dialogData;
    }

    updateDisabledState = () => {
        if (!this.parameters) {
            this.dialogConfig.submitButtonDisabled = false;
        }
        this.dialogConfig.submitButtonDisabled = !this.parameters.ipGroup && !this.parameters.trunkGroup && !this.parameters.mediaRealm && !this.parameters.mediaServer;
    };

    updateParameters(parameter: string, $event: boolean) {
        this.parameters[parameter] = $event;
        this.updateDisabledState();
    }
}


