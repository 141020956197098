import {Component, Input, ViewChild} from '@angular/core';
import {GridsterConfig, GridsterItem} from 'angular-gridster2';
import {reportGraph} from './models/report-graph';
import {ReportEntitiesService} from '../../../reports/services/report-entities.service';
import {ReportRestService} from '../../../services/report-rest.service';
import {Subject} from 'rxjs';


export enum GraphTypes {
    Bar = 'Bar',
    Line = 'Line',
    Pie = 'Pie',
    StackBar = 'StackBar'
}

@Component({
    selector: 'graphs-tab',
    templateUrl: './graphs-tab.component.html',
    styleUrls: ['./graphs-tab.component.less'],
})
export class GraphsTabComponent {

    @Input() report: any;
    @Input() isViewOnly = false;
    @Input() additionalSettings: any;
    @Input() columnList: any;
    @Input() tableColumns: any;
    @ViewChild('gridsterElement', {static: true}) gridsterElement: any;
    options: GridsterConfig;
    graphs: Array<GridsterItem>;
    graphTypes = GraphTypes;

    graphTypeList: any =
    [
        {value: GraphTypes.Bar, svgName: 'reportBar', svgHeight: '17px', svgWidth: '13px'},
        {value: GraphTypes.Line, svgName: 'reportLine', svgHeight: '17px', svgWidth: '13px'},
        {value: GraphTypes.Pie, svgName: 'reportPie', svgHeight: '17px', svgWidth: '13px'},
        {value: GraphTypes.StackBar, svgName: 'reportStackBar', svgHeight: '17px', svgWidth: '13px'}
    ];

    private updateColumnListSubject: Subject<any> = new Subject<any>();
    updateColumnList$ = this.updateColumnListSubject.asObservable();

    constructor(private reportEntitiesService: ReportEntitiesService,
                private reportRestService: ReportRestService) {
    }

    ngOnInit() {
        this.options = {
            gridType: 'scrollVertical',
            displayGrid: 'none',
            compactType: 'compactUp',
            maxItemRows: 2,
            minRows: 4,
            maxRows: 100,
            minCols: 4,
            maxCols: 4,
            margin: 10,
            pushItems: true,
            outerMarginRight: 10,
            outerMarginLeft: 10,
            mobileBreakpoint: 0,
            resizable: {
                handles: {
                    s: false,
                    e: false,
                    n: false,
                    w: false,
                    se: true,
                    ne: false,
                    sw: true,
                    nw: false
                },
                enabled: !this.isViewOnly
            },
            draggable: {
                enabled: !this.isViewOnly,
            },
            swap: true
        };
    }

    graphTypeChanged = (graph) => {
        if (graph.graphType === GraphTypes.Pie || graph.graphType === GraphTypes.StackBar) {
            graph.columns = [];
        }
    };

    handleGraphTypeDisabledOptions = (columns) => {
        const selectedColumns = [];
        if ((this.report.reportType || this.additionalSettings.reportType) !== this.reportRestService.reportTypes.TREND && columns && columns.length > 0) {
            columns.forEach((column) => {
                this.tableColumns.right.items.forEach((tableColumn) => {
                    if (tableColumn.id === column) {
                        const fullDetailedColumn = this.reportEntitiesService.getFullColumn(tableColumn, this.report.entityType);
                        selectedColumns.push(fullDetailedColumn);
                    }
                });
            });
        }

        this.graphTypeList.forEach((graphType) => {
            graphType.disabled = false;

            if (selectedColumns.length > 0) {
                selectedColumns.forEach((column) => {
                    column.graphTypes = this.changeArrayToLowerCase(column.graphTypes);
                    graphType.disabled = graphType.disabled || column.graphTypes && !column.graphTypes.includes(graphType.value.toLowerCase()) || false;
                });
            } else if ((this.report.reportType || this.additionalSettings.reportType) === this.reportRestService.reportTypes.TREND && this.report.trendReportMetricID) {
                const selectedColumn = this.reportEntitiesService.getFullColumn({
                    columnBaseId: this.report.trendReportMetricID,
                    columnType: 'Metric'
                });

                selectedColumn.graphTypes = this.changeArrayToLowerCase(selectedColumn.graphTypes);

                graphType.disabled = selectedColumn.graphTypes && !selectedColumn.graphTypes.includes(graphType.value.toLowerCase()) || false;
            }
        });

        this.graphTypeList = [...this.graphTypeList];
    };

    handleColumnsDisabledOptions = (graphType) => {
        if (graphType && this.additionalSettings.reportType === this.reportRestService.reportTypes.TREND) {
            return;
        }

        this.columnList.forEach((column) => {
            const graphTypes = this.changeArrayToLowerCase(column.graphTypes);
            column.disabled = graphTypes && graphType && !graphTypes.includes(graphType.toLowerCase()) || false;
        });

        this.updateColumnListSubject.next(this.columnList);
    };

    changeArrayToLowerCase = (array) => {
        const result = [];
        array.forEach((item) => {
            result.push(item.toLowerCase());
        });

        return result;
    };

    isSmallItemDisplay = (index) => {
        if (!this.gridsterElement.grid[index]) {
            return false;
        }
        const currentWidth = this.gridsterElement.grid[index].el.offsetWidth;
        const singleColWidth = this.gridsterElement.curColWidth;

        return currentWidth < singleColWidth * 1.5;
    };

    removeItem = (item) => {
        this.report.graphs.splice(this.report.graphs.indexOf(item), 1);
    };

    addItem = () => {
        this.report.graphs.push(new reportGraph());
    };
}


