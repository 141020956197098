import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {extend, cloneDeep} from 'lodash';

import {statuses, WSMessage} from '../../utils/ws-messages';
import {ThrottleClass} from '../../utils/throttle.class';
import {CommonNotifiersService} from '../common-notifiers.service';
import {SessionService} from '../session.service';
import {PromiseService, promiseType} from '../promise.service';
import {ConnectionService} from './connection.service';
import {WSEntities} from './ws-entities.service';
import {AutoRefreshService} from '../auto-refresh.service';
import {GeneralService} from '../general.service';
import {RefreshTableProperties} from '../../components/ac-table/ac-table.interface';
import {RequestOptions} from './communication.modals';
import {Subject} from "rxjs";

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class CachedConnection {
    cacheOptions = {timeToLive: 60000, lockedURLs: {}};
    timeoutsObject = {};
    isRunning = false;
    cachePool = {};
    unhandledWsMessageSubject: Subject<WSMessage> = new Subject<WSMessage>();
    unhandledWsMessage$ = this.unhandledWsMessageSubject.asObservable();
    update$: ThrottleClass;
    filterChangeCounter = 1; // Ensure Older filterUpdates dont get submitted if newer ones arrived.
    filterCurrentlyUpdating = false; // prevent auto refresh from going when filter is processing and gonna update UI anyway.
    private pendingWS: WSMessage[] = [];
    private fullSyncArrivedFromServer = false;
    private fullSyncDefer = false;

    constructor(private http: HttpClient,
                private connection: ConnectionService,
                private sessionService: SessionService,
                private wsEntities: WSEntities,
                private generalService: GeneralService) {
        this.update$ = new ThrottleClass({
            callback: this.doSilentUpdate,
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000
        });

        AutoRefreshService.systemRefresh$.pipe(untilDestroyed(this)).subscribe(this.update$.tick);
    }

    wsMessageArrived = (message: WSMessage) => {
        if (this.generalService.systemInitialized || message.messageType === statuses.FullSync) {
            this.processWSRequest(message);
        } else {
            this.pendingWS.push(message);
        }
    };

    copyResponse(outsidePromise) {
        const deferred = PromiseService.defer();
        outsidePromise.then(
            (success) => {
                const successClone = {...success};
                successClone.data = cloneDeep(success.data);
                deferred.resolve(successClone);
            },
            (fail) => deferred.reject(fail)
        );
        return deferred.promise;
    }

    public get(url, group?, filterChanged = false, requestOptions?: RequestOptions) {
        const self = this;

        const callback = (response) => {
            if (response.data === null) {
                response.data = {};
                response.data[group] = [];
            }
            if (self.cachePool[url]) {
                if (group) {
                    CommonNotifiersService.executeGeneralDataArrived({type: group, data: response});
                }
                if (response.status.toString()[0] !== '2') {
                    delete self.cachePool[url];
                }
                self.resetTimer(url);
            }
        };

        if (!self.sessionService.activeSession) {
            return Promise.reject();
        }
        if (!self.cachePool[url] || filterChanged) {
            if (group) {
                CommonNotifiersService.executeGeneralDataFetching(group);
            }
            self.cachePool[url] = {
                promise: self.connection.get({uri: url, ...requestOptions}),
                group
            };
            self.cachePool[url].promise.then(callback, callback);
        }
        return self.copyResponse(self.cachePool[url].promise);
    }

    fullSync(defer?) {
        this.stopRefresh();
        if (defer && this.fullSyncArrivedFromServer === false) {
            return this.fullSyncDefer = defer;
        }
        this.startRefresh(defer);
    }

    filteredUpdate = (defer?: promiseType, updatePart?: 'updateStatus' | 'updateFilteredIds') => {
        const filterChangeCounter = ++this.filterChangeCounter;
        this.filterCurrentlyUpdating = true;
        const self = this;
        const promiseArray = [];

        Object.getOwnPropertyNames(self.cacheOptions.lockedURLs).forEach((url) => {
            const lockedUrl = self.cacheOptions.lockedURLs[url];
            lockedUrl.url = lockedUrl.wsUrlBuilder();

            const fields = (updatePart === 'updateStatus') ? ['status', 'licenseStatus', 'managementStatus', 'vqControlStatus', 'vqMediaStatus', 'voiceQualityStatus'] : undefined;

            let temp_promise = PromiseService.resolvedPromise();
            if (!updatePart || updatePart === 'updateStatus') {
                temp_promise = self.connection.get({uri: lockedUrl.wsUrlBuilder(undefined, false, fields)}).then((response: any) => {
                    this.wsEntities.setData(lockedUrl.group, this.extractData(response, lockedUrl.group), 'id', updatePart === 'updateStatus');
                });
            }

            promiseArray.push(temp_promise);

            if (lockedUrl.wsGeoMapFilteredOut && (!updatePart || updatePart === 'updateFilteredIds')) {
                lockedUrl.filteredUrl = lockedUrl.wsUrlBuilder(undefined, true);
                if (lockedUrl.filteredUrl === null) {
                    temp_promise.then((response: any) => {
                        this.wsEntities.setFilteredIds(lockedUrl.group, this.wsEntities.getEntitiesArray(lockedUrl.group), 'id');
                    });
                } else {
                    promiseArray.push(self.connection.get({
                        uri: lockedUrl.filteredUrl,
                        singletonGroupName: lockedUrl.group
                    }).then((response: any) => {
                        this.wsEntities.setFilteredIds(lockedUrl.group, this.extractData(response, lockedUrl.group), 'id');
                    }));
                }
            }
        });
        Promise.all(promiseArray).then(
            (arr) => {
                this.generalService.finishedLoading('filteredUpdate');
                if (filterChangeCounter === this.filterChangeCounter) {
                    this.filterCurrentlyUpdating = false;
                    this.doSilentUpdate({gotoPage: 1, showLoader: true});
                }
                if (defer) {
                    defer.resolve();
                }
            },
            () => {
                if (defer) {
                    // return defer.resolve(); // for DEV ONLY ON OLDER SERVERS, do not fail on login when missing one of the entities
                    defer.reject();
                }
            });
    };

    public processPendingWSRequests = () => {
        this.pendingWS.forEach((message) => {
            this.processWSRequest(message);
        });
        this.pendingWS = [];
    };

    public startRefresh(defer?) {
        const self = this;
        if (self.isRunning || Object.getOwnPropertyNames(self.cacheOptions.lockedURLs).length === 0 || !self.sessionService.activeSession || self.sessionService.activeSession.locked) {
            defer.reject();
            return;
        }

        self.filteredUpdate(defer);

        self.isRunning = true;
    }

    public stopRefresh() {
        const self = this;

        if (!self.isRunning) {
            return;
        }

        Object.getOwnPropertyNames(self.timeoutsObject).forEach((prop) => {
            clearTimeout(self.timeoutsObject[prop]);
        });

        self.timeoutsObject = {};
        self.isRunning = false;
        self.cachePool = {};
        self.wsEntities.reset();
    }

    public updateCacheOptions(newOptions) {
        const self = this;
        self.cacheOptions = extend(self.cacheOptions, newOptions);
    }

    public forceRefreshLockedUrl(groups) {
        const self = this;
        groups = Array.isArray(groups) ? groups : [groups];

        groups.forEach((group) => {
            self.clearCacheForName(group);
            const lockedUrl = self.cacheOptions.lockedURLs[group];
            if (lockedUrl) {
                self.get(lockedUrl.url, lockedUrl.group);
            }
        });
    }

    public addLockedUrl(lockedUrl) {
        const self = this;
        clearTimeout(self.timeoutsObject[lockedUrl.url]);
        self.cacheOptions.lockedURLs[lockedUrl.group] = lockedUrl;

        if (lockedUrl.ws) { // listen to filter changes on websockets
            lockedUrl.url = lockedUrl.wsUrlBuilder();
            if (lockedUrl.wsGeoMapFilteredOut) {
                lockedUrl.filteredUrl = lockedUrl.wsUrlBuilder(undefined, true);
            }
        }

        if (self.isRunning && self.cachePool[lockedUrl.url] && self.cachePool[lockedUrl.url].promise.__zone_symbol__state) { // __zone_symbol__state===true for resolved promise only.
            CommonNotifiersService.executeGeneralDataArrived({type: lockedUrl.group, data: self.cachePool[lockedUrl.url].promise.__zone_symbol__value});
        } else if (self.isRunning && !lockedUrl.ws) {
            self.get(lockedUrl.url, lockedUrl.group);
        }
    }

    public removeLockedUrl(lockedUrl) {
        const self = this;
        clearTimeout(self.timeoutsObject[lockedUrl.url]);
        delete self.cacheOptions.lockedURLs[lockedUrl.group];
    }

    public clearLockedUrls() {
        const self = this;
        self.cacheOptions.lockedURLs = {};
    }

    clearCacheForName(group, ignoreWS = false) {
        const self = this;
        Object.getOwnPropertyNames(self.cachePool).forEach((url) => {
            if (self.cachePool[url].group === group && (!self.getLockedUrlFromParam(url, 'url').ws || !ignoreWS)) {
                delete self.cachePool[url];
            }
        });
    }

    resetTimer(url) {
        const self = this;
        if (!self.isRunning) {
            return;
        }

        const resetURL = () => {
            const lockedUrl = self.getLockedUrlFromParam(url, 'url');
            if (lockedUrl && lockedUrl.ws) {
                self.timeoutsObject[url] = setTimeout(resetURL, self.cacheOptions.timeToLive);
                return;
            }
            delete self.timeoutsObject[url];

            if (lockedUrl) {
                self.swapOldPromiseWithNew(url);
            } else {
                delete self.cachePool[url];
            }
        };

        if (!self.timeoutsObject[url]) {
            self.timeoutsObject[url] = setTimeout(resetURL, self.cacheOptions.timeToLive);
        }
    }

    swapOldPromiseWithNew(url) {
        const self = this;
        const lockedUrl = self.getLockedUrlFromParam(url, 'url');

        if (lockedUrl.group) {
            CommonNotifiersService.executeGeneralDataFetching(lockedUrl.group);
        }

        const tmpPromise = self.connection.get({uri: url});

        const success = (response) => {
            if (response.status.toString()[0] !== '2') {
                delete self.cachePool[url];
            } else {
                if (lockedUrl.group) {
                    CommonNotifiersService.executeGeneralDataArrived({type: lockedUrl.group, data: response});
                }

                if (!self.cachePool[url]) {
                    self.cachePool[url] = {};
                }
                self.cachePool[url].promise = tmpPromise;
            }
            self.resetTimer(url);
        };

        const error = () => {
            self.resetTimer(url);
        };

        tmpPromise.then(success, error);
    }

    getLockedUrlFromParam(param, paramName) {
        const self = this;
        const propertyNames = Object.getOwnPropertyNames(self.cacheOptions.lockedURLs);

        for (let i = 0; i < propertyNames.length; i++) {
            const lockedURL = self.cacheOptions.lockedURLs[propertyNames[i]];
            if (lockedURL[paramName] === param) {
                return lockedURL;
            }
        }

        return false;
    }

    private handleWSAddEditDelete = (message: WSMessage, lockedUrl: any, isDelete = false) => {
        if (isDelete) {
            this.deleteEntities(message, lockedUrl);
        } else {
            this.addEditEntities(message, lockedUrl);
        }
        this.clearCacheForName(message.entityTypeName, true);
    };

    private deleteEntities(message: WSMessage, lockedUrl: any) {
        this.wsEntities.deleteEntities(lockedUrl.group, message.entitiesIds);
        if (lockedUrl.wsGeoMapFilteredOut) {
            this.wsEntities.updateFilteredIds(lockedUrl.group, [], 'id', message.entitiesIds);
        }
        this.update$.tick();
        this.updateWs(message);
    }

    private addEditEntities = (message: WSMessage, lockedUrl: any) => {
        const url = lockedUrl.wsUrlBuilder(message.entitiesIds);

        this.connection.get({uri: url}).then((newEntitiesResponse: any) => {
            const newEntities = newEntitiesResponse.data ? newEntitiesResponse.data[message.entityTypeName] : [];

            this.wsEntities.setData(lockedUrl.group, newEntities, 'id');

            if (lockedUrl.wsGeoMapFilteredOut) {
                const filteredUrlWithIds = lockedUrl.wsUrlBuilder(message.entitiesIds, true); // Problem with Topology filters not able to get IDS as AND.. only as OR

                this.connection.get({uri: filteredUrlWithIds}).then((filterNewEntitiesResponse: any) => {
                    const filteredNewEntities = filterNewEntitiesResponse.data ? filterNewEntitiesResponse.data[message.entityTypeName] : [];
                    this.wsEntities.updateFilteredIds(lockedUrl.group, filteredNewEntities, 'id', message.entitiesIds);
                    this.update$.tick();
                    this.updateWs(message);
                });
            } else {
                this.update$.tick();
                this.updateWs(message);
            }
        });
    };


    private doSilentUpdate = (tableProperties: RefreshTableProperties = {}) => {
        if (!this.filterCurrentlyUpdating) {
            CommonNotifiersService.updateFinishedDataAndFiltered(tableProperties);
        }
    };

    private updateWs(message: WSMessage) {
        this.wsEntities.executeWSEntitiesUpdateFinished(message);
    }

    private extractData(response: any, group: any) {
        return (response && response.data) ? response.data[group] : [];
    }

    private processWSRequest(message: WSMessage) {
        if (this.sessionService.activeSession.locked) {
            return;
        }

        if (message.messageType === statuses.FullSync) {
            if (!this.fullSyncArrivedFromServer) {
                this.fullSyncArrivedFromServer = true;
                if (this.fullSyncDefer) {
                    this.fullSync(this.fullSyncDefer);
                    this.fullSyncDefer = false;
                }
            } else {
                this.fullSync();
            }
            return;
        }

        if (!this.isRunning) {
            return;
        }

        const lockedUrl = this.getLockedUrlFromParam(message.entityTypeName, 'group');
        if(lockedUrl && [statuses.Create, statuses.Update, statuses.Delete].includes(message.messageType)){
            switch (message.messageType) {
                case statuses.Create:
                    this.handleWSAddEditDelete(message, lockedUrl);
                    break;
                case statuses.Update:
                    this.handleWSAddEditDelete(message, lockedUrl);
                    break;
                case statuses.Delete:
                    this.handleWSAddEditDelete(message, lockedUrl, true);
            }
        }else{
            this.unhandledWsMessageSubject.next(message);
        }

    }

    reset() {
        this.stopRefresh();
        this.fullSyncArrivedFromServer = false;
        this.fullSyncDefer = undefined;
    }
}
