import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {AcFormComponent} from '../../ac-form/ac-form.component';

@Component({
    styleUrls: ['./ac-range-input.component.less'],
    selector: 'ac-range-input',
    templateUrl: './ac-range-input.component.html',
})

export class AcRangeInputComponent {

    @Input() acModel: any;
    @Input() rangeStartName: string;
    @Input() rangeEndName: string;
    @Input() rangeEnableName: string;

    @Input() rangeId = '';

    @Input() minRange: number;
    @Input() maxRange: number;

    @Input() width: string;

    @Input() step = 1;

    @Input() hideRanges: boolean;
    @Input() hideCheckbox = false;
    @Input() reverseRange = false;
    @Input() isFloatNumber = false;
    @Input() ngDisabled = false;

    @Output() acChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() acChangedEnable: EventEmitter<string> = new EventEmitter<string>();

    constructor(@Optional() public acFormComponent: AcFormComponent) {}

    ngOnInit(){
        if(this.acFormComponent && this.acFormComponent.isViewMode){
            this.ngDisabled = true;
        }
    }
    rangeEnableChanged() {
        this.acModel[this.rangeEnableName] = !this.acModel[this.rangeEnableName];
    }
}



