<ac-form *ngIf="dialogConfig.ready"
         [auxiliaryModel]="additionalSettings"
         [formModel]="operator"
         [formSchema]="schema"
         [isEdit]="isEdit"
         [allowNullProperties]="['autoSuspendTime']"
         [requiredsAuxiliaryForm]="requiredsAuxiliaryForm"
         [validateAuxiliaryForm]="validateAuxiliaryForm"
         formModelName="operator"
         class="operator-dialog-form">

    <ac-tabs tabsId="operator-tabs">

        <operator-basic-info-tab *acTab="'Basic info'; id:'operator-basic-info'"
                                 [operator]="operator"
                                 [additionalSettings]="additionalSettings"
                                 [oneLiveMode]="oneLiveMode"
                                 [isEdit]="isEdit"
                                 [operatorTopologyTabElement]="operatorTopologyTabElement"
        ></operator-basic-info-tab>


        <operator-advanced-info-tab *acTab="'Advanced info'; id:'operator-advanced-info'"
                                    [operator]="operator"
                                    [additionalSettings]="additionalSettings"
                                    [isEdit]="isEdit"
        ></operator-advanced-info-tab>

        <operator-topology-tab *acTab="'Topology'; id:'operator-tenants-info'; if: !operator.isSystemOperator"
                               #operatorTopologyTabElement
                               [additionalSettings]="additionalSettings"
                               [devices]="devices"
                               [groups]="groups"
                               [links]="links"
                               [operator]="operator"
                               [tenants]="tenants"
        ></operator-topology-tab>
    </ac-tabs>

</ac-form>

