import {Component, Inject, ViewChild} from '@angular/core';
import {AcDialog, AcDialogService, DIALOG_CONFIG, DialogConfig, GeneralService, PromiseService, SchemaHelperService, ValidatorsService} from 'ac-infra';
import * as _ from 'lodash';
import * as moment from 'moment';
import {OperatorTopologyTabComponent} from './operator-topology-tab/operator-topology-tab.component';

@AcDialog({
    width: 550,
    height: 550,
    cancelButtonText: 'Close',
    id: 'operator-dialog',
})
@Component({
    selector: 'operator-dialog',
    templateUrl: './operator-dialog.component.html',
})
export class OperatorDialogComponent {

    @ViewChild('operatorTopologyTabElement', {static: false}) operatorTopologyTabElement: OperatorTopologyTabComponent;
    operatorAddSchema = require('../../../../schema/post/operatorsprocessor.json');
    operatorEditSchema = require('../../../../schema/put/operatorsprocessor.json');
    schema;
    isEdit = false;

    securityLevelsList;
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    operator: any;
    additionalSettings: any;
    tenants: any;
    links: any;
    devices: any;
    groups: any;
    suspendSince;
    private originalOperator: any;

    constructor(private schemaHelperService: SchemaHelperService,
                private validatorsService: ValidatorsService,
                private acDialogService: AcDialogService,
                private generalService: GeneralService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {


        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.operator = this.dialogConfig.dialogData.entity;
        this.originalOperator = _.cloneDeep(this.dialogConfig.dialogData.entity);
        this.schema = this.isEdit ? _.cloneDeep(this.operatorEditSchema) : _.cloneDeep(this.operatorAddSchema);

        this.schema.properties.password.minLength = 8;
        this.schema.properties.password.maxLength = 30;
        delete this.operator.blockedStatus;

        this.additionalSettings = _.cloneDeep(this.operator);
        this.additionalSettings.confirmPassword = '';

        this.dialogConfig.preSubmit = () => this.preSubmit();

        this.tenants = this.dialogConfig.dialogData?.tenants;
        this.links = this.dialogConfig.dialogData?.links;
        this.devices = this.dialogConfig.dialogData?.devices;
        this.groups = this.dialogConfig.dialogData?.groups;

        if (this.operator.isSystemOperator) {
            if (!this.operator.assignedTenants) {
                this.operator.assignedTenants = [];
            }
        } else if (this.generalService.serverInfo && this.generalService.serverInfo.oneLiveMode) {
            this.additionalSettings.assignedTenant = this.operator.assignedTenants && this.operator.assignedTenants.length === 1 ? this.operator.assignedTenants[0] : undefined;
        }

        this.dialogConfig.title = this.operator.isSystemOperator ? 'System operator details' : 'Tenant operator details';
    }

    checkConfirm = (errors, auxModel, formModel, modelName) => {
        if (auxModel.confirmPassword && auxModel.confirmPassword !== formModel.password) {
            errors.push(this.schemaHelperService.buildErrorItem({instancePath: '/confirmPassword', inputName: modelName + '.confirmPassword', keyword: 'confirmPassword'}));
        }
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        const auxModelName = 'additionalSettings';
        const modelName = 'operator';

        if (this.isEdit) {
            if (!formModel.password || formModel.password === '') {
                errors.filterKeepRef((error) => {
                    return error.params && error.params.missingProperty && error.params.missingProperty === 'password';
                });
            } else {
                this.checkConfirm(errors, auxModel, formModel, auxModelName);
            }
        } else {
            this.checkConfirm(errors, auxModel, formModel, auxModelName);
        }

        if (formModel.loginFromIps && formModel.loginFromIps.length > 0) {
            let index = 0;
            _.forOwn(formModel.loginFromIps, (ip) => {
                if (!this.validatorsService.isValidIPAddress(ip)) {
                    errors.push(this.schemaHelperService.buildErrorItem({
                        instancePath: '/loginFromIps/' + index,
                        inputName: 'operator.loginFromIps',
                        isArray: true,
                        index,
                        keyword: 'IPv4Aux'
                    }));
                }
                index++;
            });
        }
        if (formModel.mail && formModel.mail !== '' && !this.validatorsService.isValidEmailAddress(formModel.mail)) {
            errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/mail',
                inputName: modelName + '.mail',
                keyword: 'invalidMail'
            }));
        }
        if (auxModel.suspendedState === 'suspendedDate' && (!auxModel.suspendedDateObject.from || auxModel.suspendedDateObject.from.diff(moment()) < 0)) {
            errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/suspendedDateObject',
                inputName: 'additionalSettings.suspendedDateObject',
                keyword: 'requiredAux'
            }));
        }
    };


    requiredsAuxiliaryForm = () => {
        const requiredList = [];
        if (this.isEdit) {
            if (this.operator.password !== undefined && this.operator.password !== '') {
                requiredList.push('additionalSettings.confirmPassword');
            }
        } else {
            requiredList.push('additionalSettings.confirmPassword');
        }
        requiredList.push('additionalSettings.suspendedDateObject');
        return requiredList;
    };

    preSubmit = () => {
        const defer = PromiseService.defer();

        if (this.generalService.serverInfo.oneLiveMode && !this.operator.isSystemOperator) {
            this.operator.assignedTenants = this.additionalSettings.assignedTenant ? [this.additionalSettings.assignedTenant] : [];
        }

        if (this.operator.isSystemOperator) {
            delete this.operator.assignedTenants;
        }
        if (!this.operator.suspendStatus) {
            delete this.operator.suspendReason;
        }

        if (!this.operator.isTenantOperatorWithEndpointGroups) {
            delete this.operator.assignedGroups;
        }

        if (this.operator.isUMP && this.operator.securityLevel !== 'OPERATOR') {
            this.operator.isUMP = false;
        }

        if (this.operator.isUMP && !this.originalOperator.isUMP) {
            this.acDialogService.confirm('Only one Rest Operator is allowed, Please notice that this action will override the existing Rest Operator', {
                onSubmit: () => {
                    defer.resolve();
                },
                onCancel: () => {
                    defer.reject();
                }
            });
        } else {
            defer.resolve();
        }

        return defer.promise;
    };


}


