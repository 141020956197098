import {Component, Input} from '@angular/core';

@Component({
    selector: 'ac-allocation-bar',
    templateUrl: './ac-allocation-bar.component.html',
    styleUrls: ['./ac-allocation-bar.component.less'],
})

export class AcAllocationBarComponent {
    @Input() header = '';
    @Input() total: any;
    @Input() used: any;
    @Input() verbose = true;
    @Input() hideAllocTitle = false;
    @Input() dark = false;


    calculateFreeAllocation = () => {
        const freeiAllocValue = this.isUsedValid() ? (this.total - this.used) : 0;
        return freeiAllocValue >= 0 ? freeiAllocValue : 0;
    }

    isLicenseExceeded = () => {
        return !this.isUsedValid() || parseInt(this.total, 10) < parseInt(this.used, 10);
    }

    generateVerboseBarTitle = () => {
        return this.isUsedValid() ? this.calculatePercentage() : '100% >';
    }

    calculateUsedPercentage = () => {
        return (this.isLicenseExceeded() ? 100 :
            Math.round(this.total ? this.used / this.total * 100 : 0)) + '%';
    }

    isUsedValid = () => {
        return !isNaN(parseInt(this.used, 10));
    }

    calculatePercentage = () => {
        return this.isUsedValid() ? this.calculateUsedPercentage() : '100%';
    }

}



