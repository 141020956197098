import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcCheckboxModule} from '../ac-checkbox/ac-checkbox.module';
import {AcInputContainerOldComponent} from './ac-input-container-old.component';

@NgModule({
    imports: [
        CommonModule,
        AcCheckboxModule
    ],
    declarations: [
        AcInputContainerOldComponent,
    ],
    entryComponents: [
        AcInputContainerOldComponent,
    ],
    exports: [
        AcInputContainerOldComponent,
    ]
})
export class AcInputContainerOldModule {
}
