<div class="tenant-and-region-container" [class.flexRowParentEqualChildren]="horizontalView">
    <ac-input-container [label]="tenantLabel || 'Tenant'" [class.flexItemRowEquals]="horizontalView">
        <ac-single-select [sortItems]="sortTenants"
                   [acModel]="model.tenantId || additionalSettings.tenantId"
                   [items]="tenants"
                   [attr.alternativeName]="tenantAlternativeName || 'tenantId'"
                   bindLabel="name"
                   bindValue="id"
                   [ngDisabled]="ngDisabledTenant"
                   [overrideSystemViewMode]="overrideSystemViewMode"
                   (acModelChange)="onTenantChange($event);">
        </ac-single-select>
    </ac-input-container>

    <ac-input-container label="Region" *ngIf="showRegion" [class.flexItemRowEquals]="horizontalView">
        <ac-single-select [sortItems]="true"
                   [acModel]="model.regionId || additionalSettings.regionId"
                   [items]="regionsByTenant"
                   (acModelChange)="model.regionId = $event; acRegionChanged.emit($event)"
                   [attr.alternativeName]="regionAlternativeName"
                   bindLabel="name"
                   bindValue="id"
                   [ngDisabled]="ngDisabledRegion">
        </ac-single-select>
    </ac-input-container>
</div>

