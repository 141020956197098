<div class="cluster-tooltip-inner flexColumnParent">
    <div class="flexItem">
        <input (ngModelChange)="searchNameChanged($event)" [ngModel]="searchName" class="nameFilter"
               placeholder="Search by name"/>
    </div>
    <div class="flexItemBiggest">
        <div class="virScrollContainer">
            <virtual-scroller #scroll [items]="itemsDataForDisplay" checkResizeInterval="100">
                <div *ngFor="let item of scroll.viewPortItems"
                     class="itemRow"
                     [class.selected]="isItemSelected(item)"
                     [class.highlight]="item.highlight"
                     [class.filtered]="((item.device && !item.device.filtered) || (!item.device && !item.filtered))">

                    <span (click)="selectItem($event, item)" class="itemStatus">
                        <i class='fa fa-circle status-color status-{{getImagePath(item.status||item.statusSeverityLevel)}}'></i>
                    </span>

                    <span (click)="selectItem($event, item)" class="itemName" title="{{item.name||item.device.name}}">
                        {{item.name || item.device.name}}
                    </span>

                    <span (click)="showItem(item)" [class.hideButton]="isShowButtonHidden(item)" class="itemShow">
                        <i class="fa fa-eye action-icon"></i>
                    </span>

                    <span (click)="editItem(item)" [class.hideButton]="isMonitor()" class="itemEdit">
                        <i class="fa fa-pencil action-icon"></i>
                    </span>
                </div>
            </virtual-scroller>
        </div>
        <!--<div class="flexItem itemDelete" (click)="deleteItem(item, key)"><i class="fa fa-trash action-icon ng-scope"></i></div>-->
    </div>
</div>
