<div class="device-details-statistics flexColumnParent">

    <ac-layout class="flexItemBiggest" id="dynamic-tab">
        <ac-layout-section [sectionTitle]="deviceDetails.name"
                           [titlePostfix]="(deviceDetails.sbcInfo && deviceDetails.sbcInfo.isHA && ' (HA Device)') || ''"
                           collapseDir="left"
                           section="sidebar">

            <ng-container *acLayoutPanel="'filters'; icon: 'filter'; panelClass: 'flex-h6'">
                <device-statistics-filters
                    *ngIf="dataReadyObject.fetchedProfile && dataReadyObject.fetchedDictionaries?.dictionaryTopics; else loading"
                    [deviceId]="deviceId"
                    [devicePmDictionaries]="dataReadyObject.fetchedDictionaries?.dictionaryTopics"
                    [devicePmProfile]="dataReadyObject.fetchedProfile"
                    (deviceStatisticsPMFilterEvent)="deviceStatisticsPMFilterEventChanged($event)"
                ></device-statistics-filters>
            </ng-container>
        </ac-layout-section>

        <ac-layout-section class="flex-w1" sectionTitle="Daily Summary">

            <div *ngIf="deviceStatisticsGridData" (click)="toggleGroupsCollapseState()" class="link-action" section-header>
                {{isGroupCollapsed ? 'expand' : 'collapse'}} all
            </div>

            <ng-container *acLayoutPanel="''; panelClass: 'no-padding'">
                <div *ngIf="!inProgressFilterFlag; else loading">
                    <device-details-statistics-table *ngIf="hasFilter"
                                                     [tableId]="PM_STATISTICS_TABLE_ID_PREFIX + deviceId"
                                                     [deviceId]="deviceId"
                                                     [devicePmDictionariesMap]="devicePMDictionariesMap"
                                                     [devicePmDictionaries]="dataReadyObject.fetchedDictionaries?.dictionaryTopics"
                                                     [devicePmParametersMap]="devicePMParametersMap"
                                                     [deviceStatisticsPmGridData]="deviceStatisticsGridData"
                                                     [refresh$]="refresh$"
                                                     (allCollapsed)="updateGroupCollapsedState($event)"
                    ></device-details-statistics-table>

                    <missing-pm-filters *ngIf="!hasFilter"
                                        [hasMainFilter]="existingFilters.timeRange"
                                        [hasMetrics]="existingFilters.parameters"
                                        mainFilterTitle="Time Range"
                    ></missing-pm-filters>
                </div>
            </ng-container>

        </ac-layout-section>

        <ac-layout-section class="flex-w2" sectionTitle="Details">
            <device-details-actions *ngIf="dataReadyObject.fetchedDevice"
                                    [deviceDetails]="deviceDetails"
                                    [currentFilter]="currentFilter"
                                    section-header
                                    view="detailsStatistics"></device-details-actions>

            <ng-container *acLayoutPanel="''; panelClass: 'no-padding'">

                <ng-container *ngIf="!inProgressSelectionFlag && !inProgressFilterFlag; else loading">

                    <device-details-statistics-summary *ngIf="itemsSelectedFlag"
                                                       [deviceId]="deviceId"
                                                       [devicePmDictionariesMap]="devicePMDictionariesMap"
                                                       [devicePmDictionaries]="dataReadyObject.fetchedDictionaries?.dictionaryTopics"
                                                       [devicePmParametersMap]="devicePMParametersMap"
                                                       [deviceStatisticsPmChartData]="deviceStatisticsChartData">
                    </device-details-statistics-summary>

                    <div *ngIf="!itemsSelectedFlag" class="no-item-selected">
                        <span class="select-message">No Items Selected</span>
                    </div>
                </ng-container>
            </ng-container>
        </ac-layout-section>
    </ac-layout>

    <div *ngIf="pageDetails" class="device-more-info-container flexItem flexColumnParent"
         title="{{pageDetails.tooltip}}">
        <span class="ellipsis">
            <span class="pm-profile-item">
            <span class="pm-profile-item-title">Profile:</span>
            <span class="pm-profile-item-value">
                <ac-link state="/statistics/pmProfiles">{{pageDetails.text.profileName}}</ac-link></span>
            </span>
            <span class="pm-profile-item">
                    <span class="pm-profile-item-title">Status:</span>
                    <span
                        class="pm-profile-item-value {{pageDetails.text.lastPollingStatus}}">{{pageDetails.text.lastPollingStatus}}</span>
                </span>
            <span class="pm-profile-item">Last Sampled at:&nbsp;{{pageDetails.text.lastSampledTime}}</span>
            <span
                class="pm-profile-item">Number of metrics in last sample:&nbsp;{{pageDetails.text.lastSuccessfulPollingParams}}</span>
            </span>
    </div>
</div>


<ng-template #loading>
    <div class="data-in-progress">
        <ac-progress></ac-progress>
    </div>
</ng-template>


