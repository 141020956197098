<div class="operator-tenants-multi-select">
    <ac-input-container *ngIf="!generalService.serverInfo.oneLiveMode ;else singleTenantMode" label="Assigned Tenants:">
        <ac-select [(acModel)]="operator.assignedTenants"
                   (acModelChange)="assignedTenantsChanged()"
                   [attr.alternativeName]="'operator.assignedTenants'"
                   [items]="tenants"
                   [multiple]="true"
                   bindLabel="name"
                   bindValue="id">
        </ac-select>
    </ac-input-container>

    <ng-template #singleTenantMode>
        <ac-input-container label="Assigned Tenant:">
            <ac-single-select [sortItems]="true"
                       [(acModel)]="additionalSettings.assignedTenant"
                       (acModelChange)="assignedTenantsChanged()"
                       [attr.alternativeName]="'additionalSettings.assignedTenant'"
                       [items]="tenants"
                       bindLabel="name"
                       bindValue="id">
            </ac-single-select>
        </ac-input-container>
    </ng-template>


    <ac-input-container *ngIf="operator.securityLevel === 'MONITORING_LINKS' && !generalService.serverInfo.oneLiveMode"
                        label="Assigned Links for a Specific Device:">
        <ac-select [(acModel)]="operator.assignedLinks"
                   (acModelChange)="updateLinkList()"
                   [attr.alternativeName]="'operator.assignedLinks'"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [hideFilterButtons]="true"
                   [items]="linksItems"
                   [multiple]="true"
                   [ngDisabled]="operator.assignedTenants === undefined || operator.assignedTenants.length === 0">
        </ac-select>
    </ac-input-container>

    <ac-checkbox labelText="Restrict Endpoints Actions Except For These Groups"
                 *ngIf="tenantOperatorList.includes(operator.securityLevel)"
                 [(acModel)]="operator.isTenantOperatorWithEndpointGroups"
                 (acModelChange)="groupsOperatorChanged()"
                 [attr.alternativeName]="'operator.isTenantOperatorWithEndpointGroups'">
    </ac-checkbox>

    <ac-input-container *ngIf="tenantOperatorList.includes(operator.securityLevel) && operator.isTenantOperatorWithEndpointGroups"
                        label="Assigned Endpoints groups:">
        <ac-select [(acModel)]="operator.assignedGroups"
                   [attr.alternativeName]="'operator.assignedGroups'"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [groupBy]="groupByTenantName"
                   [hideFilterButtons]="true"
                   [items]="groupsItems"
                   [multiple]="true"
                   [ngDisabled]="operator.assignedTenants === undefined || operator.assignedTenants.length === 0"
                   [titleForSelectedItemsCallback]="groupByTenantName">
        </ac-select>
    </ac-input-container>
</div>
