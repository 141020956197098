<div *ngIf="!collapsed; else collapsedLayout" class="layout-panel flexColumnParent">
    <!--conflict test-->
    <header #header *ngIf="panelTitle || iconName || collapseDir || templates?.panelHeaderTemplate" class="layout-panel-header flexRowParent flexItem">

        <ac-svg *ngIf="iconName" [name]="iconName" class="layout-panel-icon left flexItem"></ac-svg>

        <ng-template [ngTemplateOutlet]="templates?.panelHeaderTemplate" [ngTemplateOutletContext]="{$implicit: panelTitle, postfix: titlePostfix}"></ng-template>

        <div acLabel *ngIf="!templates?.panelHeaderTemplate && panelTitle"class="layout-panel-title flexItemBiggest">
            {{panelTitle | uppercase}}{{titlePostfix}}
        </div>

        <ng-template [ngTemplateOutlet]="templates.panelActionsTemplate"></ng-template>

        <div *ngIf="collapseDir" (click)="onCollapse()" class="layout-panel-icon-container collapse-arrow">
            <ac-svg [mirrorHor]="collapseDir === 'right'" class="layout-panel-icon pointer" name="chevron"></ac-svg>
        </div>
    </header>

    <div class="layout-panel-content {{contentCustomClass}}" [hidden]="loading">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>

    <ac-progress *ngIf="loading" [center]="true" size="small"></ac-progress>
</div>

<ng-template #collapsedLayout>
    <div class="layout-panel flexColumnParent">
        <div (click)="onCollapse()" class="flexItem flexRowParent layout-panel-header collapse-arrow">
            <ac-svg [mirrorHor]="collapsed && collapseDir === 'left'"
                    class="layout-panel-icon pointer flexItemBiggest"
                    name="chevron"></ac-svg>
            <span class="layout-panel-title" style="width: 0">&nbsp;</span>
        </div>
        <div class="layout-panel-header rotated">
            <span *ngIf="panelTitle && panelTitle !== ' '" class="layout-panel-title">{{panelTitle | uppercase}}{{titlePostfix}}</span>
            <span class="layout-panel-content">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
