<ac-search [search]="search"
           (searchChange)="onSearchChange($event)"
           [componentId]="treeId + '-search'"
           [placeHolder]="searchPlaceHolder"
           class="topology-tree-search flexItem">
</ac-search>

<ac-tree #acTree
             (activeChange)="onActiveNodes($event)"
             (initialized)="onInitialized($event)"

             (selectChange)="onSelectNodes($event)"

             (updateData)="onUpdateData($event)"

             [customTreeNodeWrapperTemplate]="customTreeNodeWrapperTemplate"
             [extendOptions]="extendTreeOptions"

             [filter]="topologyFilter"
             [multiActive]="true"

             [restoreExpanded]="restoreExpanded"
             [search]="search"
             [treeId]="treeId"
             [treeNodes]="treeNodes"
             class="flexItemBiggest"
></ac-tree>


<ng-template #customTreeNodeWrapperTemplate let-index="index" let-node>
    <div [class.marked]="node | acFunc: isNodeMarked: search"
         [class.node-content-wrapper-active]="node.isActive && !checkbox"
         [id]="treeId + '-node-' + node.data.id"
         class="node-wrapper flexRowParent"
         #nodeEl>

        <ac-svg [class.visibilityHidden]="!node.hasChildren"
                class="node-expander"
                name="expander"
                width="7px"
                [fillColor]="node.isExpanded ? statusColors.grayStatus : 'none'"
                [rotate]="node.isExpanded ? 45 : 0"
                (click)="node.mouseAction('expanderClick', $event)"
        ></ac-svg>

        <input *ngIf="checkbox"
               (click)="!disabled && node.mouseAction('checkboxClick', $event)"
               [checked]="fakeAllSelected || node.isSelected"
               [disabled]="disabled"
               [indeterminate]="node.isPartiallySelected"
               class="tree-node-checkbox"
               type="checkbox"
        />

        <!--       THIS NEED TO BE FIXED TO GET BETTER PERFORMANCE         -->


        <div (click)="!disabled && node.mouseAction('click', $event)"
             [class.disabled]="disabled"
             class="node-content-wrapper">

            <ng-template *ngIf="nodePrefixIconTemplate"
                         [ngTemplateOutletContext]="{$implicit: node.data, level: node.level}"
                         [ngTemplateOutlet]="nodePrefixIconTemplate">
            </ng-template>
            <span class="node-text ellipsis" acLabel parentSelectorForTitle=".node-wrapper">{{node.data.name || node.data.text}}</span>
        </div>

        <ac-svg *ngIf="!node.data.artificial"
                [class.fake-just-to-run-func-when-created]="node | acFunc: positionMenuAccordingToSelectedNode : nodeEl"
                class="action-menu flexItem"
                name="menuButton"
                (click)="toggleMenu($event, node, nodeEl)">
        </ac-svg>
    </div>
</ng-template>

<ac-drop-down #popupDropDown
              (openStatusChanged)="popupDropDownStatusChanged($event)"
              id="node-dropdown"
              parentClass="entityDropdown"
              style="visibility: hidden"
              [matTriggerId]="treeId +'-menuBtn'">
    <button parent style="visibility: hidden;display: none"></button>

    <div class="entityPopup" tooltip>
        <network-entity-popup [entityType]="entityType" [item]="dataItem" [executeTopologyTreeDropDownClose]="popupDropDown.close"></network-entity-popup>
    </div>
</ac-drop-down>

