import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewChild} from '@angular/core';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {MatFormFieldControl} from '@angular/material/form-field';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import * as _ from 'lodash';
import {GeneralService} from "../../services/general.service";


@UntilDestroy()
@Component({
    selector: 'ac-single-select',
    templateUrl: './ac-single-select.component.html',
    styleUrls: ['./ac-single-select.component.less']
})
export class AcSingleSelectComponent {


    @Input() selectId: string;
    @Input() placeholder = '';
    @Input() bindLabel = 'text';
    @Input() bindValue = 'value';
    @Input() useFullObjectInModel = false;
    @Input() selectFirstAfterSort = true;
    @Input() labelPostfix = '';
    @Input() ngDisabled = false;
    @Input() addCustomTags = false;
    @Input() allowClear = false;
    @Input() overrideSystemViewMode = false;
    @Input() optionTitle: string;
    @Input() displayImage = false;
    @Input() dropDownOptionsClass = '';
    @Input() itemTemplate: TemplateRef<any>;
    @Input() labelTemplate: TemplateRef<any>;
    @ViewChild(MatFormFieldControl) matFormFieldControl;
    @ViewChild('autocompleteInput', {read: MatAutocompleteTrigger}) triggerAutocompleteInput: MatAutocompleteTrigger;
    @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;

    @Output() acModelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();

    _acModel;
    @Input() set acModel(acModel) {
        this._acModel = acModel;
        this.selected = this.itemsMapper?.[this._acModel];
        this.initializeItems();
    }

    itemsMapper;
    _items: any[] = [];
    @Input() set items(items: any) {
        this.itemsMapper = [];
        items = items?.map(item => {
            const itemType = typeof item;
            if (itemType === 'object' || itemType === 'function') {
                return item;
            }
            return {[this.bindLabel]: item, [this.bindValue]: item};
        });

        items?.forEach(item => {
            this.itemsMapper[item[this.bindValue]] = item;
            if (item[this.bindLabel]) {
                item[this.bindLabel] = item[this.bindLabel] + this.labelPostfix;
            }
        });
        this.selected = this.itemsMapper?.[this._acModel];
        this._items = items;

        this.initializeItems();
        this.filterItems();
    }

    _sortItems;
    @Input() set sortItems(sortItems: boolean) {
        this._sortItems = sortItems;
        this.initializeItems();
    }

    filteredItems;
    selected;
    isFocused;
    newTag;
    colors = GeneralService.statusColors;

    constructor(@Optional() public acInputContainerComponent: AcInputContainerComponent,
                @Optional() public acFormComponent: AcFormComponent,
                public generalService: GeneralService) {
    }


    ngOnInit() {
        this.filterItems();
    }

    ngAfterViewInit() {
        if (this.acInputContainerComponent) {
            this.acInputContainerComponent.initializeMaterialField(this.matFormFieldControl);
        }

        if (this.acFormComponent && this.acFormComponent.isViewMode) {
            this.ngDisabled = !this.overrideSystemViewMode;
        }

        setTimeout(() => {
            const selectId = this.selectId || (this.acInputContainerComponent?.componentName);
            if (selectId) {
                this.selectId = `ngSelect.${selectId}`;
            }
        });
    }

    checkIfValueIsInItems = (event) => {
        let valueToEmit;
        if (this.filteredItems?.length === 1 &&
            (event?.toString().toLowerCase() === this.filteredItems[0][this.bindLabel]?.toLowerCase() ||
                event?.toString().toLowerCase() === this.filteredItems[0][this.bindValue]?.toString().toLowerCase())) {
            valueToEmit = this.useFullObjectInModel ? this.filteredItems[0] : this.filteredItems[0][this.bindValue];
            !_.isNil(valueToEmit) && this.modelChanged(valueToEmit);
        } else if (!_.isNil(event)) {
            valueToEmit = (typeof event === "object" && this.useFullObjectInModel) ? event : this.itemsMapper?.[event]?.[this.bindValue];
            if (!_.isNil(valueToEmit) || this._items?.length === 0) {
                this.modelChanged(valueToEmit);
            }
        }

        //don't do this.updateSelection(valueToEmit) if both condition are false.
    }

    addTag = (value) => {
        if (typeof value !== 'object' && value.toString()) {
            this.newTag = {[this.bindLabel]: value.toString(), [this.bindValue]: value.toString()};
        } else {
            this.newTag = undefined;
            this.acModelChange.emit(value);
        }
    };

    findItemInFilteredItemsList = (value) => {
        return this.filteredItems.find(item => {
            return [item?.value?.toString().toLowerCase(), item?.text?.toString().toLowerCase()].includes(value?.toString().toLowerCase());
        });
    }

    handleNewTag = () => {
        this.triggerAutocompleteInput.closePanel();
        if (this.addCustomTags && this.newTag) {
            const item = this.findItemInFilteredItemsList(this.newTag[this.bindValue]);

            if(item){
                this.acModelChange.emit(this.useFullObjectInModel ? item : item[this.bindValue]);
            }else{
                this._items.push(this.newTag);
                this.acModelChange.emit(this.useFullObjectInModel ? this.newTag : this.newTag[this.bindValue]);
                this.newTag = undefined;
            }
        }
    }

    focusOut() {
        this.isFocused = false;
        if (this.addCustomTags && this.newTag) {
            this.handleNewTag();
        }else{
            this.triggerAutocompleteInput?.writeValue(this._acModel);
        }
    }

    focusIn = () => {
        this.isFocused = true;
        this.filterItems();
    }

    modelChanged = (value) => {
        this.acModelChange.emit(value);
        this.triggerAutocompleteInput?.writeValue(value);
    }

    filterItems = ($event = undefined) => {
        let filterValue;
        if (this.addCustomTags) {
            filterValue = typeof $event === 'string' ? $event?.toString().toLowerCase() : $event?.[this.bindLabel]?.toString().toLowerCase();
        } else {
            filterValue = $event?.toString().toLowerCase();
        }

        this.filteredItems = !filterValue ? this._items : this._items.filter(item => {
            const checkLabel = item[this.bindLabel].toLowerCase().includes(filterValue);
            const checkValue = ($event && item[this.bindValue] === $event);
            return checkLabel || checkValue;
        });
    };

    initializeItems = () => {
        if(!this._sortItems){
            return;
        }

        this._items = _.orderBy(this._items, [item => item[this.bindLabel].toLowerCase()], ['asc']);

        if (this.selectFirstAfterSort && (this._acModel === '' || this._acModel === undefined) && this._items?.length > 0) {
            this.modelChanged(this._items[0][this.bindValue]);
        }
    };

    isSelectDisabled() {
        return (this.ngDisabled || (this.acInputContainerComponent && this.acInputContainerComponent.isDisabled));
    }

    displayWith = (value) => {
        if (_.isNil(value)) {
            return '';
        }

        let displayedValue;
        if (typeof value === 'object') {
            const mapperValue = this.itemsMapper?.[value[this.bindValue]]?.[this.bindLabel];
            const actualValue = value?.[this.bindLabel];

            displayedValue = !_.isNil(mapperValue) ? mapperValue : (!_.isNil(actualValue) ? actualValue : (value || ''));
        }

        return displayedValue || this.itemsMapper?.[value]?.[this.bindLabel] || '';
    }

    clear = () => {
        this.acModelChange.emit(undefined);
        this.triggerAutocompleteInput.writeValue(undefined);

        const selectFirstAfterSort = this.selectFirstAfterSort;
        this.selectFirstAfterSort = false;
        setTimeout(() => {
            this.selectFirstAfterSort = selectFirstAfterSort;
        })
    }

    getTextValue = (item, returnFakeValue = false) => {
        const result = item?.[this.bindLabel] || (typeof item === 'string' ? item : undefined) || undefined;

        return result || (returnFakeValue ? 'hide' : '');
    };
}



