import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngxs/store';
import {AcListEvents} from './ac-list.state';
import {SessionStorageService} from '../../services/session-storage.service';

export interface AcListItem {
    id: number | string;
    data: any;
    selected: boolean;
}

export interface AcListTemplate {
    itemTemplate?: TemplateRef<any>;
    itemTitleTemplate?: TemplateRef<any>;
    itemSubtitleTemplate?: TemplateRef<any>;
    itemContentTemplate?: TemplateRef<any>;
    itemActionsTemplate?: TemplateRef<any>;
}

@UntilDestroy()
@Component({
    selector: 'ac-list',
    templateUrl: './ac-list.component.html',
    styleUrls: ['./ac-list.component.less'],
})
export class AcListComponent {

    @Input() listId = '';
    items: AcListItem[];
    @Input('items') set setItems(items: any[]) {
        this.items = items?.map((data) => ({id: data?.id, data, selected: false}));
        if (this.items) {
            this.items && this.selectItem();
            this.loading = false;
        }
    }

    @Input() listOptions: any = {};
    _selection;
    @Input() set selection(selection: string) {
        this.items = this.items?.map((data) => ({...data, selected: false}));
        this._selection = selection;
        this.items && this.selectItem();
    }
    @Input() initialSelectionFirstTimeOnly = false;
    @Input() set refresh(refreshProps: any) {
        this.loading = !!refreshProps?.showLoader;
        this.refreshChange.emit();
    }
    @Input() templates: AcListTemplate;

    @Output() refreshChange = new EventEmitter();
    @Output() initialized = new EventEmitter();

    loading = true;


    constructor(private store: Store) {
    }

    ngOnInit() {
        this.initialized.emit();
    }

    onClick = (item) => {
        if (!item.selected) {
            this.items.forEach((val) => {
                val.selected = false;
            });

            item.selected = true;

            SessionStorageService.setData(this.listId + '.selectedItems', item);
            this.selectItem(item);
        }
    };

    selectItem = (item?) => {
        if (!item) {
            const itemInStorage = SessionStorageService.getData(this.listId + '.selectedItems');
            if (!this._selection) {
                item = itemInStorage;
            }

            if (itemInStorage && this.initialSelectionFirstTimeOnly) {
                this._selection = '';
                item = itemInStorage;
            }

            item = this.items.find((_item) => _item.data.id.toString() === (this._selection && this._selection.toString() || (item && item.data.id.toString())));


            if (this._selection) {
                SessionStorageService.setData(this.listId + '.selectedItems', item);
                this._selection = undefined;
            }
            if (item) {
                item.selected = true;
            } else {
                SessionStorageService.setData(this.listId + '.selectedItems', {data: {id: -1}});
            }
        }

        this.store.dispatch(new AcListEvents.Set({data: item ? item.data : {id: -1}, type: this.listId}));
    };
    trackById = (index, item) => {
        return item.id;
    };

}



