import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {AcTableComponent} from './ac-table.component';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {AcPaginationModule} from '../ac-pagination/ac-pagination.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcTableClientComponent} from './ac-table-client/ac-table-client.component';
import {AcTableCursorComponent} from './ac-table-cursor/ac-table-cursor.component';
import {AcTableCellDirective} from './ac-table-cell.directive';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcSearchModule} from '../ac-search/ac-search.module';
import {AcTableHeaderComponent} from './ac-table-header/ac-table-header.component';
import {AcTableFooterComponent} from './ac-table-footer/ac-table-footer.component';
import {AcTableBodyComponent} from './ac-table-body/ac-table-body.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        VirtualScrollerModule,

        InfraPipesModule,
        InfraDirectivesModule,

        AcProgressModule,
        AcPaginationModule,
        AcIconsModule,
        AcSearchModule,
    ],
    declarations: [
        AcTableComponent,
        AcTableHeaderComponent,
        AcTableBodyComponent,
        AcTableFooterComponent,
        AcTableCellDirective,
        AcTableClientComponent,
        AcTableCursorComponent,
    ],
    exports: [
        AcTableComponent,
        AcTableClientComponent,
        AcTableCursorComponent
    ],
})
export class AcTableModule {
}
