import {Component, Input, TemplateRef} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'operator-trusted-provider-issuer',
    templateUrl: './operator-trusted-provider-issuer.component.html',
    styleUrls: ['./operator-trusted-provider-issuer.component.less']
})

export class OperatorTrustedProviderIssuerComponent {

    @Input() parentObject;
    @Input() index;
    @Input() removeButtonTemplate: TemplateRef<any>;
    @Input() formSettings;

    cardHasError;

    @Input() set errors(errors) {
        if(errors?.length > 0 && this.formSettings.touched){
            this.cardHasError = _.some(errors, (error) => error.instancePath.includes('trustedProviderIssuers/' + this.index));
        }else{
            this.cardHasError = false;
        }
    }
}



