import {Injectable} from '@angular/core';

import {AcSvgComponent, AuthorizationService, GeneralService, PromiseService, SessionService, SvgModelName} from 'ac-infra';
import {authGroup} from '../utilities/session-helper.service';
import {LogoSelectionService} from '../components/logo-selection/logo-selection.service';

@Injectable({providedIn: 'root'})
export class LogoDisplayService {

    branding;// nuera for example

    tenantLogoUrl;
    whiteLabelLogoUrl;

    logoName;
    loginLogoName;

    hasSession;

    private oneLiveMode;
    private isTenantUserOrMonitorLinkOrGroupUser;
    type: 'internal' | 'branding' | 'login' | 'whiteLabel' | 'tenant' | 'empty';

    constructor(
        private generalService: GeneralService,
        private logoSelectionService: LogoSelectionService,
        private sessionService: SessionService,
    ) {
    }

    updateLogos = () => {
        this.hasSession = !!this.sessionService.activeSession;
        this.oneLiveMode = this.generalService.serverInfo.oneLiveMode;
        const isTenantUser = AuthorizationService.validFor(authGroup.tenant_user);
        const isMonitorLinkOrSPOrChannelOrEndCustomer = AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers();
        this.isTenantUserOrMonitorLinkOrGroupUser = isMonitorLinkOrSPOrChannelOrEndCustomer || isTenantUser;

        this.branding = this.generalService.serverInfo?.branding;
        const whiteLabelLogoId = this.generalService.serverInfo?.whiteLabeling?.logoFileId;

        this.logoName = (this.oneLiveMode ? SvgModelName.audiocodesLive : SvgModelName.OvocLogo);
        this.loginLogoName = (this.oneLiveMode ? SvgModelName.audiocodesLive : SvgModelName.OvocLogoText);

        if (this.sessionService.activeSession?.linkOperatorLogoId) {
            this.initTenantLogo().then(() => {
                this.finalizeLogoDecision();
            });
        } else if (whiteLabelLogoId && !this.whiteLabelLogoUrl) {
            this.logoSelectionService.getLogoImage(this.generalService.serverInfo?.whiteLabeling, 'logoFileId', true).then((url) => {
                this.whiteLabelLogoUrl = url;
                this.finalizeLogoDecision();
            });
        } else {
            this.finalizeLogoDecision();
        }
    };


    finalizeLogoDecision = () => {
        if (this.isTenantUserOrMonitorLinkOrGroupUser && this.tenantLogoUrl) {
            this.type = 'tenant';
        } else if (this.whiteLabelLogoUrl) {
            this.type = 'whiteLabel';
        } else if (this.branding) {
            this.type = 'branding';
        } else if (!this.hasSession) {
            this.type = 'login';
        } else if (!this.isTenantUserOrMonitorLinkOrGroupUser || (!this.oneLiveMode && !this.tenantLogoUrl)) {
            this.type = 'internal';
        } else {
            this.type = 'empty';
        }
    };

    clear() {
        this.tenantLogoUrl = undefined;
    }

    initTenantLogo = () => {
        return this.logoSelectionService.getLogoImage(this.sessionService.activeSession, 'linkOperatorLogoId').then((response) => {
            if (response !== -1) {
                this.tenantLogoUrl = response;
            }
            return response;
        });
    };

}
