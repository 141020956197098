<span *ngIf="checkboxGroupLabel">{{checkboxGroupLabel}}</span>
<div [id]="checkboxGroupId" [class.flexColumnParent]="fullHeight" class="ac-checkbox-group">
    <div *ngIf="!withoutHeader && !searchMode" [hidden]="disableCheckboxGroup"
         [class.all-items-are-disabled]="allItemsAreDisabled()" class="ac-checkbox-group-header">
        <span (click)="setAll(true)" class="clickable">ALL</span>
        <span>|</span>
        <span (click)="setAll(false)" class="clickable">NONE</span>
        <span>|</span>
        <span (click)="invertAll()" class="clickable">INVERT</span>
    </div>
    <div *ngIf="searchMode">
        <ac-textfield [(acModel)]="searchName" (acModelChange)="searchNameChanged($event)"
                      [placeholderText]="placeholderText"></ac-textfield>
    </div>
    <div class="ac-checkbox-group-content"
         [class.isHorizontal]="isHorizontal"
         [class.flexItemBiggest]="fullHeight">
        <div *ngFor="let item of dataItems" class="ac-checkbox-item-container">
            <label (click)="$event.stopPropagation()">
                <ac-checkbox (acModelChange)="parentModelChanged(item)"
                             [ngDisabled]="item.disabled || disableCheckboxGroup?true:null"
                             [acModel]="parentModel && parentModel[item.name]"
                             class="item-checkbox-input">
                    <div class="checkbox-content flexRowParent">
                        <i *ngIf="showIcon" class="fa checkbox-Icon {{iconClasses}} {{item.class}} {{item.colorClass}} {{iconType ? (iconType + '-' + (item.color || item.name)) : ''}}"></i>
                        <span class="item-checkbox-text {{item.textClass}}">
                            <span [innerHTML]="item.text"></span>
                        </span>
                    </div>
                </ac-checkbox>
            </label>
        </div>
    </div>
</div>
