import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {SessionStorageService} from 'ac-infra';
import {FilterFormatter} from '../../common/filters/filter-formatter';

@Injectable({providedIn: 'root'})
export class StatisticsFilterFormatter extends FilterFormatter {

    constructor() {
        super({
            setFilter: (resultFilterObject, filterObject, callingApi, saveToFile) => {
                this.setFilter(resultFilterObject, filterObject, callingApi);
            },
            setFilterForDynamicTab: (resultFilterObject, filterObject, callingApi) => {
            }
        });
    }

    setFilter = (resultFilterObject, filterObject, callingApi) => {
        if(callingApi!=='teamsDevice') {
            resultFilterObject.topology = callingApi === 'userLocation' ? 'location' : callingApi;
        }
        this.addTopologyOrCustomerGroupIdsFilter(filterObject, resultFilterObject);
        if(callingApi==='userLocation') {
            this.addArrayFilterParameter(filterObject, 'userLocations', resultFilterObject, 'locations', 'adFilters');
        }
        this.addTopologyFilter(filterObject, resultFilterObject);
        this.addTimeAndDateFilter(filterObject, resultFilterObject);
    };

    getPMDataFilter = (topic, callingApi, aggregatedFilter) => {
        const newAggregatedFilter: any = {
            timeRange: aggregatedFilter && aggregatedFilter.timeRange,
            Topology: aggregatedFilter && aggregatedFilter.Topology,
            Groups: aggregatedFilter && aggregatedFilter.Groups
        };

        newAggregatedFilter[callingApi] = topic;
        newAggregatedFilter.moreFilters_aggregated = aggregatedFilter && aggregatedFilter.moreFilters_aggregated;

        return this.formatPMFilter(newAggregatedFilter, callingApi);
    };

    formatPMFilter = (filterObject, callingApi) => {
        const resultFilterObject = {};

        this.addPmDataType(filterObject, resultFilterObject, callingApi);
        this.addTopologyFilterForPMView(filterObject, resultFilterObject);
        this.addPmParameters(filterObject, resultFilterObject, callingApi);
        this.addTimeAndDateFilter(filterObject, resultFilterObject);
        this.addTopologyOrCustomerGroupIdsFilter(filterObject, resultFilterObject);

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };

    getFilterFromState = (storeKey, topic) => {
        const parameters = SessionStorageService.getData(storeKey) || {statistics: {filter: {}}};

        parameters.statistics.filter.resolution = 1;
        parameters.statistics.filter.interval = '1_day';
        parameters.statistics.filter.pmDataType = 'Singular';
        parameters.statistics.filter.paramId = topic.parameters;
        parameters.statistics.filter.deviceId = storeKey.split('_')[1];

        if (topic && !_.isEmpty(topic.parameters)) {
            parameters.statistics.filter.paramId = topic.parameters;
        }
        if (topic && !_.isEmpty(topic.indexes)) {
            parameters.statistics.filter.index = topic.indexes;
        }

        return this.formatPMFilterForDynamicTab(parameters.statistics.filter);
    };

    formatPMFilterForDynamicTab = (filterObject) => {
        const parametersList = ['index', 'paramId', 'interval', 'pmDataType', 'resolution'];
        const resultFilterObject = {deviceId: filterObject.deviceId};

        this.addTimeAndDateFilter(filterObject, resultFilterObject);
        _.forOwn(parametersList, (parameter) => {
            this.addPropertyIfExists(resultFilterObject, filterObject, parameter);
        });

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };


    formatEntitiesFilter = (filterObject) => {
        const resultFilterObject = {};

        this.addTopologyFilterForPMView(filterObject, resultFilterObject);
        this.addTimeAndDateFilter(filterObject, resultFilterObject);

        return this.checkIfResultFilterIsEmptyObject(resultFilterObject);
    };


    private addTopologyFilter = (filterObject, resultFilterObject) => {
        const statisticsTopologyFilter = filterObject && filterObject.Topology;

        if (statisticsTopologyFilter) {
            this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: statisticsTopologyFilter.tenant});
            this.assignToFieldIfArray({resultFilterObject, field: 'regionId', array: statisticsTopologyFilter.region});

            const currentTopology = resultFilterObject.topology.toLowerCase();

            ['device', 'link', 'site'].forEach(function(entity) {
                let filterName = 'entityId';
                if (currentTopology !== 'site' && entity === 'site') {
                    filterName = 'siteId';
                }

                if (statisticsTopologyFilter[entity] && statisticsTopologyFilter[entity].length > 0) {
                    resultFilterObject[filterName] = resultFilterObject[filterName] || [];
                    resultFilterObject[filterName].push.apply(resultFilterObject[filterName], statisticsTopologyFilter[entity].map((entity) => entity.id));
                }
            });
        }
    };

    private addPropertyIfExists = (resultFilterObject, filterObject, fieldName) => {
        if (filterObject && filterObject[fieldName]) {
            resultFilterObject[fieldName] = filterObject[fieldName];
        }
    };

    private addTopologyFilterForPMView = (filterObject, resultFilterObject) => {
        const statisticsTopologyFilter = filterObject && filterObject.Topology;

        if (statisticsTopologyFilter) {
            this.assignToFieldIfArray({resultFilterObject, field: 'tenantId', array: statisticsTopologyFilter.tenant});
            this.assignToFieldIfArray({resultFilterObject, field: 'regionId', array: statisticsTopologyFilter.region});
            this.assignToFieldIfArray({resultFilterObject, field: 'deviceId', array: statisticsTopologyFilter.device});
        }
    };

    private addPmDataType = (filterObject, resultFilterObject, callingApi) => {
        if (callingApi === 'aggregatedPMs') {
            resultFilterObject.pmDataType = this.getFilterValueOrDefault(filterObject);
            resultFilterObject.limit = filterObject.aggregatedPMs.parameters.length;
        } else {
            resultFilterObject.pmDataType = 'Singular';
            resultFilterObject.limit = 100;
        }
    };

    private getFilterValueOrDefault = (filterObject) => {
        const moreFilters_aggregated = filterObject.moreFilters_aggregated;

        return !!(moreFilters_aggregated && moreFilters_aggregated.value) ?
            'NonDefaultAggregation' : 'Aggregated';
    };

    private addPmParameters = (filterObject, resultFilterObject, callingApi) => {
        const selectedMetrics = filterObject[callingApi];

        if (selectedMetrics && !_.isEmpty(selectedMetrics.parameters)) {
            resultFilterObject.paramId = selectedMetrics.parameters;
        }
        if (selectedMetrics && !_.isEmpty(selectedMetrics.indexes)) {
            resultFilterObject.index = selectedMetrics.indexes;
        }
    };
}
