import {Injectable} from '@angular/core';
import {AcTableRow, GeneralService} from 'ac-infra';

@Injectable({
    providedIn: 'root'
})
export class AcTableService {

    readonly SELECT_DEBOUNCE_TIME = 500;
    readonly NO_DEBOUNCE = 0;

    get selectDebounceTime() {
        return GeneralService.testMode ? this.NO_DEBOUNCE : this.SELECT_DEBOUNCE_TIME;
    }

    trackById = (index: number, row) => row.id;
    trackByField = (index: number, column) => column.field;

    widthToPixels = (width: number, minValue = 0) => {
        return isNaN(width) ? '' : (Math.max(width, minValue) + 'px');
    };

    getRowIndexById = (rows: AcTableRow[], rowId) => {
        if (!rowId) {
            return 0;
        }
        return rows?.findIndex(row => row.id === rowId);
    };
}
