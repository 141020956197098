import {Component, Inject, SkipSelf} from '@angular/core';
import * as _ from 'lodash';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {NetworkValidationsService} from '../../services/network-validations.service';

@AcDialog({
    id: 'site-dialog',
    title: 'Site Details',
    cancelButtonText: 'Close',
    width: 600,
})
@Component({
    selector: 'network-site-dialog',
    templateUrl: './network-site-dialog.component.html',
})
export class NetworkSiteDialogComponent {

    site: any = {};
    schema: any = {};
    isEdit: boolean;
    siteAddSchema = require('../../../../schema/post/sitesprocessor.json');
    siteEditSchema = require('../../../../schema/put/sitesprocessor.json');
    additionalSettings;
    selectedTopologyItem;

    constructor(@SkipSelf() private acDialogComponent: AcDialogComponent,
                public networkValidationsService: NetworkValidationsService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig)  {}

    ngOnInit() {
        const params = this.dialogConfig.dialogData;

        this.schema = _.cloneDeep(params.isEdit ? this.siteEditSchema : this.siteAddSchema);
        this.site = params.entity;
        this.selectedTopologyItem = params.selectedTopologyItem;
        this.additionalSettings = _.cloneDeep(this.site);
        this.additionalSettings.tenantId = undefined;

        this.isEdit = params.isEdit;
    }

}





