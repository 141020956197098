import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class SystemAuthenticationRestService extends Rest {


    constructor() {
        super({url: 'security/settings/globalSettings'});
    }

    public getSecuritySettings = (success, failure) => {
        this.get({skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editSecuritySettings = (success, failure, securitySettings) => {
        this.put(success, failure, securitySettings);
    };

    public getAuthentication = (success, type) => {
        this.get({url: 'security/settings/' + type, skipPopulateFilter: true, build204JSON: false}).then(success);
    };

    public editAuthentication = (success, failure, authentication, type) => {
        this.put(success, failure, authentication, 'security/settings/' + type);
    };

    public checkConnectivity(object, success, failure, type, id) {
        this.put(success, failure, object, 'security/actions/' + type + '/' + id);
    }
}
