import {Component, Input} from '@angular/core';
import * as moment from 'moment';
import {dateTimeFormatter, FormatterType} from 'ac-infra';
import {Operator} from '../../operator.interface';


@Component({
    selector: 'operator-suspend-field',
    templateUrl: './operator-suspend-field.component.html',
    styleUrls: ['./operator-suspend-field.component.less'],
})
export class OperatorSuspendFieldComponent {

    readonly MOMENT_ONLY_HOURS = {minute: 0, second: 0, millisecond: 0};

    @Input() operator: Operator;
    @Input() isEdit = false;
    @Input() additionalSettings: any;
    suspendSince;
    minSuspendedDate = moment().add(1, 'h').set(this.MOMENT_ONLY_HOURS);
    initiallyEmpty = false;
    suspendedOptions = [
        {value: 'notSuspended', text: 'Not Suspended'},
        {value: 'suspended', text: 'Suspended'},
        {value: 'suspendedDate', text: 'Future Suspension'},
    ];

    @Input() set globalAutoSuspendDays(globalAutoSuspendDays: number) {
        this.initializeFutureSuspendDate(globalAutoSuspendDays);

        if (!this.isEdit && globalAutoSuspendDays > 0) {
            this.additionalSettings.suspendedState = this.suspendedOptions[2].value;
            this.onSuspendedStateChange(this.additionalSettings.suspendedState);
        }
    }

    ngOnInit() {
        this.additionalSettings.suspendedState = this.suspendedOptions[0].value;
        if (this.operator.suspendStatus) {
            this.additionalSettings.suspendedState = this.suspendedOptions[1].value;
        } else if (this.operator.autoSuspendTime) {
            this.additionalSettings.suspendedState = this.suspendedOptions[2].value;
        }

        this.filterSuspendTimeIfNeeded();
    }

    initializeFutureSuspendDate(daysToSuspension) {
        let suspendedDate = moment().add(daysToSuspension, 'd');
        if (this.isEdit && this.operator.autoSuspendTime) {
            suspendedDate = moment(this.operator.autoSuspendTime);
        }

        suspendedDate.set(this.MOMENT_ONLY_HOURS);

        if (daysToSuspension === 0) {
            this.initiallyEmpty = this.isEdit && !this.operator.autoSuspendTime || !this.isEdit;
            this.additionalSettings.suspendedDateObject = {};
        }

        if (this.initiallyEmpty) {
            return;
        }

        this.additionalSettings.suspendedDateObject = {
            from: suspendedDate,
            to: suspendedDate,
        };

    }

    onSuspendedStateChange(suspendedState: any) {
        this.additionalSettings.suspendedState = this.suspendedOptions.find(suspendedOption => suspendedOption.value === suspendedState).value;
        switch (this.additionalSettings.suspendedState) {
            case 'suspended': {
                this.operator.suspendStatus = true;
                this.operator.autoSuspendTime = null;
                break;
            }
            case 'suspendedDate': {
                this.onSuspendDateChange(this.additionalSettings.suspendedDateObject);
                this.operator.suspendStatus = false;
                break;
            }
            default: {
                this.operator.suspendStatus = false;
                this.operator.autoSuspendTime = null;
            }
        }
    }

    onSuspendDateChange(suspendDateObject: any, isPickerEvent = false) {
        if (!suspendDateObject) {
            return;
        }
        if (isPickerEvent) {
            this.initiallyEmpty = false;
        }
        suspendDateObject.from?.set(this.MOMENT_ONLY_HOURS);
        suspendDateObject.to?.set(this.MOMENT_ONLY_HOURS);
        this.operator.autoSuspendTime = suspendDateObject.from ? suspendDateObject.from.toISOString() : null;
    }

    private filterSuspendTimeIfNeeded = () => {
        if (this.isEdit && this.operator.suspendTime && this.operator.suspendTime !== '') {
            this.suspendSince = dateTimeFormatter(this.operator.suspendTime, FormatterType.dateAndTime);
        }
    };
}


