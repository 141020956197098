import {Component, Inject} from '@angular/core';

import * as _ from 'lodash';
import {AcDialog, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    cancelButtonText: 'Close',
    width: 620,
    height: 700,
    title: 'Skype Details',
    id: 'lync-device-dialog'
})
@Component({
    selector: 'lync-device-dialog',
    templateUrl: './lync-device-dialog.component.html'
})
export class LyncDeviceDialogComponent {

    schema: any = {};
    isEdit: boolean;
    deviceAddSchema = require('../../../../schema/post/devicesprocessor.json');
    deviceEditSchema = require('../../../../schema/put/devicesprocessor.json');
    sslOptionsList;
    lyncProductTypesList;
    DEFAULT_MONITOR_DB_PORT = 1433;
    sqlConnection = {sqlMethodBy: 'monitorDbPort'};
    monitorDbUserAuthTypeItems = [
        {value: 'SQL_SERVER_AUTHENTICATION', text: 'SQL Server Authentication'},
        {value: 'WINDOWS_AUTHENTICATION', text: 'Windows Authentication'}
    ];

    sqlConnectionItems = [
        {value: 'monitorDbPort', text: 'SQL Port'},
        {value: 'monitorDbInstanceName', text: 'SQL Instance'}
    ];

    certificateFileOptionsList = [];
    FEDevicesList = [];
    optionLists;
    lyncFeDevices;
    isFrontEndType = false;
    lyncDevice: any = {};
    additionalSettings: any;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {}

    ngOnInit() {
        this.schema = _.cloneDeep(this.dialogConfig.dialogData.isEdit ? this.deviceEditSchema : this.deviceAddSchema);
        this.schema.required = this.schema.required || [];

        this.optionLists = this.dialogConfig.dialogData;
        this.lyncFeDevices = this.optionLists.lyncFeDevices;
        this.sslOptionsList = this.optionLists.sslOptionsList;
        this.lyncProductTypesList = this.optionLists.lyncProductTypesList;

        this.isEdit = this.dialogConfig.dialogData.isEdit;
        this.lyncDevice = this.dialogConfig.dialogData.entity;
        this.lyncDevice.lyncInfo = this.lyncDevice.lyncInfo || {};
        this.lyncDevice.productType = this.lyncDevice.productType ? this.lyncDevice.productType : 'MS_LYNC_FE';

        this.additionalSettings = _.cloneDeep(this.lyncDevice);
        this.additionalSettings.tenantId = undefined;
        this.isFrontEndServerType();

        this.sqlConnection.sqlMethodBy = this.lyncDevice.lyncInfo.monitorDbInstanceName ? 'monitorDbInstanceName' : 'monitorDbPort';

        if (!this.lyncDevice.lyncInfo.monitorDbUserAuthType) {
            this.lyncDevice.lyncInfo.monitorDbUserAuthType = this.monitorDbUserAuthTypeItems[0].value;
        }

        if (!this.lyncDevice.lyncInfo.monitorDbPort) {
            this.lyncDevice.lyncInfo.monitorDbPort = this.DEFAULT_MONITOR_DB_PORT;
        }

        this.lyncDevice.lyncInfo.monitorDbsslConnection = this.lyncDevice.lyncInfo.monitorDbsslConnection ? this.lyncDevice.lyncInfo.monitorDbsslConnection :
            this.sslOptionsList[0].name;


        this.dialogConfig.preSubmit = this.preSubmit;
    }

    preSubmit = () => {
        this.isEdit && this.lyncDevice.lyncInfo.monitorDbPassword === '' && delete this.lyncDevice.lyncInfo.monitorDbPassword;
        this.sqlConnection.sqlMethodBy === 'monitorDbInstanceName' && delete this.lyncDevice.lyncInfo.monitorDbPort;
        this.sqlConnection.sqlMethodBy === 'monitorDbPort' && delete this.lyncDevice.lyncInfo.monitorDbInstanceName;
    };

    isFrontEndServerType = () => {
        this.isFrontEndType = this.additionalSettings.productType && this.additionalSettings.productType === 'MS_LYNC_FE';
    };

    productTypeChanged = () => {
        this.additionalSettings.productType = this.lyncDevice.productType;
        this.isFrontEndServerType();

        if (this.additionalSettings.productType === 'MS_LYNC_MEDIATION') {
            this.lyncDevice.lyncInfo.reportedFrontEndId = this.lyncDevice.lyncInfo.reportedFrontEndId ? this.lyncDevice.lyncInfo.reportedFrontEndId :
                (this.FEDevicesList && this.FEDevicesList.length > 0 ? this.FEDevicesList[0].id : undefined);
        } else {
            delete this.lyncDevice.lyncInfo.reportedFrontEndId;
        }

        if (this.additionalSettings.productType !== 'MS_LYNC_FE') {
            delete this.lyncDevice.lyncInfo.monitorDbUserAuthType;
            delete this.lyncDevice.lyncInfo.monitorDbIp;
            delete this.lyncDevice.lyncInfo.monitorDbUser;
            delete this.lyncDevice.lyncInfo.monitorDbPassword;
            delete this.lyncDevice.lyncInfo.monitorDbPort;
            delete this.lyncDevice.lyncInfo.monitorDbInstanceName;
            this.lyncDevice.lyncInfo.monitorDbsslConnection = 'DISABLED';
        } else {
            this.lyncDevice.lyncInfo.monitorDbUserAuthType = this.lyncDevice.lyncInfo.monitorDbUserAuthType || this.monitorDbUserAuthTypeItems[0].value;
        }
    };

    tenantChanged = (tenantId) => {
        const certificateFiles = ['X509_PRIVATE_KEY_TYPE', 'X509_CERTIFICATE_TYPE', 'X509_TRUSTED_ROOT_CERTIFICATE_TYPE'];

        this.certificateFileOptionsList = this.optionLists.softwareFileList.filter((file) => certificateFiles.indexOf(file.fileType) >= 0 &&
            (file.tenantId === -1 || file.tenantId === tenantId));

        if (tenantId && _.isNumber(tenantId) && Array.isArray(this.lyncFeDevices)) {
            this.FEDevicesList = this.lyncFeDevices.filter((device) => device.tenantId === tenantId);

            if (this.lyncDevice.productType !== 'MS_LYNC_FE') {
                this.lyncDevice.lyncInfo.reportedFrontEndId = (this.FEDevicesList && this.FEDevicesList.length > 0 ? this.FEDevicesList[0].id : undefined);
            }
        }
    };

    monitorDbsslConnectionChanged = (event) => {
        event !== 'USING_CERTIFICATE' && delete this.lyncDevice.lyncInfo.monitorDbcertificateFileID;
    };

    updateMonitorDbUserDomain = () => {
        this.lyncDevice.lyncInfo.monitorDbWinUserDomain = this.lyncDevice.lyncInfo.monitorDbUserAuthType !== 'WINDOWS_AUTHENTICATION' ?
            '' : this.lyncDevice.lyncInfo.monitorDbWinUserDomain;
    };

    requiredsAuxiliaryForm = () => {
        const requiredList = [];

        if (this.additionalSettings.productType === 'MS_LYNC_FE') {
            requiredList.push('lyncDevice.lyncInfo.monitorDbIp');
            requiredList.push('lyncDevice.lyncInfo.monitorDbUser');
            requiredList.push('lyncDevice.lyncInfo.' + this.sqlConnection.sqlMethodBy);

            if (!this.isEdit) {
                requiredList.push('lyncDevice.lyncInfo.monitorDbPassword');
            }
        } else {
            requiredList.push('lyncDevice.lyncInfo.reportedFrontEndId');
        }

        if (this.lyncDevice.lyncInfo.monitorDbsslConnection === 'USING_CERTIFICATE') {
            requiredList.push('lyncDevice.lyncInfo.monitorDbcertificateFileID');
        }

        return requiredList;
    };
}








