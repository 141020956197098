<div class="cluster-tooltip">
    <div *ngFor="let objItem of obj | keyvalue"
         [class.selectedList]="visibleSection === objItem.key || objCount === 1"
         class="blockContainer">
        <ng-container *ngIf="isNotEmpty(objItem.value)">

            <div (click)="openSection(objItem.key)"
                 [class.singleList]="objCount === 1"
                 class="objContainer">

                <div class="rowContainer">
                    <span class="title">{{objItem.value.title}}</span>
                    <span class="spacer"></span>
                    <span>{{ objItem.value.TOTAL }}</span>
                </div>

                <div class="separator"></div>

                <div class="rowContainer">
                    <span class="errors">ERROR {{ objItem.value.ERROR }}</span>
                    <span class="spacer"></span>
                    <span class="warnings">WARNING {{ objItem.value.WARNING }}</span>
                </div>
            </div>
            <network-cluster-popup-inner [itemsDataKey]="objItem.key"
                                         [itemsData]="objItem.value.dataItems"></network-cluster-popup-inner>
        </ng-container>

    </div>
</div>
