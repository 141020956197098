import {Component, ComponentRef, Injector, Input, SecurityContext, ViewChild, ViewContainerRef} from '@angular/core';

import * as _ from 'lodash';

import {AcDialogService, AuthorizationService, SessionStorageService, WSEntities} from 'ac-infra';

import {DiagramConstants} from '../network-diagram-constants.model';

import {MetadataService} from '../../../../metadata/metadata.service';
import {EntityActionsService} from '../../../services/entity-actions.service';
import {NetworkDataService} from '../../../services/data/network-data.service';
import {CombinedNetworkActionsService} from '../../../services/actions/combined-network-actions.service';

import {NetworkEntityPopupComponent} from '../../../components/network-entity-popup/network-entity-popup.component';
import {NetworkClusterPopupComponent} from '../../../components/network-cluster-popup/network-cluster-popup.component';
import {authGroup} from '../../../../common/utilities/session-helper.service';

import {UntilDestroy} from '@ngneat/until-destroy';
import {LinkCreationService} from '../../../maps/services/link-creation.service';
import {DomSanitizer} from "@angular/platform-browser";

@UntilDestroy()
@Component({
    selector: 'network-map-geo',
    templateUrl: './network-map-geo.component.html',
})
export class NetworkMapGeoComponent {

    static readonly TOPOLOGY_MAP = 'topologyMap';
    static readonly GEO_MAP = 'geoMap';

    @Input() name = '';
    @ViewChild('host', {read: ViewContainerRef, static: true}) host: ViewContainerRef;

    mappedStatus = {
        OK: false,
        ERROR: false,
        WARNING: false,
        UNMONITORED: false
    };

    dataItemToShapeMapper: any;

    dataItemToLinkMapper: any;

    isLatlngMap: boolean;
    mapName: string;

    x;
    y;
    storageKey;
    audioCodesDevicesList: any;
    deviceProductTypeMapperList: any;
    isAuthorized = AuthorizationService.isAdminOrOperatorLevel();
    popup: ComponentRef<any>;

    constructor(private entityActionsService: EntityActionsService,
                private networkDataService: NetworkDataService,
                private combinedNetworkActionsService: CombinedNetworkActionsService,
                private injector: Injector,
                private wsEntities: WSEntities,
                private linkCreationService: LinkCreationService,
                private domSanitizer: DomSanitizer,
                private acDialogService: AcDialogService) {

    }

    ngOnInit() {
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        this.deviceProductTypeMapperList = MetadataService.getType('DeviceProductTypeMapper', true);

        this.initCtrl();

        this.dataItemToShapeMapper = {
            self: this,
            getTooltipShowEventName: this.getShapeTooltipShowEventName,
            getMarkerType: this.getMarkerType,
            getStatus: this.getStatus,
            getLabelText: this.getLabelText,
            getAdminState: this.getAdminState,
            getLatitude: this.getLatitude,
            getLongitude: this.getLongitude,
            isFiltered: this.isFiltered,
            isClusterFiltered: this.isClusterFiltered,
            isHighlighted: this.isHighlighted,
            getLabelTextColor: this.getLabelTextColor,
            getCompiledTooltip: this.getCompiledTooltip,
            getCompiledClusterTooltip: this.getCompiledClusterTooltip,
            saveMapLocation: this.saveLocation,
            revertLocation: this.revertLocation,
            isSaveMapLocationNeeded: this.isSaveMapLocationNeeded,
            displayInfoMessage: this.displayInfoMessage
        };

        this.dataItemToLinkMapper = {
            getTooltipShowEventName: this.getConnectionTooltipShowEventName,
            isHighlighted: this.isHighlighted,
            getStatusColor: this.getStatusColor,
            isFiltered: this.isFiltered,
            getLabelText: this.getLabelText,
            getLabelTextColor: this.getLabelTextColor,
            getCompiledLinksTooltip: this.getCompiledLinksTooltip,
            getCompiledTooltip: this.getCompiledTooltip
        };
    }

    initCtrl = () => {
        if (this.name === 'Topology') {
            this.isLatlngMap = false;
            this.mapName = NetworkMapGeoComponent.TOPOLOGY_MAP;
            this.storageKey = 'network.topology.devicesLocation';
            this.x = 'x';
            this.y = 'y';
        } else if (this.name === 'Map') {
            this.isLatlngMap = true;
            this.mapName = NetworkMapGeoComponent.GEO_MAP;
            this.storageKey = 'network.geo.devicesLocation';
            this.x = 'lat';
            this.y = 'lon';
        }
    };


    shapesFetchFunction = (success, ids?) => {
        this.getDataForShapes(success, ids);
    };

    linksFetchFunction = (success, ids?) => {
        this.getDataForLinks(success, this.entityActionsService.mapParameters.search, ids);
    };


    getDataForShapes = (success, ids?) => {
        const onSuccess = (value) => {
            this.manipulateDevices(value.devices.concat(value.sites), success);
        };

        setTimeout(()=>{
            this.networkDataService.getDevicesAndSitesForMap(onSuccess, this.entityActionsService.mapParameters.search, ids);
        });
    };

    getDataForLinks = (success, parameters, ids) => {
        const onSuccess = (value) => {
            this.manipulateLinks(value.links, success);
        };

        this.networkDataService.getLinksForMap(onSuccess, parameters, ids);
    };

    getCompiledTooltip = (entity) => {

        if (!this.popup || !this.popup.instance.item || this.popup.instance.item.id !== entity.id) {
            this.host.clear();
            this.popup = this.host.createComponent(NetworkEntityPopupComponent);
        }

        this.popup.instance.entityType = entity.entityType;
        this.popup.instance.item = entity;

        this.popup.changeDetectorRef.detectChanges();

        return this.popup;
    };

    isClusterFiltered = (dataItem) => !(dataItem.find(this.notFilteredDevice));

    notFilteredDevice = (item) => (item.device && item.device.filtered) || item.filtered;

    saveLocation = () => {
        this.combinedNetworkActionsService.updateShapeLocations(this.storageKey);
    };

    revertLocation = () => {
        this.combinedNetworkActionsService.revertShapeLocations(this.storageKey);
    };

    isSaveMapLocationNeeded = () => this.combinedNetworkActionsService.isUpdateShapeLocationsNeeded(this.storageKey);

    displayInfoMessage = (type) => {
        const typeToMessage = {
            shapeDraggedOutOfBounds: 'Dragging outside of the map\'s scope is prohibited.<br/>' +
                'Marker has been returned to it\'s original place.'
        };

        this.acDialogService.info(typeToMessage[type]);
    };

    manipulateDevices = (devices, success) => {
        const createdDevices = [];
        const idsToLocations = this.createMapOfShapesLocationFromStateRecorder(this.storageKey);
        devices.forEach((device) => {

            if (this.isLatlngMap) {
                if (this.invalidLocation(device)) {
                    device.lon = '0';
                    device.lat = '51.476852';

                    createdDevices.push({
                        lat: device.lat,
                        lon: device.lon,
                        id: device.id,
                        isSite: !device.productType
                    });
                } else {
                    device.lon = device.location.longitude;
                    device.lat = device.location.latitude;
                }
            } else {
                device.x = device.location.x;
                device.y = device.location.y;
            }

            if (idsToLocations && idsToLocations[device.id]) {
                device[this.x] = idsToLocations[device.id][this.x];
                device[this.y] = idsToLocations[device.id][this.y];
                idsToLocations[device.id].exists = true;
            }

            this.setAdminState(device);
            this.setShapeType(device);
            this.setType(device);
        });

        // this.removeNonExistingAndAddNew(this.storageKey, idsToLocations, createdDevices);

        success(devices);
    };

    invalidLocation = (device) => !device.location ||
            device.location.longitude === 'null' || device.location.longitude === null ||
            device.location.latitude === 'null' || device.location.latitude === null;

    manipulateLinks = (links, success) => {
        const linksMap = links.map((link) => {
            const type = link.type || {};

            const newLink = _.extend({
                to: link.linkDstId,
                from: link.linkSrcId,
                linkType: link.type,
                voiceQualityStatus: ((link.applicationsStatus || {}).voiceQuality || {}).voiceQualityStatus
            }, link);

            delete newLink.linkDstId;
            delete newLink.linkSrcId;
            delete newLink.type;

            return newLink;
        });

        success(linksMap);
    };

    /** :: HELPER FUNCTION HERE :: **/

    getLabelTextColor = (dataItem) => {
        switch (dataItem.status) {
            case 'WARNING':
                return DiagramConstants.COLORS.yellow;
            case 'ERROR':
                return DiagramConstants.COLORS.red;
            case 'UNMONITORED':
                return DiagramConstants.COLORS.grey;
            default:
                return DiagramConstants.COLORS.black;
        }
    };

    getStatusColor = (dataItem) => {
        if (this.dataItemToLinkMapper.isHighlighted(dataItem)) {
            return DiagramConstants.COLORS.purple;
        }

        const linkMappedStatus = _.cloneDeep(this.mappedStatus);

        dataItem = Array.isArray(dataItem) && dataItem.length > 0 ? dataItem : [dataItem];

        dataItem.forEach(this.mappedByStatus(linkMappedStatus));

        if (linkMappedStatus.ERROR) {
            return DiagramConstants.COLORS.red;
        }
        if (linkMappedStatus.WARNING) {
            return DiagramConstants.COLORS.yellow;
        }
        if (linkMappedStatus.OK) {
            return DiagramConstants.COLORS.blue;
        }
        if (linkMappedStatus.UNMONITORED) {
            return DiagramConstants.COLORS.grey;
        }
        return DiagramConstants.COLORS.blue;
    };


    getCompiledLinksTooltip = (links) => {

        // if (!this.popup || !this.popup.instance.dataItem || this.popup.instance.dataItem.innerLinks.length !== links.length) {
        // }

        this.host.clear();
        this.popup = this.host.createComponent(NetworkClusterPopupComponent);

        this.popup.instance.dataItem = {innerLinks: links};

        this.popup.changeDetectorRef.detectChanges();
        return this.popup;
    };

    getCompiledClusterTooltip = (clusterLayer) => {

        if (!this.popup || !this.popup.instance.dataItem || this.popup.instance.dataItem._leaflet_id !== clusterLayer._leaflet_id) {
            this.host.clear();
            this.popup = this.host.createComponent(NetworkClusterPopupComponent);
        }

        this.popup.instance.dataItem = clusterLayer;

        this.popup.changeDetectorRef.detectChanges();
        return this.popup;
    };

    mappedByStatus = (mapObj) => (item) => {
        mapObj[item.status] = true;
    };

    getLabelText = (dataItem) => this.domSanitizer.sanitize(SecurityContext.HTML,'<div>' + dataItem.name + '</div>');

    getMarkerType = (dataItem) => dataItem.markerType;

    isFiltered = (dataItem) => {
        dataItem = Array.isArray(dataItem) && dataItem.length > 0 ? dataItem : [dataItem];

        return !!(dataItem.find(this.filteredProperty));
    };

    filteredProperty = (item) => item.hasOwnProperty('filtered') && item.filtered || !item.hasOwnProperty('filtered');

    isHighlighted = (dataItem) => {
        dataItem = Array.isArray(dataItem) ? dataItem : [dataItem];

        return !!(dataItem.find((item) => item.highlight));
    };

    getStatus = (dataItem) => {
        const statusMap = {UNMONITORED: '-10', OK: '0', WARNING: '10', ERROR: '20'};
        return statusMap[dataItem.status];
    };

    getLatitude = (dataItem) => dataItem[this.x];

    getLongitude = (dataItem) => dataItem[this.y];

    getAdminState = (dataItem) => dataItem.adminState;

    setShapeType = (dataItem) => {
        dataItem.markerType = 'UNKNOWN';

        if (!dataItem.productType) {
            dataItem.markerType = 'SITE';
            return;
        }

        for (const shapeType in this.deviceProductTypeMapperList) {
            if (this.deviceProductTypeMapperList.hasOwnProperty(shapeType)) {
                if (this.deviceProductTypeMapperList[shapeType].indexOf(dataItem.productType) !== -1) {
                    dataItem.markerType = shapeType;
                }
            }
        }
    };

    createShapeWrapperWithLatLon = (shape) => {
        const shapeWrapper: any = {};
        shapeWrapper.id = shape.dataItem.id;
        shapeWrapper[this.x] = shape[this.x];
        shapeWrapper[this.y] = shape[this.y];
        shapeWrapper.isSite = !shape.dataItem.productType;
        return shapeWrapper;
    };

    getShapeTooltipShowEventName = (dataItem) => (!dataItem.productType) ? 'ShowSiteTooltip' : 'ShowDeviceTooltip';

    getConnectionTooltipShowEventName = (dataItem) => {
        if (dataItem && _.isNumber(dataItem.id) && dataItem.id >= 0) {
            return 'ShowConnectionTooltip';
        }
    };

    getDeviceDetails = (device) => this.audioCodesDevicesList[device.productType] || {};

    isLocked = (dataItem) => !!(dataItem.applicationsStatus && dataItem.applicationsStatus.management &&
            dataItem.applicationsStatus.management.administrationState === 'LOCKED');

    updateDraggedShapes = (draggedShapes) => {
        this.updateStateRecorderWithDraggedShapes(this.storageKey, draggedShapes.map(this.createShapeWrapperWithLatLon));
    };

    createMapOfShapesLocationFromStateRecorder = (key) => {
        const shapesLocationFromStateRecorder = SessionStorageService.getData(key) || [];
        const shapesLocationMap = {};

        shapesLocationFromStateRecorder.forEach((shapeFromStateRecorder) => {
            shapesLocationMap[shapeFromStateRecorder.id] = shapeFromStateRecorder;
        });

        return shapesLocationMap;
    };

    // removeNonExistingAndAddNew = (key, markedMap, newShapes) => {
    //     const shapesLocationToRecord = [];
    //
    //     if (newShapes) {
    //         newShapes.forEach((newShape) => {
    //             if (markedMap[newShape.id]) {
    //                 shapesLocationToRecord.push(newShape);
    //             }
    //         });
    //     }
    //
    //     for (const shapeLocation in markedMap) {
    //         if (markedMap.hasOwnProperty(shapeLocation)) {
    //             if (markedMap[shapeLocation].exists) {
    //                 shapesLocationToRecord.push(markedMap[shapeLocation]);
    //             }
    //             delete markedMap[shapeLocation].exists;
    //         }
    //     }
    //
    //     SessionStorageService.setData(key, shapesLocationToRecord);
    // };

    updateStateRecorderWithDraggedShapes = (keyToUpdate, draggedShapes) => {
        const draggedShapesMap = {};

        draggedShapes.forEach((shapeToDrag) => {
            draggedShapesMap[shapeToDrag.id] = shapeToDrag;
        });

        let shapesFromStateRecorder = SessionStorageService.getData(keyToUpdate) ? SessionStorageService.getData(keyToUpdate) : [];
        shapesFromStateRecorder = shapesFromStateRecorder.map((stateRecorderShape) => {
            if (draggedShapesMap[stateRecorderShape.id]) {
                stateRecorderShape[this.x] = draggedShapesMap[stateRecorderShape.id][this.x];
                stateRecorderShape[this.y] = draggedShapesMap[stateRecorderShape.id][this.y];

                delete draggedShapesMap[stateRecorderShape.id];
            }
            return stateRecorderShape;
        });

        for (const key in draggedShapesMap) {
            if (draggedShapesMap.hasOwnProperty(key)) {
                shapesFromStateRecorder.push(draggedShapesMap[key]);
            }
        }

        SessionStorageService.setData(keyToUpdate, shapesFromStateRecorder);
    };

    setAdminState = (device) => {
        device.adminState = (this.isLocked(device)) ? 'LOCKED' : 'UNLOCKED';
    };

    setType = (device) => {
        if (!device.productType) {
            device.productTypeName = 'Site';
            device.type = 'SITE';
            device.siteInfo = device.siteInfo || {};
        } else {

            const deviceDetails = this.getDeviceDetails(device);
            device.productTypeName = deviceDetails.viewName;

            if (['ACL', 'CLOUDBOND'].indexOf(deviceDetails.familyType) !== -1) {
                device.type = 'ACL';
                device.sbcInfo = device.sbcInfo || {};
            } else if (deviceDetails.familyType === 'LYNC') {
                device.type = 'LYNC';
                device.lyncInfo = device.lyncInfo || {};
            }
        }
    };
}
