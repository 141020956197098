<network-groups-selection   type="TOPOLOGY" *ngIf="acFilterComponent.filterDropDown?.isOpen && !oneLiveMode && (!pageName || (pageName && pageName !== 'endpointGroup'))"
                            [sortItems]="true"
                            [model]="topologyGroups.value"
                            (acChanged)="topologyGroups.value = $event"
                            alternativeName="topologyGroups.value">
</network-groups-selection>

<network-groups-selection   type="ENDPOINT" *ngIf="acFilterComponent.filterDropDown?.isOpen && !oneLiveMode && (pageName && ['topology', 'endpointGroup', 'endpoint'].includes(pageName))"
                            [sortItems]="true"
                            [model]="endpointsGroups.value"
                            (acChanged)="endpointsGroups.value = $event"
                            alternativeName="endpointsGroups.value">
</network-groups-selection>

<network-groups-selection   type="CUSTOMER" *ngIf="acFilterComponent.filterDropDown?.isOpen && oneLiveMode"
                            [sortItems]="true"
                            [model]="customerGroups.value"
                            (acChanged)="customerGroups.value = $event"
                            alternativeName="customerGroups.value">
</network-groups-selection>
