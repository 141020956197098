<div class="table-layout-container flexColumnParent" *ngIf="isReady">

    <div class="metricLabelContainer flexItem">
        <div class="flexColumnParent">
            <span class="metricLabel flexItem">Select report metrics and arrange the table layout</span>
            <span class="noteLabel flexItem">Reorder elements in a list using the mouse</span>
        </div>
    </div>

    <ac-drag-and-drop #dndElement (onDrop)="onDropItem($event)" class="metric-dnd-container flexItemBiggest"
                      (sortableOrderArrayChanged)="reportRestService.sortableOrderArray = $event"
                      *ngIf="report"
                      [buttonsDisabled]="report.reportType === reportRestService.reportTypes.TREND || isViewOnly"
                      [isViewOnly]="isViewOnly"
                      [listChanged]="listChanged"
                      [listsObject]="listsObject"
                      [lockedTypes]="lockedTypes"
                      [sortableOrderArray]="reportRestService.sortableOrderArray"
                      [updateColumnSortArray$]="updateColumnSortArray$"
                      lockPropertyName="columnType">
    </ac-drag-and-drop>

    <ac-input-container [displayAsInfo]="true" [upperPadding]="false" [sideMargin]="false"
                        class="dnd-input-container flexItem">
        <div [attr.alternativeName]="'list.metrics'" class="fakeMetricContent"></div>
    </ac-input-container>


    <div class="table-layout-inputs-container flexItem">

        <ac-checkbox [(acModel)]="topType.enable" labelText="Enable Top Values"
                     [sideMargin]="false"
                     (acModelChange)="topTypeCheckboxChanged()"
                     [ngDisabled]="isTopTypeCheckBoxDisabled()">
        </ac-checkbox>

        <ac-input-container label="Number of top records" [sideMargin]="false">
            <ac-single-select [(acModel)]="report.topType"
                       [attr.alternativeName]="'report.topType'"
                       [items]="topTypeList"
                       [ngDisabled]="!topType.enable"
                       class="top-type-select">
            </ac-single-select>
        </ac-input-container>

        <ac-input-container label="Number Of Rows Per Page" [sideMargin]="false">
            <ac-single-select [(acModel)]="report.itemsPerPage"
                       [attr.alternativeName]="'report.itemsPerPage'"
                       [items]="itemsPerPageList"
                       class="items-per-page-select">
            </ac-single-select>
        </ac-input-container>
    </div>
</div>
