<div class="background-container inner-container">
    <div class="default-background">
        <div class="stripes"></div>
    </div>
</div>

<div class="appLogo-container inner-container">
    <logo-display></logo-display>
</div>

<div class="companyLogo-container inner-container">
    <ac-svg class="default-company-logo" name="audiocodes_logo" width="243"></ac-svg>
</div>

<div class="splash-version inner-container">
    Ver. {{generalService.serverInfo?.appVersion}}
</div>
