<div [class.ac-input-has-error]="formHost && (formHost.formSettings.touched || alwaysShowError) && formHost?.formValidator?.[componentName]?.isValid === false"
     [class.ac-input-has-changed]="formHost?.formValidator?.[componentName]?.isChanged"
     [class.fullWidthContent]="fullWidthContent"
     class="ac-input-container-old">

    <label (click)="preventLabelClickFunc($event)" *ngIf="isFocusOnFirst"
           class="ac-input-container-old-label flexRowParent">
        <ng-container *ngTemplateOutlet="inputContent"></ng-container>
    </label>
    <div *ngIf="!isFocusOnFirst" class="ac-input-container-old-label flexRowParent">
        <ng-container *ngTemplateOutlet="inputContent"></ng-container>
    </div>
</div>

<ng-template #inputContent>

    <div *ngIf="!dontShowLabel" class="input-label flexItemBiggest">
        <div *ngIf="showCheckboxGap && !hideLabel" class="checkboxRadioContainer">
            <ac-checkbox *ngIf="checkboxModel !== undefined" (click)="$event.stopPropagation()"
                         [(acModel)]="checkboxModel"
                         (acModelChange)="checkboxModelChanged($event)"
                         [ngDisabled]="checkboxDisabled">
            </ac-checkbox>
        </div>
        <span *ngIf="(!labelTemplate && label) else labelTemplateRef" [attr.id]="componentName ? componentName + '_label' : null"
              [class.visibilityHidden]="hideLabel"
              [class.bold]="labelBold">
            <span>{{formHost && formHost.isEdit && editModePrefix ? editModePrefix + ' ' : ''}}</span>
            <span>{{label}}</span>
            <span class="ac-input-container-old-asterisk">{{formHost && formHost.formValidator && formHost.formValidator[componentName] && formHost.formValidator[componentName].isRequired && ignoreRequired !== true ? '*' : ''}}</span>
        </span>
        <ng-template #labelTemplateRef [ngTemplateOutlet]="labelTemplate"></ng-template>
    </div>

    <div #acInputChildElement [attr.id]="componentName ? componentName : null"
         [class.flexItemBiggest]="dontShowLabel && contentFlex"
         [class.flexItem]="!dontShowLabel && contentFlex"
         class="ac-content-container">
        <ng-content></ng-content>
        <div [class.visible]="formHost && (formHost.formSettings.touched || alwaysShowError)" class="ac-validation-message">{{showError() || '&nbsp;'}}</div>
    </div>
</ng-template>
