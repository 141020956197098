import {Component, ElementRef, EventEmitter, Input, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import $ from 'jquery';
import 'daterangepicker';

import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcDialogComponent} from '../ac-dialog/ac-dialog.component';
import {AcDropDownComponent} from '../ac-dropdown/ac-dropdown.component';

@UntilDestroy()
@Component({
    selector: 'ac-date-picker',
    templateUrl: './ac-date-picker.component.html',
    styleUrls: ['./ac-date-picker.component.less']
})
export class AcDatePickerComponent {

    @ViewChild('datePickerInput', {static: true}) datePickerInput: ElementRef;
    @ViewChild('acDatePickerDropdown', {static: true}) acDatePickerDropdown: AcDropDownComponent;
    // daterangepicker properties
    @Input() options: any = {
        drops: 'up',
        timePicker24Hour: true,
        alwaysShowCalendars: true,
        showDropdowns: true,
        autoUpdateInput: true,
        autoApply: true,
        timePicker: true,
        linkedCalendars: false,
        cancelButtonClasses: 'timeRangeApplyButton',
        locale: {
            format: 'DD-MMM-YY HH:mm',
            // applyLabel: "Close",
            separator: '  —  '
        }
    };
    // daterangepicker events
    @Input() ngDisabled: boolean;
    @Input() hideHours = false;
    @Input() acModel: any;
    @Input() startModelName = 'from';
    @Input() openDirection: string;
    @Input() endModelName = 'to';
    @Input() refreshDatePickerObservable: Observable<any>;
    @Input() singleDatePicker = false;
    @Input() minDate: any;
    @Input() timePicker = true;
    @Input() timePickerIncrement;
    @Input() initiallyEmpty = false;

    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelDaterangepicker = new EventEmitter();
    @Output() applyDaterangepicker = new EventEmitter();
    @Output() hideCalendarDaterangepicker = new EventEmitter();
    @Output() showCalendarDaterangepicker = new EventEmitter();
    @Output() hideDaterangepicker = new EventEmitter();
    @Output() showDaterangepicker = new EventEmitter();
    public datePicker: any;

    constructor(@Optional() public acFormComponent: AcFormComponent,
                @Optional() @SkipSelf() private acDialog: AcDialogComponent) {
    }

    ngOnInit() {
        if (this.acFormComponent && this.acFormComponent.isViewMode) {
            this.ngDisabled = true;
        }

        this.refreshDatePickerObservable && this.refreshDatePickerObservable.pipe(untilDestroyed(this)).subscribe((item) => {
            if (item) {
                this.acModel = item;
                this.acChanged.emit(this.acModel);
            }

            this.setDates();
        });

        this.render();
        this.attachEvents();
    }

    openDatePicker = () => {
        if(!this.acDatePickerDropdown?.isOpen){
            this.datePicker.show();
            this.acDatePickerDropdown.open();
        }
    };

    ngOnChanges() {
        this.setDates();
    }

    render = () => {
        this.options.timePicker = !this.hideHours;
        this.options.drops = this.openDirection || 'up';
        this.options.singleDatePicker = this.singleDatePicker;
        this.options.minDate = this.minDate === '' ? undefined : this.minDate;
        this.options.timePicker = this.timePicker;
        this.options.timePickerIncrement = this.timePickerIncrement;
        this.options.parentEl = this.acDatePickerDropdown.tooltip.nativeElement;

        // cast $ to any to avoid jquery type checking
        ($(this.datePickerInput.nativeElement) as any).daterangepicker(this.options, this.callback.bind(this));

        this.datePicker = ($(this.datePickerInput.nativeElement) as any).data('daterangepicker');
        this.setDates();
        this.datePicker.show();
        this.initiallyEmpty && this.datePicker.element.val('');
    };

    setDates = () => {
        if ($(this.datePickerInput.nativeElement).data('daterangepicker')) {
            if (this.acModel[this.startModelName]) {
                const startTime = new Date(this.acModel[this.startModelName]._d || this.acModel[this.startModelName]);
                ($(this.datePickerInput.nativeElement) as any).data('daterangepicker').setStartDate(startTime);
            }

            if (this.acModel[this.endModelName]) {
                const endTime = new Date(this.acModel[this.endModelName]._d || this.acModel[this.endModelName]);
                ($(this.datePickerInput.nativeElement) as any).data('daterangepicker').setEndDate(endTime);
            }
        }
    };

    closeDatePicker = () => {
        $(this.datePickerInput.nativeElement).data('daterangepicker').hide();
    };

    attachEvents = () => {

        const eventHandler = (eventEmitter: EventEmitter<any>, initialValue?) => (e: any, picker: any) => {
            if (this.initiallyEmpty && e.type === 'apply') {
                this.initiallyEmpty = false;
                this.callback(this.minDate, this.minDate);

                this.acDatePickerDropdown.close();
            }
            if(e.type === 'hide'){
                this.acDatePickerDropdown.close();
            }

            const event = {event: e, picker};
            eventEmitter.emit(event);
            (initialValue !== undefined) && this.initiallyEmpty && this.datePicker.element.val(initialValue);
        };
        const daterangepicker = $(this.datePickerInput.nativeElement);

        daterangepicker.on('cancel.daterangepicker', eventHandler(this.cancelDaterangepicker, ''));
        daterangepicker.on('apply.daterangepicker', eventHandler(this.applyDaterangepicker));
        daterangepicker.on('hideCalendar.daterangepicker', eventHandler(this.hideCalendarDaterangepicker));
        daterangepicker.on('showCalendar.daterangepicker', eventHandler(this.showCalendarDaterangepicker));
        daterangepicker.on('hide.daterangepicker', eventHandler(this.hideDaterangepicker, ''));
        daterangepicker.on('show.daterangepicker', eventHandler(this.showDaterangepicker));
    };

    destroyPicker = () => {
        try {
            ($(this.datePickerInput.nativeElement) as any).data('daterangepicker').remove();
        } catch (e) {
            console.log(e.message);
        }
    };

    ngOnDestroy() {
        this.destroyPicker();
    }

    private callback = (start?: any, end?: any, label?: any) => {

        if (this.initiallyEmpty) {
            this.initiallyEmpty = false;
        }

        this.acModel[this.startModelName] = start;
        this.acModel[this.endModelName] = end;

        this.setDates();
        this.acChanged.emit(this.acModel);
    };
}

