<div class="entityPopup">

    <network-entity-info [visibleFields]="visibleFields"
                         [smallDisplayName]="true"
                         [smallDisplayStatus]="true"
                         [entityType]="_entityType"
                         [entityObject]="_item">
    </network-entity-info>

    <div *ngIf="actions.any" class="flexRowParent actionButtonsSwitchContainer">
        <div [ngSwitch]="_entityType">
            <div *ngSwitchCase="'device'" class="flexRowParent actionButtons">
                <network-entity-popup-action (click)="devicesActionsService.editDevice(_item)" *ngIf="actions.editDevice"
                                             faIcon="pencil" title="Edit"></network-entity-popup-action>
                <network-entity-popup-action (click)="pageService.openDevicePage(_item)" *ngIf="actions.showDevice"
                                             faIcon="eye" title="Show"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesActionsService.deleteDevice([_item])"
                                             *ngIf="actions.deleteDevice" faIcon="trash-o"
                                             title="Delete"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesExtendedActionsService.syncDeviceLinks([_item])" *ngIf="actions.syncLinks"
                                             faIcon="exchange" title="Populate Links"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesExtendedActionsService.lockDevices([_item])" *ngIf="actions.lockDevice"
                                             faIcon="lock" title="Lock"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesExtendedActionsService.doRestAction([_item], 'unlockDevice')"
                                             *ngIf="actions.unlockDevice" faIcon="unlock"
                                             title="Unlock"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesExtendedActionsService.downloadFileIntoDevice([_item])"
                                             *ngIf="actions.softwareUpgrade" faIcon="upload"
                                             title="Update Firmware"></network-entity-popup-action>
                <network-entity-popup-action (click)="devicesExtendedActionsService.receiveFile(_item)"
                                             *ngIf="actions.uploadConfigFile" faIcon="download"
                                             title="Save Current Configuration to File"></network-entity-popup-action>
            </div>
            <div *ngSwitchCase="'site'" class="flexRowParent actionButtons">
                <network-entity-popup-action (click)="sitesActionsService.openSiteDialog({siteId: _item.id})" *ngIf="actions.editSite"
                                             faIcon="pencil"
                                             title="Edit"></network-entity-popup-action>
                <network-entity-popup-action (click)="sitesActionsService.deleteSite([_item])" *ngIf="actions.deleteSite"
                                             faIcon="trash-o"
                                             title="Delete"></network-entity-popup-action>
            </div>
            <div *ngSwitchCase="'link'" class="flexRowParent actionButtons">
                <network-entity-popup-action (click)="pageService.openLinkPage(_item.id, _item.name)" *ngIf="actions.showLink"
                                             faIcon="eye"
                                             title="Show"></network-entity-popup-action>
                <network-entity-popup-action (click)="linksActionsService.editLink(_item)" *ngIf="actions.editLink"
                                             faIcon="pencil"
                                             title="Edit"></network-entity-popup-action>
                <network-entity-popup-action (click)="linksActionsService.deleteLink([_item])" *ngIf="actions.deleteLink"
                                             faIcon="trash-o"
                                             title="Delete"></network-entity-popup-action>
            </div>
            <div *ngSwitchCase="'tenant'" class="flexRowParent actionButtons">
                <network-entity-popup-action (click)="regionsActionsService.openRegionDialog({tenantId: _item.id})"
                                             *ngIf="actions.addRegion"
                                             icon="assets/images/network/newActions/addRegion.svg"
                                             title="Add Region"></network-entity-popup-action>
                <network-entity-popup-action (click)="tenantsActionsService.editTenant(_item.id)" *ngIf="actions.editTenant"
                                             faIcon="pencil"
                                             title="Edit"></network-entity-popup-action>
                <network-entity-popup-action (click)="tenantsActionsService.deleteTenant(_item)"
                                             *ngIf="actions.deleteTenant"
                                             faIcon="trash-o"
                                             title="Delete">
                </network-entity-popup-action>

                <network-entity-popup-action (click)="tenantsActionsService.downloadOperatorConnectJSON(_item)"
                                             *ngIf="actions.downloadOCJSON"
                                             faIcon="download"
                                             title="Download Operator Connect JSON">
                </network-entity-popup-action>
            </div>
            <div *ngSwitchCase="'region'" class="flexRowParent actionButtons">
                <network-entity-popup-action (click)="devicesActionsService.addDevice({deviceDialogType: 'AC', selectedTopologyItem: _item})"
                                             *ngIf="actions.addDevice"
                                             icon="assets/images/network/newActions/addDevice.svg"
                                             title="Add Device"></network-entity-popup-action>
                <network-entity-popup-action (click)="sitesActionsService.openSiteDialog({regionId: _item.id, selectedTopologyItem: _item})" *ngIf="actions.addSite"
                                             icon="assets/images/network/newActions/addSite.svg"
                                             title="Add Site"></network-entity-popup-action>
                <network-entity-popup-action (click)="regionsActionsService.openRegionDialog({regionId: _item.id, isEdit: true})" *ngIf="actions.editRegion"
                                             faIcon="pencil"
                                             title="Edit"></network-entity-popup-action>
                <network-entity-popup-action (click)="regionsActionsService.deleteRegion([_item])"
                                             *ngIf="actions.deleteRegion"
                                             faIcon="trash-o"
                                             title="Delete"></network-entity-popup-action>
            </div>
        </div>
    </div>

</div>
