import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class TemplatesRestService extends Rest {

    constructor() {
        super({url: 'settings/templates/httpSettings'});
    }

    public getHttpTemplate = (success, failure) => {
        const onSuccess = (value) => {
            success(this.extractObject(value));
        };
        this.get({skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };

    public getSnmpTemplate = (success, failure) => {
        const onSuccess = (value) => {
            success(this.extractObject(value));
        };
        this.get({url: 'settings/templates/snmpSettings', skipPopulateFilter: true, build204JSON: false}).then(onSuccess).catch(failure);
    };

    public editHttpTemplate = (success, failure, httpTemplate) => {
        this.put(success, failure, httpTemplate);
    };

    public editSnmpTemplate = (success, failure, snmpTemplate) => {
        this.put(success, failure, snmpTemplate, 'settings/templates/snmpSettings');
    };

    public getAlarmSettings = (success, failure) => {
        this.get({url: 'alarms/settings', skipPopulateFilter: true, build204JSON: false}).then(success).catch(failure);
    };

    public editAlarmSettings(success, failure, alarmSettings) {
        this.put(success, failure, alarmSettings, 'alarms/settings');
    }
}
