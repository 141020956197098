import {Injectable} from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    ActivationEnd,
    Data,
    Event,
    NavigationEnd,
    NavigationStart,
    Route,
    Router, UrlTree
} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionStorageService} from '../../../services/session-storage.service';
import {QuicklinkStrategy} from 'ngx-quicklink';

export class NavType {
    parentRouteConfig?: Route;
    routeConfig?: Route;
    data?: Data;
    urlWithParams?: string;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AcNavAutoService {
    static readonly LAST_STATIC_ROUTE = 'lastStaticState';
    static readonly ROUTE_PREFIX = 'lastState_';
    public navs: NavType[] = [];
    changes = 0;
    private allActivatedRoutes = [];

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private quicklinkStrategy: QuicklinkStrategy) {
        router.events
            .pipe(untilDestroyed(this))
            .subscribe((event: Event) => {
                if (event instanceof NavigationStart) {
                    this.allActivatedRoutes = [];
                } else if (event instanceof ActivationEnd) {
                    if (!event.snapshot.routeConfig.loadChildren) {
                        this.allActivatedRoutes.push(event.snapshot);
                    }
                } else if (event instanceof NavigationEnd) {
                    this.lastRoute(event);
                    this.createRoutes();

                    const routesAmount = this.allActivatedRoutes.length;
                    if(routesAmount > 0){
                        this.preloadRoute(this.allActivatedRoutes[routesAmount - 1].data?.preloadUrl);
                    }
                }
            });
    }

    removeRoute = (startsWith: string) => {
        Object.keys(sessionStorage)
            .filter((key) => key.startsWith(AcNavAutoService.ROUTE_PREFIX + startsWith))
            .forEach(function(key) {
                sessionStorage.removeItem(key);
            });
    };

    gotoLastStaticState(replaceUrl = false) {
        const saved = SessionStorageService.getData(AcNavAutoService.LAST_STATIC_ROUTE);
        this.router.navigateByUrl(saved || '', {replaceUrl});
    }

    tick() {
        this.changes++;
    }

    private createRoutes() {
        this.allActivatedRoutes.reverse();
        this.navs = [];

        this.allActivatedRoutes.forEach((snapshot: ActivatedRouteSnapshot, index) => {
            const parentSnapshot = snapshot.parent;
            const isLazyRoute = !!parentSnapshot.routeConfig?.loadChildren;
            this.navs[index] = new NavType();
            this.navs[index].routeConfig = {
                ...snapshot.routeConfig,
                ...isLazyRoute && {path: parentSnapshot.routeConfig.path}
            };
            this.navs[index].data = snapshot.data;
            this.navs[index].urlWithParams = [
                ...(isLazyRoute ? parentSnapshot.url : []).map(x => x.path),
                ...snapshot.url.map(x => x.path),
            ].filter(url => url.length).join('/');
        });
        this.tick();
    }

    private lastRoute(event: NavigationEnd) {
        if (event.url.startsWith('/#')) {
            return this.router.navigateByUrl('', {replaceUrl: true});
        }
        let routeConfig;
        let lastRoute;
        if (this.allActivatedRoutes[0].routeConfig.children) {
            lastRoute = false;
            routeConfig = this.allActivatedRoutes[0].routeConfig;
        } else if (this.allActivatedRoutes[0].routeConfig.path === '**') {
            lastRoute = false;
            routeConfig = this.allActivatedRoutes[1].routeConfig;
        } else {
            lastRoute = true;
            const route = this.allActivatedRoutes[0];
            routeConfig = route.routeConfig;
        }

        if (routeConfig.data && routeConfig.data.autoNavNoHistory) {
            return;
        }

        if (event.urlAfterRedirects !== '/' && (!this.allActivatedRoutes[1].routeConfig.data || !this.allActivatedRoutes[1].routeConfig.data.navAutoHidden)) {
            SessionStorageService.setData(AcNavAutoService.LAST_STATIC_ROUTE, event.urlAfterRedirects);
        }

        if (lastRoute) {
            const all = event.urlAfterRedirects.split('/');
            while (all.length > 0) {
                const current = all.pop();
                SessionStorageService.setData(AcNavAutoService.ROUTE_PREFIX + (all.length === 1 ? '/' : all.join('/')), all.join('/') + '/' + current);
            }
        } else {
            if (Object.keys((this.activatedRoute.queryParams as any).value).length) {
                this.router.navigateByUrl(event.url.slice(0, event.url.lastIndexOf('?')), {replaceUrl: true});
                return;
            }

            const defaultRoute = routeConfig.children.find(x => x.path === '**');
            let defaultState = defaultRoute.data && defaultRoute.data.default;
            if (defaultState) {
                const separator = event.urlAfterRedirects.endsWith('/') ? '' : '/';
                defaultState = event.urlAfterRedirects + separator + defaultState;
            }

            const lastState = SessionStorageService.getData(AcNavAutoService.ROUTE_PREFIX + event.urlAfterRedirects);

            let nextPath = lastState ? lastState : defaultState;
            let hasMoreRoutes = true;
            while (hasMoreRoutes) {
                const nextOptionalPath = SessionStorageService.getData(AcNavAutoService.ROUTE_PREFIX + nextPath);
                if (nextOptionalPath) {
                    nextPath = nextOptionalPath;
                } else {
                    hasMoreRoutes = false;
                }
            }

            const circularRequest = nextPath === event.url;
            const circularResolved = nextPath !== event.urlAfterRedirects;
            if (circularResolved && !circularRequest) {
                this.router.navigateByUrl(nextPath, {replaceUrl: true});
            } else if (circularRequest && defaultState) {
                this.router.navigateByUrl(defaultState, {replaceUrl: true});
            }
        }
    }

    preloadRoute(url: string) {
        if (!url) {
            return;
        }
        const tree: UrlTree = this.router.parseUrl(url);
        const registry = (this.quicklinkStrategy as any).registry;
        if (!registry.shouldPrefetch(url)) {
            registry.add(tree);
        }
    }
}
