import {Component, Input} from '@angular/core';
import {MetadataService} from '../../../metadata/metadata.service';
import * as _ from 'lodash';
import {GeneralService, SchemaHelperService} from 'ac-infra';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TenantsRestService} from '../../../network/services/apis/tenants-rest.service';

@UntilDestroy()
@Component({
    selector: 'tenant-allocation',
    templateUrl: './tenant-allocation.component.html',
    styleUrls: ['./tenant-allocation.component.less']
})
export class tenantAllocationComponent {

    @Input() tenantLicenseAllocation: any;
    @Input() originalTenantLicenseAllocation: any;
    @Input() ovocMainLicense: any;
    @Input() isSystemAlloc = false;
    @Input() hideHeader = false;

    LicenseOrderList: any;

    licenseObj: any = {
        voiceQualityFeatures: 'Voice Quality',
        endpointsFeatures: 'Endpoints Management',
        licensePoolFeatures: 'License Pool'
    };

    constructor(public generalService: GeneralService,
                private schemaHelperService: SchemaHelperService,
                private tenantsRestService: TenantsRestService) {}

    ngOnInit() {
        this.LicenseOrderList = MetadataService.getType('LicenseOrder', true);

        if (this.isSystemAlloc) {
            this.licenseObj = {voiceQualityFeatures: 'Voice Quality'};
            this.LicenseOrderList.voiceQualityFeatures = {totalReports: 'Reports'};
        }

        this.tenantsRestService.validateAllocAuxiliaryForm$.pipe(untilDestroyed(this)).subscribe(({errors, auxModel, formModel}) => {
            this.validateAuxiliaryForm(errors, auxModel, formModel);
        });
    }

    spinnerChanged = (key, objName, value) => {
        if (!Number.isInteger(value)) {
            this.tenantLicenseAllocation[objName][key] = 0;
        }
    };

    buildAlternativeName = (prefix, itemName) => prefix + itemName;

    getTotalLicensesValue = (featuresName, itemName) => this.ovocMainLicense[featuresName][itemName] || 0;

    getUsedLicensesValue = (featuresName, itemName) => {
        const featureMainAllocationValue = this.ovocMainLicense[featuresName + 'Allocated'][itemName];
        const currentInputAllocationValue = this.tenantLicenseAllocation[featuresName][itemName];
        const originalInputAllocationValue = this.originalTenantLicenseAllocation[featuresName][itemName];

        return (featureMainAllocationValue + currentInputAllocationValue - originalInputAllocationValue) || currentInputAllocationValue;
    };

    getMaxLicensesValue = (featuresType, itemName) => (this.ovocMainLicense[featuresType] && this.ovocMainLicense[featuresType][itemName]) ? Math.max(0, this.calculateMaxLicensesValue(featuresType, itemName)) : 0;

    validateAuxiliaryForm = (errors, auxAllocModel, formModel) => {

        _.forOwn(auxAllocModel, (featureObj, featureName) => {
            _.forOwn(featureObj, (itemValue, itemName) => {
                if (itemValue > this.getMaxLicensesValue(featureName, itemName)) {
                    errors.push(this.schemaHelperService.buildErrorItem({instancePath: '/licenseAllocations/' + featureName + '/' + itemName,
                        inputName: 'licenseAllocations.' + featureName + '.' + itemName, keyword: 'invalidAllocation'}));
                }
            });
        });
    };

    private calculateMaxLicensesValue = (featuresType, itemName) => (this.ovocMainLicense[featuresType][itemName] - this.ovocMainLicense[featuresType + 'Allocated'][itemName] + this.originalTenantLicenseAllocation[featuresType][itemName]);
}



