<ac-input-container label="Suspension">
    <ac-single-select [items]="suspendedOptions"
               [(acModel)]="additionalSettings.suspendedState"
               (acModelChange)="onSuspendedStateChange($event)"
    ></ac-single-select>
</ac-input-container>


<ng-container *ngIf="additionalSettings.suspendedState === 'suspended'">

    <ac-input-container label="Suspension Reason">
        <ac-textfield [(acModel)]="operator.suspendReason"
                      [attr.alternativeName]="'operator.suspendReason'"
                      textFieldId="operator-form-suspension-reason">
        </ac-textfield>
    </ac-input-container>

    <ac-input-container *ngIf="isEdit && suspendSince" label="Suspended Since">
        <div>{{suspendSince}}</div>
    </ac-input-container>
</ng-container>

<ac-input-container *ngIf="additionalSettings.suspendedState === 'suspendedDate' && additionalSettings.suspendedDateObject" class="operator-suspend-date"
                    label="Choose suspension date">
    <ac-date-picker openDirection="auto"
                    [singleDatePicker]="true"
                    [timePickerIncrement]="60"
                    [initiallyEmpty]="initiallyEmpty"
                    [minDate]="minSuspendedDate"
                    [acModel]="additionalSettings.suspendedDateObject"
                    (acChanged)="onSuspendDateChange($event, true)"
                    [attr.alternativeName]="'additionalSettings.suspendedDateObject'"
    ></ac-date-picker>
</ac-input-container>
