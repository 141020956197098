<div class="ac-input-container-box flexRowParent">
    <ng-template *ngIf="preTemplate" class="flexItemBiggest" [ngTemplateOutlet]="preTemplate"></ng-template>

    <mat-form-field [appearance]="borderAppearance ? 'outline' : null"
                    floatLabel="always"
                    class="ac-input-container"
                    [class.mat-form-field-invalid]="((error && formHost?.formSettings?.touched) || customError)"
                    [class.readOnly]="readOnly"
                    [class.upper-padding]="upperPadding"
                    [class.border-appearance]="borderAppearance"
                    [class.display-only-error]="displayOnlyError"
                    [class.display-as-basic]="displayAsBasic"
                    [class.display-as-info]="displayAsInfo"
                    [class.material]="!listOfNonMaterialComponents.includes(elementType) && isMaterial">

        <mat-label class="mat-label" *ngIf="!borderAppearance"
                   [attr.id]="componentName ? componentName + '_label' : null"
                   [class.visibilityHidden]="!inputText || inputText === '' || hideLabel || !isMaterial"
                   [class.bold]="labelBold">
            <ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>
        </mat-label>

        <div #acInputChildElement class="ac-content-container">
            <ng-content></ng-content>
        </div>

        <ng-container *ngIf="beforeViewInit">
            <input matInput class="input-for-initialize"/>
        </ng-container>

        <mat-hint class="mat-error"
                  *ngIf="formHost?.formSettings?.touched || alwaysShowError || customError">{{showError()}}</mat-hint>
    </mat-form-field>

    <ng-template *ngIf="postTemplate" [ngTemplateOutlet]="postTemplate"></ng-template>
</div>


<ng-template #labelTemplate>
    <div class="flexRowParent">
        <div [title]="inputText" class="input-label-text ellipsis">{{inputText}}</div>
        <div>{{isRequired()}}</div>
        <i *ngIf="infoIconText" class="fa fa-info-circle info-icon" [matTooltip]="infoIconText"></i>
    </div>
</ng-template>
