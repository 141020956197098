import {Component, Input, TemplateRef} from '@angular/core';
import * as _ from 'lodash';
import {MetadataService} from '../../../../metadata/metadata.service';

@Component({
    selector: 'operator-connect-webhook',
    templateUrl: './operator-connect-webhook.component.html',
    styleUrls: ['./operator-connect-webhook.component.less']
})

export class OperatorConnectWebhookComponent {

    @Input() parentObject;
    @Input() fullParentPath;
    @Input() authorized;
    @Input() formSettings;
    @Input() removeButtonTemplate: TemplateRef<any>;
    @Input() calculateItemsInTreeNode: Function;

    cardHasError;
    headerHasError;
    @Input() set errors(errors) {
        if(errors?.length > 0 && this.formSettings.touched){
            const withError = _.some(errors, (error) => error.instancePath === '/operatorConnectInfo/webhook');
            this.headerHasError = {};
            this.parentObject.webhook.headers.forEach((value, index) => {
                this.headerHasError[index] = _.some(errors, (error) => error.instancePath.includes('/operatorConnectInfo/webhook/headers/' + index));
            });

            this.cardHasError = withError;
        }else{
            this.cardHasError = false;
        }
    }
    HTTPMethodType = MetadataService.getType('HTTPMethodType');
    HTTPMethodTypeList = [];

    ngOnInit(){
        _.forOwn(this.HTTPMethodType, (offerType) => {
            this.HTTPMethodTypeList.push(offerType);
        });
    }

    addHeader = () => {
        this.parentObject.webhook.headers.push({});
        this.calculateItemsInTreeNode('webhook');
    };

    removeHeader = (indexToDelete) => {
        const result = [];

        this.parentObject.webhook.headers.forEach((obj, index) => {
            if(indexToDelete !== index){
                result.push(_.cloneDeep(obj));
            }
        });

        this.parentObject.webhook.headers = result;
        this.calculateItemsInTreeNode('webhook');
    };
}



