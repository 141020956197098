import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReportEntitiesService} from '../../../reports/services/report-entities.service';
import * as _ from 'lodash';
import {ReportRestService} from '../../../services/report-rest.service';
import {TenantsRestService} from '../../../../network/services/apis/tenants-rest.service';
import {AuthorizationService, GeneralService, SessionService} from 'ac-infra';
import {authGroup} from '../../../../common/utilities/session-helper.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'definition-tab',
    templateUrl: './definition-tab.component.html',
    styleUrls: ['./definition-tab.component.less']
})
export class DefinitionTabComponent {

    @Input() reportTypeList: any;
    @Input() report: any;
    @Input() metrics: any;
    @Input() additionalSettings: any;
    @Input() isEdit: boolean;
    @Input() isReady: boolean;
    @Input() isViewOnly = false;
    @Input() handleColumnsList: Function;
    @Input() executeTopTypeListUpdate: Function;
    @Input() getGroupSubject: Subject<any>;
    @Output() topTypeListUpdate = new EventEmitter<any>();
    @Output() treeScopeChanged = new EventEmitter<any>();

    logoObject: any = {title: 'Logo (PNG, JPEG)', files: [], logoId: -1};
    customLogo = [{name: 'None', id: -1}];
    tenantList: any;

    categoriesList = [];
    session;
    authorizedToDoOnlyLinkActions = AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers();
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;

    constructor(private tenantsRestService: TenantsRestService,
                private reportEntitiesService: ReportEntitiesService,
                public reportRestService: ReportRestService,
                private sessionService: SessionService,
                private generalService: GeneralService) {}

    ngOnInit() {
        this.session = this.sessionService.activeSession;

        const authorizedForSystemAdminAndOperator = AuthorizationService.isSystemAdminOrOperatorLevel();
        this.logoObject.reportLogoType = this.report.reportLogoType || 'Global';

        if (this.oneLiveMode && this.authorizedToDoOnlyLinkActions) {
            if(!this.isEdit){
                this.report.tenantId = undefined;
                this.additionalSettings.tenantId = undefined;
            }

            if(this.report.reportLogoType === 'Tenant'){
                this.logoObject.reportLogoType = 'Global';
            }
        }

        if (this.report.reportLogoId) {
            this.logoObject.logoId = this.report.reportLogoId;
        }

        setTimeout(() => {
            this.reportTypeChanged();
        });

        let tenants = this.tenantsRestService.getAllEntities();
        tenants = _.orderBy(tenants, [tenant => tenant.name.toLowerCase()], ['asc']);

        this.tenantList = (authorizedForSystemAdminAndOperator || this.isViewOnly) ?
            [{name: 'Global', id: -2}, {name: 'System', id: -1}].concat(tenants) : tenants;

        if(AuthorizationService.validFor(authGroup.tenant_user) && this.tenantList.length > 0 && this.report.tenantId < 0){
            this.report.tenantId = this.tenantList[0].id;
        }

        if (_.isNil(this.report.description)) {
            delete this.report.description;
        }

        this.categoriesList = _.cloneDeep(this.reportEntitiesService.categories).filter((category) => category.category !== 'All');
    }

    tenantIdChanged = () => {
        this.treeScopeChanged.emit();
        this.handleColumnsList();
        this.getGroupSubject.next(null);
    };

    trendReportMetricIDChanged = () => {
        const selectedMetric = this.reportEntitiesService.getFullColumn({
            columnBaseId: this.report.trendReportMetricID,
            columnType: 'Metric'
        });

        this.report.graphs.forEach((graph, index) => {
            if (!selectedMetric.graphTypes.includes(graph.graphType)) {
                delete this.report.graphs[index].graphType;
            }
        });
    };

    reportTypeChanged = () => {
        this.additionalSettings.reportType = this.report.reportType ? this.report.reportType : this.additionalSettings.reportType;
        this.reportEntitiesService.executeUpdateTopType();
        switch (this.report.reportType) {
            case this.reportRestService.reportTypes.ELEMENT:
                delete this.report.trendReportMetricID;
                delete this.report.reportInterval;
                this.topTypeListUpdate.emit(this.reportRestService.topTypeListElement);
                break;
            case this.reportRestService.reportTypes.AGGREGATION:
                delete this.report.trendReportMetricID;
                this.topTypeListUpdate.emit(this.reportRestService.topTypeListAggAndTrend);
                break;
            case this.reportRestService.reportTypes.TREND:
                this.report.trendReportMetricID = this.report.trendReportMetricID ? this.report.trendReportMetricID : (this.metrics && this.metrics.length > 0 ? this.metrics[0].columnBaseId : undefined);
                this.topTypeListUpdate.emit(this.reportRestService.topTypeListAggAndTrend);
                this.trendReportMetricIDChanged();
                break;

        }
    };
}


