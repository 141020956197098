<div class="confirm-dialog-container">

    <span [innerHTML]="message"></span>

    <div *ngIf="options?.checkboxModel as checkboxModel" class="confirm-dialog-optional-checkbox-container">
        <ac-checkbox [(acModel)]="checkboxModel.value"
                     [labelText]="checkboxModel.label"
                     [ngDisabled]="disableModel"
                     checkboxId="confirm-dialog-additional-options-checkbox">
        </ac-checkbox>
    </div>

    <div *ngIf="options?.radioButtonsModel as radioButtonsModel" class="confirm-dialog-optional-radio-button-container">
        <ac-radio-buttons-group [(acModel)]="radioButtonsModel.value"
                                [items]="radioButtonsModel.items"
                                [ngDisabled]="disableModel"
                                class="vertical"
                                radioButtonId="confirm-dialog-additional-options-radio-button">
        </ac-radio-buttons-group>
    </div>
</div>
