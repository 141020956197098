import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import $ from 'jquery';
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'ac-slider',
    templateUrl: './ac-slider.component.html',
    styleUrls: ['./ac-slider.component.less'],
})
export class AcSliderComponent {

    @Input() acModel = 0;
    @Input() ceil = 0;
    _options:any = {};

    @Input() set options(options) {
        this._options = {...options, translate: this.translate}
    }

    @Input() hideSliderPointer = false;
    @Input() showTooltip = false;
    @Input() tooltipText: string;
    @Input() sliderName: string;
    @Input() hideValueBubble = false;
    @Output() acChanged: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('sliderContainer', {static: true}) sliderContainerElement: ElementRef;

    debounceTimer: any;
    typedNumber = '';
    public showSlider = false;

    constructor(private decimalPipe:DecimalPipe) {
    }


    ngOnInit() {
        setTimeout(() => {
            if (this.ceil > 0) {
                this._options.ceil = this.ceil;
            }

            this.showSlider = true;
        }, 100);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.showTooltip) {
                $(this.sliderContainerElement.nativeElement).find('.ng5-slider-pointer').attr('title', this.tooltipText);
                $(this.sliderContainerElement.nativeElement).find('.ng5-slider-model-value').attr('title', this.tooltipText);
            }
        }, 100);
    }

    onChange() {
        this.acChanged.emit(this.acModel);
    }

    onClick() {
        this.sliderContainerElement.nativeElement.querySelector('.ng5-slider-pointer').focus();
    }

    onKeydown(event) {
        if(isNaN(event.key)){
            return;
        }
        this.typedNumber = this.typedNumber + event.key;
        this.updateNum();
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        this.debounceTimer = setTimeout(() => {
            this.updateNum();
            this.typedNumber = '';
        }, 1500);

    }

    private updateNum() {
        const num = parseInt(this.typedNumber, 10);
        const isNumber = !isNaN(num);
        if (isNumber) {
            this.acModel = num;
            this.acChanged.emit(this.acModel);
        }
    }

    private translate = (value) => {
        const val = (this.typedNumber ? this.typedNumber : value);
        return isNaN(val)?val:this.decimalPipe.transform(val);
    };
}



