import {Injectable} from '@angular/core';
import {Rest} from '../../../common/server-actions/rest';

@Injectable({providedIn: 'root'})
export class OperatorsRestService extends Rest {

    constructor() {
        super({url: 'security/operators'});
    }

    public getOperator = (operatorId, success, failure) => {
        this.getById({success, failure, id: operatorId, skipPopulateFilter: true, build204JSON: false});
    };

    public getOperators = (success, failure?, parameters?) => {
        const onSuccess = (value) => {
            success(this.extractOperators(value));
        };
        this.get({parameters, skipPopulateFilter: true, build204JSON: false, extendParameters: true}).then(onSuccess).catch(failure);
    };

    extractOperators = (value) => value.status === 204 ? {
        data: {
            operators: [],
            pages: {size: 0, total: 0, current: 0, totalElements: 0}
        }
    } : value;
}
