<ac-form *ngIf="dialogConfig.ready"
         [formSettings]="formSettings">

    <div id="select-pm-profile-content">
        <ac-input-container label="Profile">
            <ac-single-select [(acModel)]="selectedProfile.value"
                       [bindLabel]="'name'"
                       [bindValue]="'id'"
                       [items]="profileSelectionOptions">
            </ac-single-select>
        </ac-input-container>
    </div>

</ac-form>
