import {Injectable} from '@angular/core';
import {SitesRestService} from '../apis/sites-rest.service';
import {TenantsRestService} from '../apis/tenants-rest.service';
import {RegionsRestService} from '../apis/regions-rest.service';
import * as _ from 'lodash';
import {NetworkSiteDialogComponent} from '../../dialogs/network-site-dialog/network-site-dialog.component';
import {Actions} from '../../../common/server-actions/actions';
import {RestResponseSuccess} from "../../../common/server-actions/rest";

@Injectable({providedIn: 'root'})
export class SitesActionsService extends Actions {

    visibleFieldsForSiteInfo = ['tenantId', 'regionId'];

    constructor(private sitesRestService: SitesRestService,
                private tenantsRestService: TenantsRestService,
                private regionsRestService: RegionsRestService) {
        super({entityName: 'site', entityService: sitesRestService, isWsEntity: true});
    }

    deleteSite = (sitesArray) => this.delete({entityArray: sitesArray});

    openSiteDialog = ({siteId = undefined, regionId = undefined, selectedTopologyItem = undefined}) => {
        const isEdit = !!siteId;
        const dialogRef = this.acDialogService.open();
        if(isEdit){
            const success = (response: RestResponseSuccess) => {
                this.prepareSiteDialog(isEdit, response.data, selectedTopologyItem, dialogRef);
            }
            this.sitesRestService.getById({success, extendParameters: true, skipPopulateFilter: true, id: siteId});
        }else{
            const site = {regionId, autoDetectionAddressSpace: {subnetMasks: []}};
            this.prepareSiteDialog(isEdit, site, selectedTopologyItem, dialogRef);
        }
    };

    prepareSiteDialog = (isEdit, site, selectedTopologyItem, dialogRef) => {
        if (this.regionsRestService.getAllEntities().length === 0) {
            this.acDialogService.info('Can not add site when there are no regions.');
            return;
        }

        const id = site.id;
        delete site.tenantId;

        const dialogData = {entity: site, isEdit, selectedTopologyItem};

        const serverCallback = (onSuccess, onFailure) => {
            dialogData.entity.description = dialogData.entity.description || '';

            if (isEdit) {
                this.sitesRestService.edit(onSuccess, onFailure, dialogData.entity, id);
            } else {
                this.sitesRestService.add(onSuccess, onFailure, dialogData.entity);
            }
        };
        const dialogComponentType = NetworkSiteDialogComponent;

        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogRef});
    }
}
