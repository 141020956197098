import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CountdownModule} from 'ngx-countdown';

import {AcMenuCountdownComponent} from './ac-menu-countdown/ac-menu-countdown.component';
import {AcMenuOptionsComponent} from './ac-menu-options/ac-menu-options.component';
import {AcMenuUserInfoComponent} from './ac-menu-user-info/ac-menu-user-info.component';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        AcIconsModule,
        CountdownModule,
        InfraDirectivesModule,
        InfraPipesModule,
    ],
    declarations: [
        AcMenuUserInfoComponent,
        AcMenuOptionsComponent,
        AcMenuCountdownComponent,
    ],
    exports: [
        AcMenuUserInfoComponent,
        AcMenuOptionsComponent,
        AcMenuCountdownComponent,
    ]
})
export class AcMenuModule {
}
