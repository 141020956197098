import {Component, Inject} from '@angular/core';
import {AcDialog, DIALOG_CONFIG, DialogConfig, LoggerService, SchemaHelperService} from 'ac-infra';
import * as _ from 'lodash';
import {MetadataService} from '../../../metadata/metadata.service';
import {LicenseRestService} from '../../services/apis/license-rest.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';

@AcDialog({
    id: 'license-pool-dialog',
    title: 'License Pool Details',
    cancelButtonText: 'Close',
    height: 350
})
@Component({
    selector: 'license-pool-dialog',
    templateUrl: './license-pool-dialog.component.html',
    styleUrls: ['./license-pool-dialog.component.less'],
})
export class LicensePoolDialogComponent {

    schema = {};
    sbcParameters = ['totalSBCSessions', 'totalSBCTranscoding', 'totalSBCRegistrations', 'totalSBCSignaling'];
    sbcAllocations = ['sbcSessions', 'sbcTranscoding', 'sbcRegistrations', 'sbcSignaling'];
    cbParameters = ['totalCBUsers', 'totalCBPbxUsers', 'totalCBAnalogDevices', 'totalCBVoicemailAccounts'];

    isSbc: boolean;
    isCloudBond: boolean;
    newPoolAllocation: boolean;

    _isPoolAllocation = {
        sbcRegistrations: false,
        sbcSignaling: false,
        sbcTranscoding: false,
        sbcSessions: false,
        totalCBUsers: false,
        totalCBPbxUsers: false,
        totalCBAnalogDevices: false,
        totalCBVoicemailAccounts: false
    };

    sbcAlloctions = {
        sbcRegistrations: 'SBC Registrations',
        sbcSignaling: 'SBC Signaling',
        sbcTranscoding: 'SBC Transcoding',
        sbcSessions: 'SBC Sessions'
    };
    cbAlloctions = {
        totalCBUsers: 'CB Users',
        totalCBPbxUsers: 'CB PBX Users',
        totalCBAnalogDevices: 'CB Analog Devices',
        totalCBVoicemailAccounts: 'CB Voicemail Accounts'
    };

    selectedDevices: any;
    selectedCount: number;
    HADevicesCount: number;
    licensePoolAllocations;
    licensePool: any;
    newObj: any;
    originalLicensePool: any;
    deviceType: any;
    LicenseAllocationsList;
    license;
    SBCAndCBView = false;

    constructor(private schemaHelperService: SchemaHelperService,
                private loggerService: LoggerService,
                private licenseRestService: LicenseRestService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {

        this.LicenseAllocationsList = MetadataService.getType('LicenseAllocations', true);

        this.selectedDevices = this.dialogConfig.dialogData.selectedDevices;
        this.selectedCount = this.dialogConfig.dialogData.selectedDevices.length;
        this.HADevicesCount = this.dialogConfig.dialogData.selectedDevices.filter((item) => item.isHA).length;

        this.licensePoolAllocations = {sbcAllocationsParameters: [], cbAllocationsParameters: []};
        this.getLicense(parseInt(this.dialogConfig.dialogData.licensePool.tenantId, 10));
        this.licensePool = _.cloneDeep(this.dialogConfig.dialogData.licensePool);
        this.newObj = this.dialogConfig.dialogData.entity;
        this.originalLicensePool = this.dialogConfig.dialogData.licensePool;
        this.deviceType = this.dialogConfig.dialogData.deviceType;
        this.SBCAndCBView = this.showSBCMenu() && this.showCBMenu();

        this.dialogConfig.width = this.SBCAndCBView ? 700 : 450;

        Object.keys(this._isPoolAllocation).forEach((key) => {
            this._isPoolAllocation[key] = this.licensePool[key].PoolAllocation !== -1;
            this.licensePool[key].PoolAllocation = this.licensePool[key].PoolAllocation !== -1 ? this.licensePool[key].PoolAllocation : undefined;
        });

        this.dialogConfig.preSubmit = this.updateValues;
        this.dialogConfig.formSettings = {valid: true, touched: false};
        this.poolAllocationChanged();
    }

    poolAllocationChanged(fieldKey = undefined) {
        this.isSbc = true;
        this.isCloudBond = true;
        this.sbcAllocations.forEach((item) => {
            if (this._isPoolAllocation[item]) {
                this.isCloudBond = false;
            }
        });
        this.cbParameters.forEach((item) => {
            if (this._isPoolAllocation[item]) {
                this.isSbc = false;
            }
        });

        if (this._isPoolAllocation[fieldKey] === false) {
            this.licensePool[fieldKey].PoolAllocation = 0;
        }
    }


    isUncheckedValue = (field) => {
        this.licensePool[field].PoolAllocation = undefined;
    };

    unManaged = () => {
        if (this.licensePool.managed === true) {
            this.isSbc = true;
            this.isCloudBond = true;
        } else {
            this.isSbc = false;
            this.isCloudBond = false;
        }

        Object.keys(this._isPoolAllocation).forEach((key) => {
            this.licensePool[key].PoolAllocation = undefined;
            this._isPoolAllocation[key] = false;
        });
    };

    updateValues = () => {
        this.originalLicensePool.managed = this.licensePool.managed;
        Object.keys(this._isPoolAllocation).forEach((key) => {
            this.originalLicensePool[key].PoolAllocation = this._isPoolAllocation[key] ? this.licensePool[key].PoolAllocation : -1;
        });

        if (this.deviceType === 'ACL' || this.deviceType === 'UNKNOWN') {
            this.licensePoolAllocations.sbcAllocationsParameters.forEach((field) => {
                this.newObj[field] = {PoolAllocation: this.originalLicensePool[field].PoolAllocation};
            });
        }
        if (this.deviceType === 'CLOUDBOND' || this.deviceType === 'UMP' || this.deviceType === 'UNKNOWN') {
            this.licensePoolAllocations.cbAllocationsParameters.forEach((field) => {
                this.newObj[field] = {PoolAllocation: this.originalLicensePool[field].PoolAllocation};
            });
        }
        this.newObj.managed = this.originalLicensePool.managed;
        if (this.licensePool.id) {
            this.newObj.id = this.originalLicensePool.id;
        }
    };

    showSBCMenu = () => ['ACL', 'UNKNOWN'].includes(this.deviceType);

    showCBMenu = () => ['CLOUDBOND', 'UMP', 'UNKNOWN'].includes(this.deviceType);

    private getLicense = (tenantId) => {
        const onSuccess = (value: RestResponseSuccess) => {
            this.license = value.data;
            this.buildDevicesAllocationObj(value.data);
        };
        const onFailure = () => {
            this.loggerService.error('Failed to get license allocation for tenant with id ' + tenantId);
        };
        this.licenseRestService.getLicenseAllocationById(onSuccess, onFailure, tenantId);
    };

    private buildDevicesAllocationObj = (license) => {
        Object.keys(license.licensePoolFeatures).forEach((key) => {
            if (license.licensePoolFeatures[key] !== undefined && this.sbcParameters.indexOf(key) > -1) {
                this.licensePoolAllocations.sbcAllocationsParameters.push(this.LicenseAllocationsList[key].field);
            }
            if (license.licensePoolFeatures[key] !== undefined && this.cbParameters.indexOf(key) > -1) {
                this.licensePoolAllocations.cbAllocationsParameters.push(this.LicenseAllocationsList[key].field);
            }
        });
    };
}


