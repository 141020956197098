import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {RefreshTableProperties} from '../components/ac-table/ac-table.interface';

@Injectable({
    providedIn: 'root'
})
export class CommonNotifiersService {

    private static httpErrorSubject = new Subject();
    static httpError$ = CommonNotifiersService.httpErrorSubject.asObservable();

    static updateHttpError = (data: any) => CommonNotifiersService.httpErrorSubject.next(data);

    private static finishedDataAndFilteredUpdate: Subject<RefreshTableProperties> = new Subject();
    static finishedDataAndFilteredUpdate$ = CommonNotifiersService.finishedDataAndFilteredUpdate.asObservable();

    private static generalDataArrivedSubject: Subject<any> = new Subject<any>();
    static generalDataArrived$ = CommonNotifiersService.generalDataArrivedSubject.asObservable();

    private static generalDataFetchingSubject: Subject<any> = new Subject<any>();
    static generalDataFetching$ = CommonNotifiersService.generalDataFetchingSubject.asObservable();

    static updateFinishedDataAndFiltered = (tableProperties: RefreshTableProperties = {}) => {// meaning at AcTable
        CommonNotifiersService.finishedDataAndFilteredUpdate.next({...tableProperties, entityName: 'finishedDataAndFilteredUpdate'});
    };

    static executeGeneralDataArrived = (data) => {
        CommonNotifiersService.generalDataArrivedSubject.next(data);
    };

    static executeGeneralDataFetching = (type) => {
        CommonNotifiersService.generalDataFetchingSubject.next(type);
    };
}
