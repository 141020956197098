import {Injectable} from '@angular/core';

import {AuthorizationService, CommonNotifiersService, GeneralService} from 'ac-infra';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import {DevicesActionsService} from './actions/devices-actions.service';
import {LinksActionsService} from './actions/links-actions.service';
import {MetadataService} from '../../metadata/metadata.service';
import {SitesActionsService} from './actions/sites-actions.service';
import {TenantsActionsService} from './actions/tenants-actions.service';
import {RegionsActionsService} from './actions/regions-actions.service';
import {LicensePoolActionsService} from './actions/license-pool-actions.service';
import {CombinedNetworkActionsService} from './actions/combined-network-actions.service';
import {PMProfilesService} from '../../statistics/services/PMProfilesService';
import {PageService} from '../../common/services/routing/page.service';
import {LinkCreationService} from '../maps/services/link-creation.service';
import {GeoSelectionService} from '../maps/services/geo-selection.service';
import {authGroup} from "../../common/utilities/session-helper.service";

class DeviceActionMapper {
    [key: string]: DeviceActionMapperItem;
}

interface DeviceActionMapperItem {
    allDevicesConditionFunc?: Function;
    additionalConditions?: boolean | ((devices: any) => boolean);
    views?: string[];
    enabled?: boolean;
    doesntRequireSelection?: boolean;
    isPM?: boolean;
    allowedByProductType?: boolean;
}

@Injectable({providedIn: 'root'})
export class EntityActionsService {

    DEVICE_DETAILS = 'device';
    MANAGE_DEVICES = 'devices';
    TOPOLOGY_GEO_MAP = 'topologyGeo';
    FIXED_LICENSE = 'fixedLicense';
    BACKUP_MANAGER = 'backupManager';
    FLOATING_LICENSE = 'floatingLicense';

    ALL_DEVICES_PAGES = [this.MANAGE_DEVICES, this.TOPOLOGY_GEO_MAP, this.BACKUP_MANAGER, this.FIXED_LICENSE, this.FLOATING_LICENSE];

    MAP_AND_DEVICES_PAGES = [this.MANAGE_DEVICES, this.TOPOLOGY_GEO_MAP];
    MAP_DEVICES_BACKUP_MANAGER_PAGES = [this.MANAGE_DEVICES, this.TOPOLOGY_GEO_MAP, this.BACKUP_MANAGER];

    supportedTypes;
    audioCodesDevicesList;
    productTypeActionsMapList;

    mapParameters: any = {
        search: null
    };
    linkSelection = [];
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    private pageName = '';
    private networkTopologySearchSubject: Subject<any> = new Subject<any>();
    networkTopologySearch$ = this.networkTopologySearchSubject.asObservable();

    constructor(private pageService: PageService,
                private generalService: GeneralService,
                private geoSelectionService: GeoSelectionService,
                private linksActionsService: LinksActionsService,
                private regionsActionsService: RegionsActionsService,
                private devicesActionsService: DevicesActionsService,
                private sitesActionsService: SitesActionsService,
                private tenantsActionsService: TenantsActionsService,
                private combinedNetworkActionsService: CombinedNetworkActionsService,
                private licensePoolActionsService: LicensePoolActionsService,
                private linkCreationService: LinkCreationService,
                private pmProfilesService: PMProfilesService) {

        this.supportedTypes = MetadataService.getType('SupportedTypes');
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        this.productTypeActionsMapList = MetadataService.getType('ProductTypeActionsMap', true);

        geoSelectionService.networkSelectionChanged$.subscribe(({devices, links}) => {
            this.deviceSelection = devices || [];
            this.linkSelection = links || [];
        });
    }

    private _deviceSelection = [];

    get deviceSelection() {
        return this._deviceSelection;
    }

    set deviceSelection(devices) {
        this._deviceSelection = _.cloneDeep((Array.isArray(devices) ? devices : Object.values(devices || {})));
    }

    static isAllCustomersEnabled = (customerSelection) => {
        if (customerSelection === undefined || customerSelection.length < 1) {
            return false;
        }

        const oneCustomerIsDisabled = customerSelection.filter((customer) => customer && !customer.isEnabled);

        return oneCustomerIsDisabled.length === 0;
    };

    setPageName = (pageName = '') => {
        this.pageName = pageName;
    };

    networkTopologySearch = (search) => {
        this.networkTopologySearchSubject.next(search);
        setTimeout(() => {
            CommonNotifiersService.updateFinishedDataAndFiltered({gotoPage: 1, showLoader: true});
        });
    };

    deleteSelectedEntities = () => {
        if (this._deviceSelection.length > 0) {
            const selectedDevices = [];
            const selectedSites = [];

            this._deviceSelection.forEach((device) => {
                (this.isSite(device) ? selectedSites : selectedDevices).push(device);
            });

            selectedDevices.length > 0 && this.devicesActionsService.deleteDevice(selectedDevices);
            selectedSites.length > 0 && this.sitesActionsService.deleteSite(selectedSites);
        } else {
            this.linksActionsService.deleteLink(this.linkSelection);
        }
    };

    editSelectedDeviceOrSiteOrLink = () => {
        const device = this._deviceSelection[0];
        const link = this.linkSelection[0];

        if (device) {
            if (this.isSite(device)) {
                this.sitesActionsService.openSiteDialog({siteId: device.id});
            } else {
                this.devicesActionsService.editDevice(device);
            }
        } else {
            this.linksActionsService.editLink(link);
        }
    };

    showDeviceOrLink = () => {
        if (this._deviceSelection[0]) {
            this.pageService.openDevicePage(this._deviceSelection[0]);
        } else if (this.linkSelection[0]) {
            this.pageService.openLinkPage(this.linkSelection[0].id, this.linkSelection[0].name);
        }
    };

    isEditBtnEnabled = () => {
        return (this._deviceSelection.length === 1 && this.linkSelection.length === 0) || this._deviceSelection.length === 0 && this.linkSelection.length === 1;
    };

    isDeleteBtnEnabled = () => this._deviceSelection.length > 0 && this.linkSelection.length === 0 || this._deviceSelection.length === 0 && this.linkSelection.length > 0;

    isShowButtonEnabled = (devices?) => {
        let usedDevices = devices || this._deviceSelection;
        if (devices && !devices.length) {
            usedDevices = [devices];
        }

        if (!devices && (usedDevices.length + this.linkSelection.length) !== 1) {
            return false;
        }
        if (usedDevices[0] && this.isSite(usedDevices[0])) {
            return false;
        }
        if (usedDevices[0] && usedDevices[0].productType === 'NONE_ACL_DEVICE') {
            return false;
        }

        return this.linkSelection.length !== 1 || !this.oneLiveMode;
    };

    isDeviceSupportQOE = (device: any) => device && this.supportedTypes.QOE_SUPPORTED_PRODUCTS_TYPES.includes(device.productType);

    isActionAvailable = (devices, actionName = undefined) => {
        if (actionName) {
            return this.checkAction(actionName, this.deviceActionMapper[actionName], devices);
        }

        let allActionAreDisabled = true;
        _.forOwn(this.deviceActionMapper, (deviceAction: DeviceActionMapperItem, name: string) => {
            deviceAction.enabled = this.checkAction(name, this.deviceActionMapper[name], devices);

            if (deviceAction.enabled) {
                allActionAreDisabled = false;
            }
        });

        return allActionAreDisabled;
    };

    private onlySWVersionAbove7_3AndIsInSBCSupportedList = (testDevice) => {
        const swVersionFloat = parseFloat(testDevice?.swVersion?.substring(0, 3));
        const isDeviceInSBCSupportedList = this.supportedTypes.VMT_SUPPORTED_PRODUCTS_TYPES.includes(testDevice.productType);

        return swVersionFloat >= 7.3 && isDeviceInSBCSupportedList;
    };

    private permittedManagedDevice = (devices) => {
        const withSameTenantId = devices.some((device) => device.tenantId === devices[0].tenantId);
        const withSameFamilyType = devices.some((device) => device.familyType === devices[0].familyType);
        const umpOrCloudBond = devices.some((device) => ['UMP', 'CLOUDBOND'].includes(device.familyType));

        return withSameTenantId && (withSameFamilyType || umpOrCloudBond);
    };

    private withSameConnectionState = (connectionState, testDevice) => {
        const deviceConnectionState = testDevice?.applicationsStatus?.management?.connectionState;
        return (deviceConnectionState === connectionState || deviceConnectionState === undefined);
    };

    private isSite = (item) => !item.productType;

    private ha = (testDevice) => testDevice?.sbcInfo?.isHA;

    private fixedLicenseManaged = (testDevice) => this.pageName === this.FLOATING_LICENSE ? testDevice?.clmOperationalState === 'ENABLED' : testDevice?.managed;

    private fixedLicenseManagedAndHa = (testDevice) => testDevice?.managed && this.ha(testDevice);

    private hasFlexPoolPriority = (testDevice) => testDevice?.flexPool?.priority || false;

    private notLyncAndHasIPAddress = (testDevice) => this.notLync(testDevice) && !_.isEmpty(testDevice?.ipAddress);

    private notLync = (testDevice) => testDevice?.familyType !== 'LYNC';

    private notCloudBondOrUMP = (testDevice) => !['CLOUDBOND', 'UMP'].includes(testDevice.familyType);

    private withDifferentAdminState = (adminState) => (testDevice) => testDevice?.applicationsStatus?.management?.administrationState !== adminState;

    private withDifferentOperState = (operState) => (testDevice) => testDevice?.clmOperationalState !== operState;

    private withSamePMPollingStatus = (status) => (testDevice) => testDevice?.sbcInfo?.pmInfo?.pollingEnable === status;

    private isWithSameTenantId = (testDevice) => testDevice?.tenantId === (this._deviceSelection.length > 0 ? this._deviceSelection[0].tenantId : -1);

    private isSingleSelectionAndNotLync = (devices) => devices?.length === 1 && this.notLync(devices[0]);

    deviceActionMapper: DeviceActionMapper = {
        softwareUpgrade: {enabled: true, views: this.MAP_AND_DEVICES_PAGES, allDevicesConditionFunc: this.notLync, allowedByProductType: true},
        moveDevice: {enabled: true, views: this.MAP_AND_DEVICES_PAGES, allDevicesConditionFunc: this.notLync, allowedByProductType: true},
        restore: {enabled: true, views: this.MAP_DEVICES_BACKUP_MANAGER_PAGES, allDevicesConditionFunc: this.notLync, allowedByProductType: true},
        setConfigFactoryDefaults: {enabled: true, views: this.MAP_DEVICES_BACKUP_MANAGER_PAGES, allDevicesConditionFunc: this.notLync, allowedByProductType: true},
        saveConfiguration: {enabled: true, views: this.MAP_DEVICES_BACKUP_MANAGER_PAGES, allDevicesConditionFunc: this.notLync, allowedByProductType: true},
        stopUpgradeMC: {enabled: true, views: this.MAP_AND_DEVICES_PAGES, allDevicesConditionFunc: this.onlySWVersionAbove7_3AndIsInSBCSupportedList, allowedByProductType: true},
        syncLinks: {
            enabled: true,
            views: this.MAP_AND_DEVICES_PAGES,
            allDevicesConditionFunc: this.notLyncAndHasIPAddress,
            allowedByProductType: true,
            additionalConditions: () => !this.oneLiveMode,
        },
        resetDevice: {enabled: true, views: this.ALL_DEVICES_PAGES, allDevicesConditionFunc: this.notCloudBondOrUMP, allowedByProductType: true},
        lockDevice: {enabled: true, views: this.ALL_DEVICES_PAGES, allDevicesConditionFunc: this.withDifferentAdminState('LOCKED'), allowedByProductType: true},
        unlockDevice: {enabled: true, views: this.ALL_DEVICES_PAGES, allDevicesConditionFunc: this.withDifferentAdminState('UNLOCKED'), allowedByProductType: true},
        uploadConfigFile: {enabled: true, views: this.MAP_DEVICES_BACKUP_MANAGER_PAGES, allowedByProductType: true, additionalConditions: this.isSingleSelectionAndNotLync},
        backup: {enabled: true, views: this.MAP_DEVICES_BACKUP_MANAGER_PAGES, allowedByProductType: true},
        manageDeviceLicense: {enabled: true, views: [this.FIXED_LICENSE], additionalConditions: this.permittedManagedDevice},
        unmanagedCLMDevice: {enabled: true, views: [this.FLOATING_LICENSE], allDevicesConditionFunc: this.withDifferentOperState('DISABLED')},
        updateDeviceLicense: {enabled: true, views: [this.FIXED_LICENSE, this.FLOATING_LICENSE], allDevicesConditionFunc: this.fixedLicenseManaged},
        applyDeviceLicense: {enabled: true, views: [this.FIXED_LICENSE], allDevicesConditionFunc: this.fixedLicenseManagedAndHa},
        refreshDeviceLicense: {enabled: true, views: [this.FIXED_LICENSE], allDevicesConditionFunc: this.fixedLicenseManagedAndHa},
        updatePriorityLevel: {enabled: true, views: [this.FLOATING_LICENSE], allDevicesConditionFunc: this.hasFlexPoolPriority},
        switchover: {enabled: true, views: this.ALL_DEVICES_PAGES, allDevicesConditionFunc: this.ha, allowedByProductType: true},
        resetRedundantBoard: {enabled: true, views: this.ALL_DEVICES_PAGES, allDevicesConditionFunc: this.ha, allowedByProductType: true},
        startPolling: {enabled: true, views: [this.MANAGE_DEVICES, this.DEVICE_DETAILS], allDevicesConditionFunc: this.withSamePMPollingStatus('Disable'), isPM: true},
        stopPolling: {enabled: true, views: [this.MANAGE_DEVICES, this.DEVICE_DETAILS], allDevicesConditionFunc: this.withSamePMPollingStatus('Enable'), isPM: true},
        selectPMProfile: {enabled: true, views: [this.MANAGE_DEVICES, this.DEVICE_DETAILS], allDevicesConditionFunc: this.isWithSameTenantId, isPM: true},
        openRdpSession: {enabled: true, views: [this.MANAGE_DEVICES, this.DEVICE_DETAILS], additionalConditions: this.isSingleSelectionAndNotLync, allowedByProductType: true},
        downloadTopology: {enabled: true, views: [this.MANAGE_DEVICES], doesntRequireSelection: true, additionalConditions: AuthorizationService.isSystemAdminOrOperatorLevel()},
    };

    private checkAction = (actionName, deviceAction: DeviceActionMapperItem, devices) => {
        const canView = deviceAction.views === undefined || deviceAction.views.includes(this.pageName);

        if(devices.length === 0 && !deviceAction.doesntRequireSelection){
            return false;
        }

        if (deviceAction.allDevicesConditionFunc || deviceAction.allowedByProductType || deviceAction.isPM) {
            for (let i = 0; i <= devices.length - 1; i++) {
                const device = devices[i];
                const productType = device.productType || 'UNKNOWN';

                if (!['moveDevice', 'manageDeviceLicense', 'selectPMProfile'].includes(actionName) && !this.withSameConnectionState('CONNECTED', device)) {
                    return false;
                }

                if (deviceAction.allowedByProductType && !(this.productTypeActionsMapList[productType] || []).includes(actionName)) {
                    return false;
                }

                if (deviceAction.allDevicesConditionFunc && !deviceAction.allDevicesConditionFunc(device)) {
                    return false;
                }

                if (deviceAction.isPM && !this.pmProfilesService.isDeviceSupportPM(device)) {
                    return false;
                }
            }
        }

        const additionalConditionsResult = deviceAction.additionalConditions === undefined ||
            (_.isFunction(deviceAction.additionalConditions) ? deviceAction.additionalConditions(devices) : deviceAction.additionalConditions);

        return !!(canView && additionalConditionsResult);
    };
}
