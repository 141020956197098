import {Component, Input} from '@angular/core';

import {AuthorizationService, GeneralService, SessionStorageService} from 'ac-infra';

import {LinksActionsService} from '../../../services/actions/links-actions.service';
import {DevicesActionsService} from '../../../services/actions/devices-actions.service';
import {SitesActionsService} from '../../../services/actions/sites-actions.service';
import {PageService} from '../../../../common/services/routing/page.service';
import {authGroup} from '../../../../common/utilities/session-helper.service';
import {AcGeoEventsService} from '../../ac-geo/services/ac-geo-events.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntityActionsService} from '../../../services/entity-actions.service';
import {GeoSelectionService} from "../../../maps/services/geo-selection.service";

@UntilDestroy()
@Component({
    selector: 'network-cluster-popup-inner',
    templateUrl: './network-cluster-popup-inner.component.html',
    styleUrls: ['./network-cluster-popup-inner.component.less'],
})
export class NetworkClusterPopupInnerComponent {

    @Input() itemsDataKey: any;
    searchName: any;
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    itemsDataForDisplay;

    _itemsData;

    @Input() set itemsData(itemsData) {
        this._itemsData = itemsData;
        this.itemsDataForDisplay = itemsData;
    }

    constructor(private linksActionsService: LinksActionsService,
                private devicesActionsService: DevicesActionsService,
                private sitesActionsService: SitesActionsService,
                private geoSelectionService: GeoSelectionService,
                private pageService: PageService,
                private entityActionsService: EntityActionsService,
                private generalService: GeneralService) {
    }

    ngOnInit() {
        this.searchName = SessionStorageService.getData('networkSearch');
        this.entityActionsService.networkTopologySearch$.pipe(untilDestroyed(this)).subscribe((searchObject) => {
            this.searchName = searchObject;
        });
    }


    getFilterObj = (items, search) => {
        if (!items) {
            return [];
        }

        return items.filter((item) => {
            const name = item.name || item.device.name;
            return name.toLowerCase().includes(search.toLowerCase());
        });
    };

    showItem = (item) => {
        switch (this.itemsDataKey) {
            case 'devices':
                return this.pageService.openDevicePage(item.device);
            case 'links':
                return this.pageService.openLinkPage(item.id, item.name);
        }
    };

    editItem = (item) => {
        this.closeMapTooltips();
        switch (this.itemsDataKey) {
            case 'devices':
                this.devicesActionsService.editDevice(item.device);
                break;
            case 'sites':
                this.sitesActionsService.openSiteDialog({siteId: item.device.id});
                break;
            case 'links':
                this.linksActionsService.editLink(item);
                break;
        }
    };

    // deleteItem = (item) => {
    //     switch (this.itemsDataKey) {
    //         case "devices":
    //             this.devicesActionsService.deleteDevice([item.device]);
    //             break;
    //         case "links":
    //             this.linksActionsService.deleteLink([item]);
    //             break;
    //         case "sites":
    //             this.sitesActionsService.deleteSite([item.device]);
    //             break;
    //     }
    // };

    isItemSelected = (item) => this.geoSelectionService.isItemSelected(item);

    selectItem = (e, item) => {
        const event = e.originalEvent || e;
        if (!event.ctrlKey) {
            this.geoSelectionService.clearSelection(true);
        }
        this.geoSelectionService.toggleItem(item);
    };

    closeMapTooltips = () => {
        AcGeoEventsService.executeCloseOpenTooltipsOnMap();
    };

    searchNameChanged(search) {
        this.itemsDataForDisplay = this.getFilterObj(this._itemsData, search);
    }

    getImagePath = (status) => {
        switch (status) {
            case 'OK':
            case '0':
                return 'ok';
            case 'WARNING':
            case '10':
                return 'warning';
            case 'ERROR':
            case '20':
                return 'error';
            default:
            case 'UNMONITORED':
            case '-10':
                return 'unmonitored';

        }
    };


    isShowButtonHidden = (item) => {
        switch (this.itemsDataKey) {
            case 'devices':
                return item.device.productType === 'NONE_ACL_DEVICE';
            case 'sites':
                return true;
            case 'links':
                return this.oneLiveMode;
        }
    };

    isMonitor = () => {
        return AuthorizationService.isMonitor();
    }
}
