import {Component, Inject} from '@angular/core';
import * as _ from 'lodash';
import {DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {NetworkValidationsService} from '../../services/network-validations.service';

@Component({
    selector: 'network-region-dialog',
    templateUrl: './network-region-dialog.component.html'
})
export class NetworkRegionDialogComponent {

    region: any = {};
    schema: any = {};
    isEdit: boolean;
    regionAddSchema = require('../../../../schema/post/regionsprocessor.json');
    regionEditSchema = require('../../../../schema/put/regionsprocessor.json');
    additionalSettings: any;
    selectedTopologyItem: any;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig, public networkValidationsService: NetworkValidationsService) {}

    ngOnInit() {
        this.isEdit = this.dialogConfig.dialogData.isEdit;

        this.schema = this.isEdit ? _.cloneDeep(this.regionEditSchema) : _.cloneDeep(this.regionAddSchema);
        this.region = this.dialogConfig.dialogData.entity;

        this.additionalSettings = _.cloneDeep(this.region);
        this.selectedTopologyItem = this.dialogConfig.dialogData.selectedTopologyItem;

        if (this.selectedTopologyItem && !this.region.tenantId) {
            if (this.selectedTopologyItem && !this.selectedTopologyItem.tenantId && this.selectedTopologyItem.id) {
                this.region.tenantId = this.selectedTopologyItem.id;
            }
        }
    }
}


