import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ac-infra';

@Injectable({providedIn: 'root'})
export class FilterObjectCacheService {

    filterObjectStore = 'filterObject_';
    filterIdObjectStore = 'filterIdObject_';
    private fiveMinutes = 1000 * 60 * 5;

    constructor() {
    }

    getFilterId = (filter) => this.testFiveMinutes(SessionStorageService.getData(this.filterObjectStore + filter));

    getFilterById = (filterId) => this.testFiveMinutes(SessionStorageService.getData(this.filterIdObjectStore + filterId));

    testFiveMinutes = (storedObj) => {
        const now = new Date().getTime();
        if (storedObj && ((storedObj.time + this.fiveMinutes - now) < 0)) {
            return null;
        }
        return storedObj && storedObj.obj;
    };

    setFilter = (filterObject) => {
        const now = new Date().getTime();
        SessionStorageService.setData(this.filterIdObjectStore + filterObject.id, {
            time: now,
            obj: filterObject.filter
        });
        SessionStorageService.setData(this.filterObjectStore + filterObject.filter, {time: now, obj: filterObject.id});
    };

}
