import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'operator-connect-contact',
    templateUrl: './operator-connect-contact.component.html',
    styleUrls: ['./operator-connect-contact.component.less']
})

export class OperatorConnectContactComponent {

    @Input() contactObject;
    @Input() index;
    @Input() authorized;
    @Input() operatorContactTypesList;
    @Input() removeButton: TemplateRef<any>;
    @Input() formSettings;
    @Output() operatorContactTypeWasSelected: EventEmitter<string> = new EventEmitter<string>();

    cardHasError;

    @Input() set errors(errors) {
        if(errors?.length > 0 && this.formSettings.touched){
            this.cardHasError = _.some(errors, (error) => error.instancePath.includes('operatorContacts/' + this.index));
        }else{
            this.cardHasError = false;
        }
    }
}



