import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {authGroup} from "../../../../src/app/common/utilities/session-helper.service";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    private static _authGroups;
    private static _oneLiveMode;

    static set authGroups(authGroups) {
        AuthorizationService._authGroups = authGroups;
    }

    static set oneLiveMode(value) {
        AuthorizationService._oneLiveMode = value;
    }

    static validationAccordingToMode = ({ovoc, oneLive}) => {
        return AuthorizationService.validFor(AuthorizationService._oneLiveMode ? oneLive : ovoc);
    };

    static validFor = (securityGroups: any): boolean => {
        if (!SessionService.activeSession) {
            return false;
        }

        if(securityGroups === 'none'){
            return false;
        }

        if(securityGroups === 'all'){
            return true;
        }

        if (Array.isArray(securityGroups)) {
            return securityGroups.some(securityGroup => {
                if(Array.isArray(securityGroup)){
                    return securityGroup.some(subSecurityGroup => AuthorizationService.validateSecurityLevel(subSecurityGroup));
                }

                return AuthorizationService.validateSecurityLevel(securityGroup);
            });
        }

        return AuthorizationService.validateSecurityLevel(securityGroups);
    };

    static validateSecurityLevel = (securityGroup) => {
        return AuthorizationService._authGroups[securityGroup].includes(SessionService.activeSession.securityLevel);
    };

    public isSameUserConnected = (name, authType) => {
        const loggedUser = SessionService.activeSession;

        return loggedUser.name === name && loggedUser.operatorAuthenticationMode === authType;
    };

    static validForMonitorLinkOrOneLiveTenantUserOrGroupUsers = () => {
        return AuthorizationService.validationAccordingToMode({
            ovoc: AuthorizationService._authGroups.TENANT_MONITORING_LINKS,
            oneLive: [
                AuthorizationService._authGroups.tenant_user,
                AuthorizationService._authGroups.end_customer_operator,
                AuthorizationService._authGroups.channel_operator
            ]});
    };

    static validForSystemOperatorOrTenantOperatorInStandardMode = () => {
        return AuthorizationService.validationAccordingToMode({
            ovoc: AuthorizationService._authGroups.admin_operator,
            oneLive: AuthorizationService._authGroups.system_admin_operator
        });
    };

    static validForServiceProvider = () => {
        return AuthorizationService.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.tenant_user});
    };

    static validForChannel = () => {
        return AuthorizationService.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.channel_operator});
    };

    static validForEndCustomer = () => {
        return AuthorizationService.validationAccordingToMode({ovoc: 'none', oneLive: AuthorizationService._authGroups.end_customer_operator});
    };

    public static isMonitor = () => AuthorizationService.validFor(authGroup.monitor);
    public static isAdminOrOperatorLevel = () => AuthorizationService.validFor(authGroup.admin_operator);
    public static isSystemAdminOrOperatorLevel = () => AuthorizationService.validFor(authGroup.system_admin_operator);
    public static isSystemLevel = () => AuthorizationService.validFor(authGroup.system_users);
    public static isEndCustomerLevel = () => AuthorizationService.validFor(authGroup.end_customer_operator);
}
