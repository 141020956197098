import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import * as _ from 'lodash';
import {MetadataService} from '../../../../metadata/metadata.service';

@Component({
    selector: 'operator-connect-offer',
    templateUrl: './operator-connect-offer.component.html'
})

export class OperatorConnectOfferComponent {

    @Input() offerObject;
    @Input() index;
    @Input() offersTypesList;
    @Input() removeButton: TemplateRef<any>;
    @Input() formSettings;
    @Output() offerTypeWasSelected: EventEmitter<string> = new EventEmitter<string>();

    cardHasError;

    @Input() set errors(errors) {
        if(errors?.length > 0 && this.formSettings.touched){
            this.cardHasError = _.some(errors, (error) => error.instancePath.includes('offers/' + this.index));
        }else{
            this.cardHasError = false;
        }
    }

    countryCodesType = MetadataService.getType('CountryCodesType');
    countryCodesTypeList = [];

    allowedList = [
        {text: 'Allowed', value: true},
        {text: 'Blocked', value: false}
    ];

    ngOnInit(){
        _.forOwn(this.countryCodesType, (countryCode) => {
            this.countryCodesTypeList.push(countryCode);
        });
    }
}



