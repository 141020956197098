<ac-form *ngIf="dialogConfig.ready" [formSettings]="dialogConfig.formSettings">

    <div id="flex-pool-update-priority-level-content">
        <ac-input-container label="Priority" class="no-validation-message">
            <ac-single-select [(acModel)]="flexPool.priority"
                       [items]="priorityOptions"
                       bindLabel="priority"
                       bindValue="priority">
            </ac-single-select>
        </ac-input-container>
    </div>

</ac-form>
