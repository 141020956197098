<ac-tree-navigation
    #ACTreeNavigation
    class="ac-tree-router"
    [doSort]="false"
    [customTemplate]="routingTemplate"
    [extractDataFromNode]="false"
    [treeId]="treeId"
    [treeNodes]="routeNodes"
    (activeChange)="onActiveNodes($event)"
    (initialized)="onInitialized($event)"
></ac-tree-navigation>

<ng-template #routingTemplate let-index="index" let-node>
    <div *ngIf="(node.data | filterNavs: routePathLevel + node.level).length"
         (click)="preClick(node); node.mouseAction((node.hasChildren && node.data?.data?.navAutoAbstract) ? 'expanderClick' : 'click' , $event)"
         [class.active-parent]="activeParents[node.id]"
         [class.expanded-parent]="node.isExpanded"
         [class.node-content-wrapper-active]="node.isActive"
         [id]="treeId + '-node-' + node.data.id"
         [class.last-node]="(node.parent?.children?.length - 2) === index"
         class="node-wrapper flexRowParent {{(node | acFunc: isParent) ? 'parent' : 'child'}}">

        <div class="node-content-wrapper flexItemBiggest">
            <ng-container
                *ngTemplateOutlet="customTemplate ? customTemplate : default; context: { node: node }"
            ></ng-container>
            <ng-template #default>
                <div acLabel>{{node.data?.data?.text || node.data.path}}</div>
            </ng-template>
        </div>

        <span *ngIf="node.hasChildren"
              [class.toggle-children-wrapper-collapsed]="node.isCollapsed"
              [class.toggle-children-wrapper-expanded]="node.isExpanded"
              class="toggle-children-wrapper flexItem">

            <ac-svg name="chevron"
                    width="8px"
                    [fillColor]="activeParents[node.id] ? 'white' : statusColorsList.grayStatus"
                    [rotate]="node.isCollapsed ? -90 : 90"
            ></ac-svg>
        </span>
    </div>
</ng-template>
