<div *ngIf="dialogConfig.ready" id="software-upgrade-dialog-content">

    <ac-radio-buttons-group *ngIf="!isDownloadMode && deviceDetails.isAllDevicesSWVersionAbove7_3 && deviceDetails.isAllDevicesInSBCSupportedList"
                            [(acModel)]="deviceDetails.clusterManagerRadioGroup.value" class="mtc-type-container"
                            (acModelChange)="clusterManagerRadioGroupChanged($event)"
                            [items]="MTCOptions"
                            radioButtonId="radio-button-group-mtc">
    </ac-radio-buttons-group>

    <div class="grid-container">
        <ac-table-client tableId="software-upgrade-grid"
                         [columns]="softwareUpgradeColumns"
                         [rows]="tableRows"
                         [pagingSizeOption]="false"
                         [refresh$]="softwareUpgradeTableRefresh$"
                         (tableUpdate)="softwareUpgradeFetchFn()">
        </ac-table-client>
    </div>
    <br/>
    <ng-container *ngIf="isDownloadMode">
        <div *ngIf="selectedFile[0]?.fileType==='INI_STAND_ALONE_TYPE'">
            <div>INI File Update Settings:</div>
            <ac-radio-buttons-group [(acModel)]="iniOptions.forwardType"
                                    [items]="forwardingType.options"
                                    class="vertical"
                                    radioButtonId="radio-button-fwd-type">
            </ac-radio-buttons-group>
        </div>

        <ac-input-container label="Device TLS Context" [sideMargin]="false" *ngIf="isAllSelectedFilesAreTLS(selectedFile);">
            <ac-single-select [(acModel)]="TLS.TLSContextSelected"
                       [items]="TLS.TLSContexts">
            </ac-single-select>
        </ac-input-container>
    </ng-container>

    <div class="mtc-properties" *ngIf="deviceDetails.clusterManagerRadioGroup.value === 'WITH_MTC'">
        <ng-container *ngTemplateOutlet="hitlessTemplate"></ng-container>

        <ac-input-container label="Graceful Timeout (seconds)" [sideMargin]="false">
            <ac-num-spinner class="mtc-graceful-timeout-num-spinner"
                            [(acModel)]="iniOptions.gracefulTimeout"
                            [maxValue]="4000"
                            [minValue]="0">
            </ac-num-spinner>
        </ac-input-container>
    </div>

    <div *ngIf="deviceDetails.isAllDevicesHA && selectedFile.length === 1 && selectedFile[0].fileTypeDescription === 'CMP' && deviceDetails.clusterManagerRadioGroup.value !== 'WITH_MTC'">
        <ng-container *ngTemplateOutlet="hitlessTemplate"></ng-container>
    </div>
</div>

<ng-template #hitlessTemplate>
    <ac-radio-buttons-group [(acModel)]="iniOptions.hitless"
                            [items]="hitlessOptions"
                            radioButtonId="radio-button-hitless">
    </ac-radio-buttons-group>
</ng-template>
