import {Component, Inject, TemplateRef, ViewChild} from '@angular/core';

import {AcDialog, AcNumSpinnerComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';

@AcDialog({
    id: 'lock-device-dialog',
    title: 'Lock Device',
    cancelButtonText: 'Close',
    submitButtonText: 'Lock',
    width: 420
})
@Component({
    selector: 'lock-device-dialog',
    templateUrl: './lock-device-dialog.component.html',
    styleUrls: ['./lock-device-dialog.component.less']
})
export class LockDeviceDialogComponent {

    @ViewChild('spinner', {static: true}) spinner: TemplateRef<AcNumSpinnerComponent>;

    lockByItems: any;

    deviceConfiguration = {lockBy: 'immediateLock'};
    confirmMsg;
    selectedDevice;

    constructor(@Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {
    }

    ngOnInit() {
        this.lockByItems = [
            {value: 'immediateLock', text: 'Immediate Lock'},
            {value: 'gracefulLock', text: 'Graceful Lock'},
            {value: 'gracefulLockWithSeconds', text: 'Graceful Lock (time)', postfixTemplate: this.spinner}
        ];
        this.dialogConfig.formSettings = {valid: true, touched: true};

        this.confirmMsg = this.dialogConfig.dialogData.confirmMsg;
        this.selectedDevice = this.dialogConfig.dialogData;
        this.selectedDevice.deviceConfigs = this.deviceConfiguration;
    }
}


