import {Injectable} from '@angular/core';
import {Rest, RestResponseSuccess} from '../../common/server-actions/rest';

enum reportTypes {
    ELEMENT = 'Element',
    AGGREGATION = 'Aggregation',
    TREND = 'Trend'
}

@Injectable({providedIn: 'root'})
export class ReportRestService extends Rest {

    public sortableOrderArray = [];
    public reportTypes = reportTypes;
    public topTypeListElement = [
        {value: 'TOP_10', text: '10'},
        {value: 'TOP_20', text: '20'},
        {value: 'TOP_30', text: '30'},
        {value: 'TOP_50', text: '50'},
        {value: 'TOP_100', text: '100'},
        {value: 'TOP_1000', text: '1000'},
        {value: 'TOP_10000', text: '10000'}
    ];

    public topTypeListAggAndTrend = [
        {value: 'TOP_10', text: '10'},
        {value: 'TOP_20', text: '20'},
        {value: 'TOP_30', text: '30'},
        {value: 'TOP_50', text: '50'},
        {value: 'TOP_100', text: '100'}
    ];

    constructor() {
        super({url: 'analytics/reports'});
    }

    getReportResult = (success, failure, reportId, prefix, parameters?, requestAPI?) => {
        this.get({url: 'analytics/reportsResult/' + reportId + '/' + prefix, parameters, requestAPI}).then(success).catch(failure);
    };

    executeReport = (success, failure, reportId) => {
        this.put(success, failure, {reportId}, 'analytics/actions/execute');
    };

    getMetrics = (success, failure) => {
        this.get({url: 'analytics/metrics', extendParameters: true, build204JSON: false}).then(success).catch(failure);
    };

    getInfoColumns = (success, failure) => {
        this.get({url: 'analytics/infoColumns', extendParameters: true, build204JSON: false}).then(success).catch(failure);
    };

    exportToCsv = (success, failure, resultId) => {
        this.get({url: 'analytics/actions/exportToCsv/' + resultId, responseType: 'blob'}).then(success).catch(failure);
    };

    exportToPDF = (resultId) => {
        return this.get({url: 'analytics/actions/exportToPdf/' + resultId, responseType: 'blob', skipErrorHandler: true});
    };

    protected handleSuccess = (requestAPI, response): RestResponseSuccess => {
        const defaultObject: RestResponseSuccess = new RestResponseSuccess();
        defaultObject.data = null;
        return (response.status === 204 ? defaultObject : response);
    };

}
