<div [(dragulaModel)]="_list.items" [attr.id]="dndId" class="dnd-list" dragula="dnd">
    <div (click)="clickRow(item)"
         *ngFor="let item of _list.items; let itemIndex = index"
         [attr.index]="itemIndex"
         [class.selected]="item.selected"
         [class.search-active]="!item.displayName.toLowerCase().includes(searchedValue)"
         class="dnd {{dndId}} dnd-list-item">
        <div class="item-list-content flexRowParent">
            <div class="ellipsis" title="{{item.displayName}}">
                <span class="item-order-number">{{displaySortableArrows ? ((itemIndex + 1) + '. ') : ''}}</span>
                <span class="item-display-name">{{item.displayName}}</span>
            </div>

            <div class="flexItem item-sorting-container">
                <div class="column-icons-container flexRowParent">
                    <div class="notAllowedContainer">
                        <ac-svg *ngIf="inverseListAllowedTypes && !inverseListAllowedTypes.includes(item[lockPropertyName])"
                                [name]="svgModelName.NotAllowed"></ac-svg>
                    </div>

                    <div (click)="!isViewOnly && orderChanged($event, item);$event.stopPropagation()"
                         [class.visibilityHidden]="!displaySortableArrows"
                         class="arrow-container flexItemRowEquals">
                        <div *ngIf="!item.orderObj || item.orderObj?.sortType === 'DESC'"
                             [class.arrow-none]="!item.orderObj"
                             class="arrow-down"></div>
                        <div *ngIf="item.orderObj?.sortType === 'ASC'" class="arrow-up"></div>

                        <div *ngIf="displayOrderNumber(item) else emptyOrderNumber" class="arrow-order-number">
                            {{findSortOrderOfItem(item.orderObj) + 1}}
                        </div>

                        <ng-template #emptyOrderNumber>
                            <div class="arrow-order-number visibilityHidden">0</div>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

