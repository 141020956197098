import {Component, Inject, ViewChild} from '@angular/core';
import {ReportRestService} from '../../services/report-rest.service';
import * as _ from 'lodash';
import {ActiveDirectoriesRestService} from '../../../users/apis/active-directories-rest.service';
import {
    AcDialog,
    AcDialogService,
    AcFormComponent,
    AuthorizationService,
    DIALOG_CONFIG,
    DialogConfig,
    GeneralService,
    PromiseService,
    promiseType,
    SchemaHelperService,
    SessionService,
    WSEntities
} from 'ac-infra';
import {TenantsRestService} from '../../../network/services/apis/tenants-rest.service';
import {DevicesRestService} from '../../../network/services/apis/devices-rest.service';
import {SitesRestService} from '../../../network/services/apis/sites-rest.service';
import {LinksRestService} from '../../../network/services/apis/links-rest.service';
import {ReportEntity} from './models/report-entity';
import {ReportInfoColumns} from './models/report-info-column';
import {ReportColumns} from './models/report-columns';
import {ReportEntitiesService} from '../../reports/services/report-entities.service';
import {reportGraph} from './graphs-tab/models/report-graph';
import {authGroup} from '../../../common/utilities/session-helper.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {ReportFilterTabComponent} from './report-filter-tab/report-filter-tab.component';
import {Subject} from 'rxjs';
import {TableLayoutTabComponent} from './table-layout-tab/table-layout-tab.component';

@AcDialog({
    id: 'report-dialog',
    title: 'report',
    cancelButtonText: 'Close',
    width: 820,
    height: 750,
})
@Component({
    selector: 'report-dialog',
    templateUrl: './report-dialog.component.html',
    styleUrls: ['./report-dialog.component.less'],
})
export class ReportDialogComponent {

    @ViewChild('reportForm', {static: true}) reportForm: AcFormComponent;
    @ViewChild('reportFilterTab', {static: false}) reportFilterTabEl: ReportFilterTabComponent;
    @ViewChild('tableLayoutTab', {static: false}) tableLayoutTabEl: TableLayoutTabComponent;

    schema: any;
    reportAddSchema = require('../../../../schema/post/reportsprocessor.json');
    reportEditSchema = require('../../../../schema/put/reportsprocessor.json');
    modalName = 'report';
    lockedTypes = ['Mandatory', 'Entity'];
    report;
    activeTabId = '';
    metrics;
    infoColumns;
    columnList = [];
    additionalSettings: any = {};
    isEdit: boolean;
    isViewOnly: boolean;
    reviveObject: Function;
    isFirstInitInEdit = false;
    dataIsReady = false;
    mapEntityToServiceFunc;
    session;
    img: HTMLImageElement;
    oneLiveMode = this.generalService.serverInfo.oneLiveMode;
    listsObject = {
        left: {items: [], allowedTypes: ['Metric', 'Static'], header: 'Metrics Optional'},
        right: {
            items: [],
            enableSortOrder: true,
            allowedTypes: ['Metric', 'Static', 'Mandatory', 'Entity'],
            header: 'Selected Metrics'
        }
    };
    reportTypeList = [
        {
            value: this.reportRestService.reportTypes.ELEMENT,
            text: 'Element (Entity) Statistics',
            subText: 'Element statistics is similar to the current statistics layout that exists in todays report.'
        },
        {
            value: this.reportRestService.reportTypes.AGGREGATION,
            text: 'Aggregated Statistics Trends',
            subText: 'Trend aggregated statistics is similar to current trends statistics exist in the OVOC'
        },
        {
            value: this.reportRestService.reportTypes.TREND,
            text: 'Trends Statistics Comparison',
            subText: 'Trend statistics comparison is similar to the singular real time statistics in OVOC'
        }
    ];

    private handleOrderArraySubject: Subject<any> = new Subject<any>();
    handleOrderArray$ = this.handleOrderArraySubject.asObservable();

    private updateColumnSortArraySubject: Subject<any> = new Subject<any>();
    updateColumnSortArray$ = this.updateColumnSortArraySubject.asObservable();

    getGroupSubject: Subject<any> = new Subject<any>();
    getGroup$ = this.getGroupSubject.asObservable();

    constructor(public reportRestService: ReportRestService,
                private activeDirectoriesRestService: ActiveDirectoriesRestService,
                private tenantsRestService: TenantsRestService,
                private devicesRestService: DevicesRestService,
                private sitesService: SitesRestService,
                private linksRestService: LinksRestService,
                private reportEntitiesService: ReportEntitiesService,
                private wsEntities: WSEntities,
                private schemaHelperService: SchemaHelperService,
                private sessionService: SessionService,
                private acDialogService: AcDialogService,
                private generalService: GeneralService,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig) {

        this.oneLiveMode = this.generalService.serverInfo?.oneLiveMode;

        this.mapEntityToServiceFunc = {
            tenants: this.tenantsRestService.getEntitiesByIds,
            devices: this.devicesRestService.getEntitiesByIds,
            sites: this.sitesService.getEntitiesByIds,
            links: this.linksRestService.getEntitiesByIds
        };

        if (!AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
            this.mapEntityToServiceFunc.ads = this.activeDirectoriesRestService.getHashedActiveDirectoriesByIds;
        }
    }

    ngOnInit() {
        this.reviveObject = this.reportEntitiesService.reviveObject;
        this.session = this.sessionService.activeSession;
        const isMonitor = AuthorizationService.isMonitor();

        this.dialogConfig.formSettings = {touched: false, valid: true};

        this.dialogConfig.preSubmit = this.preSubmit;

        this.onReportDialogOpen(this.dialogConfig.dialogData, isMonitor);

        setTimeout(() => {
            this.executeUpdateColumnSortArray();
            if (this.isEdit && this.reportForm) {
                this.reportForm.updateOriginalModelAndRunInitValidation();
            }
        });
    }

    executeUpdateColumnSortArray = (emptySortableOrderArray?) => {
        this.updateColumnSortArraySubject.next(emptySortableOrderArray);
    };

    preSubmit = () => {
        this.dialogConfig.dialogData.entity = this.reportToServer(this.report, this.listsObject, this.additionalSettings);
        if (this.additionalSettings.isScheduled) {
            const promise: promiseType = PromiseService.defer();

            const message = 'please be advised that this report has a scheduler, if you choose to save the report all scheduler results data will be deleted.';

            this.acDialogService.confirm(message, {
                onSubmit: () => {
                    promise.resolve();
                    return true;
                },
                onCancel: () => {
                    this.dialogConfig.submitButtonProcessing = false;
                    promise.reject();
                }
            });
            return promise.promise;
        }
    };

    reportToServer = (report, listsObject, additionalSettings) => {
        const _report = _.cloneDeep(report);
        const isServiceProvider = this.oneLiveMode && AuthorizationService.validFor(authGroup.tenant_user);
        // const isEntityDeviceOrLink = ['device', 'link'].includes(this.additionalSettings?.entityType.toLowerCase());

        if (additionalSettings.selectAllTreeEntities || isServiceProvider) {
            _report.entitiesFilter = null;
        }

        if (_report.reportLogoType !== 'Custom') {
            delete _report.reportLogoId;
        }

        _report.columns = [];
        const columns = _.cloneDeep(listsObject.right.items);

        let orderCounter = 0;
        columns.forEach((column) => {
            let sortType = 'NONE';
            let sortOrder = 'NONE';
            if (column.orderObj) {
                sortType = column.orderObj.sortType;
                sortOrder = this.reportEntitiesService.sortOrderMap[column.orderObj.sortOrder];
            }

            delete column.orderObj;

            _report.columns.push({
                id: column.id,
                columnType: column.columnType,
                columnBaseId: column.columnBaseId,
                order: ++orderCounter,
                sortType,
                sortOrder,
            });
        });

        _report.graphs.forEach((graph, graphIndex) => {
            graph.columns.forEach((column, columnIndex) => {
                _report.graphs[graphIndex].columns[columnIndex] = {columnId: column};
            });
        });

        if (_report.reportType === this.reportRestService.reportTypes.ELEMENT) {
            delete _report.reportInterval;
        }

        if (_.isNil(_report.topType)) {
            delete _report.topType;
        }

        if (_report.timeFilter.filterType === 'EXPLICIT') {
            _report.timeFilter.from = new Date(_report.timeFilter.from._d);
            _report.timeFilter.to = new Date(_report.timeFilter.to._d);
        }

        return _report;
    };

    validateAuxiliaryForm = (errors, auxModel, formModel) => {
        if (this.additionalSettings.reportType !== this.reportRestService.reportTypes.TREND && this.listsObject.right.items && this.listsObject.right.items.length > 0) {
            const atLeastOneMetricExist = this.listsObject.right.items.some((item) => item.columnType === 'Metric');

            if (!atLeastOneMetricExist) {
                errors.push(this.schemaHelperService.buildErrorItem({
                    instancePath: '/list/metrics',
                    inputName: 'list.metrics',
                    keyword: 'requiredAtLeastOneMetric'
                }));
            }
        }

        if (this.listsObject.right.items.length > 100) {
            errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/list/metrics',
                inputName: 'list.metrics',
                keyword: 'maxItems',
                limit: 100
            }));
        }

        if (formModel.category && formModel.category.toLowerCase() === 'all') {
            errors.push(this.schemaHelperService.buildErrorItem({
                instancePath: '/category',
                inputName: 'report.category',
                message: 'Category All Is not allowed'
            }));
        }
    };

    listChanged = () => {
        this.handleGraphsColumns();
    };

    handleColumnsList = (runUpdateColumnSortArray?) => {
        if (this.isFirstInitInEdit) {
            return;
        }

        const isTrend = (this.report.reportType || this.additionalSettings.reportType) === this.reportRestService.reportTypes.TREND;
        const newAvailableColumnsList = [];

        this.handleElementOrAggregatedReportType(newAvailableColumnsList, _.cloneDeep(this.infoColumns));

        if (isTrend) {
            if (!this.additionalSettings.selectAllTreeEntities) {
                this.handleTrendReportType(newAvailableColumnsList);
            }
        } else {
            this.handleElementOrAggregatedReportType(newAvailableColumnsList, _.cloneDeep(this.metrics));
        }

        this.updateColumnsLists(newAvailableColumnsList);


        if (runUpdateColumnSortArray) {
            this.executeUpdateColumnSortArray(true);
        }

        setTimeout(() => {
            this.dataIsReady = true;
        });
    };

    extendColumnDetails = (column, forGraphs = false) => {
        const fullDetailedColumn = this.reportEntitiesService.getFullColumn(column, this.report.entityType || this.additionalSettings.entityType);

        if (!forGraphs && column.sortType !== 'NONE') {
            const sortOrder = this.reportEntitiesService.sortOrderMap[column.sortOrder];
            const orderObj = {
                id: column.id,
                sortType: column.sortType,
                sortOrder
            };

            fullDetailedColumn.orderObj = orderObj;

            this.reportRestService.sortableOrderArray[sortOrder - 1] = orderObj;
        }

        return fullDetailedColumn;
    };

    onTabSelect(tab: any) {
        this.activeTabId = tab.id;
    }

    private onReportDialogOpen(params: any, isMonitor: boolean) {
        this.dataIsReady = false;
        this.metrics = _.cloneDeep(this.reportEntitiesService.getEntitiesArray('ReportMetric', null));
        this.infoColumns = _.cloneDeep(this.reportEntitiesService.getEntitiesArray('ReportInfoColumn', null));

        this.isEdit = params.isEdit;
        this.schema = this.isEdit ? _.cloneDeep(this.reportEditSchema) : _.cloneDeep(this.reportAddSchema);
        this.schema.required = this.schema.required || [];
        this.reportRestService.sortableOrderArray = [];
        this.listsObject.left.items = [];

        this.listsObject.right.items = [];
        this.report = params.entity;

        if (!this.isEdit && !params.isDuplicate) {
            delete this.report.trendReportMetricID;
        }

        this.isViewOnly = params.isViewOnly || isMonitor;
        this.dialogConfig.onSubmit = this.isViewOnly ? null : this.dialogConfig.onSubmit;

        this.additionalSettings = _.cloneDeep(this.report);
        this.additionalSettings.selectAllTreeEntities = this.report.entitiesFilter === null;

        if (!AuthorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()) {
            this.getADsAndAddThemToWSEntities();
        }

        this.isFirstInitInEdit = true;
        this.initColumnsAndGraphsFromServer();
        this.isFirstInitInEdit = false;

        this.handleColumnsList();
    }

    private getADsAndAddThemToWSEntities() {
        const onSuccess = (value: RestResponseSuccess) => {
            this.wsEntities.setData('ads', value.data.activeDirectories, 'id');
        };

        this.activeDirectoriesRestService.getActiveDirectories(onSuccess, () => {
        });
    }

    private handleElementOrAggregatedReportType(newAvailableColumnsList, listDest) {
        _.forOwn(listDest, (column) => {
            if (column.relevantForReportType && column.relevantForEntityType) {
                if (column.relevantForReportType.includes(this.report.reportType || this.additionalSettings.reportType) &&
                    column.relevantForEntityType.includes(this.report.entityType || this.additionalSettings.entityType)) {
                    newAvailableColumnsList.push(column);
                }
            } else {
                newAvailableColumnsList.push(column);
            }
        });
    }

    private handleTrendReportType(newAvailableColumnsList) {
        const entityListName = (this.report.entityType || this.additionalSettings.entityType).toLowerCase() + 's';
        if (this.mapEntityToServiceFunc[entityListName]) {
            const entitiesIdFromTree = this.report.entitiesFilter[entityListName];
            const entitiesObjectsFromTree = this.mapEntityToServiceFunc[entityListName](entitiesIdFromTree);
            _.forOwn(entitiesObjectsFromTree, (entity) => {
                const assignedEntity: ReportEntity = new ReportEntity(entity);
                assignedEntity.displayName = entity.name;

                newAvailableColumnsList.push(assignedEntity);
            });
        }
    }

    private findItemInList = (list: ReportColumns[], item: ReportColumns) => {
        const foundItem = list.find((listItem) => item.columnType === listItem.columnType && item.columnBaseId === listItem.columnBaseId);
        if (foundItem) {
            list.splice(list.indexOf(foundItem), 1);
        }
        return foundItem;
    };

    private updateColumnsLists(newAvailableColumnsList: ReportColumns[]) {
        this.listsObject.left.items = this.listsObject.left.items.filter((item) => this.findItemInList(newAvailableColumnsList, item));
        this.listsObject.right.items = this.listsObject.right.items.filter((item) => {
            const foundItem = this.findItemInList(newAvailableColumnsList, item);

            if (!foundItem) {
                this.handleOrderArraySubject.next(item);
            }

            return foundItem;
        });


        newAvailableColumnsList.forEach((item) => {
            if (!this.findItemInList(this.listsObject.left.items, item) && !this.findItemInList(this.listsObject.right.items, item)) {
                if (item instanceof ReportInfoColumns) {
                    this.listsObject.right.items.unshift(item);
                } else if (this.lockedTypes.includes(item.columnType)) {
                    this.listsObject.right.items.push(item);
                } else {
                    this.listsObject.left.items.push(item);
                }
            }
        });


        this.handleGraphsColumns();
    }

    private handleGraphsColumns() {
        if (this.listsObject.right.items.length > 0) {
            this.report.graphs.forEach((graph) => {
                graph.columns = graph.columns.filter((columnId) => this.listsObject.right.items.some((leftColumn) => leftColumn.id === columnId));
            });

            this.columnList = this.listsObject.right.items.filter((item) => !(item instanceof ReportInfoColumns));
        }
    }

    private initColumnsAndGraphsFromServer() {
        let graphsIdCounter = 0;
        let columnsIdCounter = 0;
        this.report.columns.forEach((rightColumn) => {
            if (rightColumn.id > columnsIdCounter) {
                columnsIdCounter = rightColumn.id;
            }

            const extendedColumn = this.extendColumnDetails(rightColumn);
            this.listsObject.right.items.push(extendedColumn);
            this.report.graphs.forEach((graph) => {
                if (graph.id > graphsIdCounter) {
                    graphsIdCounter = graph.id;
                }

                graph.columns.forEach((columnObj, i) => {
                    if (columnObj.columnId === rightColumn.id) {
                        graph.columns[i] = extendedColumn.id;
                    }
                });
            });
        });

        reportGraph.idCounter = graphsIdCounter;
        ReportColumns.idCounter = columnsIdCounter;
    }
}


