<ac-drop-down #acDatePickerDropdown [upperCaseContent]="true"
              class="acDatePickerDropdown"
              [class.ac-disable]="ngDisabled"
              [parentClass]="singleDatePicker ? 'datePickerDropdown singleDatePicker' : 'datePickerDropdown'">

    <label parent [class.datePickerInputIsDisabled]="ngDisabled"
           [class.open]="acDatePickerDropdown.isOpen"
           class="datePickerContainer flexRowParent">
        <div (click)="datePickerInput.click();$event.preventDefault();$event.stopPropagation()" class="flexItem">
            <i class="fa fa-calendar calIcon"></i>
        </div>
        <input #datePickerInput
               [attr.disabled]="ngDisabled ? true : null"
               [class.datePickerInputIsDisabled]="ngDisabled"
               class="datePickerInput flexItemBiggest" type="text"/>

        <div (click)="datePickerInput.click();$event.preventDefault();$event.stopPropagation()" class="flexItem">
            <i class="fa fa-caret-down arrowIcon"></i>
        </div>
    </label>
</ac-drop-down>
