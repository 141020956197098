import {TopologyFilterResolver} from './topology-filter-resolver.class';
import {BaseResolverClass} from '../base-resolver.class';

export class FilterResolverFactory {
    static getFilterResolver(filterType: string): BaseResolverClass {
        switch (filterType) {
            case 'Topology':
                return new TopologyFilterResolver();
            default:
                return new BaseResolverClass();
        }
    }
}
