import {BaseResolverClass} from './base-resolver.class';

export class InfoPanelResolver extends BaseResolverClass {

    constructor(private appVersion) {
        super();
    }
    import = (importedInfoPanel, importVersion) => this.migrate(importedInfoPanel, importVersion);
    migrate = (importedInfoPanel, importVersion) => importVersion !== this.appVersion ? null : importedInfoPanel;
}
