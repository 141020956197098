import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AcFormComponent} from './ac-form.component';
import {AcButtonModule} from '../ac-button/ac-button.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AcButtonModule
    ],
    declarations: [
        AcFormComponent,
    ],
    entryComponents: [
        AcFormComponent,
    ],
    exports: [
        AcFormComponent,
    ]
})
export class AcFormModule {
}
