import {BaseResolverClass} from './base-resolver.class';
import {WorkspaceVersions} from '../workspace-versions.model';


export class TableResolverClass extends BaseResolverClass {

    static migrations = {
        2: (tableState) => {
            return {
                tableState: Object.getOwnPropertyNames(tableState).reduce((migratedTableState, tableId) => {
                    const columnsWidth = tableState[tableId].columnOptions?.reduce((acc, curr) => (acc[curr.field || curr.title] = curr.width, acc), {});
                    const paging = {
                        page: tableState[tableId].pagingOptions.page,
                        size: tableState[tableId].pagingOptions.pageSize
                    };
                    const scrollPosition = tableState[tableId].lastScrollPosition;
                    const sorting = tableState[tableId].lastSortedFields;
                    migratedTableState[tableId.replace('.acTable.stateAndConfigs', '')] = {
                        columnsWidth,
                        scrollPosition,
                        sorting,
                        paging
                    };
                    return migratedTableState;
                }, {})
            };
        }
    };

    migrate = (importedTableState, importVersion) => {
        let migratedTableState = {...importedTableState};

        let migrationCounter = 0;
        while (migratedTableState && importVersion < WorkspaceVersions.tableVersion) {
            const migrationFunction = TableResolverClass.migrations[importVersion + 1];
            if (migrationFunction) {
                migratedTableState = migrationFunction(migratedTableState);
                migrationCounter++;
            }
            importVersion++;
        }

        return migratedTableState;
    };
}
