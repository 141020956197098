<operator-suspend-field [operator]="operator"
                        [additionalSettings]="additionalSettings"
                        [globalAutoSuspendDays]="ovocAuthentication?.globalAutoSuspendDays"
                        [isEdit]="isEdit"
></operator-suspend-field>

<div class="flexRowParentEqualChildren">
    <ac-input-container label="Account Inactivity Period (Days)">
        <ac-num-spinner [(acModel)]="operator.accountInactivityPeriodDays"
                        [attr.alternativeName]="'operator.accountInactivityPeriodDays'"
                        [maxValue]="1000"
                        [minValue]="0" numSpinnerId="operator-form-account-inactivity-days">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container label="Session Timeout Period (Minutes)">
        <ac-num-spinner [(acModel)]="operator.sessionInactivityPeriodMin"
                        [attr.alternativeName]="'operator.sessionInactivityPeriodMin'"
                        [maxValue]="600"
                        [minValue]="0" numSpinnerId="operator-form-session-inactivity-minutes">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container label="Session Leasing Period (Hours)">
        <ac-num-spinner [(acModel)]="operator.sessionLeasingDurationHours"
                        [attr.alternativeName]="'operator.sessionLeasingDurationHours'"
                        [maxValue]="72"
                        [minValue]="0" numSpinnerId="operator-form-session-leasing">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container label="Password Update Min Period (Hours)">
        <ac-num-spinner [(acModel)]="operator.pswdUpdateMinPeriodHours"
                        [attr.alternativeName]="'operator.pswdUpdateMinPeriodHours'"
                        [maxValue]="999"
                        [minValue]="0" numSpinnerId="operator-form-password-update">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container label="Password Validity Max Period (Days)">
        <ac-num-spinner [(acModel)]="operator.pswdValidityMaxPeriodDays"
                        [attr.alternativeName]="'operator.pswdValidityMaxPeriodDays'"
                        [maxValue]="999"
                        [minValue]="0" numSpinnerId="operator-form-password-validity">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container label="Password Warning Max Period (Days)">
        <ac-num-spinner [(acModel)]="operator.pswdWarningMaxPeriodDays"
                        [attr.alternativeName]="'operator.pswdWarningMaxPeriodDays'"
                        [maxValue]="999"
                        [minValue]="0" numSpinnerId="operator-form-password-warning">
        </ac-num-spinner>
    </ac-input-container>
</div>

<div class="flexRowParentEqualChildren">
    <ac-input-container label="Allowed Login Attempts">
        <ac-num-spinner [(acModel)]="operator.allowedLoginAttempts"
                        [attr.alternativeName]="'operator.allowedLoginAttempts'"
                        [maxValue]="10"
                        [minValue]="0" numSpinnerId="operator-allowedLoginAttempts">
        </ac-num-spinner>
    </ac-input-container>

    <ac-input-container label="Max Simultaneous Login Sessions">
        <ac-num-spinner [(acModel)]="operator.maxNumOfSimultaneousSessions"
                        [attr.alternativeName]="'operator.maxNumOfSimultaneousSessions'"
                        [maxValue]="100"
                        [minValue]="0"
                        numSpinnerId="operator-globalMaxNumOfSimultaneousSessions">
        </ac-num-spinner>
    </ac-input-container>
</div>

<ac-input-container label="Notifications display time (sec)">
    <ac-num-spinner [(acModel)]="operator.sessionNotificationDuration"
                    [attr.alternativeName]="'operator.sessionNotificationDuration'"
                    [maxValue]="60"
                    [minValue]="0"
                    numSpinnerId="operator-sessionNotificationDuration">
    </ac-num-spinner>
</ac-input-container>
