import {Component, Input, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {AcTreeNavigationComponent} from 'ac-infra';
import {MetadataService} from '../../../metadata/metadata.service';

@Component({
    selector: 'operator-connect-menu',
    templateUrl: './operator-connect-menu.component.html',
    styleUrls: ['./operator-connect-menu.component.less']
})

export class OperatorConnectMenuComponent {

    @Input() showTree = true;
    @Input() model: any;
    @Input() parentName: any;
    @Input() authorized = true;
    @Input() tenantFormSettings: any;
    @Input() availableNodesList = ['operatorContacts', 'offers', 'webhook', 'trustedProviderIssuers'];
    @Input() set errors(errors) {
        this._errors = errors;
    }

    @ViewChild('navigationTree', {static: false}) ACTreeNavigationComponent: AcTreeNavigationComponent;
    _errors;
    changeDetected = 0;

    selectedOCType;
    treeDataNodesOptions = {
        operatorContacts: {type: 'operatorContacts', title: 'Operator Contacts', dataPaths: ['/operatorConnectInfo/operatorContacts']},
        offers: {type: 'offers', title: 'Offers', dataPaths: ['/operatorConnectInfo/offers']},
        webhook: {type: 'webhook', title: 'Webhook', dataPaths: ['/operatorConnectInfo/webhook']},
        trustedProviderIssuers: {type: 'trustedProviderIssuers', title: 'Trusted Provider Issuers', dataPaths: ['/operatorConnectInfo/trustedProviderIssuers']}
    };

    treeData = [];

    typesDefaultsMap = {
        operatorContacts: {},
        offers: {allowed: true},
        webhook: {headers: []},
        trustedProviderIssuers: 'https://sts.windows.net/partner-aad-tenant-id/'
    };

    operatorContactsList;
    offersList;
    operatorContactsItems = MetadataService.getType('OperatorContactType');
    offersItems = MetadataService.getType('OperatorConnectOfferType');

    ngOnInit(){
        this.availableNodesList.forEach((nodeName) => {
            this.treeData.push(this.treeDataNodesOptions[nodeName]);
        });

        this.selectedOCType = {type: this.availableNodesList[0]};
        this.getUpdatedListOfItems('operatorContacts');
        this.getUpdatedListOfItems('offers');

        this.calculateItemsInTreeNode('all');
    }

    byIndex = (index) => index;

    onOCTypeNavigationTreeSelection = (ocTypeSelection) => {
        this.selectedOCType = ocTypeSelection.length === 1 ? ocTypeSelection[0] : undefined;
    };

    changeHappened() {
        this.changeDetected++;
    }

    onInitialize() {
        this.setActiveItems(this.treeData && this.treeData.length > 0 && this.treeData[0]);
    }

    setActiveItems(selectedItems) {
        this.ACTreeNavigationComponent?.setActive(selectedItems);
    }

    addItem = () => {
        if (this.selectedOCType.type === 'webhook') {
            this.model[this.parentName].webhook = _.cloneDeep(this.typesDefaultsMap.webhook);
        } else {
            this.model[this.parentName][this.selectedOCType.type].push(_.cloneDeep(this.typesDefaultsMap[this.selectedOCType.type]));
        }

        this.calculateItemsInTreeNode(this.selectedOCType.type);
    };

    removeItem = (indexToDelete, type) => {
        if (this.selectedOCType.type === 'webhook') {
            delete this.model[this.parentName].webhook;
        } else {
            const result = [];

            this.model[this.parentName][type].forEach((obj, index) => {
                if (indexToDelete !== index) {
                    result.push(_.cloneDeep(obj));
                }
            });

            this.model[this.parentName][type] = result;
        }

        this.calculateItemsInTreeNode(type);
    };

    disableAddButton = () => {
        if (this.selectedOCType) {
            switch (this.selectedOCType.type) {
                case 'operatorContacts':
                    return this.model[this.parentName].operatorContacts.length === 5;
                case 'offers':
                    return this.model[this.parentName].offers.length === 2;
                case 'webhook':
                    return this.model[this.parentName].webhook;
                case 'trustedProviderIssuers':
                    return this.model[this.parentName].trustedProviderIssuers.length === 50;
            }

            return true;
        }

        return false;
    };

    calculateItemsInTreeNode = (type) => {
        _.forOwn(this.treeData, (treeNode: any) => {
            if (type === 'all' || treeNode.type === type) {
                if (treeNode.type === 'webhook') {
                    treeNode.text = treeNode.title + ' (' + (this.model[this.parentName]?.webhook ? '1' : '0') + ')' + ' - Headers '+ '(' + ((this.model[this.parentName]?.webhook && this.model[this.parentName].webhook?.headers?.length) || 0) + ')';
                }else{
                    treeNode.text = treeNode.title + ' (' + ((this.model[this.parentName] && this.model[this.parentName][treeNode.type]?.length) || 0) + ')';
                }
            }
        });
    };

    getUpdatedListOfItems = (type) => {
        const typeItems = _.cloneDeep(type === 'operatorContacts' ? this.operatorContactsItems : this.offersItems);
        const typeToSearch = type === 'operatorContacts' ? 'operatorContactType' : 'offerType';
        const result = [];

        _.forOwn(typeItems,(item: any) => {
            if(this.model[this.parentName] && this.model[this.parentName][type]?.length > 0){
                item.disabled = _.some(this.model[this.parentName][type], {[typeToSearch]: item.name});
            }

            result.push(item);
        });

        if(type === 'operatorContacts'){
            this.operatorContactsList = result;
        }else{
            this.offersList = result;
        }
    };
}



