import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcNavComponent} from './ac-nav.component';
import {RouterModule} from '@angular/router';

import {RouteBuilderPipe} from './route-builder.pipe';
import {AcNavAutoComponent} from './ac-nav-auto/ac-nav-auto.component';
import {AcNavButtonComponent} from './ac-nav-button/ac-nav-button.component';
import {AcNavDynamicComponent} from './ac-nav-dynamic/ac-nav-dynamic.component';
import {FilterNavsPipe} from './ac-nav-auto/filter-navs.pipe';
import {RouterLinkDirective} from './ac-nav-auto/router-link.directive';
import {InfraPipesModule} from '../../pipes/infra.pipes.module';
import {InfraDirectivesModule} from '../../directives/infra.directives.module';
import {AcProgressModule} from '../ac-progress/ac-progress.module';
import {AcIconsModule} from '../ac-icons/ac-icons.module';
import {AcDropdownModule} from '../ac-dropdown/ac-dropdown.module';
import {QuicklinkModule} from 'ngx-quicklink';

const components = [
    AcNavComponent,
    RouteBuilderPipe,
    AcNavAutoComponent,
    AcNavButtonComponent,
    AcNavDynamicComponent,
    FilterNavsPipe,
    RouterLinkDirective
];

@NgModule({
    imports: [
        CommonModule,
        QuicklinkModule,
        InfraDirectivesModule,
        InfraPipesModule,
        InfraDirectivesModule,
        RouterModule,
        AcDropdownModule,
        AcProgressModule,
        AcIconsModule
    ],
    declarations: [
        ...components,
    ],
    exports: [
        ...components
    ]
})
export class AcNavModule {
}
