import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AC_TABLE_CONFIG, AcTableColumn, AcTableConfig} from '../ac-table.interface';
import {AcTableComponent} from '../ac-table.component';
import {AcTableService} from '../ac-table.service';


@UntilDestroy()
@Component({
    selector: '[ac-table-header]',
    templateUrl: './ac-table-header.component.html',
    styleUrls: ['./ac-table-header.component.less'],
})
export class AcTableHeaderComponent {
    @ViewChild('headerRow', {static: true}) headerRow: any;
    @Input() columns: AcTableColumn[];
    @Output() columnSort = new EventEmitter<AcTableColumn>();

    constructor(private acTableComponent: AcTableComponent,
                public acTableService: AcTableService,
                @Inject(AC_TABLE_CONFIG) public acTableConfig: AcTableConfig,
    ) {
    }

    onColumnSort(column: AcTableColumn) {
        this.columnSort.emit(column);
    }

}
