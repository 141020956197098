import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';

import $ from 'jquery';
import {isNil} from 'lodash';

import {AcFormComponent} from '../ac-form/ac-form.component';
import {AcTabDirective} from '../ac-tabs/ac-tab.directive';
import {MatFormField} from '@angular/material/form-field';

@Component({
    selector: 'ac-input-container',
    templateUrl: './ac-input-container.component.html',
    styleUrls: ['./ac-input-container.component.less']
})
export class AcInputContainerComponent {
    @ViewChild('labelTemplate', {static: false}) labelTemplate: TemplateRef<any>;

    @Input() upperPadding = true;
    @Input() displayAsInfo = false;
    @Input() displayAsBasic = false;// without all the error paddings and the upperPaddings
    @Input() displayOnlyError = false;// will show only error if true - field will not be displayed
    @Input() hideLabel = false;
    @Input() isMaterial = true;
    @Input() borderAppearance = false;
    @Input() alwaysShowError = false;
    @Input() labelBold = false;
    @Input() editModePrefix = '';
    @Input() preventLabelClick = false;
    @Input() ignoreRequired = false;
    @Input() fullWidthContent = false;
    @Input() infoIconText;
    @Output() acChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('acInputChildElement', {static: false}) acInputChildElementObj: ElementRef;
    @Input() preTemplate: TemplateRef<any>;
    @Input() postTemplate: TemplateRef<any>;
    @ViewChild(MatFormField) _matFormField: MatFormField;
    @Input() isValid = false;
    @Input() readOnly = false;
    @Input() required = false;
    @Input() customError;
    @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @HostBinding('style.margin') margin = '0 10px';

    @Input() set sideMargin(sideMargin) {
        this.margin = sideMargin ? '0 10px' : null;
    }

    matFormFieldControl;
    componentName = '';
    elementType = '';
    isDisabled = false;
    error = '';
    inputText = '';
    beforeViewInit = true;
    listOfNonMaterialComponents = ['ac-select'];
    showLabel = false;

    private _alternativeName;
    acInputContainerReady = false;

    _label;
    @Input() set label(label) {
        this._label = label;
        this.updateLabel();
    }

    @Input() set alternativeName(alternativeName) {
        this._alternativeName = alternativeName;
        this.updateForm();
    }

    constructor(@Optional() public formHost: AcFormComponent, @Optional() private tabHost: AcTabDirective, public cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.formHost?.borderAppearance) {
            this.borderAppearance = true;
        }

        if (this.displayAsBasic) {
            this.upperPadding = false;
        }
    }

    ngAfterViewInit() {
        this.updateLabel();

        if (this._matFormField && this.matFormFieldControl && this.beforeViewInit) {
            this.initializeMaterialField(this.matFormFieldControl);
        }

        this.updateForm();
    }

    updateLabel = () => {
        const editPrefix = this.formHost?.isEdit && this.editModePrefix ? this.editModePrefix + ' ' : '';
        this.inputText = this.hideLabel || !this._label ? '' : editPrefix + this._label;

        if (this.borderAppearance && !this.preTemplate && !this.hideLabel) {
            this.preTemplate = this.labelTemplate;
        }
    };

    initializeMaterialField = (matFormFieldControl) => {
        this.matFormFieldControl = matFormFieldControl;
        if (this._matFormField && matFormFieldControl) {
            this._matFormField._control = matFormFieldControl;
            this.beforeViewInit = false;
            this.cdRef.detectChanges();
        }
    };

    showError() {
        if (this.customError) {
            return this.customError;
        }

        this.error = '';
        if (isNil(this.componentName) || this.componentName === '') {
            return;
        }

        if (this.formHost && this.formHost.formValidator && this.formHost.formValidator[this.componentName] && this.formHost.formValidator[this.componentName].errors) {
            this.error = this.formHost.formValidator[this.componentName].errors.message;
            this.isValid = false;
        } else {
            this.isValid = true;
        }

        this.isValidChange.emit(this.isValid);

        return this.error;
    }

    preventLabelClickFunc(event) {
        if (this.preventLabelClick) {
            event.preventDefault();
        }
    }

    isRequired = () => {
        return ((this.formHost?.formValidator?.[this.componentName]?.isRequired || this.required) && this.ignoreRequired !== true ? '*' : '');
    };

    private updateForm = () => {
        const children = this.acInputChildElementObj && this.acInputChildElementObj.nativeElement.children;

        if (!children || children.length <= 0) {
            return;
        }

        const alternativeName = $(this.acInputChildElementObj.nativeElement).children().attr('alternativeName') || $(this.acInputChildElementObj.nativeElement).children().children().attr('alternativeName');
        this.componentName = this._alternativeName || alternativeName;

        this.elementType = children[0].nodeName.toLowerCase();

        if (this.formHost) {
            this.registerForm();
        }

        this.cdRef.detectChanges();

        this.acInputContainerReady = true;
    };


    private registerForm = () => {

        this.isDisabled = !this.formHost.isAuthorized;

        if (this.formHost.formValidator && this.componentName && this.componentName !== '') {
            this.formHost.formValidator[this.componentName] = this.formHost.formValidator[this.componentName] || {};
            this.formHost.formValidator[this.componentName].type = this.elementType;
        }


        if (this.tabHost && this.tabHost.id && this.componentName) {
            const tabId = this.tabHost.id;

            this.formHost.tabsValidationObj[tabId] = this.formHost.tabsValidationObj[tabId] || {inputs: []};
            this.formHost.tabsValidationObj[tabId].inputs.push(this.formHost.formValidator[this.componentName]);
        }
    };
}



