import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';

import {IDTypeDictionary} from '@circlon/angular-tree-component/lib/defs/api';
import {TreeNode} from '@circlon/angular-tree-component/lib/models/tree-node.model';
import {TreeModel} from '@circlon/angular-tree-component/lib/models/tree.model';
import {IActionMapping, ITreeOptions, KEYS, TreeComponent} from '@circlon/angular-tree-component';

import {AcTreeService} from './ac-tree.service';
import {SessionStorageService} from '../../services/session-storage.service';

@Component({
    selector: 'ac-tree',
    templateUrl: './ac-tree.component.html',
    styleUrls: ['./ac-tree.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcTreeComponent implements OnInit {

    static EXPAND_POSTFIX = '_expand';

    @ViewChild('tree', {static: true}) tree: TreeComponent;

    @Input() treeId = 'ac-tree';
    @Input() overwriteOptions: ITreeOptions;
    @Input() extendOptions: ITreeOptions;
    @Input() customTreeNodeWrapperTemplate: TemplateRef<any>;
    @Input() restoreExpanded = true;
    @Input() multiActive = false;
    @Input() filter: (...args) => boolean;
    @Input() doSort = true;
    @Input() sortCompare: (source, target) => number;
    @Output() activeChange = new EventEmitter<TreeNode[]>();
    @Output() selectChange = new EventEmitter<TreeNode[]>();
    @Output() initialized = new EventEmitter<any>();
    @Output() updateData = new EventEmitter<any>();

    loading = false;
    actionMapping: IActionMapping;
    searchQuery = '';
    treeOptions: ITreeOptions = {
        useVirtualScroll: true,
        scrollContainer: document.body,
        allowDrop: false,
        allowDrag: false,
        useCheckbox: false,
        useTriState: true,
    };

    constructor(private acTreeService: AcTreeService, private cdRef: ChangeDetectorRef) {
    }

    _treeNodes: any[];
    @Input() set treeNodes(treeNodes: any[]) {
        this._treeNodes = treeNodes;
        this.doSort && this.acTreeService.sortNodes(this._treeNodes, true, this.treeOptions.displayField, this.sortCompare);
    }

    @Input() set search(query: string) {
        if (!this.searchQuery && query) {
            this.setExpand({}, false, false);
        }
        this.searchQuery = query;

        this.loading = true;
        this.filterTree();
        setTimeout(() => {
            this.loading = false;
            this.cdRef.detectChanges();
        });
    }

    ngOnInit() {

        if (this.overwriteOptions) {
            this.treeOptions = this.overwriteOptions;
        } else if (this.extendOptions) {
            this.treeOptions = {
                ...this.treeOptions,
                ...this.extendOptions,
            };
        }

        this.actionMapping = {
            mouse: {
                expanderClick: this.onExpand,
                click: this.treeOptions.useCheckbox ? this.onSelect : this.onActive,
                checkboxClick: this.onSelect,
            },
            keys: {
                [KEYS.SPACE]: null,
                [KEYS.ENTER]: null,
            }
        };
        this.treeOptions.actionMapping = this.actionMapping;

        if (this.restoreExpanded && this.tree) {
            const expandedNodesIds = SessionStorageService.getData(this.treeId + AcTreeComponent.EXPAND_POSTFIX) || {};
            this.setExpand(expandedNodesIds);
        }
    }

    saveExpandStateToSession() {
        if (this.searchQuery) {
            return;
        }
        SessionStorageService.setData(this.treeId + AcTreeComponent.EXPAND_POSTFIX, this.tree.treeModel.getState().expandedNodeIds);
    }

    // On set

    setActive(ids: IDTypeDictionary, emit = true) {
        this.acTreeService.setActiveNodeIds(this.tree.treeModel, ids);
        emit && this.onActiveNodesChange();
    }

    setSelect(ids: IDTypeDictionary, loadFromState = false) {
        this.acTreeService.setSelectedLeafNodeIds(this.tree.treeModel, ids, loadFromState);
        this.onSelectNodesChange();
    }

    setExpand(ids: IDTypeDictionary, loadFromState = false, saveToSession = true) {
        this.acTreeService.setExpandNodeIds(this.tree.treeModel, ids, loadFromState);
        saveToSession && this.saveExpandStateToSession();
    }

    // On event

    @HostListener('window:resize')
    resizeTree = () => setTimeout(() => this.tree && this.tree.sizeChanged(), 100);

    updateTree = () => this.tree && this.tree.treeModel.update();

    filterTree = () => {
        this.tree && this.acTreeService.filterTree(this.tree.treeModel, this.searchQuery, this.filter, this.treeId + AcTreeComponent.EXPAND_POSTFIX);
    };

    onActiveNodesChange() {
        this.activeChange.emit(this.tree.treeModel.getActiveNodes());
    }

    onSelectNodesChange() {
        if (this.treeOptions.useTriState) {
            this.selectChange.emit(this.tree.treeModel.roots);
        } else {
            this.selectChange.emit(this.tree.treeModel.selectedLeafNodes);
        }
    }

    onInitialized($event: any) {
        this.initialized.next($event);
    }

    onUpdateData($event: any) {
        this.updateData.next($event);
        this.filterTree();
    }

    private onExpand = (model: TreeModel, node: TreeNode, $event: any) => {
        node.toggleExpanded();
        this.saveExpandStateToSession();
    };

    private onActive = (model: TreeModel, node: TreeNode, $event: any) => {

        if (this.multiActive && ($event.ctrlKey || $event.shiftKey)) {
            node.toggleActivated(true);
        } else {
            node.setIsActive(true);
        }
        this.onActiveNodesChange();
    };

    private onSelect = (model: TreeModel, node: TreeNode, $event: any) => {
        const leafsIdsState = {...model.selectedLeafNodeIds};

        if (this.treeOptions.useTriState) {
            this.acTreeService.updateSelectNodes(node, !node.isSelected, leafsIdsState);
        } else {
            leafsIdsState[node.id] = !!!leafsIdsState[node.id];
        }
        this.acTreeService.setSelectedLeafNodeIds(model, leafsIdsState);
        this.onSelectNodesChange();
    };
}
