<ac-progress *ngIf="isLoading"></ac-progress>
<section *ngIf="!isLoading" class="flexColumnParent" [class.fullScreen]="isFullScreen">
    <!--conflict test-->

    <span *ngIf="fullScreenToggle" class="fullScreenToggle" (click)="toggleFullScreen()">
        <i *ngIf="!isFullScreen" aria-hidden="true" class="fa fa-expand"></i>
        <i *ngIf="isFullScreen" aria-hidden="true" class="fa fa-compress"></i>
    </span>

    <ac-layout-section-header *ngIf="hasHeader"
                              [sectionTitle]="sectionTitle"
                              [postfixTitle]="titlePostfix"
                              [class.visibilityHidden]="isCollapsed"
                              class="section-header flexItem">
        <ng-content select="[section-header]"></ng-content>
    </ac-layout-section-header>

    <ng-container *ngIf="panels.length > 0; else allNaturalChilds">

        <!--COLLAPSED-->
        <div [hidden]="!isCollapsed" class="section-content-collapsed flexItemBiggest">
            <ac-layout-panel (collapse)="toggleCollapseState()"
                             [collapseDir]="collapseDir"
                             [collapsed]="true"
                             [titlePostfix]="titlePostfix"
                             [panelTitle]="sectionTitle">

            <span *ngFor="let panel of panels; let first=first">
                <span *ngIf="!first && panel.title" class="ampersand-spacer">&</span>
                {{panel.title | uppercase}}
            </span>
            </ac-layout-panel>
        </div>

        <!--NOT COLLAPSED-->
        <ng-template  [ngTemplateOutlet]="spacerTemplate"></ng-template>
        <div [hidden]="isCollapsed" class="section-content spaced flexColumnParent flexItemBiggest" [ngClass]="ContentClasses">

            <ng-container *ngFor="let panel of panels; let first=first">
                <ng-container *ngIf="panel.templateRef; else nativeElement">

                    <ac-layout-panel *ngIf="panel.if || panel.else"
                                     class="{{panel.panelClass}}"
                                     [class.flex-h1]="panel.defaultStyle"
                                     [collapseDir]="first && collapseDir"
                                     [iconName]="panel.iconName"
                                     [loading]="panel.loading"
                                     [panelTitle]="panel.title"
                                     [templates]="panel | acFunc: getPanelTemplates"
                                     (collapse)="toggleCollapseState()">

                        <ng-container *ngIf="panel.if" [ngTemplateOutlet]="panel.templateRef"></ng-container>
                        <ng-container *ngIf="!panel.if && panel.else" [ngTemplateOutlet]="panel.else"></ng-container>
                    </ac-layout-panel>
                </ng-container>

                <ng-template #nativeElement>
                    <div *ngIf="panel.elementRef"
                         [innerHTML]="panel.elementRef.nativeElement.outerHTML | safe: 'html'"
                    ></div>
                </ng-template>
            </ng-container>
        </div>
    </ng-container>
</section>

<ng-template #allNaturalChilds>
    <div class="section-content spaced flexItemBiggest" [ngClass]="ContentClasses">
        <ng-content></ng-content>
    </div>
</ng-template>

