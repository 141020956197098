import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'acFunc'})
export class AcFuncPipe implements PipeTransform {
    static urlToId = (url) => url.toLowerCase().split('/').filter((u) => !!u).join('-');

    transform(value: any, func: Function, ...args): any {
        return func(value, ...args);
    }
}
