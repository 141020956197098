import * as _ from 'lodash';

import {ServiceInjector} from '../services/service-injector';
import {ComponentRef, Type} from '@angular/core';
import {RestResponseFailure, RestResponseSuccess} from './rest';
import {Observable, Subject} from 'rxjs';
import {MessagesService} from '../utilities/messages.service';
import {
    AcDialogRef,
    AcDialogService,
    CommonNotifiersService,
    ConfirmDialogComponent,
    ConfirmDialogData,
    DialogConfig,
    GeneralService,
    LoggerService,
    PromiseService,
    promiseType,
    RefreshTableProperties,
    WSEntities
} from 'ac-infra';

export class Actions {

    wsEntities = ServiceInjector.injector.get(WSEntities);
    messagesService = ServiceInjector.injector.get(MessagesService);
    logger = ServiceInjector.injector.get(LoggerService);
    acDialogService = ServiceInjector.injector.get(AcDialogService);
    generalService = ServiceInjector.injector.get(GeneralService);
    deleteDialogConfig: DialogConfig = {
        title: 'Delete Confirmation',
        submitButtonText: 'Delete',
        elementsColorPalette: {
            titleColor: 'red',
            submitButtonColor: 'red',
        },
    };

    cleanEmptyValues: Function;


    private refreshSubject: Subject<RefreshTableProperties> = new Subject();
    refreshSubject$: Observable<RefreshTableProperties> = this.refreshSubject.asObservable();
    private entityService: any;
    private entityName: any;
    private isWsEntity: boolean;

    constructor({entityName = undefined, entityService = undefined, isWsEntity = false}) {
        this.entityName = entityName;
        this.entityService = entityService;// Entity Rest Service
        this.isWsEntity = isWsEntity;
        this.cleanEmptyValues = this.generalService.cleanEmptyValues;
    }

    refresh = (tableProperties: RefreshTableProperties = {}) => {
        if (this.isWsEntity || this.entityName === 'endpoint' || this.entityName === 'channel' || this.entityName === 'operator') {
            CommonNotifiersService.updateFinishedDataAndFiltered(tableProperties);
        } else {
            this.refreshSubject.next({...tableProperties, entityName: this.entityName});
        }
    };


    delete = ({
        entityArray, confirmMessage = undefined, dontPerformRefresh = undefined, deleteCallback = undefined, isDeleteSingle = false,
        optionsModel = undefined, alternativeEntityName = undefined, url = undefined, fakeMultipleDelete = false
    }) => {
        const confirmMsg = this.getConfirmMessage({
            entityName: alternativeEntityName || this.entityName,
            confirmMessage,
            entitiesArray: entityArray
        });
        const dialogConfig: DialogConfig = _.cloneDeep(this.deleteDialogConfig);
        const serverCallback = (success, failure) => {
            const promise: promiseType = PromiseService.defer();
            const failureMsg = this.messagesService.getFailedMessage(entityArray, alternativeEntityName || this.entityName);
            const arrayOfIDs = entityArray.map((entityObj) => entityObj.id);

            const onSuccess = (value) => {
                const response = fakeMultipleDelete ? value[0] : value;
                deleteCallback && deleteCallback(response);
                this.checkServerResponseStatus(response, dontPerformRefresh, {showLoader: true});
                promise.resolve(true);
            };
            const onFailure = (error) => {
                const response = fakeMultipleDelete ? error[0] : error;
                deleteCallback && deleteCallback(response);
                dialogConfig.submitButtonProcessing = false;
                this.logger.error(failureMsg);
                promise.reject();
            };

            let params;
            if (optionsModel) { // Checkbox inside the confirm dialog
                params = {};
                const checkboxModel = !!(optionsModel.checkboxModel && optionsModel.checkboxModel.value);
                params[optionsModel.checkboxModelName] = checkboxModel;
            }

            if (isDeleteSingle) {
                this.entityService.deleteById({success: onSuccess, failure: onFailure, id: arrayOfIDs[0], url, params});
            } else {
                if (fakeMultipleDelete) {// will be deleted if server will add multiple delete in all places
                    const promiseArr = [];
                    _.forOwn(entityArray, (entity) => {
                        promiseArr.push(this.entityService.deleteById({id: entity.id}));
                    });
                    Promise.all(promiseArr).then((response) => onSuccess(response)).catch(onFailure);
                } else {
                    this.entityService.deleteMultiple(onSuccess, onFailure, arrayOfIDs, url, params);
                }
            }
            return promise.promise;
        };

        const dialogData: ConfirmDialogData = {message: confirmMsg, options: optionsModel};
        this.genericConfirmAction({serverCallback, dialogData, dialogConfig});
    };

    genericConfirmAction = ({serverCallback, dialogData, dialogConfig}: { serverCallback: Function; dialogData: ConfirmDialogData; dialogConfig?: DialogConfig }) => {
        const dialogComponentType = ConfirmDialogComponent;
        dialogData.message = dialogData.message || this.getConfirmMessage({
            ...dialogData,
            entityName: this.entityName,
            actionName: dialogData.confirmAlternativeText || (dialogConfig || dialogData).submitButtonText,
        });

        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogConfig});
    };

    genericAction = ({
        serverCallback,
        dialogData,
        dialogComponentType,
        dialogConfig,
        dialogRef
    }: { serverCallback; dialogData?; dialogComponentType: Type<any>; dialogConfig?: DialogConfig; dialogRef?: AcDialogRef }) => {

        const onSubmit = () => this.afterDialogSubmitted({serverCallback, dialogConfig: dialogConfig || {dialogData}});

        dialogConfig = Object.assign(dialogConfig || {}, {dialogData, onSubmit});
        if (dialogRef) {
            dialogConfig = this.acDialogService.setDialogContent(dialogRef, dialogComponentType, dialogConfig);
        } else {
            this.acDialogService.open(dialogComponentType, dialogConfig);
        }
    };

    afterDialogSubmitted = ({serverCallback, dialogConfig = undefined}: { [key: string]: any; dialogConfig: DialogConfig }) => {
        const dialogData = dialogConfig.dialogData;
        const promise: promiseType = PromiseService.defer();

        const onSuccess = (response) => {
            this.refreshAndInsertToLog({
                successMsg: this.handleLoggerSuccessMsg(dialogConfig, response),
                dontPerformRefresh: dialogData.dontPerformRefreshOnSuccess,
                tableProperties: {showLoader: true}
            });
            response && dialogData.onSuccessCallback && dialogData.onSuccessCallback(response);
            promise.resolve(true);
        };

        const onFailure = (error) => {
            dialogConfig.submitButtonProcessing = false;
            this.handleLoggerFailureMsg(dialogData, error);
            dialogData.onFailureCallback && dialogData.onFailureCallback(error);
            promise.reject();
        };


        return serverCallback(onSuccess, onFailure, dialogConfig) || promise.promise;
    };

    handleLoggerSuccessMsg = (dialogConfig: DialogConfig, response) => {
        const dialogData = dialogConfig.dialogData;
        const entity = dialogData.entity;

        if (entity && entity.name) {
            return this.buildSingleSuccessMessage(entity.name, dialogData.isEdit);
        } else if (dialogData.successMessage) {
            return dialogData.successMessage;
        } else if (dialogData.entitiesArray) {
            const successAction = dialogData.successAlternativeText || dialogConfig.submitButtonText || dialogData.actionName || this.entityName;
            return this.buildMultipleSuccessActionMessage(dialogData.entitiesArray, successAction);
        }

        return response && response.data && response.data.description || '';
    };

    handleLoggerFailureMsg = (dialogData, error) => {
        const entity = dialogData.entity;
        if (dialogData.failureMessage) {
            this.logger.error(dialogData.failureMessagerMgs);
        } else if (entity && entity.name) {
            this.logger.error(this.buildSingleFailureMessage(entity.name, dialogData.isEdit));
        } else {
            this.logger.error((error && error.data && error.data.description) || (typeof error === 'string' ? error : 'General Failure'));
        }
    };

    onGenericSuccess = ({msg = undefined, requestAPI = undefined, callback = undefined}) => (response: RestResponseSuccess) => {
        this.handleSuccess({msg, requestAPI, response, callback});
    };

    onGenericFailure = (error: RestResponseFailure) => {
        this.handleFailure(error);
    };

    handleSuccess = ({msg = undefined, requestAPI = undefined, response = undefined, callback = undefined}) => {
        const message = msg || (requestAPI && this.buildFromRequestAPISuccessMessage(requestAPI, response.status)) || (response && response.data && response.data.description);

        this.acDialogService.info(message);
        this.logger.info(message);

        if (_.isFunction(callback)) {
            callback();
        }
    };

    handleFailure = (error) => {
        const failureMsg = (error && error.data && error.data.description) || 'General Error';
        this.logger.error(failureMsg);
    };

    openFailedActionDialog = (idsOfFailedEntities) => {
        const failureMsg = 'Failed to remove ' + idsOfFailedEntities.length + ' ' + this.entityName + '/s';
        this.acDialogService.fail(failureMsg);
        this.logger.error(failureMsg);
    };

    refreshAndInsertToLog = ({successMsg, dontPerformRefresh = undefined, tableProperties}) => {
        if (!this.isWsEntity && !dontPerformRefresh) {
            this.refresh(tableProperties);
        }
        this.logger.info(successMsg);
    };

    private checkServerResponseStatus = (response, dontPerformRefresh?, tableProperties?) => {
        const successMsg = 'The requested ' + this.entityName + '(s) were deleted successfully';

        if (response && (response.status === 200 || response.status === 202)) {
            this.refreshAndInsertToLog({successMsg, dontPerformRefresh, tableProperties});
        } else {
            this.checkServerMultiStatus(response?.data?.multistatus, successMsg, dontPerformRefresh);
        }
    };

    private checkServerMultiStatus = (multiStatus, successMsg, dontPerformRefresh?) => {
        const idsOfFailedEntities = this.getFailedEntityIds(multiStatus);

        if (idsOfFailedEntities.length > 0) {
            this.openFailedActionDialog(idsOfFailedEntities);
        } else {
            this.refreshAndInsertToLog({successMsg, dontPerformRefresh, tableProperties: {showLoader: true}});
        }
    };

    private getFailedEntityIds = (multiStatus) => multiStatus.filter((item) => item.status !== 200).map((item) => item.id);

    // MESSAGES
    private getConfirmMessage = (
        {
            entityName,
            entitiesArray,
            confirmMessage = undefined,
            actionName = undefined,
            confirmForItem = undefined,
            messagePostfix = undefined
        }: any
    ) => {
        return confirmMessage || (entitiesArray && this.messagesService.getConfirmMessage({
            entityName,
            entitiesArray,
            actionName,
            forItem: confirmForItem,
            messagePostfix
        }));
    };

    private buildMultipleSuccessActionMessage = (entitiesArray, actionName = 'unknown') => this.messagesService.buildMultipleSuccessActionMessage(this.entityName, entitiesArray, actionName);

    private buildSingleSuccessMessage = (name, isEdit, entityName?) => this.messagesService.buildSuccessMessage(name, isEdit, entityName || this.entityName);

    private buildSingleFailureMessage = (name, isEdit, entityName?) => this.messagesService.buildFailureMessage(name, isEdit, entityName || this.entityName);

    private buildFromRequestAPISuccessMessage = (requestAPI, status) => this.messagesService.buildFromRequestAPISuccessMessage(requestAPI, status);
}
