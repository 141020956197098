<input #numSpinnerInput matInput
       (focusout)="focusOut()"
       (keypress)="onKeypress($event)"
       (blur)="onBlur()"
       (ngModelChange)="modelChanged($event)"
       [(ngModel)]="acModel"
       [disabled]="(ngDisabled || (acInputContainer && acInputContainer.isDisabled))?true:null"
       [id]="numSpinnerId?numSpinnerId:null"
       [max]="limitSpinner(maxValue)"
       [min]="limitSpinner(minValue)"
       [step]="step"
       [value]="acModel"
       class="ac-num-spinner"
       type="number"/>
