import {Component, Inject, InjectionToken, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export const getProgressLoaderPathFactory = (defaultPath?: string) => {
    return () => new BehaviorSubject(defaultPath || '');
};

export const AC_PROGRESS_LOADER_PATH = new InjectionToken<BehaviorSubject<string>>('ac.progress.loader.path', {
    providedIn: 'root',
    factory: getProgressLoaderPathFactory()
});


@Component({
    selector: 'ac-progress',
    templateUrl: './ac-progress.component.html',
    styleUrls: ['./ac-progress.component.less'],
})
export class AcProgressComponent {

    @Input() center = true;
    options: any = {
        path: 'assets/loader.json'
    };

    sizes = {
        small: '24px',
        large: '48px'
    };

    currentSize: any = this.sizes.large;

    constructor(@Inject(AC_PROGRESS_LOADER_PATH) private progressLoaderPath: BehaviorSubject<string>) {
        this.options.path = this.progressLoaderPath.getValue() || this.options.path;
    }

    @Input() set size(size: string) {
        if(this.sizes[size]){
            this.currentSize = this.sizes[size];
        }else{
            this.currentSize = size;
        }
    }
}
