<tr #headerRow>
    <th *ngFor="let column of columns; trackBy: acTableService.trackByField"
        [attr.headers]="column.field"
        [class.left]="column.stickyLeft"
        [class.sortable-column]="column.headerSort || acTableComponent.sortingMap[column.field]"
        [class.sortable-column-pointer]="column.headerSort"
        acChild>

        <div class="table-cell flexRowParent"
             (click)="!column.disableSort && column.headerSort && onColumnSort(column)"
             [style.width]="column.colWidth | acFunc : acTableService.widthToPixels : acTableComponent.minCellWidth">
            <div class="header-table-cell" acLabel #headerCell>
                <ng-template [acTableCell]="headerCell"
                             [acTableCellTemplate]="defaultHeaderCellTemplate"
                             [acTableCellFormatter]="column.titleFormatter"
                             [acTableCellColumn]="column">
                </ng-template>
            </div>

            <ac-search *ngIf="column.filter as columnFilter"
                       class="flexItemRowEquals table-column-filter"
                       [placeHolder]="columnFilter.placeholder"
                       (click)="$event.stopPropagation()"
                       (searchChange)="acTableComponent.onColumnFilterChanged($event, column)"
            ></ac-search>

            <span *ngIf="column.headerSort || acTableComponent.sortingMap[column.field]" class="sorter flexItem">
                <span class="sorter-arrow {{acTableComponent.sortingMap[column.field]?.dir}}"></span>
                <span *ngIf="acTableComponent._sorting.length > 1"
                      class="sorter-order">{{acTableComponent.sortingMap[column.field]?.order}}</span>
            </span>
        </div>
        <span class="resize-handle"
              [class.no-user-selection]="!!(acTableComponent.tableCellContextIndex | acFunc: acTableComponent.getTableCellContext) || !acTableComponent.userSelectState"></span>
    </th>
</tr>

<ng-template let-column="column" #defaultHeaderCellTemplate>{{column.title | uppercase}}</ng-template>
