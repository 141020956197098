<div class="dnd-container flexRowParent">
    <ac-search (searchChange)="searchEvent($event)" class="flexItem"
               componentId="search-field"
               placeHolder="Search Metric">
    </ac-search>

    <div class="flexRowParent flexItemBiggest">
        <div class="list-container flexItemRowEquals flexColumnParent">
            <div class="list-header flexItem">{{listsObject.left.header}}</div>
            <div class="first-list flexItemBiggest">
                <ac-drag-and-drop-list (listChanged)="listsObject.left = $event;"
                                       [inverseListAllowedTypes]="listsObject.right.allowedTypes"
                                       [isViewOnly]="isViewOnly"
                                       [list]="listsObject.left"
                                       [lockPropertyName]="lockPropertyName"
                                       [onRowClick]="onRowClick"
                                       [searchedValue]="searchedValue"
                                       [updateColumnSortArray$]="updateColumnSortArray$"
                                       dndId="leftList"
                                       inverseListName="right">
                </ac-drag-and-drop-list>
            </div>

            <div class="list-footer flexItem">
            </div>
        </div>

        <div class="middle-buttons flexItem">
            <div class="flexColumnParent">
                <div class="list-header center-list flexItem"></div>
                <div class="dnd-buttons-container flexItemBiggest">
                    <div class="button-container right-button">
                        <ac-action-button class="dnd-button" type="custom" icon="fa-angle-right"
                                          (acClick)="moveSelectedToTheOtherSide({direction: 'left'})"
                                          [ngDisabled]="!isElementsSelected('left') || buttonsDisabled">
                        </ac-action-button>
                    </div>

                    <div class="button-container left-button">
                        <ac-action-button [title]="disableReason" class="dnd-button" type="custom" icon="fa-angle-left"
                                          (acClick)="moveSelectedToTheOtherSide({direction: 'right'})"
                                          [ngDisabled]="!isElementsSelected('right') || buttonsDisabled">
                        </ac-action-button>
                    </div>

                    <div class="button-container">
                        <ac-action-button class="dnd-button" type="custom" icon="fa-angle-double-right"
                                          (acClick)="moveAllToTheOtherSide({direction: 'left'})"
                                          [ngDisabled]="buttonsDisabled || isViewOnly">
                        </ac-action-button>
                    </div>

                    <div class="button-container">
                        <ac-action-button class="dnd-button" type="custom" icon="fa-angle-double-left"
                                          (acClick)="moveAllToTheOtherSide({direction: 'right'})"
                                          [ngDisabled]="buttonsDisabled || isViewOnly">
                        </ac-action-button>
                    </div>
                </div>
                <div class="list-footer flexItem"></div>
            </div>
        </div>

        <div class="list-container second-list flexItemRowEquals flexColumnParent">
            <div class="list-header flexItem">{{listsObject.right.header}}</div>
            <div class="flexItemBiggest">
                <ac-drag-and-drop-list #secondList (listChanged)="listsObject.right = $event;listChanged()"
                                       (sortableOrderArrayChanged)="sortableOrderArray = $event;sortableOrderArrayChanged.emit($event)"
                                       [displaySortableArrows]="true"
                                       [inverseListAllowedTypes]="listsObject.left.allowedTypes"
                                       [isViewOnly]="isViewOnly"
                                       [list]="listsObject.right"
                                       [lockPropertyName]="lockPropertyName"
                                       [onRowClick]="onRowClick"
                                       [searchedValue]="searchedValue"
                                       [sortableOrderArray]="sortableOrderArray"
                                       [updateColumnSortArray$]="updateColumnSortArray$"
                                       dndId="rightList"
                                       inverseListName="left">
                </ac-drag-and-drop-list>
            </div>
            <div class="list-footer flexItem">
                <div class="list-button-container">
                    <div class="button-container">
                        <ac-action-button class="dnd-button" type="custom" icon="fa-angle-down"
                                          (acClick)="secondList.moveSelectedToNextPosition({isDirectionDown: true})"
                                          [ngDisabled]="isViewOnly">
                        </ac-action-button>
                    </div>

                    <div class="button-container">
                        <ac-action-button class="dnd-button" type="custom" icon="fa-angle-up"
                                          (acClick)="secondList.moveSelectedToNextPosition({isDirectionDown: false})"
                                          [ngDisabled]="isViewOnly">
                        </ac-action-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
