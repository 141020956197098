<div class="flexRowParent list-item">

    <div #icon [hidden]="!icon?.childElementCount" class="flexItem icon">
        <ng-content select="[icon]"></ng-content>
    </div>

    <div class="flexColumnParent item-info">
        <div class="title flexItem">
            {{header}}
        </div>

        <div class="content flexItemBiggest">
            {{content}}
        </div>

        <div *ngIf="subContentTemplate" class="sub-content">
            <ng-template [ngTemplateOutlet]="subContentTemplate"></ng-template>
        </div>
    </div>

    <div #action [hidden]="!action?.childElementCount" class="flexItem action">
        <ng-content select="[action]"></ng-content>
    </div>
</div>
