import {Component, Input} from '@angular/core';

import {Operator} from '../operator.interface';

import {RestResponseSuccess} from '../../../../common/server-actions/rest';
import {SystemAuthenticationRestService} from '../../../administration/administration-api/system-authentication-rest.service';

@Component({
    selector: 'operator-advanced-info-tab',
    templateUrl: './operator-advanced-info-tab.component.html',
})
export class OperatorAdvancedInfoTabComponent {
    @Input() operator: Operator;
    @Input() additionalSettings: any;

    @Input() isEdit = false;
    ovocAuthentication;

    constructor(private systemAuthenticationRestService: SystemAuthenticationRestService) {
    }

    ngOnInit() {
        this.getOvocAuthentication(
            (ovocAuthentication) => {
                if (!this.isEdit) {
                    if (ovocAuthentication?.globalMaxNumOfSimultaneousSessions) {
                        this.operator.maxNumOfSimultaneousSessions = ovocAuthentication.globalMaxNumOfSimultaneousSessions;
                    }
                    if (ovocAuthentication?.globalSessionNotificationDuration) {
                        this.operator.sessionNotificationDuration = ovocAuthentication.globalSessionNotificationDuration;
                    }
                }
                this.ovocAuthentication = ovocAuthentication;
            }
        );
    }


    getOvocAuthentication = (succes, failure?) => {
        this.systemAuthenticationRestService.getAuthentication(
            (response: RestResponseSuccess) => {
                succes(response.data);
            },
            'localAuthentication'
        );
    };
}



