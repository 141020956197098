<button mat-button
        (click)="!_ngDisabled && onClick($event)"
        [id]="buttonId ? buttonId : null"
        [attr.type]="type"
        [class.ngDisabled]="_ngDisabled"
        [class.transparent-mode]="transparentMode"
        class="ac-button prevent-text-selection">
        <span class="ac-button-content">
            {{buttonValue}}
            <ng-content></ng-content>
        </span>
</button>

