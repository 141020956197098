import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild, ContentChildren,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef
} from '@angular/core';

@Component({
    selector: 'ac-layout-panel',
    templateUrl: './ac-layout-panel.component.html',
    styleUrls: ['./ac-layout-panel.component.less']
})
export class AcLayoutPanelComponent {

    @ContentChild('panelHeader') set panelHeaderTemplate(panelHeaderTemplate: TemplateRef<any>) {
        panelHeaderTemplate && this.setTemplate({panelHeaderTemplate});
    }
    @ContentChild('panelActions') set panelActionsTemplate(panelActionsTemplate: TemplateRef<any>) {
        panelActionsTemplate && this.setTemplate({panelActionsTemplate});
    }

    @Input() templates: {[key: string]: TemplateRef<any>} = {};
    @Input() @HostBinding('class.collapsed') collapsed = false;
    @Input() collapseDir = '';

    @Input() panelTitle = '';
    @Input() titlePostfix = '';
    @Input() iconName = '';
    @Input() contentCustomClass = 'flexItemBiggest';
    @Input() loading = false;

    @Output() collapse = new EventEmitter();

    setTemplate(template) {
        this.templates = {...this.templates, ...template};
    }

    onCollapse = () => {
        this.collapseDir && this.collapse.emit();
    };
}



