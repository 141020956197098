import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'azure-app-registration',
    templateUrl: './azure-app-registration.component.html',
    styleUrls: ['./azure-app-registration.component.less'],
})
export class AzureAppRegistrationComponent {

    @Input() appRegistrationObject: any;
    @Input() authorizedToUpdate: any;
    @Input() showHost = true;

    urlModeOptions = [
        {text: 'Organizations', value: 'organizations'},
        {text: 'Tenant', value: 'tenant'}
    ];
}



