import {Component, Input} from '@angular/core';
import {Operator} from '../operator.interface';
import {OperatorTopologyTabComponent} from '../operator-topology-tab/operator-topology-tab.component';
import * as _ from 'lodash';
import {MetadataService} from '../../../../metadata/metadata.service';

;


@Component({
    selector: 'operator-basic-info-tab',
    templateUrl: './operator-basic-info-tab.component.html',
})
export class OperatorBasicInfoTabComponent {

    @Input() operator: Operator;
    @Input() additionalSettings: any;

    @Input() oneLiveMode = false;
    @Input() isEdit = false;

    @Input() operatorTopologyTabElement: OperatorTopologyTabComponent;

    securityLevelOptions = [];
    operatorType: string;

    ngOnInit() {
        const securityLevelsList = MetadataService.getType('OperatorSecurityLevel');

        _.forOwn(securityLevelsList, (secLevel) => {
            if((this.operator.isSystemOperator && secLevel.name !== 'MONITORING_LINKS') || !this.operator.isSystemOperator){
                this.securityLevelOptions.push({name: secLevel.name, viewName: secLevel.viewName, displayOrder: secLevel.displayOrder});
            }
        });

        this.securityLevelOptions = this.securityLevelOptions.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.operatorType = this.operator.isSystemOperator ? 'System' : 'Tenant';
    }

    securityLevelChanged = () => {
        if (!this.operatorTopologyTabElement) {
            return;
        }
        this.operatorTopologyTabElement.updateLinkList();
        this.operatorTopologyTabElement.updateGroupList();
    };
}


