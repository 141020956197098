import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {AcDropDownComponent, CachedConnection, SessionStorageService} from 'ac-infra';


export type FilterStateType = 'pinned' | 'unpinned';

export interface FilterChangedProperties {
    type: string;
    filter: any;
}

@Injectable({providedIn: 'root'})
export class FilterState {

    static readonly UNPINNED_STATE: FilterStateType = 'unpinned';
    static readonly PINNED_STATE: FilterStateType = 'pinned';

    stateEventMap: any;
    filterOpenedBefore = false;

    private filterChangedSubject: Subject<FilterChangedProperties> = new Subject<FilterChangedProperties>();
    filterChanged$: Observable<FilterChangedProperties> = this.filterChangedSubject.asObservable();

    private topologyFilterChangedSubject: Subject<any> = new Subject<any>();
    topologyFilterChanged$ = this.topologyFilterChangedSubject.asObservable();

    private timeRangeFilterChangedSubject: Subject<any> = new Subject<any>();
    timeRangeFilterChanged$ = this.timeRangeFilterChangedSubject.asObservable();

    private setFilterUpdateSubject: Subject<any> = new Subject<any>();
    setFilterUpdate$ = this.setFilterUpdateSubject.asObservable();

    currentFilterDropdown: AcDropDownComponent;

    constructor(private router: Router, private cachedConnection: CachedConnection) {}

    public executeTimeRangeFilterChanged = (item) => this.timeRangeFilterChangedSubject.next(item);

    public executeTopologyFilterChanged = (topology) => this.topologyFilterChangedSubject.next(topology);

    public executeFilterChanged = (filterProp: FilterChangedProperties) => this.filterChangedSubject.next(filterProp);

    public executeFilterUpdate = (filter) => this.setFilterUpdateSubject.next(filter);

    public registerEvents = (stateToEventMap) => this.stateEventMap = stateToEventMap;

    public get = (eventName) => {
        const filter = {};

        this.appendPinnedFilter(filter);
        this.appendUnpinnedFilter(eventName, filter);

        return filter;
    };

    stateStartEventHandler = (toState, filter) => {
        const eventName = this.findMatchingEventName(toState);
        const currentRouteEventName = this.findMatchingEventName(this.router.url);

        if (eventName && filter && filter.auxiliary && filter.auxiliary.filter) {
            const oldUnpinned = SessionStorageService.getData(FilterState.UNPINNED_STATE + eventName) || {};
            const newUnpinned = eventName === currentRouteEventName ? _.extend(oldUnpinned, filter.auxiliary.filter) : filter.auxiliary.filter;
            this.setUnpinned(eventName, newUnpinned);

            const currentFilter = this.get(eventName);
            if (eventName === currentRouteEventName) {
                this.executeFilterUpdate(currentFilter);
            }
        }
    };

    setFilterToStorage = (event: string, filter, savePinned) => {
        savePinned && this.setPinned(this.extractPinnedFilter(filter));
        this.setUnpinned(event, this.extractUnpinnedFilter(filter));
    };

    private appendPinnedFilter = (filter) => {
        this.appendFilter(FilterState.PINNED_STATE, filter, FilterState.PINNED_STATE);
    };

    private appendUnpinnedFilter = (eventName, filter) => {
        this.appendFilter(FilterState.UNPINNED_STATE + eventName, filter, FilterState.UNPINNED_STATE);
    };

    private appendFilter = (storeKey, filter, fieldName) => {
        const partial = SessionStorageService.getData(storeKey) || {};

        Object.getOwnPropertyNames(partial).forEach((type) => {
            filter[type] = filter[type] || {};
            filter[type][fieldName] = partial[type];
        });
    };

    private setPinned(newPinned: {}) {
        const oldPinned = SessionStorageService.getData(FilterState.PINNED_STATE) || {};
        SessionStorageService.setData(FilterState.PINNED_STATE, newPinned);

        this.compareFilters(oldPinned, newPinned);
    }

    private setUnpinned(event: string, newUnpinned: {}) {
        const oldUnpinned = SessionStorageService.getData(FilterState.UNPINNED_STATE + event) || {};
        SessionStorageService.setData(FilterState.UNPINNED_STATE + event, newUnpinned);

        if (event === 'NetworkFilter') {
            this.compareFilters(oldUnpinned, newUnpinned);
        }
    }

    private findMatchingEventName = (stateName) => {
        let parentState;

        Object.getOwnPropertyNames(this.stateEventMap).forEach((state) => {
            if (stateName.startsWith(state)) {
                parentState = (!parentState || (state.length > parentState.length)) ? state : parentState;
            }
        });

        if (parentState) {
            return this.stateEventMap[parentState];
        }
    };

    private extractPinnedFilter = (filter) => this.extractFilter(filter, FilterState.PINNED_STATE);

    private extractUnpinnedFilter = (filter) => this.extractFilter(filter, FilterState.UNPINNED_STATE);

    private extractFilter = (filter, pinType) => {
        const extracted = {};

        Object.getOwnPropertyNames((filter || {})).forEach((type) => {
            if (filter[type][pinType]) {
                extracted[type] = filter[type][pinType];
            }
        });

        return extracted;
    };

    private compareFilters(oldFilter: any, newFilter: any) {
        if (!_.isEqual(oldFilter.timeRange, newFilter.timeRange)) {
            // Update status
            this.cachedConnection.filteredUpdate(undefined, 'updateStatus');
        }
        delete oldFilter.timeRange;
        delete newFilter.timeRange;
        if (!_.isEqual(oldFilter, newFilter)) {
            // Update filteredIds
            this.cachedConnection.filteredUpdate(undefined, 'updateFilteredIds');
        }
    }

}
