import {Injectable} from '@angular/core';
import {DevicesRestService} from '../apis/devices-rest.service';
import {SitesRestService} from '../apis/sites-rest.service';
import {LoggerService, SessionStorageService} from 'ac-infra';
import {CombinedNetworkRestService} from '../apis/combined-network-rest.service';
import {MessagesService} from '../../../common/utilities/messages.service';

@Injectable({providedIn: 'root'})
export class CombinedNetworkActionsService {

    constructor(private devicesRestService: DevicesRestService,
                private sitesService: SitesRestService,
                private loggerService: LoggerService,
                private messagesService: MessagesService,
                private combinedNetworkRestService: CombinedNetworkRestService) {
    }

    updateShapeLocations = (deviceLocationStateKey) => {
        const shapesToMove = SessionStorageService.getData(deviceLocationStateKey);

        const success = () => {
            SessionStorageService.setData(deviceLocationStateKey, []);
        };

        if (Array.isArray(shapesToMove) && shapesToMove.length > 0) {
            this.combinedNetworkRestService.setNetworkCoordinates(success, success, shapesToMove);
        }
    };

    revertShapeLocations = (deviceLocationStateKey) => {
        SessionStorageService.setData(deviceLocationStateKey, []);
    };

    isUpdateShapeLocationsNeeded = (deviceLocationStateKey) => {
        const shapesToMove = SessionStorageService.getData(deviceLocationStateKey);
        return (Array.isArray(shapesToMove) && shapesToMove.length > 0);
    };
}
