<ng-container *ngFor="let feature of licenseObj | keyvalue : generalService.noCompare">
    <ng-container *ngIf="tenantLicenseAllocation[feature.key]">
        <ac-form-group *ngIf="!hideHeader" [titleText]="feature.value" class="no-padding">
            <ng-template [ngTemplateOutletContext]="{feature: feature}" [ngTemplateOutlet]="allocations"></ng-template>
        </ac-form-group>

        <ng-template *ngIf="hideHeader" [ngTemplateOutletContext]="{feature: feature}"
                     [ngTemplateOutlet]="allocations"></ng-template>
    </ng-container>

</ng-container>

<ng-template #allocations let-feature="feature">
    <ng-container *ngFor="let item of LicenseOrderList[feature.key] | keyvalue : generalService.noCompare">
        <allocation (spinnerChanged)="tenantLicenseAllocation[feature.key][item.key] = $event;
                                    spinnerChanged(item.key, feature.key, tenantLicenseAllocation[feature.key][item.key])"
                    [allocationBarWithInput]="true"
                    [alternativeName]="buildAlternativeName('licenseAllocations.' + feature.key + '.', item.key)"
                    [barTitle]="item.value"
                    [barTotal]="getTotalLicensesValue(feature.key, item.key)"
                    [barUsed]="getUsedLicensesValue(feature.key, item.key)"
                    [containerLabel]="item.value"
                    [hideAllocTitle]="true"
                    [inputMaxValue]="getMaxLicensesValue(feature.key, item.key)"
                    [inputModel]="tenantLicenseAllocation[feature.key][item.key]"
                    class="license-allocation">
        </allocation>
    </ng-container>
</ng-template>


