
<ng-select #ngSelectElement
           (change)="ngSelectChanged()"
           (close)="onCloseEvent()"
           (search)="searchTerm = $event?.term"
           (focus)="isFocused = true"
           (blur)="isFocused = false; onBlur()"
           (ngModelChange)="selectionChanged($event)"
           (open)="onOpenEvent()"
           [addTag]="addCustomTags ? addTag : false"
           [appendTo]="'body'"
           [attr.id]="selectId"
           [bindLabel]="bindLabel"
           [bindValue]="bindValue"
           [clearAllText]="true"
           [clearSearchOnAdd]="true"
           [clearable]="allowClear && _acModel"
           [closeOnSelect]="decideCloseOnSelect()"
           [disabled]="isSelectDisabled()"
           [groupBy]="groupBy"
           [hideSelected]="multiple"
           [isOpen]="isOpen !== undefined ? isOpen : null"
           [searchFn]="isOpen === false ? noSearch : null"
           [items]="_items"
           [markFirst]="!addCustomTags"
           [maxSelectedItems]="maxSelectedItems || 'none'"
           [multiple]="multiple"
           [class.clearable]="allowClear"
           [class.is-multiple]="multiple"
           [class.open-dropdown-right]="openRight"
           [class.model-is-not-empty]="_acModel"
           [ngModel]="_acModel"
           [ngStyle]="{'width': width?width + 'px': ''}"
           [placeholder]="acInputContainerComponent?.inputText && !acInputContainerComponent?.borderAppearance ? (acInputContainerComponent.inputText + acInputContainerComponent.isRequired()) : placeholder"
           [searchable]="searchable!==false"
           [selectOnTab]="false"
           [virtualScroll]="true"
           [appearance]="acInputContainerOldComponent ? 'outline' : 'underline'"
           class="ng-select-opened">


    <!--###################### MULTIPLE SELECTED VALUES ########################-->
    <ng-template *ngIf="multiple" let-clear="clear" let-items="items" ng-multi-label-tmp>
        <div *ngFor="let item of items; let i = index" [attr.data-index]="i"
             [class.error-in-element]="(!acFormComponent || acFormComponent.formSettings.touched) && addCustomTags && acFormComponent?.formValidator && validateItem(i)"
             class="ng-value pointerEventsNone">
            <div acLabel [postfixTitleInNewRow]="addCustomTags ?
                      ((!acFormComponent || acFormComponent.formSettings.touched) && (acFormComponent?.formValidator[selectionName + '.' + i]?.errors?.message)) :
                      (titleForSelectedItemsCallback?titleForSelectedItemsCallback(item) : null)"
                       class="ng-value-label flexItemBiggest"
                       parentSelectorForTitle=".ng-value"
                       parentSelectorForWidth=".ng-value-container">
                <ng-template *ngTemplateOutlet="itemContentTemplate; context: {$implicit: item}"></ng-template>
            </div>
            <i (click)="clear(item)" aria-hidden="true" class="ng-value-icon fa fa-times right flexItem"></i>
        </div>
    </ng-template>

    <!--###################### SINGLE SELECTED VALUE ########################-->
    <ng-template let-item="item" ng-label-tmp>
        <div class="ng-value pointerEventsNone" style="width: 100%;display: flex">
            <div *ngIf="displayImage" class="ng-select-optionImage">
                <img *ngIf="item.image" [src]="item.image" class="selectedImage" height="15" width="15"/>
                <ac-svg *ngIf="item.svgName" [height]="item.svgHeight" [name]="item.svgName"
                        [width]="item.svgWidth"></ac-svg>
            </div>
            <div acLabel parentSelectorForTitle=".ng-value-container">
                <ng-template *ngTemplateOutlet="itemContentTemplate; context: {$implicit: item}"></ng-template>
            </div>
        </div>
    </ng-template>

    <!--###################### OPTIONS LIST BUTTONS FOOTER ########################-->
    <ng-template *ngIf="showFooter()" ng-footer-tmp>
        <div *ngIf="showSelectedCount" class="selectedCountContainer">
            Selected count: {{getSelectedCount()}}
        </div>

        <ng-template #notFilter>
            <div class="flexRowParent">
                <div *ngIf="showAllButton()" class="flexItemRowEquals">
                    <ac-button (acClick)="setAll()">All</ac-button>
                </div>
                <div *ngIf="setClearButton()" [class.clear-button-alone]="addCustomTags"
                     class="flexItemRowEquals">
                    <ac-button (acClick)="setNone()">Clear</ac-button>
                </div>
                <div *ngIf="showInvertButton()" class="flexItemRowEquals">
                    <ac-button (acClick)="invertAll()">Invert</ac-button>
                </div>
            </div>
        </ng-template>
        <div *ngIf="showAllFilteredButton(); else notFilter" class="flexRowParent">
            <div class="flexItemRowEquals">
                <ac-button (acClick)="setAllFiltered()">All Filtered</ac-button>
            </div>
        </div>
    </ng-template>

    <!--###################### OPTIONS LIST ########################-->
    <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <div [attr.title]="item.disabled ? optionTitle : null"
             [class.pointerEventsNone]="!item.disabled || optionTitle===''"
             class="flexRowParent {{dropDownOptionsClass}}">
            <div *ngIf="displayImage" class="ng-select-optionImage">
                <img *ngIf="item.image" [src]="item.image" class="optionImage" height="15" width="15"/>
                <ac-svg *ngIf="svgFixedName || item.svgName" [height]="svgFixedHeight || item.svgHeight" [name]="svgFixedName || item.svgName"
                        [width]="svgFixedWidth || item.svgWidth"></ac-svg>
            </div>

            <div acLabel
                 class="ac-option"
                 parentSelectorForTitle=".ng-option"
                 [attr.id]="item | acFunc: getTextValue | acFunc: generalService.generateIdForText">
                <ng-template *ngTemplateOutlet="itemContentTemplate; context: {$implicit: item}"></ng-template>
            </div>
        </div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
        <div *ngIf="!labelTemplate" acLabel parentSelectorForTitle=".ng-value-container">{{item | acFunc: getTextValue}}</div>
        <ng-template *ngTemplateOutlet="labelTemplate; context: {$implicit: item}"></ng-template>
    </ng-template>
</ng-select>

<ng-template let-item #itemContentTemplate>
    <span *ngIf="!itemTemplate" [class.visibilityHidden]="(item | acFunc: getTextValue : true) === 'hide'">{{item | acFunc: getTextValue: true}}</span>
    <ng-template *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-template>
</ng-template>

<div *ngIf="placeholder && acInputContainerComponent && acInputContainerComponent.inputText && (!_acModel || _acModel === '') && (!isFocused || (isFocused && !searchTerm))" class="ac-select-placeholder">
    {{placeholder}}
</div>
