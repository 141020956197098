import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {ConstantStringsService} from '../../../common/utilities/constant-strings.service';

@Component({
    selector: 'network-cluster-popup',
    templateUrl: './network-cluster-popup.component.html',
    styleUrls: ['./network-cluster-popup.component.less'],

})
export class NetworkClusterPopupComponent {

    @Input() dataItem: any;

    visibleSection = '';
    objCount = 0;
    obj;

    constructor(private cdr: ChangeDetectorRef) {
        this.obj = {
            devices: {TOTAL: 0, ERROR: 0, WARNING: 0, dataItems: [], title: ConstantStringsService.deviceOrCustomerTeamsMany},
            links: {TOTAL: 0, ERROR: 0, WARNING: 0, dataItems: [], title: ConstantStringsService.linkOrCustomerProviderMany},
            sites: {TOTAL: 0, ERROR: 0, WARNING: 0, dataItems: [], title: 'sites'}
        };
    }

    ngOnInit() {

        if (this.dataItem.getAllChildMarkers) {
            this.dataItem.getAllChildMarkers().forEach((item) => {
                if (this.isDevice(item)) {
                    this.obj.devices[item.device.status]++;
                    this.obj.devices.TOTAL++;
                    this.obj.devices.dataItems.push(item);
                } else {
                    this.obj.sites[item.device.status]++;
                    this.obj.sites.TOTAL++;
                    this.obj.sites.dataItems.push(item);
                }
            });
        }

        this.dataItem.innerLinks.forEach((link) => {
            this.obj.links[link.status]++;
            this.obj.links.TOTAL++;
            this.obj.links.dataItems.push(link);
        });

        if (this.obj.links.TOTAL === 0) {
            delete this.obj.links;
        }
        if (this.obj.devices.TOTAL === 0) {
            delete this.obj.devices;
        }
        if (this.obj.sites.TOTAL === 0) {
            delete this.obj.sites;
        }

        this.objCount = Object.keys(this.obj).length;
    }

    isNotEmpty = (value) => value.TOTAL === 'Total' || value.TOTAL > 0 || value.ERROR > 0 || value.WARNING > 0;


    // getObjToPass = (key, value) => {
    //     return {
    //         dataItemsKey: key,
    //         dataItems: value.dataItems
    //     };
    // };
    openSection(key) {
        this.visibleSection = key;
    }

    isDevice = (item) => item.device.productType;
}
