import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {environment} from '../../projects/infra/src/environments/environment';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: '',
        loadChildren: () => import('./ovoc/ovoc.module').then(m => m.OVOCModule),
    },
    {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
    imports: [
        QuicklinkModule,
        RouterModule.forRoot(
            routes,
            {
                preloadingStrategy: QuicklinkStrategy,
            },
        ) // , {enableTracing: true}) // <-- debugging purposes only)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
