import {Component, Inject, SkipSelf} from '@angular/core';
import * as _ from 'lodash';
import {AcDialog, AcDialogComponent, DIALOG_CONFIG, DialogConfig} from 'ac-infra';
import {
    ServerConfigurationActionService
} from "../../../system/administration/administration-actions/server-configuration-action.service";

@AcDialog({
    id: 'customer-dialog',
    title: 'Customer Details',
    cancelButtonText: 'Close',
    width: 600,
})
@Component({
    selector: 'network-customer-dialog',
    templateUrl: './network-customer-dialog.component.html',
})
export class NetworkCustomerDialogComponent {

    customer: any = {};
    schema: any = {};
    isEdit = true;
    customerAddSchema = require('../../../../schema/post/customerprocessor.json');
    customerEditSchema = require('../../../../schema/put/customerprocessor.json');
    additionalSettings;

    supportedCustomerSourceTypes = ['LTC', 'OC', 'ZOOM'];

    constructor(@SkipSelf() private acDialogComponent: AcDialogComponent,
                @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
                public serverConfigurationActionService: ServerConfigurationActionService)  {}

    ngOnInit() {
        this.schema = _.cloneDeep(this.customerEditSchema);
        this.customer = this.dialogConfig.dialogData.entity;
        this.additionalSettings = _.cloneDeep(this.customer);
    }

}





