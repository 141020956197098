<div>
    <div *ngIf="_type==='CUSTOMER'">
        <div class="filter-by-field">Filter By Field</div>
        <ac-radio-buttons-group [acModel]="customerFilter" class="filter-radio-buttons"
                                (acModelChange)="customerFilterTypeChanged($event);"
                                [items]="customerFilterTypes">
        </ac-radio-buttons-group>
    </div>
    <ac-input-container label="{{label | titlecase}}" *ngIf="isVisible" [fullWidthContent]="!!networkGroupsFilterComponent">
        <ac-select [acModel]="model"
                   (acModelChange)="acChanged.emit($event)"
                   [attr.alternativeName]="alternativeName"
                   [multiple]="isMultiple"
                   [bindLabel]="'name'"
                   [bindValue]="'id'"
                   [items]="items"
                   [ngDisabled]="ngDisabled"
                   [sortItems]="sortItems"
                   [searchable]="true">
        </ac-select>
    </ac-input-container>
</div>
