import {Component, HostListener, Injector, Type, ViewChild, ViewContainerRef, ViewRef,} from '@angular/core';

import {AcDialogRef, DIALOG_CONFIG, DIALOG_DATA, DialogConfig} from '../ac-dialog.models';
import {Observable, Subject} from 'rxjs';

@Component({
    selector: 'ac-dialog-host',
    templateUrl: './ac-dialog-host.component.html',
})
export class AcDialogHostComponent {

    @ViewChild('dialogHost', {read: ViewContainerRef, static: true}) public dialogHost: ViewContainerRef;

    escape$: Observable<ViewRef>;
    private escapeSubject = new Subject<ViewRef>();

    constructor(private injector: Injector) {
        this.escape$ = this.escapeSubject.asObservable();
    }

    createDialog(acDialogContentComponent: Type<any>, dialogConfig: DialogConfig): AcDialogRef {
        const acDialogRef = new AcDialogRef();
        const customInjector = Injector.create({
            providers: [
                {provide: DIALOG_CONFIG, useValue: dialogConfig},
                {provide: DIALOG_DATA, useValue: dialogConfig.dialogData},
                {provide: AcDialogRef, useValue: acDialogRef},
                {provide: AcDialogHostComponent, useValue: this}
            ],
            parent: this.injector
        });

        const dialogRef = this.dialogHost.createComponent(acDialogContentComponent, {
            injector: customInjector
        });

        dialogRef.onDestroy(() => {
            acDialogRef.clear();
        });

        acDialogRef.componentRef = dialogRef;
        return acDialogRef;
    }

    @HostListener('document:keydown.escape')
    setCloseTargetRef() {
        const escapeTargetRef = this.dialogHost.get(this.dialogHost.length - 1);

        escapeTargetRef && this.escapeSubject.next(escapeTargetRef);
    }

    removeDialog(componentHostView: ViewRef) {
        const index = this.dialogHost.indexOf(componentHostView);

        index >= 0 && index < this.dialogHost.length && this.dialogHost.remove(index);
        return index;
    }

    clearHost() {
        this.dialogHost.clear();
    }
}
