import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() {
        //
    }

    public static setData(key: string, data: any) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
        }
    }

    public static getData(key: string) {
        try {
            const item = localStorage.getItem(key);
            const json = JSON.parse(item);
            return json === null ? undefined : json;
        } catch (e) {
        }
    }

    public static removeData(key: string) {
        localStorage.removeItem(key);
    }

    public static getAllRecords() {
        const keys = Object.keys(localStorage);
        const records = [];
        keys.forEach(key => {
            const item = localStorage.getItem(key).trim();
            if (item[0] === '{' || item[0] === '[') {
                const record = JSON.parse(item);
                records.push(record);
            }
        });

        return records;
    }

    public static cleanOldRecords() {
        const timestampNow = Date.now();
        const keys = Object.keys(localStorage);
        const records = [];
        keys.forEach(key => {
            const item = localStorage.getItem(key).trim();

            if (key.includes('loglevel')) {
                this.removeData(key);
                return;
            }
            if (item[0] === '{' || item[0] === '[') {
                const record = JSON.parse(item);
                const diffInDays = Math.abs(timestampNow - record.timestamp) / (1000 * 60 * 60 * 24);
                if (diffInDays > 30) {
                    this.removeData(key);
                } else {
                    records.push(record);
                }
            }
        });

        return records;
    }


}
