import {Injectable} from '@angular/core';
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject';
import {AcDialogRef, AcDialogService, CachedConnection, ExceptionService, PromiseService, RestServerUrlService, SessionService, statuses, WSMessage} from 'ac-infra';
import {WebSocketNotification} from './ws.notification.service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {ExternalApplication} from '../../state/external-application.actions';
import {wsConnectionLostDialogComponent} from '../../../dialogs/ws-connection-lost-dialog/ws-connection-lost-dialog.component';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class WebSocketService {
    pingInterval;
    pongArrived = true;
    reconnectAfterServerRefused = false;
    retryTimeout;
    retryCounter = new BehaviorSubject<number>(1);
    wsConnectionLostDialogRef: AcDialogRef;
    readonly RETRIES = 20;
    readonly RETRY_INTERVAL = 5000;
    private socket$: WebSocketSubject<any>;

    constructor(private sessionService: SessionService,
                private webSocketNotification: WebSocketNotification,
                private cachedConnection: CachedConnection,
                private restServerURLService: RestServerUrlService,
                private acDialogService: AcDialogService,
                private exceptionService: ExceptionService,
                private router: Router,
                private store: Store) {
        // setTimeout(() => {
        //     this.parseMessage({
        //         "protocol": "v1",
        //         "messageType": "Notifications",
        //         "name": "Gateway Administrative State Changed",
        //         "description": "[Device 2] Administrative state is unlocked",
        //         "module": "Alarms",
        //         "status": "critical",
        //         "entityType": "Alarm",
        //         "entities": [{"entityId": 7855, "tenantId": 413}]
        //     });
        // }, 5000)
    }

    hasActiveSocket = () => !!this.socket$;

    openWsConnectionDialog() {
        if (this.wsConnectionLostDialogRef) {
            return;
        }
        this.wsConnectionLostDialogRef = this.acDialogService.open(wsConnectionLostDialogComponent, {
            dialogData: {
                retryCounter: this.retryCounter.asObservable(),
                retries: this.RETRIES,
            }
        });
    }

    closeWsConnectionDialog() {
        const dialogRef = this.wsConnectionLostDialogRef;
        this.wsConnectionLostDialogRef = null;

        if (dialogRef) {
            dialogRef.dialogConfig.onCancel = undefined;
            dialogRef.close();
        }
    }

    connect = (connectionEstablished?) => {
        const defer = PromiseService.defer();

        if (this.socket$) {
            defer.resolve(true);
            return defer.promise;

        }
        this.pongArrived = true;

        if (this.sessionService.activeSession) {
            this.socket$ = new WebSocketSubject(
                {
                    url: this.restServerURLService.getWebSocketServerURL(),
                    openObserver: {
                        next: this.onOpen
                    },
                    closeObserver: {
                        next: this.onClose
                    },

                });

            this.socket$
                .subscribe(
                    (message) => {
                        // MESSAGES WHILE CONNECTED
                        if (!connectionEstablished) {
                            this.socket$.next('SessionID='+this.sessionService.activeSession.sessionId);
                            defer.resolve(true);
                        }
                        connectionEstablished = true;
                        this.reconnectAfterServerRefused = false;

                        this.closeWsConnectionDialog();
                        this.retryCounter.next(1);
                        try {
                            this.parseMessage(message);
                        } catch (ex) {
                            this.exceptionService.checkError(ex);
                        }
                    },
                    (err) => {
                        // ERROR CONNECTING OR WHILE CONNECTED (server shut down abruptly)
                        if (!connectionEstablished) {
                            defer.reject('error');
                        } else if (err && err.reason && err.reason === 'Invalid session') {
                            this.logout();
                        } else {
                            if (this.sessionService.activeSession) {
                                this.openWsConnectionDialog();
                                this.reconnectAfterServerRefused = true;
                                this.reconnect(err);
                            }
                        }
                    },
                    () => {
                        // CLOSED BY SERVER (token wrong/expired)
                        if (this.reconnectAfterServerRefused) {
                            console.warn('WS Disconnected Logging out!');
                            this.logout();
                            defer.reject('expired');
                        } else if (this.sessionService.activeSession) {
                            console.log('WS Closed Reconnecting...');
                            this.openWsConnectionDialog();
                            this.reconnectAfterServerRefused = true;
                            this.reconnect(); // 'CLOSED BY SERVER (token wrong/expired)'
                        }
                    }
                );

        } else if (!connectionEstablished) {
            defer.reject();
        }
        return defer.promise;
    };

    disconnect = (stopRetry = true) => {
        if (stopRetry) {
            clearTimeout(this.retryTimeout);
        }
        clearInterval(this.pingInterval);
        this.pongArrived = true;
        this.clearSocket();
    };

    onOpen = (val: any) => {
        clearInterval(this.pingInterval);
        this.pingInterval = setInterval(this.doPing, 30000);
        this.doPing();
    };

    onClose = (val: any) => {
        if (val.reason) {
            this.disconnect();
        }
    };

    // public send(message: Message): void {
    //     this.socket$.next(message);
    // }

    private logout = () => {
        this.sessionService.endSession();
        this.router.navigateByUrl('login');
    };

    private parseMessage = (_message: any) => {
        if (_message === 'pong') {
            this.pongArrived = true;
            return;
        }
        const message: WSMessage = _.assign(new WSMessage(), _message);
        message.prepareMessage();

        if (message.messageType === statuses.Notifications) {
            this.webSocketNotification.WSNotificationArrived.next(message);
        } else if (message.entityTypeName === 'externalApplications') {
            this.store.dispatch(new ExternalApplication.FetchWS());
        } else {
            this.cachedConnection.wsMessageArrived(message);
        }
    };

    private reconnect = (err?: any, instant?) => {
        console.warn('WS RECONNECT', err);
        if (this.sessionService.activeSession) {
            this.disconnect(true);

            if (this.retryCounter.getValue() === this.RETRIES) {
                this.closeWsConnectionDialog();
                this.exceptionService.reportException({type: 'WSServerException', message: 'Connection to Server Lost'});
                return;
            }

            this.retryTimeout = setTimeout(() => {
                this.retryCounter.next(this.retryCounter.getValue() + 1);
                this.clearSocket();
                this.connect(true);
            }, this.retryCounter.getValue() === 1 || instant ? 0 : this.RETRY_INTERVAL);

        }
    };

    private clearSocket = () => {
        if (this.socket$) {
            this.socket$.unsubscribe();
            this.socket$ = undefined;
        }
    };

    ngOnDestroy() {
        clearInterval(this.pingInterval);
    }

    private doPing = () => {
        if (this.pongArrived === false) {
            console.warn('WS, NO PONG FOR PING');
            this.disconnect(false);
            this.logout();
            return;
        }
        this.pongArrived = false;
        this.socket$.next('ping');
    };
}
