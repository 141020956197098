import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
    ViewRef
} from '@angular/core';

import {AC_DIALOG_ANNOTATION, AcDialogRef, DIALOG_CONFIG, DialogConfig} from './ac-dialog.models';

import {AcDialogHostComponent} from './ac-dialog-host/ac-dialog-host.component';
import {AcDialogComponent} from './ac-dialog.component';

import {FailDialogComponent} from './ac-dialogs/failed-dialog/failed-dialog.component';
import {InfoDialogComponent} from './ac-dialogs/info-dialog/info-dialog.component';
import {ErrorDialogComponent} from './ac-dialogs/error-dialog/error-dialog.component';
import {AboutDialogComponent} from './ac-dialogs/about-dialog/about-dialog.component';
import {ConfirmDialogComponent} from './ac-dialogs/confirm-dialog/confirm-dialog.component';


@Injectable({providedIn: 'root'})
export class AcDialogService {

    dialogContainerComponentRef: ComponentRef<AcDialogHostComponent>;
    dialogState: { [key: string]: boolean } = {};
    currentlyOpenedFailDialogMessages = [];

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private appRef: ApplicationRef,
                private injector: Injector) {
        this.initDialogsContainer();
    }

    open(acDialogContentComponent?: Type<any>, dialogConfig: DialogConfig = {}): AcDialogRef {
        const acDialogRef = this.dialogContainerComponentRef.instance.createDialog(AcDialogComponent, dialogConfig);

        acDialogContentComponent && this.setDialogContent(acDialogRef, acDialogContentComponent);

        acDialogRef.onClose$?.subscribe({
            next: () => {
                setTimeout(() => this.closeDialog(acDialogRef));
            },
            complete: () => {
                const dialogConfigRef = acDialogRef.dialogConfig;
                this.setDialogState(dialogConfigRef, !!dialogConfigRef.keepState);
            }
        });

        return acDialogRef;
    }

    setDialogContent(acDialogRef: AcDialogRef, acDialogContentComponent: Type<any>, dialogConfig?: DialogConfig): DialogConfig {
        const dialogConfigRef = acDialogRef.dialogInjector.get(DIALOG_CONFIG);

        if (!dialogConfigRef) {
            console.error(`No dialog container for ${acDialogContentComponent.name}`);
            return;
        }

        Object.assign(dialogConfigRef, {...(acDialogContentComponent[AC_DIALOG_ANNOTATION] || {}), ...dialogConfigRef});

        dialogConfigRef && Object.assign(dialogConfigRef, {
            ready: true,
            name: acDialogContentComponent.name,
            ...dialogConfig
        });

        if (this.dialogState[dialogConfigRef.id] && !dialogConfigRef.multiInstanceAllowed) {
            dialogConfigRef.keepState = true;
            this.closeDialog(acDialogRef);
            return;
        }

        this.setDialogState(dialogConfigRef, true);

        acDialogRef.dialogInstance.createDialogContent(acDialogContentComponent);

        return dialogConfigRef;
    }

    setDialogState(dialogConfig: DialogConfig, state: boolean) {
        if (dialogConfig.multiInstanceAllowed) {
            return;
        }
        this.dialogState[dialogConfig.id] = state;
    }

    closeDialog(dialogRef: AcDialogRef) {
        const hostInstance = this.dialogContainerComponentRef.instance;

        return hostInstance.removeDialog(dialogRef.dialogHostView); // $keydownEvent ? hostInstance.removeLastDialog(componentHostView) : ;
    }

    closeAllDialogs() {
        this.dialogState = {};
        this.dialogContainerComponentRef.instance.clearHost();
    }

    about(logoForViewTemplate, showAudioCodesImage) {
        return this.open(AboutDialogComponent, {dialogData: {logoTemplate: logoForViewTemplate, showAudioCodesImage}});
    }

    confirm(message: string, config: DialogConfig = {}) {

        config.submitButtonText = config.submitButtonText || 'Confirm';
        config.dialogData = config.dialogData || {};
        config.dialogData.message = message;

        return this.open(ConfirmDialogComponent, config);
    }

    fail(message: string, {dialogData, onClose, ...config}: DialogConfig = {}): AcDialogRef {
        message = (typeof message === 'string') ? message : 'User action failed';

        if (this.currentlyOpenedFailDialogMessages.includes(message)) {
            return;
        }
        this.currentlyOpenedFailDialogMessages.push(message);

        return this.open(FailDialogComponent, {
            dialogData: {...dialogData, message},
            ...config,
            onClose: () => {
                const indexOfMessage = this.currentlyOpenedFailDialogMessages.indexOf(message);
                this.currentlyOpenedFailDialogMessages.splice(indexOfMessage, 1);
                onClose && onClose();
            }
        });
    }

    info(message: string, {dialogData, ...config}: DialogConfig = {}) {

        const dialogConfig: DialogConfig = {
            dialogData: {...dialogData, message},
            ...config,
        };

        return this.open(InfoDialogComponent, dialogConfig);
    }

    error(exception: string, {dialogData, ...config}: DialogConfig = {}) {

        const dialogConfig: DialogConfig = {
            dialogData: {...dialogData, exception},
            ...config,
        };

        return this.open(ErrorDialogComponent, dialogConfig);
    }

    private initDialogsContainer() {
        const hostComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AcDialogHostComponent);
        const componentRef = hostComponentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.dialogContainerComponentRef = componentRef;
    }
}
