import {Directive, Inject, Input, TemplateRef} from '@angular/core';
import {DIALOG_CONFIG, DialogConfig} from '../ac-dialog.models';


@Directive({selector: '[ac-dialog-actions], [acDialogActions]'})
export class AcDialogActionsDirective {

    @Input('ac-dialog-actions') set extendActions(extendActions: boolean) {
        this.dialogConfig.actionsTemplateExtended = extendActions;
    }

    constructor(
        @Inject(DIALOG_CONFIG) public dialogConfig: DialogConfig,
        private templateRef: TemplateRef<any>,
    ) {

        dialogConfig.actionsTemplate = templateRef;
    }
}
