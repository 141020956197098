<mat-accordion>
    <ac-expansion-panel [classes]="'operator-connect-expansion-panel ' + (cardHasError ? 'cardHasError' : '')"
                        [expanded]="true"
                        [headerTemplate]="headerTemplate"
                        [descriptionTemplate]="removeButton"
                        [descriptionObject]="{index: index, type: 'operatorContacts'}">
        <div>
            <ac-input-container label="Type">
                <ac-single-select [(acModel)]="contactObject.operatorContactType" (click)="$event.stopPropagation()"
                           (acModelChange)="operatorContactTypeWasSelected.emit($event)"
                           [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.operatorContactType'"
                           [items]="operatorContactTypesList"
                           bindLabel="viewName" bindValue="name">
                </ac-single-select>
            </ac-input-container>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Title" [alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.title'">
                    <ac-textfield [(acModel)]="contactObject.title"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-title' + index">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Display Name">
                    <ac-textfield [(acModel)]="contactObject.displayName"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.displayName'"
                                  textFieldId="tenant-operatorConnectInfo-operatorContacts-displayName">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="First Name">
                    <ac-textfield [(acModel)]="contactObject.firstName"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.firstName'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-firstName' + index">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Last Name">
                    <ac-textfield [(acModel)]="contactObject.lastName"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.lastName'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-lastName' + index">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Email">
                    <ac-textfield [(acModel)]="contactObject.email"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.email'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-email' + index">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="SIP Alias">
                    <ac-textfield [(acModel)]="contactObject.sipAlias"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.sipAlias'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-sipAlias' + index">
                    </ac-textfield>
                </ac-input-container>
            </div>

            <div class="flexRowParentEqualChildren">
                <ac-input-container label="Phone Number">
                    <ac-textfield [(acModel)]="contactObject.phoneNumber"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.phoneNumber'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-phoneNumber' + index">
                    </ac-textfield>
                </ac-input-container>

                <ac-input-container label="Second Phone Number">
                    <ac-textfield [(acModel)]="contactObject.secondPhoneNumber"
                                  [attr.alternativeName]="'tenant.operatorConnectInfo.operatorContacts.' + index + '.secondPhoneNumber'"
                                  [textFieldId]="'tenant-operatorConnectInfo-operatorContacts-secondPhoneNumber' + index">
                    </ac-textfield>
                </ac-input-container>
            </div>
        </div>
    </ac-expansion-panel>

    <ng-template #headerTemplate>
        <div class="contact-panel-header">
            {{contactObject.title || ('Operator Contact ' + (index + 1))}}
        </div>
    </ng-template>
</mat-accordion>


