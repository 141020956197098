import {GeneralService} from 'ac-infra';

export const DiagramConstants = {
    COLORS: {
        grey: GeneralService.statusColors.grayStatus,
        blue: GeneralService.statusColors.blueStatus,
        red: GeneralService.statusColors.redStatus,
        orange: GeneralService.statusColors.orangeStatus,
        yellow: GeneralService.statusColors.yellowStatus,
        purple:  GeneralService.statusColors.highlightColor,
        black: '#000000'
    },

    DASH_TYPES: {
        solid: 'solid',
        dash: 'dash',
        dashDot: 'dashDot',
        dot: 'dot',
        longDash: 'longDash',
        longDashDot: 'longDashDot',
        longDashDotDot: 'longDashDotDot'
    },

    CONNECTION_WIDTH_TYPES: {
        thin: 0.2,
        regular: 1,
        thick: 3
    },

    textBoxBorderColor: '#B4B4B4',

    // new start
    MED_ICON_WIDTH: 44,
    MED_ICON_HEIGHT: 44,

    MED_ICON_WIDTH_SELECTED: 55,
    MED_ICON_HEIGHT_SELECTED: 55,

    SELECT_SHADOW_IMAGE_WIDTH: 75,
    SELECT_SHADOW_IMAGE_HEIGHT: 75,

    HOVER_SHADOW_OF_SELECTED_IMAGE_WIDTH: 85,
    HOVER_SHADOW_OF_SELECTED_IMAGE_HEIGHT: 85,

    HOVER_SHADOW_IMAGE_WIDTH: 65,
    HOVER_SHADOW_IMAGE_HEIGHT: 65,

    DISTANCE_FROM_CHEVRON_TO_ICON: 1,

    SMALL_ICON_WIDTH: 14,
    SMALL_ICON_HEIGHT: 14,

    SHAPE_CENTER_POINT: {
        x: 27.5, y: 27.5
    },

    CLUSTER_OFFSET: {
        x: 30.5, y: 32
    },

    // new end
    BASE_IMAGE_PATH: 'assets/images/diagram/',

    Y_SOURCE_CONNECTION_FIX_FOR_SMALL_ICON: -5,

    TEXT_BOX_WIDTH: 116,
    TEXT_BOX_HEIGHT: 25,
    TEXT_BOX_CHEVRON_WIDTH: 10,
    TEXT_BOX_CHEVRON_HEIGHT: 6,
    TEXT_MARGIN: 10,

    MIN_DISTANCE_WIDTH_FROM_BOX_BORDER_TO_TEXT: 4,

    LARGE_ICON_WIDTH: 50,
    LARGE_ICON_HEIGHT: 64,

    CONNECTION_LOCK_CIRCLE_RADIUS: 8.5
};
