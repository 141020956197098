<ac-progress *ngIf="loading || !dialogConfig.ready"></ac-progress>
<div [hidden]="loading || !dialogConfig.ready"
     [attr.id]="dialogConfig.id"
     class="dialog flexColumnParent {{dialogConfig.customClasses?.dialog}}"
     cdkDragBoundary="body"
     cdkDrag
     (cdkDragStarted)="onDrag()"
     #dialog>
    <div class="dialog-header flexItem prevent-text-selection {{dialogConfig.customClasses?.header}}"
         cdkDragHandle
         #windowHeader>

        <div class="{{dialogConfig.elementsColorPalette?.titleColor || dialogConfig.colorPalette}}">
            <ng-container *ngIf="!dialogConfig.titleTemplate">{{dialogConfig.title | uppercase}}</ng-container>
            <ng-template [ngTemplateOutletContext]="dialogConfig"
                         [ngTemplateOutlet]="dialogConfig.titleTemplate"
            ></ng-template>
        </div>

    </div>

    <div class="dialog-body flexItemBiggest {{dialogConfig.customClasses?.content}}"
         [style.width]="dialogConfig.width + 'px'"
         [style.height]=" dialogConfig.height + 'px'">
        <ng-template #dialogContentHost></ng-template>
    </div>

    <div class="dialog-footer flexItem {{dialogConfig.customClasses?.footer}}" [class.reverse-order]="dialogConfig.reverseButtonsOrder">

        <ng-template [ngTemplateOutlet]="dialogConfig.actionsTemplate"
                     [ngTemplateOutletContext]="dialogConfig"
        ></ng-template>

        <ng-container *ngIf="!dialogConfig.actionsTemplate || dialogConfig.actionsTemplateExtended">

            <ac-button *ngIf="!dialogConfig.noCancelButton"
                       (acClick)="close()"
                       [buttonValue]="dialogConfig.cancelButtonText || 'Cancel'"
                       class="ac-dialog-button {{dialogConfig.colorPalette || dialogConfig.elementsColorPalette?.cancelButtonColor}} clear">
            </ac-button>

            <ac-button *ngIf="!dialogConfig.noSubmitButton && dialogConfig.onSubmit && !allFormsAreInViewMode"
                       (acClick)="onSubmit()"
                       [buttonValue]="dialogConfig.submitButtonText || 'OK'"
                       [ngDisabled]="dialogConfig.submitButtonDisabled || dialogConfig.submitButtonProcessing"
                       class="ac-dialog-button {{dialogConfig.elementsColorPalette?.submitButtonColor || dialogConfig.colorPalette}}">
            </ac-button>
        </ng-container>
    </div>
</div>
