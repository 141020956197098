import {Injectable} from '@angular/core';
import {CachedConnection, WSMessage} from 'ac-infra';
import {Actions} from "../../../common/server-actions/actions";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ServerConfigurationRestService} from "../administration-api/server-configuration-rest.service";
import * as _ from 'lodash';

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class ServerConfigurationActionService extends Actions {

    meteringConfiguration: any;

    constructor(public cachedConnectionService: CachedConnection,
                private serverConfigurationRestService: ServerConfigurationRestService) {
        super({});
        cachedConnectionService.unhandledWsMessage$.pipe(untilDestroyed(this)).subscribe(({messageType}: WSMessage) => {
            if(messageType === 'MeteringSync'){
                this.getMeteringConfiguration();
            }
        })
    }

    getMeteringConfiguration = () => {
        this.serverConfigurationRestService.getMeteringConfiguration().then((meteringConfiguration) => {
            this.meteringConfiguration = _.cloneDeep(meteringConfiguration);
        });
    }
}
