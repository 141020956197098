import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'http-connectivity',
    templateUrl: './http-connectivity.component.html'
})

export class HttpConnectivityComponent {
    @Input() httpObject: any;
    @Output() acChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input() httpObjectName: string;
    @Input() isEdit = undefined;
    @Input() hasDefaultObject = false;
    @Input() deleteWholeHTTPObject = false;
    @Input() hasEditHttpCheckbox = false;
    @Input() componentType;
    originalHTTPObject;
    httpConnectionItems = [{value: 'DISABLED', text: 'HTTP'}, {value: 'ENABLED', text: 'HTTPS'}];
    useDefaultHTTPSettings = true;
    editHttpSettings = true;
    useDefaultSettingsCheckboxTitle;

    ngOnInit() {
        this.originalHTTPObject = _.cloneDeep(this.httpObject);

        if(!this.isEdit){
            const defaultObjectType = this.componentType === 'device' ? 'Tenant' : 'System';// http default http object of device is tenant
            this.useDefaultSettingsCheckboxTitle = 'Use ' + defaultObjectType + ' HTTP Profile';
        }

        if(this.hasEditHttpCheckbox){
            this.editHttpSettings = false;
        }

        this.useDefaultHTTPSettingsChanged();
    }

    httpsConnectionChanged = () => {
        if (this.httpObject.verifyCertificate !== undefined && this.httpObject.httpsConnection === 'DISABLED') {
            this.httpObject.verifyCertificate = false;
        }
    };

    useDefaultHTTPSettingsChanged = () => {
        if(this.hasDefaultObject || this.hasEditHttpCheckbox){
            if ((!this.isEdit && this.useDefaultHTTPSettings) || (this.isEdit && !this.editHttpSettings)) {
                if (this.deleteWholeHTTPObject) {
                    this.acChanged.emit(undefined);
                } else {
                    delete this.httpObject.gatewayUser;
                    delete this.httpObject.gatewayPassword;
                    delete this.httpObject.httpsConnection;
                    delete this.httpObject.verifyCertificate;
                }
            } else {
                if (this.deleteWholeHTTPObject) {
                    this.acChanged.emit(_.cloneDeep(this.originalHTTPObject));
                } else {
                    this.httpObject.gatewayUser = this.originalHTTPObject.gatewayUser;
                    this.httpObject.gatewayPassword = this.originalHTTPObject.gatewayPassword;
                    this.httpObject.httpsConnection = this.originalHTTPObject.httpsConnection;
                    this.httpObject.verifyCertificate = this.originalHTTPObject.verifyCertificate;
                }
            }
        }
    };

}



