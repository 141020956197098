<div class="allocationContainer flexRowParentEqualChildren">
    <ac-input-container *ngIf="showSpinner" [hideLabel]="hideLabel" [label]="containerLabel"
                        [class.invalidAllocation]="((inputModel > inputMaxValue) || (inputModel < inputMinValue))">
        <ac-num-spinner [acModel]="inputModel"
                        (acModelChange)="inputChanged($event)"
                        [attr.alternativeName]="alternativeName"
                        [ignoreMinMax]="true"
                        [minValue]="0">
        </ac-num-spinner>
    </ac-input-container>

    <ac-allocation-bar [hideAllocTitle]="hideAllocTitle"
                       [class.allocation-bar-with-input]="allocationBarWithInput"
                       [header]="barTitle"
                       [total]="barTotal"
                       [used]="barUsed">
    </ac-allocation-bar>
</div>


